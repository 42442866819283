import { useState, useEffect } from "react";
import { useLazyGetScoringSnapshotQuery } from "../../services/cclScoringStatusApi";
import { Typography } from "@mui/material";
import ScoringSnapshotDataGridPro from "./scoringSnapshotDataGridPro";
import {
    ScoringSnapshotResponse,
    ScoringSnapshotItem,
} from "../../services/types/scoringStatusTypes";

export interface ScoringSnapshotProps {}

export const ScoringSnapshot: React.FC<ScoringSnapshotProps> = (props) => {
    const [snapshotItems, setSnapshotItems] = useState<ScoringSnapshotItem[]>([]);
    const [getSnapshot, { data: lazySnapshot, isLoading, isSuccess }] =
        useLazyGetScoringSnapshotQuery();

    useEffect(() => {
        refreshSnapshot();
    }, []);

    const refreshSnapshot = () => {
        getSnapshot()
            .unwrap()
            .then((snapshot: ScoringSnapshotResponse) => {
                setSnapshotItems([...snapshot.items]);
            })
            .catch(() => {});
    };

    if (isLoading || (isSuccess && lazySnapshot != null)) {
        return (
            <ScoringSnapshotDataGridPro
                loading={isLoading}
                snapshotItems={snapshotItems}
                refresh={refreshSnapshot}
            />
        );
    }

    return <Typography>Error loading page</Typography>;
};

export default ScoringSnapshot;
