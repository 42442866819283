import * as React from "react";
import { EmailMessageTemplate } from "../../../../services/types/emailTemplateTypes";
import CclInfoTable, { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclMyEmailTemplate } from "./cclEmailTemplate";
import { Box, FormControlLabel, Stack, Switch } from "@mui/material";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import EmailViewer from "../../../../components/common/emailViewer";
import { Editor } from "@tinymce/tinymce-react";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import { useUpdateEmailTemplateMutation } from "../../../../services/cclTokenedEmailTemplateApi";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventEmailTemplate } from "../../../../services/types/accessEventTypes";


interface EmailTemplateGeneralProps {
    loading: boolean;
    data: EmailMessageTemplate;
}

const EmailTemplateGeneral: React.FC<EmailTemplateGeneralProps> = (props) => {

    let claimservice = new UserClaimsService();

    const [template, setTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [contentValue, setcontentValue] = React.useState<string>("");
    const [originalContent, setOriginalContent] = React.useState<string>("");
    const [changeLog, setChangeLog] = React.useState<any[]>([]);

    const [saveTemplate] = useUpdateEmailTemplateMutation();

    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        if (props.data) {
            var newTemplate = CclMyEmailTemplate;

            // var rowitem = newTemplate.find((item) => item.id === "notificationType");
            // if (rowitem && rowitem.valueField) {
            //     rowitem.valueField.selectOptions = notificationTypes.map((c) => {
            //         return {
            //             val: c.value.toString(),
            //             option: c.name
            //         }
            //     })
            // }
            
            setTemplate(newTemplate);
            setcontentValue(props.data.content);
            setOriginalContent(props.data.content);
        }
    }, [props.data]);

    const handleEdit = () => {
        if (editMode) {
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    }

    const checkChanged = () => {
        if (contentValue !== originalContent) {
            return true;
        }
        return false;
    };

    const handleInfoTableChange = (value: any) => { 
        setChangeLog(value);
    }

    const handleSave = () => {
        let temp: EmailMessageTemplate = {...props.data};
        temp.content = contentValue;
        temp.modifiedBy = claimservice.GetCurrentUserEmail();
        temp.modifiedUtc = new Date().toUTCString();
        temp.notificationType = `${temp.notificationType}`;
        temp.languageKey = `${temp.languageKey}`;
        
        for (let i = 0; i < Object.keys(changeLog).length; i++) {
            switch(changeLog[i].name) {
                case "templateName":
                    temp.templateName = changeLog[i].value;
                    break;
                case "subject":
                    temp.subject = changeLog[i].value;
                    break;
                case "ccRecipients":
                    temp.ccRecipients = changeLog[i].value;
                    break;
                case "bccRecipients":
                    temp.bccRecipients = changeLog[i].value;
                    break;
                case "notificationType":
                    temp.notificationType = changeLog[i].value;
                    break;
                default:
                    break;
            }
        }

        saveTemplate(temp)
            .unwrap()
            .then(() => {
                const evtData: AccessEventEmailTemplate = {
                    templateName: temp.templateName,
                };
                logEvent("EmailTemplateDetailsSaved", evtData);
                if (contentValue !== originalContent) {
                    logEvent("EmailTemplateContentsSaved", evtData);
                }
            });
    };

    return (
        <Stack direction={"row"} spacing={2} width={1}>
            <Box marginRight={3} width={"40%"}>
                <CclGenericAccordian headingText={"Template Details"}>
                    {template.length > 0 &&
                        <CclInfoTable 
                            template={template} 
                            data={props.data}   
                            onChange={handleInfoTableChange}
                            onSave={handleSave}     
                        />
                    }
                </CclGenericAccordian>
            </Box>
            <Box sx={{margin: "0px !important"}} width={"70%"}>
                <CclGenericAccordian headingText={"Preview"} >
                    <Stack sx={{width: "100%", margin: 2}} direction={"row"}>
                        <FormControlLabel 
                            control= {
                                <Switch  onChange={handleEdit}/>
                            }
                            label= "Enable Edit Mode"
                        />
                        <Box sx={{ml:"auto", mr:2}}>
                            <CclUnrestrictedButton
                                disabled={!checkChanged()}
                                onClick={handleSave}
                            >
                                Save
                            </CclUnrestrictedButton>
                        </Box>
                    </Stack>
                    {editMode ? 
                    <>
                        <Editor
                            apiKey="y7y53muqd751xs3r3tgo3wey3uwqehd18hiyp1s2pjdprezv"
                            onInit={(
                                evt: any,
                                editor: { getContent: () => string | undefined }
                            ) => setcontentValue(editor.getContent()!)}
                            onEditorChange={(newValue: string | undefined) => {
                                let val = newValue;
                                if (val === undefined) {
                                    val = "";
                                }
                                setcontentValue(val)
                            }}
                            value={contentValue}
                            init={{
                                height: 700,
                                menubar: "file edit view insert format table help",
                                branding: false,
                                contextmenu: "",
                                menu: {
                                    insert: {
                                        title: "Insert",
                                        items: "image link inserttable | charmap | insertdatetime",
                                    },
                                    format: {
                                        title: "Format",
                                        items: "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat",
                                    },
                                },
                                plugins: [
                                    "advlist autolink lists link image charmap print preview",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime table paste code help properties",
                                    "code",
                                    "fullscreen",
                                    "image",
                                ],
                                toolbar:
                                    "undo redo | formatselect | properties | bold italic fontcolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                        />
                    </>
                    :
                         <EmailViewer 
                            content={contentValue} 
                            disableLinks={false}                    
                        />
                    }
                </CclGenericAccordian>
            </Box>
        </Stack>
    );
};

export default EmailTemplateGeneral;