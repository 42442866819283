import * as React from "react";
import { Alert, Box, CardActions } from "@mui/material";
import CclInfoTable from "../../../../components/common/cclInfoTable/cclInfoTable";
import { UserAuth0Detail } from "../../../../services/types/userPreferenceTypes";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import { CclInfoTblUserAuth0GeneralInfoTemplate } from "./CclInfoTblUserAuth0GeneralInfoTemplate";
import { useDeleteAuth0AccountMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { DeleteAuth0AccountRequest } from "../../../../services/types/rtkQueryTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

export type Auth0DetailsCardProps = {
    gcAccountEmail: string;
    auth0Detail: UserAuth0Detail | null;
    isUserAdmin: boolean;
    imKey: string;
};

const Auth0DetailsCard: React.FC<Auth0DetailsCardProps> = (props) => {
    const [confirmOpenAuth0, setConfirmOpenAuth0] = React.useState<boolean>(false);
    const { logEvent } = useLogAccessEvent();
    const [DeleteAuth0AccountMutation] = useDeleteAuth0AccountMutation();

    const DeleteAuth0 = () => {
        const Request: DeleteAuth0AccountRequest = {
            email: props.gcAccountEmail,
            auth0Id: props.auth0Detail?.accounts.userId ?? "0",
        };
        setConfirmOpenAuth0(false);
        DeleteAuth0AccountMutation(Request).then(() => {
            const evtData: AccessEventIndividualDetails = {
                imKey: props.imKey,
                email: props.gcAccountEmail,
            };

            logEvent("UserAuth0AccountDeleted", evtData);
        });
    };

    return (
        <CclGenericAccordian headingText={"Auth0"} sx={{ height: 1 }}>
            <Box sx={{ m: 2, border: 1, borderColor: "#EEEEEE", borderRadius: 3 }}>
                <CclGenericConfirmationDialog
                    open={confirmOpenAuth0}
                    onCancel={() => setConfirmOpenAuth0(false)}
                    onOk={DeleteAuth0}
                    msg={
                        "Are you sure you want to delete the associated Auth0 account? This may prevent logging into this account in the future."
                    }
                />
                {props.auth0Detail?.accounts != null ? (
                    <CclInfoTable
                        template={CclInfoTblUserAuth0GeneralInfoTemplate}
                        data={props.auth0Detail.accounts}
                    />
                ) : (
                    <Alert severity="warning">
                        No Auth0 account data found for {props.gcAccountEmail}. This may be ok if
                        the user has yet to login to any applications.
                    </Alert>
                )}
                {props.isUserAdmin ? (
                    <CardActions disableSpacing sx={{ pt: 0, justifyContent: "flex-end" }}>
                        <CclRestrictedButton
                            size={"small"}
                            onClick={() => setConfirmOpenAuth0(true)}
                        >
                            Delete Auth0 Account
                        </CclRestrictedButton>
                    </CardActions>
                ) : null}
            </Box>
        </CclGenericAccordian>
    );
};

export default Auth0DetailsCard;
