import * as React from "react";
import { Stack, Typography, Card, Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import {
    useGetAccessEventsByDateMutation,
    useGetAccessEventsByEmailQuery,
} from "../../services/cclTokenedAccessEventsApi";
import { AccessEvent } from "../../services/types/accessEventTypes";
import AccessLogsDataGridPro from "./accessLogsDataGridPro";
import CclDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";

const AccessLogs: React.FC = (props) => {
    // redux state
    const config = useSelector((state: any) => state.accessEventListConfig);

    const [isEmailSearchActive, setIsEmailSearchActive] = React.useState<boolean>(
        config.IsEmailSearchActive
    );
    const [skipEmailSearch, setSkipEmailSearch] = React.useState<boolean>(
        !config.IsEmailSearchActive
    );
    const [searchEmail, setSearchEmail] = React.useState<string>(
        config.IsEmailSearchActive ? config.Email : ""
    );
    const [startDate, setStartDate] = React.useState<Date | null>(new Date(config.StartDate));
    const [endDate, setEndDate] = React.useState<Date | null>(new Date(config.EndDate));
    const [endDateError, setEndDateError] = React.useState<boolean>(false);
    const [startDateError, setStartDateError] = React.useState<boolean>(false);
    const [
        runByDateSearch,
        {
            data: actionsByDate,
            isLoading: byDateIsLoading,
            isError: byDateIsError,
            error: byDateError,
        },
    ] = useGetAccessEventsByDateMutation();
    const {
        data: actionsByEmail,
        isLoading: byEmailIsLoading,
        isError: byEmailIsError,
        error: byEmailError,
    } = useGetAccessEventsByEmailQuery(searchEmail.trim(), { skip: skipEmailSearch });
    const [searchResultString, setSearchResultString] = React.useState<string>("");

    let actionEvents: AccessEvent[] = [];

    if (isEmailSearchActive) {
        if (actionsByEmail !== undefined) actionEvents = actionsByEmail;
    } else if (actionsByDate !== undefined) {
        actionEvents = actionsByDate;
    }

    const clearDateSearch = () => {
        // this is a hack to overcome an error in the mui datepicker that won't allow us to set an invalid date to null.
        // We have to first set a valid date, then go to null
        if (startDateError) {
            setStartDate(new Date());
            setTimeout(() => setStartDate(null), 100);
        } else {
            setStartDate(null);
        }
        if (endDateError) {
            setEndDate(new Date());
            setTimeout(() => setEndDate(null), 100);
        } else {
            setEndDate(null);
        }
        setEndDateError(false);
        setStartDateError(false);
    };

    React.useEffect(() => {
        // the config date times are set to generic dates when searching by keyword
        //  here we make sure the dates are cleared if the keyword search is active
        //  this is really only for when we return to the page after leaving
        if (isEmailSearchActive) {
            setStartDate(null);
            setEndDate(null);
        } else {
            runByDateSearch({
                keyword: null,
                start: config.StartDate,
                end: config.EndDate,
            });
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setSkipEmailSearch(true);
        setSearchResultString(
            `${actionsByEmail?.length} results for search by email ('${searchEmail.trim()}')`
        );
        // eslint-disable-next-line
    }, [actionsByEmail]);

    React.useEffect(() => {
        if (actionsByDate != null)
            setSearchResultString(
                `${
                    actionsByDate?.length
                } results for search by event date (from ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()})`
            );
        // eslint-disable-next-line
    }, [actionsByDate]);

    const getLogsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            return;
        }
        setSearchEmail("");
        setIsEmailSearchActive(false);
        runByDateSearch({
            keyword: null,
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        });
    };

    const getLogsByEmail = async (email: string) => {
        if (email == null || email.trim() === "") {
            return;
        }
        clearDateSearch();
        setSearchEmail(email.trim());
        setSkipEmailSearch(false);
        setIsEmailSearchActive(true);
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Logs
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (!byEmailIsError && !byDateIsError) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <Stack height={1} width={1}>
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ width: 1, height: 1, display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <CclTextSearchBar
                                    initialSearchTerm={searchEmail}
                                    searchLabel="Enter Email"
                                    executeSearch={getLogsByEmail}
                                />
                                <CclDateSearchBar
                                    initialStartDate={startDate}
                                    initialEndDate={endDate}
                                    startDateLabel="Earliest Log Date"
                                    endDateLabel="Latest Log Date"
                                    executeSearch={getLogsByDate}
                                    allowNullDates={false}
                                />
                            </Box>
                            <Grid
                                sx={{
                                    height: 1,
                                    width: 1,
                                    padding: 2,
                                    paddingTop: 0,
                                }}
                            >
                                <AccessLogsDataGridPro
                                    loading={byDateIsLoading || byEmailIsLoading}
                                    accessEvents={actionEvents}
                                    showResultBar={!(byDateIsLoading || byEmailIsLoading)}
                                    resultBarText={searchResultString}
                                />
                            </Grid>
                        </Box>
                    </Card>
                </Stack>
            </StandardLayout>
        );
    }

    if (byEmailIsError || byDateIsError) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <span>
                    {`Error Loading Log Events: ${byEmailIsError ? byEmailError : byDateError}`}{" "}
                </span>
            </StandardLayout>
        );
    }

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <span>Unknown Error Loading Logged Events</span>
        </StandardLayout>
    );
};

export default AccessLogs;
