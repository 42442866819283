import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useAddCclSystemMutation } from "../../services/cclTokenedGrandCentralApi";
import { generateGUID } from "../../utilities/generalUtilities";
import CclErrorDialog from "../../components/common/cclErrorDialog";
import CclUnrestrictedOutlinedButton from "../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedButton from "../../components/common/cclButtons/cclRestrictedButton";

export interface AddSystemDialogProps {
    open: boolean;
    onClose: () => void;
}

export const AddSystemDialog: React.FC<AddSystemDialogProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [newSystemName, setNewSystemName] = React.useState<string>("");
    const [addSystem] = useAddCclSystemMutation();

    const onAddSystem = () => {
        addSystem({
            name: newSystemName,
            systemId: generateGUID(),
        })
            .unwrap()
            .then((res: boolean) => {
                console.log("Add System result: ", res ? "Success" : "Failed");
                props.onClose();
            })
            .catch((err) => {
                setErrorMessage(`Add System error: ${err}`);
                setShowErrorDialog(true);
                props.onClose();
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title="Error Adding System"
            />
            <Dialog
                open={props.open}
                onClose={() => props.onClose()}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>Add System</DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <Stack spacing={3}>
                        <TextField
                            required
                            id="new-system-name"
                            label="New System Name"
                            variant="standard"
                            value={newSystemName}
                            onChange={(e) => setNewSystemName(e.target.value)}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <CclUnrestrictedOutlinedButton onClick={props.onClose}>
                        Cancel
                    </CclUnrestrictedOutlinedButton>
                    <CclRestrictedButton
                        onClick={() => onAddSystem()}
                        disabled={newSystemName === ""}
                    >
                        Add System
                    </CclRestrictedButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddSystemDialog;
