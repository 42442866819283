import * as React from "react";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import { useAddRoleToUserMutation } from "../../../../services/cclTokenedApplicationRolesApi ";
import { AddRoleToUser, ApplicationRole } from "../../../../services/types/applicationRolesTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventUserApplicationRole } from "../../../../services/types/accessEventTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { GrandCentralUserByGroups } from "../../../../services/types/cclGrandCentralApiTypes";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedLoadingButton from "../../../../components/common/cclButtons/cclRestrictedLoadingButton";

export interface AddRoleToUserPanelProps {
    open: boolean;
    users: GrandCentralUserByGroups[];
    roles: ApplicationRole[];
    onClose: () => void;
}

export const AddRoleToUserPanel: React.FC<AddRoleToUserPanelProps> = (props) => {
    const { logEvent } = useLogAccessEvent();

    const claimservice = new UserClaimsService();

    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
    const [roleKey, setRoleKey] = React.useState<number>(0);
    const [imKey, setImKey] = React.useState<number>(0);

    const [error, setError] = React.useState<boolean>(false);
    const [duplicateError, setDuplicateError] = React.useState<boolean>(false);

    const [AddRoleToUser, { isLoading, isError }] = useAddRoleToUserMutation();

    const getEmailfromImKey = (imKey: number) => {
        if (!props.users) {
            return "";
        }
        for (let i = 0; i < props.users.length; i++) {
            if (imKey === +props.users[i].imKey) {
                return props.users[i].accountEmails[0].email;
            }
        }
        return "";
    };

    const addNewRoleToUser = () => {
        const payload: AddRoleToUser = {
            roleKey: roleKey,
            imKey: imKey,
            email: getEmailfromImKey(imKey),
            createdUser: claimservice.GetCurrentUserId(),
        };

        if (!roleKey || !imKey || !payload.email || payload.email === "" || !payload.createdUser) {
            setError(true);
            return;
        }

        AddRoleToUser(payload)
            .unwrap()
            .then((e) => {
                if (e === null) {
                    setDuplicateError(true);
                } else {
                    const evtData: AccessEventUserApplicationRole = {
                        imKey: imKey.toString(),
                        email: getEmailfromImKey(imKey),
                    };
                    logEvent("UserApplicationRoleAdded", evtData);
                    props.onClose();
                }
            });
    };

    const onCancel = () => {
        setOpenConfirmation(false);
    };

    const onOk = () => {
        setOpenConfirmation(false);
        setError(false);
        setDuplicateError(false);
        addNewRoleToUser();
    };

    return (
        <>
            <CclGenericConfirmationDialog open={openConfirmation} onCancel={onCancel} onOk={onOk} />

            <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth={true}>
                <DialogTitle>Add role to user</DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <Stack spacing={3} sx={{ marginTop: "10px" }}>
                        {props.users && (
                            <FormControl>
                                <InputLabel id="user-select-dropdown">User</InputLabel>
                                <Select
                                    id="user-select-dropdown"
                                    label="User"
                                    onChange={(e) =>
                                        setImKey(
                                            typeof e.target.value === "string" ? +e.target.value : 0
                                        )
                                    }
                                >
                                    {props.users.map((user) => (
                                        <MenuItem value={user.imKey}>
                                            {user.firstName + " " + user.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {props.roles && (
                            <FormControl>
                                <InputLabel id="role-select-dropdown">Role</InputLabel>
                                <Select
                                    id="role-select-dropdown"
                                    label="Role"
                                    onChange={(e) =>
                                        setRoleKey(
                                            typeof e.target.value === "number" ? e.target.value : 0
                                        )
                                    }
                                >
                                    {props.roles.map((role) => (
                                        <MenuItem value={role.applicationRoleKey}>
                                            {role.roleName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {(isError || error) && (
                            <Alert severity="error" sx={{ fontSize: 14 }}>
                                Failed to add role to user
                            </Alert>
                        )}

                        {duplicateError && (
                            <Alert severity="error" sx={{ fontSize: 14 }}>
                                This user already has that role
                            </Alert>
                        )}

                        {isLoading && (
                            <Alert severity="info" sx={{ fontSize: 14 }}>
                                Saving new role
                            </Alert>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <CclUnrestrictedOutlinedButton onClick={props.onClose} disabled={isLoading}>
                        Cancel
                    </CclUnrestrictedOutlinedButton>

                    <CclRestrictedLoadingButton
                        onClick={() => setOpenConfirmation(true)}
                        loading={isLoading}
                        disabled={imKey && roleKey ? false : true}
                    >
                        Add Roles
                    </CclRestrictedLoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddRoleToUserPanel;
