import * as React from "react";
import { Grid, TextFieldProps, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CclUnrestrictedButton from "../../components/common/cclButtons/cclUnrestrictedButton";

interface CclDateWithTextSearchBarProps {
    initialSearchTerm: string;
    initialStartDate: Date | null;
    initialEndDate: Date | null;
    startDateLabel: string;
    endDateLabel: string;
    searchTermLabel: string;
    executeSearch: (startDate: Date | null, endDate: Date | null, searchTerm: string) => void;
    errorStateChange?: (error: boolean) => void;
    allowNullDates?: boolean;
}

const CclDateWithTextSearchBar: React.FC<CclDateWithTextSearchBarProps> = (props) => {
    const [startDate, setStartDate] = React.useState<Date | null>(
        props.initialStartDate ? new Date(props.initialStartDate) : null
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        props.initialEndDate ? new Date(props.initialEndDate) : null
    );
    const [searchTerm, setSearchTerm] = React.useState<string>(props.initialSearchTerm ?? "");
    const [endDateError, setEndDateError] = React.useState<boolean>(false);
    const [startDateError, setStartDateError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setStartDate(props.initialStartDate);
        setEndDate(props.initialEndDate);
    }, [props.initialStartDate, props.initialEndDate]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    const handleDateSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            if (!endDateError && !startDateError && endDate != null && startDate != null) {
                evt.preventDefault();
                props.executeSearch(startDate, endDate, searchTerm);
            }
        }
    };

    const setStartSearchError = (error: boolean) => {
        if (error !== startDateError) {
            setStartDateError(error);
            if (props.errorStateChange) props.errorStateChange(error || endDateError);
        }
    };

    const setEndSearchError = (error: boolean) => {
        if (error !== endDateError) {
            setEndDateError(error);
            if (props.errorStateChange) props.errorStateChange(error || startDateError);
        }
    };

    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12} sm={3}>
                <DatePicker
                    label={props.startDateLabel ?? "Start Date"}
                    value={startDate}
                    onChange={(newValue: Date | null) => {
                        setStartDate(newValue);
                    }}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                        params.fullWidth = true;
                        return (
                            <TextField
                                {...params}
                                error={startDateError}
                                helperText={startDateError ? "Enter a valid date" : ""}
                                onKeyDown={(e) => handleDateSearchKeyPress(e)}
                                sx={searchInputSx}
                            />
                        );
                    }}
                    onError={(reason, value) => {
                        if (reason) {
                            setStartSearchError(true);
                        } else {
                            setStartSearchError(false);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <DatePicker
                    label={props.endDateLabel ?? "End Date"}
                    value={endDate}
                    onChange={(newValue: Date | null) => {
                        setEndDate(newValue);
                    }}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                        params.fullWidth = true;
                        return (
                            <TextField
                                {...params}
                                error={endDateError}
                                helperText={endDateError ? "Enter a valid date" : ""}
                                onKeyDown={(e) => handleDateSearchKeyPress(e)}
                                sx={searchInputSx}
                            />
                        );
                    }}
                    onError={(reason, value) => {
                        if (reason) {
                            setEndSearchError(true);
                        } else {
                            setEndDateError(false);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4} md={5}>
                <TextField
                    value={searchTerm === "*" ? "" : searchTerm}
                    sx={searchInputSx}
                    id="outlined-adornment-keyword-search"
                    label={props.searchTermLabel ?? "Search Term"}
                    variant="outlined"
                    fullWidth={true}
                    onChange={(e) => {
                        e.preventDefault();
                        setSearchTerm(e.target.value);
                    }}
                    onKeyDown={(e) => handleDateSearchKeyPress(e)}
                />
            </Grid>
            <Grid item xs={12} sm={2} md={1}>
                <CclUnrestrictedButton
                    onClick={() => props.executeSearch(startDate, endDate, searchTerm)}
                    aria-label="Search By Date"
                    sx={{ width: 1 }}
                    disabled={
                        endDateError || startDateError || endDate == null || startDate == null
                    }
                >
                    Search
                </CclUnrestrictedButton>
            </Grid>
        </Grid>
    );
};

export default CclDateWithTextSearchBar;
