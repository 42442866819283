import { Box, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";

interface StyledDropzoneProps {
    onAddFile: (acceptedFiles: File[]) => void;
    disabled: boolean;
    maxFilesToUpload: number;
}

const StyledDropzone: React.FC<StyledDropzoneProps> = (props) => {
    const maxSize = 1048576 * 10;

    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            props.onAddFile([...acceptedFiles]);
        },
        [props]
    );

    const { isDragAccept, isFocused, getRootProps, getInputProps, isDragReject, fileRejections } =
        useDropzone({
            onDrop,
            accept: {
                "application/msword": [".doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
                    ".docx",
                ],
                "application/pdf": [".pdf"],
                "application/vnd.ms-powerpoint": [".ppt"],
                "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
                    ".pptx",
                ],
                "application/vnd.ms-excel": [".xls"],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            },
            minSize: 0,
            maxSize,
            multiple: true,
            maxFiles: props.maxFilesToUpload,
            disabled: props.disabled,
        });

    const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;

    const getBoarderColor = (): string => {
        if (isFocused) return "darkblue";
        else if (isDragAccept) return "darkgreen";
        else if (isDragReject) return "darkred";
        return "gray";
    };

    const getBackgroundColor = (): string => {
        if (isFocused) return "aliceblue";
        else if (isDragAccept) return "honeydew";
        else if (isDragReject) return "mistyrose";
        return "lightgray";
    };

    return (
        <Box
            className="container"
            sx={{
                borderColor: getBoarderColor(),
                backgroundColor: getBackgroundColor(),
                m: 2,
                p: 8,
                borderStyle: "dashed",
                borderWidth: 2,
                borderRadius: 3,
                "&:hover": {
                    border: "2px dashed darkblue",
                    color: "black",
                    backgroundColor: "aliceblue",
                },
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    {props.disabled && (
                        <Typography
                            align="center"
                            variant="h6"
                        >{`You have reached the max number of files to upload at once (${props.maxFilesToUpload}). Please upload existing files or remove files from the current upload list below.`}</Typography>
                    )}
                    {!props.disabled && (
                        <Typography align="center" variant="h6">
                            Drop files here or click to ADD.
                        </Typography>
                    )}
                    {!props.disabled && (
                        <Typography align="center" variant="body2">
                            Only PDF and Microsoft Office files are allowed for upload.
                        </Typography>
                    )}
                    {!props.disabled && (
                        <Typography align="center" variant="body2">
                            (Max number of files: <strong>{props.maxFilesToUpload}</strong>, max
                            individual file size: <strong>10 MB</strong>)
                        </Typography>
                    )}
                    {!props.disabled && isDragReject && (
                        <Typography align="center" variant="body1" color="error">
                            Invalid file type or too many files selected
                        </Typography>
                    )}
                    {!props.disabled && isFileTooLarge && (
                        <Typography align="center" variant="body1" color="error">
                            File is too large (must be less than 10 MB)
                        </Typography>
                    )}
                </Box>
            </div>
        </Box>
    );
};

export default StyledDropzone;
