import React from "react";
import {
    useGetSessionSetUpProgramManagersByEventKeyQuery,
    usePutProgramManagerSessionMutation,
} from "../../../../../services/cclTokenedSessionApi";
import CclGenericAccordian from "../../../../../components/common/cclGenericAccordian";
import SessionProgramManagersTable from "./sessionProgramManagersTable";
import AddEditProgramManagerDialog from "./addEditProgramManagerDialog";
import { SessionProgramManager } from "../../../../../services/types/sessionDetailTypes";
import { Alert } from "@mui/material";
import CclStatusDialog from "../../../../../components/common/cclStatusDialog";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";

export interface SessionProgramManagersAccordionProps {
    esKey: number;
    programCode: string;
    hasAssessmentPlatform: boolean;
    readOnly?: boolean;
}

export const SessionProgramManagersAccordion: React.FC<SessionProgramManagersAccordionProps> = (
    props
) => {
    const [selectedPM, setSelectedPM] = React.useState<SessionProgramManager | null>(null);
    const [openRemoveStatusDialog, setOpenRemoveStatusDialog] = React.useState<boolean>(false);
    const [openAddEditDialog, setOpenAddEditDialog] = React.useState<boolean>(false);
    const {
        data: programManagers,
        isSuccess,
        isLoading,
        isError,
    } = useGetSessionSetUpProgramManagersByEventKeyQuery(props.esKey.toString(), {
        skip: !props.esKey,
    });
    const [saveProgramManager] = usePutProgramManagerSessionMutation();
    const { logEvent } = useLogAccessEvent();

    const removeProgramManager = (id: number) => {
        var removeManager = programManagers?.find((pm) => pm.individualId === id);
        if (!removeManager) return; // TODO: Error?
        var payload: SessionProgramManager = { ...removeManager };
        payload.programId = props.esKey;
        payload.status = "Remove";
        saveProgramManager(payload)
            .unwrap()
            .then(() => {
                const evtData:AccessEventSessionDetails = {
                    projectCode: props.programCode
                };
                logEvent("SessionSetupSaved",evtData);
                setOpenRemoveStatusDialog(false);
            })
            .catch(() => {
                setOpenRemoveStatusDialog(false);
            });
    };

    const handleEvent = (evt: string, id: number) => {
        switch (evt) {
            case "add":
                setSelectedPM(null);
                setOpenAddEditDialog(true);
                break;

            case "remove":
                removeProgramManager(id);
                setOpenRemoveStatusDialog(true);
                break;
        }
    };

    return (
        <CclGenericAccordian headingText="Client Administrators" sx={{ h: 1 }}>
            {openRemoveStatusDialog && (
                <CclStatusDialog
                    open={openRemoveStatusDialog}
                    title={"Removing program manager..."}
                    msg={"Removing program manager..."}
                    enableOk={false}
                    severity="info"
                    onOk={() => {
                        return;
                    }}
                />
            )}
            {
                // note we open the dialog this way so the mount/unmount will clear the state from the dialog when it's closed
                openAddEditDialog && (
                    <AddEditProgramManagerDialog
                        open
                        esKey={props.esKey}
                        programCode={props.programCode}
                        onCancel={() => setOpenAddEditDialog(false)}
                        managerToEdit={selectedPM}
                    />
                )
            }
            {!props.hasAssessmentPlatform ? (
                <Alert severity="warning" sx={{ width: "100%" }}>
                    An Assessment Platform has not been specified in D365 and therefore this feature
                    is unavailable at this time. Please specify an Assessment Platform in D365 to
                    gain access to this feature.
                </Alert>
            ) : isSuccess || isLoading ? (
                <SessionProgramManagersTable
                    ProgramManagersList={programManagers}
                    isLoading={isLoading}
                    handleEvent={handleEvent}
                    readOnly={props.readOnly}
                />
            ) : isError ? (
                <Alert severity="error" sx={{ width: "100%" }}>
                    There was an error retrieving Client Administrators for this session.
                </Alert>
            ) : null}
        </CclGenericAccordian>
    );
};
export default SessionProgramManagersAccordion;
