import * as React from "react";
import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
    useGetCclApplicationQuery,
    useUpdateCclApplicationMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import CclInfoTable, {
    CclInfoTableFormValue,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblApplicationTemplate } from "./cclInfoTblApplicationTemplate";
import CallbackUrlCard from "./callbackUrlCard";
import GcConnectionCard from "./gcConnectionCard";
import Auth0ConnectionCard from "./auth0ConnectionCard";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import ComponentLoader from "../../../../components/common/componentLoader";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

export type SystemApplicationDetailProps = {
    applicationId: string;
    systemId: string;
    systemName: string;
    readonly: boolean;
    expanded: boolean;
};

export const SystemApplicationDetail: React.FC<SystemApplicationDetailProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const { data: appDetail, isLoading } = useGetCclApplicationQuery(props.applicationId);
    const [updateApplication] = useUpdateCclApplicationMutation();
    const template = JSON.parse(JSON.stringify(CclInfoTblApplicationTemplate));
    const { logEvent } = useLogAccessEvent();

    const showError = (msg: string) => {
        setErrorMessage(msg);
        setShowErrorDialog(true);
    };

    if (isLoading) {
        return (
            <Accordion key={props.applicationId} defaultExpanded={props.expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`loading-application-panel-content`}
                    id={`$loading-application-panel-header`}
                >
                    <Typography>Retrieving Application Data...</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ComponentLoader msg={""} />
                </AccordionDetails>
            </Accordion>
        );
    }

    if (isLoading) {
        return (
            <Accordion key={props.applicationId} defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`loading-application-panel-content`}
                    id={`$loading-application-panel-header`}
                >
                    <Typography>Error Loading Application {props.applicationId}</Typography>
                </AccordionSummary>
            </Accordion>
        );
    }

    if (!appDetail) {
        return (
            <Accordion key={props.applicationId} defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`loading-application-panel-content`}
                    id={`$loading-application-panel-header`}
                >
                    <Typography>No application found with id={props.applicationId}</Typography>
                </AccordionSummary>
            </Accordion>
        );
    }

    const saveApplicationEdits = (changedFields: CclInfoTableFormValue[]) => {
        if (appDetail === undefined) return;
        var updateBody = JSON.parse(JSON.stringify(appDetail.loginApplication));
        changedFields.forEach((field) => {
            updateBody[field.name] = field.value;
        });
        updateApplication({
            applicationId: appDetail.applicationId,
            systemId: props.systemId,
            systemName: props.systemName,
            name: updateBody["name"],
            loginApplication: updateBody,
        })
            .unwrap()
            .then((response) => {
                const evtData : AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
                if (!response) {
                    showError("Failed to update application information");
                }
            })
            .catch((error) => {
                showError(`Failed to update application information: ${error.data}`);
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title="Error Updating Application Information"
            />
            <Accordion key={props.applicationId} defaultExpanded={props.expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${appDetail.applicationId}-application-panel-content`}
                    id={`${appDetail.applicationId}-application-panel-header`}
                >
                    <Typography>{appDetail.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {appDetail?.loginApplication && (
                                <CclInfoTable
                                    template={template}
                                    data={appDetail?.loginApplication}
                                    onSave={(changeFields: CclInfoTableFormValue[]) =>
                                        saveApplicationEdits(changeFields)
                                    }
                                    readonly={props.readonly}
                                ></CclInfoTable>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <CallbackUrlCard
                                systemId={props.systemId}
                                systemName={props.systemName}
                                applicationId={appDetail?.applicationId}
                                gcCallbackUrls={appDetail?.loginApplication.gcCallbackUrls}
                                readonly={props.readonly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <GcConnectionCard
                                systemId={props.systemId}
                                systemName={props.systemName}
                                applicationId={appDetail?.applicationId}
                                gcConnections={appDetail?.loginApplication.gcConnections}
                                readonly={props.readonly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Auth0ConnectionCard
                                applicationId={appDetail?.applicationId}
                                auth0Connections={appDetail?.auth0Connections}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SystemApplicationDetail;
