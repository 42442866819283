import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclPersistentDataGrid from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { updateAssignmentGridState } from "../../app/slices/assignmentListTableStateSlice";
import { SessionDetailResourceAppointment } from "../../services/types/sessionDetailTypes";
import { useSelector } from "react-redux";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../components/common/cclCommonLink";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

const COLUMNS = [
    {
        field: "programCode",
        headerName: "Project ID",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink text={params.value} keyVal={params.value} to={`/session/${params.row.esKey}`} />
        ),
        flex: 0.4,
    },
    {
        field: "name",
        headerName: "Resource",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink text={params.value} keyVal={params.value} to={`/resource/${params.row.pKey}/0`} />
        ),
        flex: 0.4,
        valueGetter: getFullName,
    },
    {
        field: "email",
        headerName: "Email",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink text={params.value} keyVal={params.value} to={`mailto:${params.value}`} type={"email"} />
        ),
        flex: 0.75,
    },
    {
        field: "resourceType",
        headerName: "Resource Type",
        type: "string",
        flex: 1,
    },
    {
        field: "startDateTimeUtc",
        headerName: "Start",
        valueGetter: (params: any) => new Date(params.row.startDateTimeUtc),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        width: 150,
    },
    {
        field: "endDateTimeUtc",
        headerName: "End",
        valueGetter: (params: any) => new Date(params.row.endDateTimeUtc),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        width: 150,
    },
    {
        field: "categoryName",
        headerName: "Category",
        type: "string",
        flex: 1.1,
    },
    {
        field: "taskName",
        headerName: "Task Name",
        type: "string",
        flex: 1.15,
    },
    {
        field: "programTitle",
        headerName: "Title",
        type: "string",
        flex: 1,
    },
];

export interface SessionAssignmentListProps {
    assignments: SessionDetailResourceAppointment[];
    loading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
}

export const SessionAssignmentListDataGridPro: React.FC<SessionAssignmentListProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.assignmentListTableState);

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={props.assignments}
            loading={props.loading}
            stateSelector={stateSelector}
            userPreferenceId={8}
            getRowIdFn={(row: SessionDetailResourceAppointment) => row.raKey}
            actionCreator={updateAssignmentGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "startDateTimeUtc", sort: "asc" }] },
            })}
            resultBar={props.showResultBar ?? false}
            resultBarText={props.resultBarText ?? ""}
        />
    );
};

export default SessionAssignmentListDataGridPro;
