import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";

interface HomeTitleBarProps {}

const HomeTitleBar: React.FC<HomeTitleBarProps> = () => {
    const background = process.env.PUBLIC_URL + "/media/images/homeTitleBackground.png";

    return (
        <Box
            sx={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`,
                height: "75px",
                width: "100%",
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
            }}
        >
            <Stack direction={"column"} paddingRight={3}>
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{
                        color: "black",
                    }}
                >
                    CCL Access
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: "black",
                        textAlign: "right",
                    }}
                >
                    Version 7.1.0
                </Typography>
            </Stack>
        </Box>
    );
};

export default HomeTitleBar;
