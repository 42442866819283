import * as React from "react";
import {
    Stack,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    Tooltip,
    Typography,
} from "@mui/material";
import { Application, Tenant } from "../../../../services/types/cclGrandCentralApiTypes";
import { useGetCclSystemQuery } from "../../../../services/cclTokenedGrandCentralApi";
import { SendInvitationsRequest } from "../../../../services/types/rtkQueryTypes";
import TenantSelect from "./tenantSelect";
import TenantGroupSelect from "./groupSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ApplicationSelect from "./applicationSelect";
import SystemSelect from "./systemSelect";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export interface ConfigureInvitationStepPanelProps {
    onNextPanel: (
        request: SendInvitationsRequest,
        app: Application | undefined,
        tenant: Tenant | undefined,
        systemId: string
    ) => void;
    request: SendInvitationsRequest | null;
    systemId: string;
}

export const ConfigureInvitationStepPanel: React.FC<ConfigureInvitationStepPanelProps> = (
    props
) => {
    const [daysValid, setDaysValid] = React.useState<number>(28);
    const [invitationState, setInvitationState] = React.useState<string>("");
    const [applicationId, setApplicationId] = React.useState<string>();
    const [tenantId, setTenantId] = React.useState<string>("");
    const [disableEmail, setDisableEmail] = React.useState<boolean>(false);
    const [autoAccept, setAutoAccept] = React.useState<boolean>(false);
    const [ignorePending, setIgnorePending] = React.useState<boolean>(false);
    const [selectedGroupIds, setSelectedGroupIds] = React.useState<string[]>([]);
    const [systemId, setSystemId] = React.useState<string>(props.systemId);
    const { data: gcsystem } = useGetCclSystemQuery(systemId, {
        skip: systemId == null || systemId === "",
    });

    const ignorePendingTooltip: string =
        "Checking this box will invalidate any open invitations for the user for the selected application.";

    React.useEffect(() => {
        if (gcsystem?.tenants.length === 1) setTenantId(gcsystem.tenants[0].tenantId);
        if (gcsystem?.applications.length === 1)
            setApplicationId(gcsystem.applications[0].applicationId);
    }, [gcsystem]);

    return (
        <Stack direction={"column"}>
            <Box
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={3}>
                    <SystemSelect
                        onChange={(systemId) => setSystemId(systemId)}
                        selectedSystem={systemId}
                    />
                    <ApplicationSelect
                        applications={gcsystem?.applications ?? []}
                        onChange={(appId) => setApplicationId(appId)}
                        selectedApplication={applicationId ?? ""}
                    />
                    <TenantSelect
                        tenants={gcsystem?.tenants ?? []}
                        onChange={(val: string) => setTenantId(val)}
                        selectedTenant={tenantId ?? ""}
                    />
                    <TextField
                        value={daysValid}
                        sx={{ width: 1, maxHeight: "57px", mt: 2 }}
                        id="outlined-adornment-days-valid"
                        label="Days To Expiration"
                        variant="outlined"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        onChange={(evt) => setDaysValid(+evt.target.value)}
                    />
                    <TextField
                        value={invitationState}
                        sx={{ width: 1, maxHeight: "57px", mt: 2 }}
                        id="outlined-adornment-invitation-state"
                        label="State information that may be needed by application upon invitation acceptance"
                        variant="outlined"
                        onChange={(evt) => setInvitationState(evt.target.value)}
                    />
                    <TenantGroupSelect
                        tenantId={tenantId}
                        onChange={(ids) => setSelectedGroupIds(ids)}
                    />
                    <FormGroup>
                        <FormControlLabel
                            label="Disable Email"
                            control={
                                <Checkbox
                                    checked={disableEmail}
                                    onChange={(evt) => setDisableEmail(evt.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Auto Accept"
                            control={
                                <Checkbox
                                    checked={autoAccept}
                                    onChange={(evt) => setAutoAccept(evt.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={
                                <React.Fragment>
                                    Ignore Pending Invitations
                                    {
                                        <Tooltip
                                            title={
                                                <Typography sx={{ fontSize: "1.25em" }}>
                                                    {ignorePendingTooltip}
                                                </Typography>
                                            }
                                        >
                                            <InfoOutlinedIcon fontSize="small" color={"info"} />
                                        </Tooltip>
                                    }
                                </React.Fragment>
                            }
                            control={
                                <Checkbox
                                    checked={ignorePending}
                                    onChange={(evt) => setIgnorePending(evt.target.checked)}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclButton disabled={true} sx={{ mr: 1 }} restricted={false} mode={"secondary"}>
                    Back
                </CclButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclButton
                    onClick={() => props.onNextPanel(
                        {
                            recipients: [],
                            tenantId: tenantId,
                            applicationId: applicationId ?? "",
                            disableEmail: disableEmail,
                            daysValid: daysValid,
                            state: invitationState,
                            autoAccept: autoAccept,
                            ignorePendingInvites: ignorePending,
                            groups: selectedGroupIds,
                        },
                        gcsystem?.applications.find((a) => a.applicationId === applicationId),
                        gcsystem?.tenants.find((t) => t.tenantId === tenantId),
                        gcsystem?.systemId ?? ""
                    )}
                    disabled={!systemId} 
                    restricted={false} 
                    mode={"primary"}                
                >
                    Next
                </CclButton>
            </Box>
        </Stack>
    );
};

export default ConfigureInvitationStepPanel;
