import { Box, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";

interface ImageDropzoneProps {
    onAddFile: (acceptedFiles: File[]) => void;
    disabled: boolean;
    fileToDisplay: File;
}

const ImageDropzone: React.FC<ImageDropzoneProps> = (props) => {
    const maxSize = 1048576 * 10;

    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            props.onAddFile([...acceptedFiles]);
        },
        [props]
    );

    const { isDragAccept, isFocused, getRootProps, getInputProps, isDragReject, fileRejections } =
        useDropzone({
            onDrop,
            accept: {
                'image/jpg': [".jpg"],
                'image/jpeg': [".jpeg"],
                'image/jpe': [".jpe"]
            },
            minSize: 0,
            maxSize,
            multiple: false,
            disabled: props.disabled,
        });

    //const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
    const fileErrors = fileRejections.length > 0 ? true : false

    const compileErrors = () => {
        let errors = fileRejections[0].errors;
        let errorMessage:string[] = []
        for (let i = 0; i < errors.length; i++) {
            switch(errors[i].code) {
                case "file-invalid-type":
                    errorMessage.push("Only .jpg, .jpeg, or .jpe are allowed for upload.")
                    break;
                case "file-too-large":
                    errorMessage.push("File must be less than 10 MB")
                    break;
                default:
                    break;
            }
        }

        return errorMessage.map((error)=> <Typography align="center" variant="body1" color="error">{error}</Typography>);
        
    }

    const getBoarderColor = (): string => {
        if (isFocused) return "darkblue";
        else if (isDragAccept) return "darkgreen";
        else if (isDragReject) return "darkred";
        return "gray";
    };

    const getBackgroundColor = (): string => {
        if (isFocused) return "aliceblue";
        else if (isDragAccept) return "honeydew";
        else if (isDragReject) return "mistyrose";
        return "lightgray";
    };

    return (
        <Box
            className="container"
            sx={{
                borderColor: getBoarderColor(),
                backgroundColor: getBackgroundColor(),
                m: 2,
                p: 8,
                borderStyle: "dashed",
                borderWidth: 2,
                borderRadius: 3,
                "&:hover": {
                    border: "2px dashed darkblue",
                    color: "black",
                    backgroundColor: "aliceblue",
                },
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    {props.disabled && (
                        <Typography
                            align="center"
                            variant="h6"
                        >{`?`}</Typography>
                    )}
                    {!props.disabled && (
                        <Typography align="center" variant="h6">
                            Drop files here or click to ADD.
                        </Typography>
                    )}
                    {!props.disabled && (
                        <Typography align="center" variant="body1">
                            {props.fileToDisplay?.name}
                        </Typography>
                    )}
                    {!props.disabled && (
                        <Typography align="center" variant="body2">
                            Only .jpg, .jpeg, or .jpe are allowed for upload.
                        </Typography>
                    )}
                    {!props.disabled && (
                        <Typography align="center" variant="body2">
                            max individual file size: <strong>10 MB</strong>
                        </Typography>
                    )}
                    {!props.disabled && isDragReject && (
                        <Typography align="center" variant="body1" color="error">
                            Invalid file type or too many files selected
                        </Typography>
                    )}
                    {!props.disabled && fileErrors && (
                        compileErrors()
                    )}
                </Box>
            </div>
        </Box>
    );
};

export default ImageDropzone;