import * as React from "react";
import DetailPage from "../../../../components/common/detailPage/detailPage";
import { SimpleBreadcrumbs } from "../../../../components/navigation/simpleBreadcrumbs";
import { Link, Typography } from "@mui/material";
import { useParams, Link as RouterLink } from "react-router-dom";
import WorkPoolGeneral from "./general/workPoolGeneralTab";
import { useGetCadreByCkeyQuery } from "../../../../services/cclTokenedEnterpirseCoachingCadreApi";
import { WorkPool } from "../../../../services/types/resources/workPoolTypes";
import StandardLayout from "../../../../layouts/standard";
import PageLoader from "../../../../components/common/pageLoader";

export type WorkPoolDetailProps = {
    id: string
};

const WorkPoolDetail: React.FC = (props) => {

    const params = useParams<WorkPoolDetailProps>();
    const id = params.id !== undefined ? params.id : "";

    const { data, isLoading } = useGetCadreByCkeyQuery(+id);

    const emptyData = {} as WorkPool;

    const tablist: [string, number, React.ReactNode][] = [
        ["Members", 0,  <WorkPoolGeneral loading={isLoading} data={data ? data : emptyData} />],
    ];

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                <Link key="findResourceLink" component={RouterLink} to="/resources/6">
                    Work Pools
                </Link>
            </Typography>
            <Typography variant="h6" color="text.primary">
                {data? data.name : "Loading"}
            </Typography>
        </SimpleBreadcrumbs>
    );

    if (isLoading) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={"Loading Work Pool Data..."} />
            </StandardLayout>
        );
    }

    return (
        <DetailPage
            breadcrumbs={breadcrumbs}
            descriptor={"resources"}
            tablist={tablist}
        ></DetailPage>
    );
}

export default WorkPoolDetail;