import { useContext, useState } from "react";
import { PeopleAltOutlined } from "@mui/icons-material";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AllowedRoutesState } from "../../app/slices/allowedRoutesSlice";
import { useAuth0 } from "@auth0/auth0-react";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import CclGenericConfirmationDialog from "../common/cclGenericConfirmationDialog";
import CclStatusDialog from "../common/cclStatusDialog";
import { FeatureFlag } from "../../featureFlags";

interface SideNavBarProps {
    open?: boolean;
}

export const SideNavBar: React.FC<SideNavBarProps> = (props) => {
    const [showLogoutConfirm, setShowLogoutConfirm] = useState<boolean>(false);
    const [openLogoutStatus, setOpenLogoutStatus] = useState<boolean>(false);
    const allowedRoutes: AllowedRoutesState = useSelector((state: any) => state.allowedRoutes);
    const claimsService = new UserClaimsService();
    var location = useLocation();
    const { logout } = useAuth0();
    const { logEvent } = useLogAccessEvent();
    const features = useContext(FeatureFlag);

    const logoutWithRedirect = () => {
        setOpenLogoutStatus(true);
        logEvent("UserLoggedOut", null);
        claimsService.ClearToken();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    return (
        <Box
            className="navsidebar"
            sx={{
                border: 1,
                paddingTop: "25px",
                borderRightStyle: "solid",
                borderRightColor: "rgba(0, 0, 0, 0.13)",
                borderRightWidth: "1px",
                backgroundColor: "#F0F0F0",
                color: "rgb(0,0,0)",
                height: "100%",
                fontSize: "14px",
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "150%",
            }}
        >
            <CclStatusDialog
                title="Logging Out"
                msg="Logging out..."
                open={openLogoutStatus}
                enableOk={false}
                onOk={() => setOpenLogoutStatus(false)}
                severity="info"
            />
            <CclGenericConfirmationDialog
                msg="Are you sure you want to logout?"
                title="Logout Confirmation"
                open={showLogoutConfirm}
                onCancel={() => setShowLogoutConfirm(false)}
                onOk={() => {
                    setShowLogoutConfirm(false);
                    logoutWithRedirect();
                }}
            />

            <Toolbar sx={{ paddingBottom: "25px", marginLeft: "25px" }}>
                <img
                    src="https://public-assets.ccl.org/images/logos/ccl/2-line/ccl-logo-color.svg"
                    alt="CCL logo - small, color"
                    width={props.open ? "80%" : "0%"}
                />
            </Toolbar>
            <List>
                {allowedRoutes.routeFlags.allowHome && (
                    <ListItem
                        button
                        key="Home"
                        component={RouterLink}
                        to="/home"
                        selected={location.pathname === "/home"}
                        dense
                    >
                        <ListItemIcon>
                            <HouseOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Home" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowMyAccess && (
                    <ListItem
                        button
                        key="My Access"
                        component={RouterLink}
                        to="/myaccess"
                        selected={location.pathname.startsWith("/myaccess")}
                        dense
                    >
                        <ListItemIcon>
                            <PersonOutlineOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="My Access" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowSessions && (
                    <ListItem
                        button
                        key="Sessions"
                        component={RouterLink}
                        to="/sessions"
                        selected={location.pathname.startsWith("/session")}
                        dense
                    >
                        <ListItemIcon>
                            <GroupsOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Sessions" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowRegistrations && (
                    <ListItem
                        key="Registrations"
                        button
                        component={RouterLink}
                        to="/registrations"
                        selected={location.pathname.startsWith("/registration")}
                        dense
                    >
                        <ListItemIcon>
                            <PeopleOutlineIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Registrations"
                            sx={{ opacity: props.open ? 1 : 0 }}
                        />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowProductSales && !features?.scoring?.active && (
                    <ListItem
                        button
                        key="Product Sales"
                        component={RouterLink}
                        to="/productsales"
                        selected={location.pathname.startsWith("/productsale")}
                        dense
                    >
                        <ListItemIcon>
                            <ArticleOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Product Sales"
                            sx={{ opacity: props.open ? 1 : 0 }}
                        />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowProductSales && features?.scoring?.active && (
                    <ListItem
                        button
                        key="Assessments"
                        component={RouterLink}
                        to="/assessments"
                        selected={location.pathname.startsWith("/assessment")}
                        dense
                    >
                        <ListItemIcon>
                            <ArticleOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Assessments" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowResources && (
                    <ListItem
                        button
                        key="Resources"
                        component={RouterLink}
                        to="/resources"
                        selected={location.pathname.startsWith("/resource")}
                        dense
                    >
                        <ListItemIcon>
                            <PeopleAltOutlined htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Resources" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowApplications && (
                    <ListItem
                        button
                        key="Applications"
                        component={RouterLink}
                        to="/applications"
                        selected={location.pathname.startsWith("/application")}
                        dense
                    >
                        <ListItemIcon>
                            <AppsOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Applications" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowUsers && (
                    <ListItem
                        button
                        key="Users"
                        component={RouterLink}
                        to="/users"
                        selected={location.pathname.startsWith("/user")}
                        dense
                    >
                        <ListItemIcon>
                            <AccountBoxOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Users" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowNotifications && (
                    <ListItem
                        key="Emails"
                        button
                        component={RouterLink}
                        to="/notifications"
                        selected={
                            location.pathname.startsWith("/notification") ||
                            location.pathname.startsWith("/email")
                        }
                        dense
                    >
                        <ListItemIcon>
                            <EmailOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Emails" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                {allowedRoutes.routeFlags.allowLogs && (
                    <ListItem
                        key="Logs"
                        button
                        component={RouterLink}
                        to="/logs"
                        selected={location.pathname.startsWith("/log")}
                        dense
                    >
                        <ListItemIcon>
                            <HistoryOutlinedIcon htmlColor={"#A6A6A6"} />
                        </ListItemIcon>
                        <ListItemText primary="Logs" sx={{ opacity: props.open ? 1 : 0 }} />
                    </ListItem>
                )}
                <Divider sx={{ my: 1 }} />
                <ListItem
                    key="Support"
                    onClick={() => {
                        window.open("https://support.ccl.org", "_blank");
                    }}
                    sx={{ cursor: "pointer" }}
                    dense
                >
                    <ListItemIcon>
                        <HelpOutlineIcon htmlColor={"#A6A6A6"} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Support"
                        sx={{ opacity: props.open ? 1 : 0, color: "black" }}
                    />
                </ListItem>
                <ListItem
                    key="Logout"
                    onClick={() => setShowLogoutConfirm(true)}
                    sx={{ cursor: "pointer" }}
                    dense
                >
                    <ListItemIcon>
                        <LogoutOutlinedIcon htmlColor={"#A6A6A6"} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Logout"
                        sx={{ opacity: props.open ? 1 : 0, color: "black" }}
                    />
                </ListItem>
            </List>
        </Box>
    );
};

export default SideNavBar;

SideNavBar.defaultProps = {
    open: true,
};
