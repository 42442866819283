import * as React from "react";
import { useEffect } from "react";
import {
    useGetCclEventsMutation,
    useGetProgramManagersSessionsQuery,
} from "../../services/cclTokenedSessionApi";
import SessionsDataGridPro from "./sessionList";
import { Alert, AlertTitle } from "@mui/lab";
import { useSelector } from "react-redux";
import { getErrorMsg } from "../../utilities/rtkQueryErrorHelpers";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";

interface ActiveSessionsTabPanelProps {
    isProgramManager: boolean;
}

const ActiveSessionsTabPanel: React.FC<ActiveSessionsTabPanelProps> = (props) => {
    const [runSearch, { data, isLoading, isError, error }] = useGetCclEventsMutation();
    const searchSelector = useSelector((state: any) => state.sessionListConfig);
    const claimsService = new UserClaimsService();
    const pmImKey = claimsService.GetCurrentUserId();
    const {
        data: pmData,
        isLoading: pmIsLoading,
        isError: pmIsError,
        error: pmError,
    } = useGetProgramManagersSessionsQuery(pmImKey, { skip: !props.isProgramManager });

    useEffect(() => {
        if (props.isProgramManager || searchSelector == null) return;
        runSearch({
            keyword: searchSelector.Keyword,
            start: searchSelector.StartDate,
            end: searchSelector.EndDate,
        });
        // eslint-disable-next-line
    }, [searchSelector]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isError || pmIsError) {
        if (isError) return showErrorAlert(getErrorMsg(error));
        return showErrorAlert(getErrorMsg(pmError));
    }

    return (
        <SessionsDataGridPro
            sessions={props.isProgramManager ? pmData : data}
            loading={isLoading || pmIsLoading}
            showResultBar={!isLoading && !pmIsLoading && !props.isProgramManager}
        />
    );
};

export default ActiveSessionsTabPanel;
