import { Config } from "../../config";
import { AssetDownloadRequest, ReportDownloadRequest } from "../types/assetApiTypes";

export class FileDownloadService {
    base_url: string | undefined = Config.REACT_APP_CCL_API_MANAGEMENT_URL;

    public async DownloadProductSaleReports(request: ReportDownloadRequest): Promise<Response> {
        const url: string = `${this.base_url}ccl-access2/Assets/download-reports`;

        return await this.httpPostDownloadRequest(
            url,
            JSON.stringify(request.reportids),
            request.fname
        );
    }

    public async DownloadAssets(request: AssetDownloadRequest): Promise<Response> {
        const url: string = request.flatzip
            ? `${this.base_url}ccl-access2/Assets/download/flat`
            : `${this.base_url}ccl-access2/Assets/download`;
        return await this.httpPostDownloadRequest(
            url,
            JSON.stringify(request.aikeys),
            request.fname
        );
    }

    public async DownloadContractorsHandbook(year: string): Promise<Response> {
        const url = `${this.base_url}ccl-access2/Resources/handbook/${year}`;
        return await this.httpGetDownloadRequest(url, `CclContractorsHandbook${year}`);
    }

    private async httpPostDownloadRequest(
        url: string,
        jsonBody: string,
        defaultFilename: string
    ): Promise<any> {
        const token: string = localStorage.getItem("token") as string;
        const config: RequestInit = {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            body: jsonBody,
        };

        return this.httpDownloadRequest(url, config, defaultFilename);
    }

    private async httpGetDownloadRequest(url: string, defaultFilename: string): Promise<any> {
        const token: string = localStorage.getItem("token") as string;
        const config: RequestInit = {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
        };

        return this.httpDownloadRequest(url, config, defaultFilename);
    }

    private async httpDownloadRequest(url: string, config: RequestInit, defaultFilename: string) {
        const response = await fetch(url, config);
        if (!response.ok) {
            const msg = await response.text();
            throw Error(msg);
        }

        let fullDefaultFileName: string = defaultFilename.split(".")[0];
        const filetype: string = response.headers.get("content-type") ?? "";
        if (filetype === "application/pdf") {
            fullDefaultFileName = `${fullDefaultFileName}.pdf`;
        } else if (filetype === "application/zip") {
            fullDefaultFileName = `${fullDefaultFileName}.zip`;
        }

        const filename: string = this.getFilenameFromContentDispostionHeader(
            response.headers.get("content-disposition"),
            fullDefaultFileName
        );
        const newBlob = new Blob([await response.blob()], { type: filetype });
        const objUrl = window.URL.createObjectURL(newBlob);

        let link = document.createElement("a");
        link.href = objUrl;
        link.download = filename;
        link.click();
    }

    private getFilenameFromContentDispostionHeader(
        header: string | null,
        defaultFilename: string
    ): string {
        let fileName = defaultFilename;
        if (header !== null) {
            let contentDispostion = header.split(";");
            const fileNameToken = `filename*=UTF-8''`;

            for (let thisValue of contentDispostion) {
                if (thisValue.trim().indexOf(fileNameToken) === 0) {
                    fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ""));
                    break;
                }
            }
        }
        return fileName;
    }
}
