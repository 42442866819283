import * as React from "react";
import {
    useLazyGetCclParticipantsWithFilesByCoachImKeyQuery,
    useLazyGetParticipantCompassUrlQuery,
} from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import FlexGrid from "../../../../../layouts/flexGrid";
import { ResourceRegistrationsListDataGridPro } from "./resourceParticipantList";
import { Alert, AlertColor, AlertTitle } from "@mui/material";
import ComponentLoader from "../../../../../components/common/componentLoader";
import { getErrorMsg } from "../../../../../utilities/rtkQueryErrorHelpers";
import {
    AssetDownloadRequest,
    IndividualAssetsList,
    IndividualAssetsReleaseRequest,
} from "../../../../../services/types/assetApiTypes";
import { useReleaseAssetsMutation } from "../../../../../services/cclTokenedAssetsApi";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import {
    Asset,
    GetCompassUrlResponse,
    Participant,
} from "../../../../../services/types/enterpriseParticipantApiTypes";
import { useSendCompassInvitationsMutation } from "../../../../../services/cclTokenedGrandCentralApi";
import {
    RecipientInfo,
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../../../../services/types/rtkQueryTypes";
import { FileDownloadService } from "../../../../../services/fileDownloadService/fileDownloadService";
import CclStatusDialog from "../../../../../components/common/cclStatusDialog";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";

interface ResourceRegistrationsPanelProps {
    imkey: number;
    alternateEventOnClickHandler?: any;
}

const ResourceRegistrationsPanel: React.FC<ResourceRegistrationsPanelProps> = (props) => {
    const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
    const [statusTitle, setStatusTitle] = React.useState<string>("");
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [statusEnableOk, setStatusEnableOk] = React.useState<boolean>(false);
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("info");
    const [getParticipantsTrigger, { data: participants, isLoading, isError, error }] =
        useLazyGetCclParticipantsWithFilesByCoachImKeyQuery();
    const [releaseAssets] = useReleaseAssetsMutation();
    const { logEvent } = useLogAccessEvent();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();

    const downloadService = new FileDownloadService();
    const claimsService = new UserClaimsService();
    const allowParticipantOperations: boolean =
        claimsService.IsUserCclAdminOrSuperAdmin() ||
        props.imkey.toString() === claimsService.GetCurrentUserId();
    const isResourceCurrentUser = props.imkey.toString() === claimsService.GetCurrentUserId();

    React.useEffect(() => {
        if (props.imkey != null) getParticipantsTrigger(props.imkey);
    }, [getParticipantsTrigger, props.imkey]);

    const getParticipantsWithSelectedAiKeys = (aikeys: number[]): Participant[] => {
        let pax: Participant[] = [];
        if (participants != null && participants.length > 0) {
            participants.forEach((p) => {
                const foundAssets = p.assets.filter((a) => aikeys.some((ak) => ak === a.id));
                if (foundAssets.length > 0) pax.push(p);
            });
        }
        return pax;
    };

    const getSelectedParticipantAssetsByEsiKeys = (
        esikeys: number[],
        aikeys: number[]
    ): Asset[] => {
        let assets: Asset[] = [];
        let pax: Participant[] =
            participants?.filter((p) => esikeys.some((ek) => p.esiKey === ek)) ?? [];
        if (pax != null && pax.length > 0) {
            pax.forEach((p) => {
                const foundAssets = p.assets.filter((a) => aikeys.some((ak) => ak === a.id));
                assets = assets.concat(foundAssets);
            });
        }
        return assets;
    };

    const getParticipantAssetsByAiKeys = (aikeys: number[]): Asset[] => {
        let assets: Asset[] = [];
        if (participants != null && participants.length > 0) {
            participants.forEach((p) => {
                const foundAssets = p.assets.filter((a) => aikeys.some((ak) => ak === a.id));
                assets = assets.concat(foundAssets);
            });
        }
        return assets;
    };

    const handleEvent = async (evt: string, ids: number[]) => {
        switch (evt) {
            case "download":
                setStatusOpen(true);
                if (ids === undefined || ids.length <= 0) {
                    setStatusTitle("No Participant Files Selected");
                    setStatusMessage("Please select one or more participant files to download.");
                    setStatusSeverity("warning");
                    setStatusEnableOk(true);
                    return;
                }

                setStatusTitle("Downloading Participant Files");
                setStatusMessage(
                    "...downloading selected participant files. If you have selected multiple participants this could take several minutes. Please be patient. A confirmation message will be displayed when the download has completed."
                );
                setStatusSeverity("info");
                setStatusEnableOk(false);

                let filename = "ParticipantFiles.zip";
                if (ids.length === 1) {
                    const paxAssets = getParticipantAssetsByAiKeys([ids[0]]);
                    filename = paxAssets[0].name;
                    if (paxAssets[0].fileExtension !== "") {
                        var fileExt = paxAssets[0].name.split(".").pop();
                        if (fileExt?.toLowerCase() !== paxAssets[0].fileExtension.toLowerCase())
                            filename += paxAssets[0].fileExtension;
                    }
                }

                const downloadrequest: AssetDownloadRequest = {
                    fname: filename,
                    aikeys: ids,
                    flatzip: false,
                };

                downloadService
                    .DownloadAssets(downloadrequest)
                    .then(() => {
                        logEvent("UserDownloadedParticipantFiles", null);
                        setStatusMessage("Download Complete");
                        setStatusSeverity("success");
                        setStatusEnableOk(true);
                    })
                    .catch((error) => {
                        const msg = error.message ?? "Unknown error";
                        setStatusMessage("Error: " + msg);
                        setStatusSeverity("error");
                        setStatusEnableOk(true);
                    });
                break;

            case "release":
                const selectedParticipants = getParticipantsWithSelectedAiKeys(ids);
                if (selectedParticipants.length === 0) return;

                setStatusOpen(true);
                setStatusTitle("Confirming Files for Release");
                setStatusMessage("Checking that selected files can be released....");
                setStatusSeverity("info");
                setStatusEnableOk(false);

                const allowedEsiKeys: number[] =
                    selectedParticipants
                        .filter(
                            (p) =>
                                (p.coachCanReleasePaxFiles && isResourceCurrentUser) ||
                                claimsService.IsUserCclAdminOrSuperAdmin()
                        )
                        .map((p) => p.esiKey) ?? [];
                if (allowedEsiKeys.length <= 0) {
                    setStatusTitle("No Participant Files Can Be Released");
                    setStatusMessage(
                        "All selected files are already released or can't be released."
                    );
                    setStatusSeverity("warning");
                    setStatusEnableOk(true);
                    return;
                }

                const paxAssets = getSelectedParticipantAssetsByEsiKeys(allowedEsiKeys, ids);
                // don't attempt to release consultant reports
                const releasablePaxAssets: Asset[] = paxAssets.filter(
                    (a) => a.fileType !== "Consultant Report" && !a.isReleased
                );

                if (releasablePaxAssets === undefined || releasablePaxAssets.length <= 0) {
                    setStatusTitle("No Participant Files Found to Release");
                    setStatusMessage(
                        "All selected files either can't be released or are already released."
                    );
                    setStatusSeverity("warning");
                    setStatusEnableOk(true);
                    return;
                }

                setStatusTitle("Releasing Participant Files");
                setStatusMessage(
                    `Releasing selected participant files (${releasablePaxAssets.length} files)....`
                );
                setStatusSeverity("info");

                // construct the request object
                const individualsList: IndividualAssetsList[] = selectedParticipants
                    .map((p) => {
                        const thispaxassets: number[] = releasablePaxAssets
                            .filter((a) => a.imKey === p.imKey)
                            .map((pa) => pa.id);
                        return {
                            esikey: p.esiKey,
                            imkey: p.imKey,
                            aikeys: thispaxassets,
                        };
                    })
                    .filter((i) => i.aikeys.length > 0);

                if (individualsList.length <= 0) {
                    setStatusTitle("No Participant Files Found to Release");
                    setStatusMessage(
                        "All selected files either can't be released or are already released."
                    );
                    setStatusSeverity("warning");
                    setStatusEnableOk(true);
                    return;
                }

                const releaserequest: IndividualAssetsReleaseRequest = {
                    individualAssetLists: individualsList.filter((i) => i.aikeys.length > 0),
                };

                releaseAssets(releaserequest)
                    .unwrap()
                    .then(() => {
                        logEvent("UserReleasedParticipantFiles", null);
                        setStatusMessage("Release Complete, Refreshing Participant Data...");
                        setStatusSeverity("info");

                        getParticipantsTrigger(props.imkey)
                            .unwrap()
                            .then(() => {
                                setStatusMessage("Release Complete");
                                setStatusSeverity("success");
                                setStatusEnableOk(true);
                            })
                            .catch((error) => {
                                setStatusMessage(
                                    "Release Complete, Failed to Refresh Participant Data"
                                );
                                setStatusSeverity("success");
                                setStatusEnableOk(true);
                            });
                    })
                    .catch((error) => {
                        const msg = error.data ?? "Unknown error";
                        setStatusMessage("Error: " + msg);
                        setStatusSeverity("error");
                        setStatusEnableOk(true);
                    });
                break;

            case "activate":
                if (participants == null || participants.length <= 0) return;
                setStatusOpen(true);
                setStatusTitle("Activating Compass");
                setStatusMessage("Activating Compass for selected participants....");
                setStatusSeverity("info");
                setStatusEnableOk(false);
                let allowedPax: Participant[] = participants
                    .filter((p) => ids.some((i) => i === p.esiKey))
                    .filter(
                        (p) =>
                            p.sessionHasCompassSku &&
                            p.sessionHostPlatform !== "Leadership Accelerator Platform"
                    )
                    .filter(
                        (p) =>
                            (p.coachCanActivateCompass && isResourceCurrentUser) ||
                            claimsService.IsUserCclAdminOrSuperAdmin()
                    );

                if (allowedPax.length <= 0) {
                    setStatusTitle("Compass NOT Activated");
                    setStatusMessage(
                        `Compass activation could NOT be completed for any of the selected participants. These particpants are either in a session that does not have Compass included, or does not allow assigned resources to activate Compass, or is hosted on Leadership Accelerator which requires Compass activation at the session level.`
                    );
                    setStatusSeverity("warning");
                    setStatusEnableOk(true);
                    return;
                }

                const recipientList: RecipientInfo[] = allowedPax.map((p) => {
                    return {
                        email: p.emailAddress,
                        firstName: p.firstName,
                        lastName: p.lastName,
                    };
                });

                const request: SendCompassInvitationRequest = {
                    recipients: recipientList,
                    disableEmail: false,
                    autoAccept: false,
                    ignorePendingInvites: false,
                };

                sendCompassInvitations(request)
                    .unwrap()
                    .then((response: SendInvitationResponse) => {
                        if (response === undefined || response.numberInivitationsSent <= 0) {
                            setStatusMessage(
                                "Compass could not be activated for any of the selected participants. These particpants may already have Compass accounts or invitations, or are in a session that does not have Compass included or does not allow assigned resources to activate Compass, or is hosted on Leadership Accelerator which requires Compass activation at the session level."
                            );
                            setStatusSeverity("warning");
                            setStatusEnableOk(true);
                            return;
                        }
                        logEvent("UserActivatedCompass", null);
                        setStatusEnableOk(true);
                        if (response.numberFailed > 0) {
                            setStatusMessage(
                                `Compass activation not completed for ${response.numberFailed} of ${recipientList.length} valid selected participants. These participants may already have a Compass account.`
                            );
                            setStatusSeverity("warning");
                        } else {
                            if (recipientList.length < ids.length) {
                                setStatusMessage(
                                    `Compass activation was completed for ${recipientList.length} of the selected participants. The others are either in a session that does not have Compass included, or does not allow assigned resources to activate Compass, or is hosted on Leadership Accelerator which requires Compass activation at the session level.`
                                );
                                setStatusSeverity("warning");
                            } else {
                                setStatusMessage("Compass activation complete");
                                setStatusSeverity("success");
                            }
                        }
                    })
                    .catch((error) => {
                        const msg = error.data ?? "Unknown error";
                        setStatusMessage("Error: " + msg);
                        setStatusSeverity("error");
                        setStatusEnableOk(true);
                    });
                break;
            default:
                return;
        }
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch((error) => {
                setStatusTitle("Error Opening Compass For Participant");
                const msg = error.data ?? "Unknown error";
                setStatusMessage("Error: " + msg);
                setStatusSeverity("error");
                setStatusOpen(true);
                setStatusEnableOk(true);
            });
    };

    if (isError) {
        let msg = getErrorMsg(error);
        console.log(msg);
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading Participants" />;
    }

    return (
        <React.Fragment>
            <CclStatusDialog
                open={statusOpen}
                onOk={() => setStatusOpen(false)}
                severity={statusSeverity}
                title={statusTitle}
                msg={statusMessage}
                enableOk={statusEnableOk}
            />
            <FlexGrid>
                <ResourceRegistrationsListDataGridPro
                    isLoading={isLoading}
                    participants={participants}
                    handleEvent={handleEvent}
                    viewCompass={viewCompass}
                    allowOperations={allowParticipantOperations}
                    alternativeEventOnClickHandler={props.alternateEventOnClickHandler}
                />
            </FlexGrid>
        </React.Fragment>
    );
};

export default ResourceRegistrationsPanel;
