import * as React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { Application } from "../../../../services/types/cclGrandCentralApiTypes";
import ApplicationLoginsDataGrid from "./applicationLoginsDataGrid";
import ComponentLoader from "../../../../components/common/componentLoader";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import { useGetAuth0ApplicationLoginsQuery } from "../../../../services/cclTokenedGrandCentralApi";

interface ApplicationLoginsPanelProps {
    application: Application | undefined;
}

const ApplicationLoginsPanel: React.FC<ApplicationLoginsPanelProps> = (props) => {
    const {
        data: userAuth0LogsData,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetAuth0ApplicationLoginsQuery(
        props.application?.loginApplication?.identityProviderApplicationId ?? "",
        {
            skip:
                !props.application?.loginApplication?.identityProviderApplicationId ||
                props.application?.loginApplication?.identityProviderApplicationId === "",
        }
    );

    if (isLoading) {
        return <ComponentLoader msg={"Loading application logins..."} />;
    }

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isError || isError) {
        return showErrorAlert(
            `Error retrieving auth0 logins for application ${
                props.application?.name
            } (${getErrorMsg(error)})`
        );
    }

    if (isSuccess) {
        return (
            <ApplicationLoginsDataGrid isLoading={isLoading} Auth0LoginList={userAuth0LogsData} />
        );
    }

    return <span>No User Auth0 Logs</span>;
};

export default ApplicationLoginsPanel;
