import React, { useEffect } from "react";
import {
    Alert,
    Stack,
    Autocomplete,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import CclUnrestrictedButton from "../../../../../components/common/cclButtons/cclUnrestrictedButton";
import { AssessmentListItem, DocumentList } from "../../../../../services/types/sessionDetailTypes";
import { useLazyGetAIKeybyDocKeyQuery } from "../../../../../services/cclTokenedSessionApi";

interface AddDocumentDialogProps {
    open: boolean;
    esKey: number;
    onCancel: () => void;
    onAdd: (newItem: AssessmentListItem) => void;
    onViewDocument: (docId: number) => void;
    documentOptionsList: DocumentList[];
}

const AddDocumentDialog: React.FC<AddDocumentDialogProps> = (props) => {
    const [selectedDocumentValue, setSelectedDocumentValue] = React.useState<
        string | undefined | null
    >(props.documentOptionsList?.length > 0 ? props.documentOptionsList[0].documentName : null);
    const [showError, setShowError] = React.useState<boolean>(false);
    const [getDocsAiKey] = useLazyGetAIKeybyDocKeyQuery();

    useEffect(() => {
        if (
            selectedDocumentValue == null &&
            props.documentOptionsList != null &&
            props.documentOptionsList.length > 0
        ) {
            setSelectedDocumentValue(props.documentOptionsList[0].documentName);
        }
    }, [props.documentOptionsList, selectedDocumentValue]);

    const textInputSx = {
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    const getDefaultDocumentValue = (): string | null => {
        return props.documentOptionsList != null && props.documentOptionsList.length > 0
            ? props.documentOptionsList[0].documentName
            : null;
    };

    const getSelectedDocumentId = (): number => {
        if (props.documentOptionsList == null || props.documentOptionsList.length === 0) return -1;

        const docs = props.documentOptionsList.filter(
            (d) => d.documentName === selectedDocumentValue
        );

        return docs && docs.length > 0 ? docs[0].documentID : -1;
    };

    const close = () => {
        setShowError(false);
        setSelectedDocumentValue(getDefaultDocumentValue());
        props.onCancel();
    };

    const onDocumentChange = (e: any, newValue: any) => {
        setShowError(false);
        if (newValue == null) {
            setSelectedDocumentValue(getDefaultDocumentValue());
            return;
        }
        setSelectedDocumentValue(newValue);
    };

    const addDocument = () => {
        if (selectedDocumentValue) {
            const docs = props.documentOptionsList.filter(
                (d) => d.documentName === selectedDocumentValue
            );
            if (docs && docs.length > 0) {
                const doc = docs[0];
                getDocsAiKey(doc.documentID)
                    .unwrap()
                    .then((aidata) => {
                        if (aidata && aidata?.aI_Key > 0) {
                            let item: AssessmentListItem = {
                                esKey: props.esKey,
                                bPI_Key: 0,
                                bPI_Seq_Num: 1,
                                bPIT_Key: 2,
                                cclMasterKey: doc.documentID,
                                assessmentType: "Document",
                                instrumentTypeKey: 0,
                                cclMasterRaterKey: 0,
                                title: doc.documentName,
                                status: "Add",
                                groupReportRequested: false,
                                aggregateReportRequested: false,
                                aggregateReportAvailable: false,
                                groupReportAvailable: false,
                                docA_Path: "",
                                assetKey: aidata?.aI_Key,
                            };

                            props.onAdd(item);
                            close();
                        } else {
                            setShowError(true);
                        }
                    })
                    .catch(() => {
                        setShowError(true);
                    });
            }
        }
    };

    return (
        <Dialog open={props.open} maxWidth={"lg"} fullWidth>
            <DialogTitle id="add-document-dialog">{"Add Document"}</DialogTitle>
            <DialogContent sx={{ padding: "10px !important" }}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Autocomplete
                            disabled={props.documentOptionsList == null}
                            disablePortal={false}
                            id="combo-document"
                            value={selectedDocumentValue}
                            onChange={(e: any, newValue: any) => onDocumentChange(e, newValue)}
                            options={props.documentOptionsList.map((option) => option.documentName)}
                            sx={{ width: 1 }}
                            renderInput={(params) => (
                                <TextField {...params} sx={textInputSx} label="Document List" />
                            )}
                            disableClearable={
                                props.documentOptionsList[0] == null ||
                                selectedDocumentValue === getDefaultDocumentValue()
                            }
                        />
                        <CclUnrestrictedButton
                            disabled={selectedDocumentValue === null}
                            onClick={() => {
                                if (selectedDocumentValue != null)
                                    props.onViewDocument(getSelectedDocumentId());
                            }}
                            sx={{ minWidth: 150 }}
                        >
                            View Document
                        </CclUnrestrictedButton>
                    </Stack>
                    {showError ? (
                        <Alert severity="error" sx={{ marginTop: "8px" }}>
                            Could not find selected document ({selectedDocumentValue})
                        </Alert>
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <CclUnrestrictedButton onClick={() => close()}>Cancel</CclUnrestrictedButton>
                <CclUnrestrictedButton onClick={() => addDocument()}>
                    Add Document
                </CclUnrestrictedButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddDocumentDialog;
