import * as React from "react";
import {
    useGetProductSaleParticipantsByFacilitatorEmailQuery,
    useLazyGetParticipantCompassUrlQuery,
    useReleaseProductSalesMutation,
} from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import FlexGrid from "../../../../../layouts/flexGrid";
import { Alert, AlertColor, AlertTitle } from "@mui/material";
import ResourceProductSalesListDataGridPro from "./resourceProductSalesList";
import { getErrorMsg } from "../../../../../utilities/rtkQueryErrorHelpers";
import { useSendCompassInvitationsMutation } from "../../../../../services/cclTokenedGrandCentralApi";
import {
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../../../../services/types/rtkQueryTypes";
import {
    GetCompassUrlResponse,
    ProductSaleParticipant,
} from "../../../../../services/types/enterpriseParticipantApiTypes";
import { FileDownloadService } from "../../../../../services/fileDownloadService/fileDownloadService";
import CclStatusDialog from "../../../../../components/common/cclStatusDialog";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";

interface ResourceProductSalesPanelProps {
    email: string;
}

const ResourceProductSalesPanel: React.FC<ResourceProductSalesPanelProps> = (props) => {
    const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
    const [statusTitle, setStatusTitle] = React.useState<string>("");
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [statusEnableOk, setStatusEnableOk] = React.useState<boolean>(false);
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("info");
    const [releaseReports /*, { data: releaseResults, isSuccess: releaseSuccess }*/] =
        useReleaseProductSalesMutation();
    const {
        data: productSales,
        isLoading,
        isError,
        error,
    } = useGetProductSaleParticipantsByFacilitatorEmailQuery(props.email, {
        skip: !props.email,
    });
    const { logEvent } = useLogAccessEvent();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();
    const downloadService = new FileDownloadService();
    const claimsService = new UserClaimsService();

    const handleEvents = (evt: string, ids: string[]) => {
        switch (evt) {
            case "download":
                setStatusOpen(true);
                setStatusTitle("Download Assessment(s)");
                setStatusMessage(
                    "...downloading selected assessment(s). If you have selected multiple participants this could take several minutes. Please be patient. A confirmation message will be displayed when the download has completed."
                );
                setStatusSeverity("info");
                setStatusEnableOk(false);
                if (ids === undefined || ids.length <= 0) {
                    setStatusMessage("You must select at least one item to download.");
                    setStatusSeverity("error");
                    setStatusEnableOk(true);
                    return;
                }

                let filename = "ProductSales.zip";
                if (ids.length === 1) {
                    const ps = productSales?.find((p) => p.reportId === ids[0]);
                    if (ps == null) return; // shouldn't happen but just in case
                    filename = `${ps.fullName} ${ps.assessmentType}.pdf`;
                }

                downloadService
                    .DownloadProductSaleReports({ fname: filename, reportids: ids })
                    .then(() => {
                        logEvent("UserDownloadedProductSale", null);
                        setStatusTitle("Assesment(s) downloaded");
                        setStatusMessage(" ");
                        setStatusSeverity("success");
                        setStatusEnableOk(true);
                    })
                    .catch((error) => {
                        const msg = error.message ?? "Unknown error";
                        setStatusMessage("Error: " + msg);
                        setStatusSeverity("error");
                        setStatusEnableOk(true);
                    });
                break;
            case "activateCompass":
                setStatusOpen(true);
                setStatusTitle("Activate Compass");
                setStatusMessage("Releasing product sale reports to Compass....");
                setStatusSeverity("info");
                setStatusEnableOk(false);
                if (ids === undefined || ids.length <= 0) {
                    setStatusMessage("You must select at least one item to activate.");
                    setStatusSeverity("error");
                    setStatusEnableOk(true);
                    return;
                }

                releaseReports(ids)
                    .unwrap()
                    .then((releaseResults) => {
                        if (releaseResults === undefined || releaseResults.length <= 0) {
                            setStatusMessage("No reports were released to Compass.");
                            setStatusSeverity("warning");
                            setStatusEnableOk(true);
                            return;
                        }

                        const releasedReportIds: string[] =
                            releaseResults?.filter((r) => r.success).map((r) => r.reportId) ?? [];

                        if (releasedReportIds != null && releasedReportIds.length > 0) {
                            setStatusTitle("Sending Compass Invitation(s)");
                            const prodSaleParticipants: ProductSaleParticipant[] =
                                productSales?.filter((ps) =>
                                    releasedReportIds.some((r) => r === ps.reportId)
                                ) ?? [];
                            const sendInvitesPayload: SendCompassInvitationRequest = {
                                recipients: prodSaleParticipants.map((p) => {
                                    return {
                                        email: p.emailAddress,
                                        firstName: p.firstName,
                                        lastName: p.lastName,
                                    };
                                }),
                                disableEmail: false,
                                autoAccept: false,
                                ignorePendingInvites: false,
                            };
                            sendCompassInvitations(sendInvitesPayload)
                                .unwrap()
                                .then((response: SendInvitationResponse) => {
                                    setStatusTitle("Compass Activation Results");
                                    let msg: string =
                                        response.numberInivitationsSent !== 0
                                            ? `Activation successful for ${response.numberInivitationsSent} report(s). `
                                            : "";

                                    if (response.numberFailed > 0)
                                        msg = `${msg}Failed to send Compass invitations for ${response.numberFailed} report(s).`;

                                    const severity: AlertColor =
                                        response.numberInivitationsSent === 0
                                            ? "error"
                                            : response.numberFailed === 0
                                            ? "success"
                                            : "warning";

                                    if (response.numberInivitationsSent > 0) {
                                        logEvent("UserActivatedProductSales", null);
                                    }
                                    setStatusMessage(msg);
                                    setStatusSeverity(severity);
                                    setStatusEnableOk(true);
                                })
                                .catch((error) => {
                                    const msg = error.data ?? "Unknown error";
                                    setStatusMessage("Error: " + msg);
                                    setStatusSeverity("error");
                                    setStatusEnableOk(true);
                                });
                        } else {
                            setStatusMessage(
                                "Error: Failed to release any of the selected reports."
                            );
                            setStatusSeverity("error");
                            setStatusEnableOk(true);
                        }
                    })
                    .catch((error) => {
                        const msg = error.data ?? "Unknown error";
                        setStatusMessage("Error: " + msg);
                        setStatusSeverity("error");
                        setStatusEnableOk(true);
                    });
                break;
            default:
                break;
        }
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch((error) => {
                setStatusTitle("Error Opening Compass For Participant");
                const msg = error.data ?? "Unknown error";
                setStatusMessage("Error: " + msg);
                setStatusSeverity("error");
                setStatusOpen(true);
            });
    };

    if (isError) {
        let msg = getErrorMsg(error);
        console.log(msg);
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    return (
        <React.Fragment>
            <CclStatusDialog
                open={statusOpen}
                onOk={() => setStatusOpen(false)}
                severity={statusSeverity}
                title={statusTitle}
                msg={statusMessage}
                enableOk={statusEnableOk}
            />
            <FlexGrid>
                <ResourceProductSalesListDataGridPro
                    isLoading={isLoading}
                    productSales={productSales ?? []}
                    handleEvent={handleEvents}
                    viewCompass={viewCompass}
                    isUsersData={
                        props.email.toLowerCase() ===
                        claimsService.GetCurrentUserEmail().toLowerCase()
                    }
                />
            </FlexGrid>
        </React.Fragment>
    );
};

export default ResourceProductSalesPanel;
