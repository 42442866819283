import * as React from "react";
import { Box } from "@mui/material";
import {
    useGetDemographicsByProfileKeyQuery,
    usePostDemographicsByProfileKeyMutation,
    useGetAllCountriesQuery,
    useGetStatesandProvincesByCMKeyQuery,
} from "../../../services/cclTokenedEnterpriseCoachingApi";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblMyInfoTemplate } from "./myInfoDetial/cclInfoTblMyInfoTemplate";
import {
    SaveProfileAddressData,
    SaveProfileDemographicDataPayload,
    SaveProfileDemographicsData,
} from "../../../services/types/myAccessTypes";
import { flattenObject } from "../../../utilities/flattenObject";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import SignContractBanner from "../../resources/resourceDetail/contracts/signContractBanner";
import ProfileImage from "./myInfoDetial/profileImage";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../../services/types/accessEventTypes";
import CclGenericAccordian from "../../../components/common/cclGenericAccordian";

interface MyInfoProps {
    email: string;
    name: string;
}

const MyInfo: React.FC<MyInfoProps> = (props) => {
    const claimservice = new UserClaimsService();

    const [pKey, setPKey] = React.useState<string>("");
    const [cmKey, setCmKey] = React.useState<number>(0);
    const [modTemplate, setModTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    const [lock, setLock] = React.useState<boolean>(false);
    const [imKey, setImKey] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    if (!pKey) {
        setPKey(claimservice.GetCurrentResourceId());
    }
    if (!imKey) {
        setImKey(claimservice.GetCurrentUserId());
    }

    const isReadOnly = claimservice.IsReadOnly();
    const [saveInfoData] = usePostDemographicsByProfileKeyMutation();

    const { data: userData, refetch } = useGetDemographicsByProfileKeyQuery(pKey, {
        skip: !pKey,
    });
    const { data: countryList } = useGetAllCountriesQuery();
    const { data: stateList, refetch: fetch } = useGetStatesandProvincesByCMKeyQuery(cmKey);

    const labelStyle = "original";
    const displayValue = "UTC";
    const timezones = {
        ...allTimezones,
    };

    const { options } = useTimezoneSelect({ labelStyle, displayValue, timezones });

    React.useEffect(() => {
        if (!userData) {
            return;
        }
        var newTemplate = CclInfoTblMyInfoTemplate;
        setModTemplate([...newTemplate]);

        if (!lock) {
            if (countryList != null && countryList.length > 0) {
                var rowitem = newTemplate.find((r) => r.id === "address.country");

                let temp = [...countryList];

                temp.sort((a, b) => a.name.localeCompare(b.name));
                if (rowitem && rowitem.valueField) {
                    rowitem.valueField.selectOptions = temp.map((c) => {
                        return { val: c.cmKey.toString(), option: c.name };
                    });
                }
                rowitem = newTemplate.find((r) => r.id === "nationalityKey");
                if (rowitem && rowitem.valueField) {
                    rowitem.valueField.selectOptions = temp.map((c) => {
                        return { val: c.cmKey.toString(), option: c.name };
                    });
                }

                if (cmKey === 0) {
                    if (userData?.address?.country != null) {
                        for (let i = 0; i < temp.length; i++) {
                            if (
                                temp[i].name.toLowerCase() ===
                                userData?.address?.country?.toLowerCase()
                            ) {
                                setCmKey(temp[i].cmKey);
                                fetch();
                                break;
                            }
                        }
                    }
                }

                rowitem = newTemplate.find((r) => r.id === "ianaTimeZoneId");
                if (rowitem && rowitem.valueField) {
                    rowitem.valueField.selectOptions = options.map((c) => {
                        return { val: c.value, option: c.label };
                    });
                }

                setLock(true);
            }
        }

        if (stateList != null) {
            rowitem = newTemplate.find((r) => r.id === "address.spmKey");
            if (rowitem && rowitem.valueField) {
                rowitem.valueField.selectOptions = stateList.map((c) => {
                    return { val: c.spKey.toString(), option: c.name };
                });
            }
        }
        // eslint-disable-next-line
    }, [countryList, userData, fetch, stateList, cmKey]);

    const editedProfileEvent = (imkey: string) => {
        const evtData: AccessEventIndividualDetails = {
            imKey: imkey,
            email: props.email,
        };

        logEvent("UserEditedProfile", evtData);
    };

    const saveData = (data: any) => {
        if (!userData) {
            return;
        }

        let tempAddressData: SaveProfileAddressData = {
            spmKey: userData.address ? userData.address.spmKey : null,
            cmKey: userData.address ? userData.address.cmKey : null,
            addressOne: userData.address ? userData.address.addressOne : null,
            addressTwo: userData.address ? userData.address.addressTwo : null,
            city: userData.address ? userData.address.city : null,
            postalCode: userData.address ? userData.address.postalCode : null,
        };

        let tempData: SaveProfileDemographicDataPayload = {
            addressDTO: tempAddressData,
            preferredPhoneKey: userData.preferredCtKey,
            officePhone: userData.officePhone,
            homePhone: userData.homePhone,
            mobilePhone: userData.mobilePhone,
            originCmKey: userData.nationalityKey,
            ianaTimeZoneId: userData.ianaTimeZoneId,
        };

        for (let i = 0; i < Object.keys(data).length; i++) {
            if (data[i].name.includes("homePhone")) {
                tempData.homePhone = data[i].value;
            } else if (data[i].name.includes("officePhone")) {
                tempData.officePhone = data[i].value;
            } else if (data[i].name.includes("mobilePhone")) {
                tempData.mobilePhone = data[i].value;
            } else if (data[i].name.includes("nationality")) {
                tempData.originCmKey = parseInt(data[i].value.val);
            } else if (data[i].name.includes("ianaTimeZoneId")) {
                tempData.ianaTimeZoneId = data[i].value.val;
            } else if (data[i].name.includes("address")) {
                if (data[i].name.includes("addressOne")) {
                    tempData.addressDTO.addressOne = data[i].value;
                } else if (data[i].name.includes("addressTwo")) {
                    tempData.addressDTO.addressTwo = data[i].value;
                } else if (data[i].name.includes("city")) {
                    tempData.addressDTO.city = data[i].value;
                } else if (data[i].name.includes("postalCode")) {
                    tempData.addressDTO.postalCode = data[i].value;
                } else if (data[i].name.includes("country")) {
                    tempData.addressDTO.cmKey = parseInt(data[i].value.val);
                } else if (data[i].name.includes("spmKey")) {
                    tempData.addressDTO.spmKey = parseInt(data[i].value.val);
                }
            }
        }

        let packagedData: SaveProfileDemographicsData = {
            pKey: pKey,
            payload: tempData,
        };
        saveInfoData(packagedData)
            .unwrap()
            .then(() => {
                editedProfileEvent(imKey);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onChange = (data: any) => {
        let val: number = -1;
        for (let i = 0; i < Object.keys(data).length; i++) {
            if (data[i].name.includes("country")) {
                val = parseInt(data[i].value.val);
            }
        }
        if (val !== -1) {
            setCmKey(val);
            fetch();
        }
    };

    return (
        <Box sx={{ width: 1, height: 1 }}>
            {userData ? (
                <Box width={"60%"}>
                    <SignContractBanner isUsersRecord={true} pKey={pKey} />
                    {modTemplate.length > 0 && countryList != null && countryList.length > 0 ? (
                        <CclGenericAccordian headingText={"Profile"}>
                            <>
                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                    <ProfileImage
                                        name={props.name}
                                        picture={userData.profilePictureUrl}
                                        refetch={refetch}
                                        imKey={imKey}
                                        email={props.email}
                                    />
                                </Box>
                                <Box sx={{ m: 2, mt: 4, borderRadius: 3 }}>
                                    <CclInfoTable
                                        template={modTemplate}
                                        data={flattenObject(userData)}
                                        onSave={saveData}
                                        onChange={onChange}
                                        readonly={isReadOnly}
                                    />
                                </Box>
                            </>
                        </CclGenericAccordian>
                    ) : (
                        <></>
                    )}
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default MyInfo;
