import * as React from "react";
import { Box, Grid, Stack } from "@mui/material";
import FlexGrid from "../../../../layouts/flexGrid";
import ApplicationUsersDataGrid from "./applicationUsersDataGrid";
import {
    useGetCclSystemQuery,
    useLazyGetGroupsByTenantIdQuery,
    useLazyGetCclUserApplicationAccountsQuery,
    useSearchCclApplicationUsersMutation,
    useUpdateUserAccountMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import {
    ApplicationUsersSearchListConfigState,
    updateApplicationsUsersSearchParams,
} from "../../../../app/slices/applicationUsersSearchListConfigSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    ApplicationGroups,
    PersonGrandCentralDocument,
} from "../../../../services/types/search.service.types";
import UserEditApplicationDialog from "../../../users/userDetail/applications/userEditApplicationDialog";
import { Application } from "../../../../services/types/cclGrandCentralApiTypes";
import {
    ApplicationAccount,
    UpdateUserAccountRequest,
    UpdateUserAccountRequestPayload,
} from "../../../../services/types/rtkQueryTypes";
import { AccessEventApplicationDetails } from "../../../../services/types/accessEventTypes";
import PageLoader from "../../../../components/common/pageLoader";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import CclDoubleTextSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclDoubleTextSearchBar";
import CclTextSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclTextSearchBar";

interface ApplicationUsersPanelProps {
    application: Application | undefined;
}

const ApplicationUsersPanel: React.FC<ApplicationUsersPanelProps> = (props) => {
    const dispatch = useDispatch();
    const config: ApplicationUsersSearchListConfigState = useSelector(
        (state: any) => state.applicationUsersListConfig
    );

    const [users, setUsers] = React.useState<PersonGrandCentralDocument[]>([]);
    const [selectedUserEmail, setSelectedUserEmail] = React.useState<string>("");
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = React.useState<boolean>(false);
    const [searchResultString, setSearchResultString] = React.useState<string>("");
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);

    const [userAccount, setUserAccount] = React.useState<ApplicationAccount | null>(null);

    const { data: system } = useGetCclSystemQuery(props.application?.systemId ?? "", {
        skip: props.application === undefined,
    });
    const [getGroups, { data: tenantGroups }] = useLazyGetGroupsByTenantIdQuery();
    const [getUserAccount, { data: userAccounts }] = useLazyGetCclUserApplicationAccountsQuery();
    const [searchUsers, { data, isLoading, isSuccess }] = useSearchCclApplicationUsersMutation();
    const { logEvent } = useLogAccessEvent();
    const [updateUserAccount] = useUpdateUserAccountMutation();

    React.useEffect(() => {
        const newUsers = data === undefined ? [] : [...data];
        setUsers(newUsers);
        if (isSuccess && data && data.length >= 30000) setShowSearchToBroad(true);
    }, [data, isSuccess]);

    React.useEffect(() => {
        if (userAccounts === undefined || system === undefined) return;

        const useraccts = userAccounts.filter((a) =>
            system?.tenants.some((t) => t.tenantId === a.applicationId)
        );
        if (useraccts.length > 0) {
            // for now just take the first tenant/user. This is almost always right but would fail if a person is a user under multiple tenants in the application (really system in GC)
            setUserAccount(useraccts[0] ?? null);
            getGroups(useraccts[0].applicationId)
                .unwrap()
                .then(() => {
                    setShowEditDialog(true);
                    setShowLoader(false);
                });
        }
    }, [userAccounts, system, getGroups]);

    const handleSearchUsers = (firstName: string, lastName: string, email: string) => {
        if (props.application && props.application.applicationId.length > 0) {
            searchUsers({
                emailAddress: email,
                firstName: firstName,
                lastName: lastName,
                applicationId: props.application.applicationId,
            });
            dispatch(
                updateApplicationsUsersSearchParams({
                    FirstName: firstName ?? "",
                    LastName: lastName ?? "",
                    Email: email ?? "",
                    IsEmailSearchActive: email !== undefined && email?.length > 0,
                })
            );

            var s = firstName;
            var e = lastName;
            let msg =
                email === undefined || email.length === 0
                    ? `results for search by name ( ${s}  ${e})`
                    : `results for search by Email ('${email}')`;

            if (s?.length === 0 && e?.length === 0 && email?.length === 0) {
                msg = `results for get all`;
            }
            setSearchResultString(msg);
        }
    };

    const updateUserApplication = (
        enabled: boolean,
        newGroups: string[] | undefined,
        expiration: Date | null
    ) => {
        if (userAccount === null) return;
        const addGroups: string[] =
            newGroups?.filter((g) => !userAccount?.groups?.includes(g)) ?? [];
        const addGroupIds: string[] =
            addGroups.length === 0
                ? []
                : tenantGroups
                      ?.filter((g) => addGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];
        const removeGroups: string[] =
            userAccount?.groups?.filter((g) => !newGroups?.includes(g)) ?? [];
        const removeGroupIds: string[] =
            removeGroups.length === 0
                ? []
                : tenantGroups
                      ?.filter((g) => removeGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];

        let payload: UpdateUserAccountRequestPayload = {
            accountId: userAccount.accountId,
            enabled: enabled,
            expirationDate: expiration,
            addToGroups: addGroupIds,
            removeFromGroups: removeGroupIds,
        };

        let request: UpdateUserAccountRequest = {
            userId: userAccount.userId,
            payload: payload,
        };

        updateUserAccount(request)
            .unwrap()
            .then(() => {
                let oldAppAccount = users.find((u) => u.accountId === userAccount.accountId);

                const evtData: AccessEventApplicationDetails = {
                    applicationName: props.application?.systemName ?? "",
                    applicationId: userAccount.applicationId,
                    imKey: +(oldAppAccount?.imKey ?? "-1"),
                    email: oldAppAccount?.accountEmails[0].Email ?? "unknown"
                };
                logEvent("UserApplicationAccountEdited", evtData);

                // update user record. NOTE: This assumes a user is only a member of a single tenant in a system.
                if (oldAppAccount == null) return;
                let newUsers = users.filter((u) => u.accountId !== userAccount.accountId);
                let newAppAccount: PersonGrandCentralDocument = {
                    accountId: oldAppAccount.accountId,
                    firstName: oldAppAccount.firstName,
                    lastName: oldAppAccount.lastName,
                    accountEmails: [...oldAppAccount.accountEmails],
                    dateCreated: oldAppAccount.dateCreated,
                    accountApplications: [
                        {
                            applicationName: oldAppAccount.accountApplications[0].applicationName,
                            applicationId: oldAppAccount.accountApplications[0].applicationId,
                            applicationEnabled: enabled,
                            applicationGroups:
                                newGroups?.map((g) => {
                                    return { groupName: g } as ApplicationGroups;
                                }) ?? [],
                        },
                    ],
                    imKey: oldAppAccount.imKey,
                };
                newUsers.push(newAppAccount);
                setUsers([...newUsers]);
            });
        setShowEditDialog(false);
        setUserAccount(null);
    };

    const handleEditBtnClick = (userEmail: string) => {
        setSelectedUserEmail(userEmail);
        setShowLoader(true);
        getUserAccount(userEmail);
    };

    return (
        <Stack width={1} height={1} spacing={2}>
            {showLoader ? <PageLoader msg={"Loading user's application data..."} /> : null}
            {userAccount !== null && tenantGroups !== undefined ? (
                <UserEditApplicationDialog
                    emailAddress={selectedUserEmail}
                    userAccount={userAccount}
                    open={showEditDialog}
                    onOk={updateUserApplication}
                    onCancel={() => setShowEditDialog(false)}
                    tenantGroups={
                        [...tenantGroups!]?.sort((a, b) => {
                            return a.name > b.name ? 1 : -1;
                        })!
                    }
                />
            ) : null}
            <CclErrorDialog
                open={showSearchToBroad}
                title={"Too Many Results"}
                msg={
                    "There are too many results for this search. Consider adjusting your search parameters and searching again."
                }
                onOk={() => setShowSearchToBroad(false)}
            />
            <Box width={1} sx={{ display: "flex", flexDirection: "row" }}>
                <CclTextSearchBar
                    initialSearchTerm={config.Email}
                    searchLabel="Enter Email"
                    executeSearch={(searchTerm: string) => handleSearchUsers("", "", searchTerm)}
                />
                <CclDoubleTextSearchBar
                    initialFieldOneSearchTerm={config.FirstName}
                    fieldOneSearchLabel={"Enter First Name"}
                    initialFieldTwoSearchTerm={config.LastName}
                    fieldTwoSearchLabel={"Enter Last Name"}
                    executeSearch={(fieldOneSearchTerm: string, fieldTwoSearchTerm: string) =>
                        handleSearchUsers(fieldOneSearchTerm, fieldTwoSearchTerm, "")
                    }
                    requireBothFields={false}
                    suppressErrors={false}
                />
                <Grid
                    container
                    spacing={1}
                    padding={2}
                    sx={{ maxWidth: "fit-content", height: "fit-content" }}
                >
                    <Grid item>
                        <CclUnrestrictedButton
                            onClick={() => {
                                handleSearchUsers("", "", "");
                            }}
                            aria-label="Get All Users"
                            disabled={props.application === undefined}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                            }}
                        >
                            Get All
                        </CclUnrestrictedButton>
                    </Grid>
                </Grid>
            </Box>
            <FlexGrid>
                <ApplicationUsersDataGrid
                    users={users}
                    isLoading={isLoading}
                    showResultBar={!isLoading}
                    resultBarText={`${
                        users?.length === 0
                            ? ""
                            : users?.length ?? (searchResultString !== "" ? "No" : "")
                    } ${searchResultString}`}
                    editButtonHandler={handleEditBtnClick}
                />
            </FlexGrid>
        </Stack>
    );
};

export default ApplicationUsersPanel;
