import * as React from "react";
import UnderConstruction from "../../components/common/underConstruction";
import { Box } from "@mui/material";

export interface ScoringReportDownloadsProps {}

export const ScoringReportDownloads: React.FC<ScoringReportDownloadsProps> = (props) => {
    return (
        <Box sx={{ width: 1 }}>
            <UnderConstruction
                msg={"Scoring Report Downloads status page is coming soon to CCL Access!"}
            />
        </Box>
    );
};

export default ScoringReportDownloads;
