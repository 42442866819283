import * as React from "react";
import { Card, CardHeader } from "@mui/material";
import {
    useGetCclEventByEventKeyQuery,
    useGetSessionOptionsByEventKeyQuery,
    useSetEventSessionOptionMutation,
} from "../../../../../services/cclTokenedSessionApi";
import {
    SessionEventOption,
    SetSessionEventOptionRequest,
} from "../../../../../services/types/sessionOptionsType";
import CompassOptionAlert from "./compassOptionAlert";
import CompassOptions from "./compassOptions";
import ComponentLoader from "../../../../../components/common/componentLoader";
import CclGenericConfirmationDialog from "../../../../../components/common/cclGenericConfirmationDialog";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import { useSendCompassInvitationsMutation } from "../../../../../services/cclTokenedGrandCentralApi";
import { SendCompassInvitationRequest } from "../../../../../services/types/rtkQueryTypes";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclUnrestrictedButton from "../../../../../components/common/cclButtons/cclUnrestrictedButton";

interface ActivateCompassCardProps {
    sessionId: string;
}

const ActivateCompassCard: React.FC<ActivateCompassCardProps> = (props) => {
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [btnText, setBtnText] = React.useState<string>("Select An Action");
    const [scheduledDate, setScheduledDate] = React.useState<Date | null>(null);
    const [alertState, setAlertState] = React.useState<
        "info" | "warning" | "error" | "success" | undefined
    >(undefined);
    const [activeOption, setActiveOption] = React.useState<
        "activate" | "schedule" | "resource" | "deactivate" | null
    >(null);
    const [savedOption, setSavedOption] = React.useState<
        "activate" | "schedule" | "resource" | "deactivate" | null
    >(null);
    const [btnDisabled, setBtnDisabled] = React.useState<boolean>(true);
    const {
        data: sessionDetails,
        isSuccess: detailsSuccess,
        isError,
    } = useGetCclEventByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });
    const { data: sessionOptions, isSuccess: sessionOptionsSuccess } =
        useGetSessionOptionsByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });
    const [
        setCompassOption,
        { isSuccess: isOptionSuccess, isLoading: isOptionLoading, isError: isOptionError },
    ] = useSetEventSessionOptionMutation();
    const [
        sendCompassInvitations,
        {
            data: compassInvitationResponse,
            isSuccess: compassInvitationsIsSuccess,
            isLoading: compassInvitationsIsLoading,
            isError: compassInvitationsIsError,
        },
    ] = useSendCompassInvitationsMutation();
    const { logEvent } = useLogAccessEvent();
    const { data: allParticipants } = useGetCclParticipantsByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const userClaimsService = new UserClaimsService();

    const invitationRadioLabels: string[] = [
        "Activate & Send Compass Invitations",
        "Schedule Activation and Invitations",
        "Assigned Resource Activates Compass",
        "Deactivate (Removes Scheduled Activation)",
    ];
    const noInvitationRadioLabels: string[] = [
        "Activate Compass",
        "Schedule Activation",
        "Deactivate (Removes Scheduled Activation)",
    ];

    React.useEffect(() => {
        if (
            compassInvitationsIsSuccess &&
            compassInvitationResponse != null &&
            compassInvitationResponse.numberFailed === 0
        )
            setAlertState("success");
        else if (compassInvitationsIsLoading) setAlertState("warning");
        else if (
            compassInvitationsIsError ||
            (compassInvitationResponse != null && compassInvitationResponse.numberFailed > 0)
        )
            setAlertState("error");
        // eslint-disable-next-line
    }, [compassInvitationsIsSuccess, compassInvitationsIsLoading, compassInvitationsIsError]);

    React.useEffect(() => {
        if (isOptionSuccess) {
            if (sessionDetails != null && allParticipants != null && allParticipants.length > 0) {
                const payload: SendCompassInvitationRequest = {
                    recipients: allParticipants.map((p) => {
                        return {
                            email: p.emailAddress,
                            firstName: p.firstName,
                            lastName: p.lastName,
                        };
                    }),
                    disableEmail: sessionDetails?.hostingPlatformKey === 10,
                    autoAccept: sessionDetails?.hostingPlatformKey === 10,
                    ignorePendingInvites: false,
                };
                if (activeOption === "activate") sendCompassInvitations(payload);
                else setAlertState("success");
            }
        } else if (isOptionLoading) setAlertState("warning");
        else if (isError) setAlertState("error");
        // eslint-disable-next-line
    }, [isOptionSuccess, isOptionLoading, isOptionError]);

    React.useEffect(() => {
        if (sessionOptionsSuccess && sessionOptions != null) {
            setAlertState("info");
            const compassopt = sessionOptions.sessionEventOptions.find((opt) => opt.optionId === 1);
            const coachopt = sessionOptions.sessionEventOptions.find((opt) => opt.optionId === 2);
            let opt: "activate" | "schedule" | "resource" | "deactivate" | null = null;
            if (compassopt == null && coachopt == null) opt = "deactivate";
            else if (coachopt?.active)
                // order important here
                opt = "resource";
            else if (compassopt?.active)
                // order important here
                opt = "activate";
            else opt = "schedule";

            setSavedOption(opt);
            setActiveOption(opt);
            if (
                compassopt !== null &&
                compassopt?.active &&
                sessionDetails?.hostingPlatformKey !== 10 &&
                (coachopt == null || !coachopt.active)
            )
                setBtnDisabled(true);
        }
        // eslint-disable-next-line
    }, [sessionOptions]);

    const handleDateChange = (date: Date | null, keyboardInputValue: string | undefined) => {
        setScheduledDate(date);
        if (activeOption === "schedule") {
            if (savedOption === activeOption) {
                const compassopt =
                    sessionOptions?.sessionEventOptions.find((opt) => opt.optionId === 1) ?? null;
                if (compassopt != null) {
                    const schedDate = new Date(compassopt.scheduledActivation + "Z");
                    if (date?.toISOString() !== schedDate.toISOString()) setAlertState("warning");
                    else setAlertState("info");
                }
            }
            if (date === null) setBtnDisabled(true);
            else setBtnDisabled(false);
        } else setBtnDisabled(false);
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        let disabled: boolean = value === savedOption;

        // if new state go to warning, if moved back to existing state go to info
        if (!disabled) setAlertState("warning");
        else setAlertState("info");

        switch (value) {
            case "activate":
                setBtnText(
                    sessionDetails?.hostingPlatformKey !== 10
                        ? invitationRadioLabels[0]
                        : noInvitationRadioLabels[0]
                );
                setScheduledDate(null);
                break;
            case "schedule":
                setBtnText(
                    sessionDetails?.hostingPlatformKey !== 10
                        ? invitationRadioLabels[1]
                        : noInvitationRadioLabels[1]
                );
                disabled = scheduledDate == null || value === savedOption;
                const compassopt =
                    sessionOptions?.sessionEventOptions.find((opt) => opt.optionId === 1) ?? null;
                if (compassopt != null) {
                    if (compassopt.scheduledActivation != null)
                        setScheduledDate(new Date(compassopt.scheduledActivation + "Z"));
                    else setScheduledDate(null);
                }
                break;
            case "resource":
                setBtnText(invitationRadioLabels[2]);
                setScheduledDate(null);
                break;
            case "deactivate":
                setBtnText(
                    sessionDetails?.hostingPlatformKey !== 10
                        ? invitationRadioLabels[3]
                        : noInvitationRadioLabels[2]
                );
                setScheduledDate(null);
                break;
            default:
                return;
        }

        setActiveOption(value);
        setBtnDisabled(disabled);
    };

    const onSaveClick = () => {
        setConfirmOpen(false);
        if (activeOption != null && detailsSuccess && sessionDetails != null) {
            let dt: Date | null = null;

            if (scheduledDate != null) {
                const dt_num = Date.UTC(
                    scheduledDate.getUTCFullYear(),
                    scheduledDate.getUTCMonth(),
                    scheduledDate.getUTCDate(),
                    scheduledDate.getUTCHours(),
                    scheduledDate.getUTCMinutes(),
                    scheduledDate.getUTCSeconds()
                );
                dt = new Date(dt_num);
            }

            // if we are activating resource, we want optId 2, but if we are deactivating from resource, we want 1 (compass option will delete child options)
            let optId: number = 1;
            let parentOptId: number | null = null;

            if (activeOption === "resource") {
                optId = 2;
                parentOptId = 1;
            }

            const option: SessionEventOption = {
                optionId: optId,
                optionName: "CCL Compass",
                parentOptionId: parentOptId,
                active: activeOption === "activate" || activeOption === "resource",
                scheduled: activeOption === "schedule",
                scheduledActivation: activeOption === "schedule" ? dt : null,
                changedBy: userClaimsService.GetCurrentUserEmail(),
                changedDate: new Date(),
            };

            const request: SetSessionEventOptionRequest = {
                eskey: sessionDetails.sessionKey.toString(),
                eventOption: option,
            };

            setCompassOption(request)
                .unwrap()
                .then(() => {
                    let tag: string = "CompassActivatedWithInvitations";
                    if (!option.active) {
                        if (option.scheduled) tag = "CompassActivationScheduled";
                        else tag = "ScheduledCompassActivationRemoved";
                    } else if (option.optionId === 2) {
                        tag = "AssignedResourcesProvidedCompassActivation";
                    }

                    const evtData: AccessEventSessionDetails = {
                        projectCode: sessionDetails?.sessionCode ?? "",
                    };

                    logEvent(tag, evtData);
                });
        }
    };

    if (detailsSuccess) {
        return (
            <Card sx={{ height: "100%", width: "100%", p: 2 }}>
                <CardHeader title={"Activate Compass"} sx={{ p: 1 }} />
                <CompassOptionAlert
                    severity={alertState}
                    state={activeOption}
                    needInvitations={sessionDetails.hostingPlatformKey !== 10}
                />
                <CompassOptions
                    sessionId={sessionDetails.sessionKey.toString()}
                    compassOption={
                        sessionOptionsSuccess
                            ? sessionOptions.sessionEventOptions.find(
                                  (opt) => opt.optionId === 1
                              ) ?? null
                            : null
                    }
                    coachOption={
                        sessionOptionsSuccess
                            ? sessionOptions.sessionEventOptions.find(
                                  (opt) => opt.optionId === 2
                              ) ?? null
                            : null
                    }
                    needsInvitations={sessionDetails.hostingPlatformKey !== 10}
                    radioLabels={
                        sessionDetails.hostingPlatformKey !== 10
                            ? invitationRadioLabels
                            : noInvitationRadioLabels
                    }
                    onRadioChange={handleOptionChange}
                    onScheduledDateChange={handleDateChange}
                />
                <CclGenericConfirmationDialog
                    open={confirmOpen}
                    onCancel={() => setConfirmOpen(false)}
                    onOk={() => onSaveClick()}
                />
                <CclUnrestrictedButton
                    disabled={btnDisabled}
                    sx={{ m: 2 }}
                    onClick={() => setConfirmOpen(true)}
                >
                    {btnText}
                </CclUnrestrictedButton>
            </Card>
        );
    }

    return <ComponentLoader msg={"Loading Compass Management Info"} />;
};

export default ActivateCompassCard;
