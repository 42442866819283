import * as React from "react";
import { Box, Stack } from "@mui/material";
import { useGetAllAttributeOptionsQuery, useGetProfilesAttributesQuery } from "../../../services/cclTokenedEnterpriseCoachingApi";
import MyAttributesTable from "./myAttributesDetail/myAttributeTable";

interface MyAttributesProps {
    readonly: boolean;
    pkey: string;
    fromResource: boolean;
    imKey: string;
    email: string;
}

const MyAttributesTabPanel: React.FC<MyAttributesProps> = (props) => {

    const { data: AllAttributesData } = useGetAllAttributeOptionsQuery();

    const { data: userProfileData } = useGetProfilesAttributesQuery(props.pkey, {
        skip: props.pkey === "",
    });

    return (
        <Box sx={{ width: 1, height: 1 }}>
            {userProfileData && AllAttributesData &&
                <Stack spacing={2}>
                    <MyAttributesTable 
                        allAttributes={AllAttributesData}
                        userProfileAttributes={userProfileData}
                        attributeKey={1}
                        attributeName="Education" 
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}  
                        readonly={props.readonly}                  
                    />
                    <MyAttributesTable 
                        allAttributes={AllAttributesData} 
                        userProfileAttributes={userProfileData} 
                        attributeKey={2}
                        attributeName="Languages"
                        fromResource={props.fromResource}     
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}          
                    />
                    <MyAttributesTable 
                        allAttributes={AllAttributesData} 
                        userProfileAttributes={userProfileData} 
                        attributeKey={3}
                        attributeName="Industry Experience"
                        fromResource={props.fromResource}
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}            
                    />
                    <MyAttributesTable 
                        allAttributes={AllAttributesData} 
                        userProfileAttributes={userProfileData} 
                        attributeKey={6}
                        attributeName="Assessments (maintained by CCL)"
                        fromResource={props.fromResource}    
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                    <MyAttributesTable 
                        allAttributes={AllAttributesData} 
                        userProfileAttributes={userProfileData} 
                        attributeKey={5}
                        attributeName="Professional Certifications"
                        fromResource={props.fromResource}  
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}        
                    />
                    <MyAttributesTable 
                        allAttributes={AllAttributesData} 
                        userProfileAttributes={userProfileData} 
                        attributeKey={8}
                        attributeName="Tools, Models, & Content"
                        fromResource={props.fromResource}   
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}   
                    />
                    <MyAttributesTable 
                        allAttributes={AllAttributesData} 
                        userProfileAttributes={userProfileData} 
                        attributeKey={7}
                        attributeName="Simulations, Experientials, & Activities"
                        fromResource={props.fromResource}        
                        imkey={props.imKey}
                        pkey={props.pkey}
                        email={props.email}
                        readonly={props.readonly}
                    />
                 </Stack>
            }
        </Box>
    );
};

export default MyAttributesTabPanel;
