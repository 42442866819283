import * as React from "react";
import { DataGridPro, GridColumns, GridRenderCellParams, GridRowId, useGridApiRef } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import { WorkPool } from "../../../services/types/resources/workPoolTypes";
import { addCustomDataGridFilters } from "../../../components/common/customDataGridFilters/customDataGridFilters";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import { Link } from "@mui/material";
import { useDeleteCadreByCkeyMutation } from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventWorkPoolDetails } from "../../../services/types/accessEventTypes";
import { useNavigate } from "react-router-dom";
import CclGenericConfirmationDialog from "../../../components/common/cclGenericConfirmationDialog";
import EditWorkPoolDialog from "./workPoolDetails/editWorkPool/editWorkPoolDialog";
import CclDataGridToolbar from "../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

export interface WorkPoolsSearchListProps {
    resources: WorkPool[];
    loading: boolean;
    addWorkPool: () => void;
    fromResources?: boolean;
}

export const WorkPoolsSearchDataGridPro: React.FC<WorkPoolsSearchListProps> = (props) => {
    const claimservice = new UserClaimsService();
    const isUserWorkPoolManager: boolean = claimservice.IsUserInAppRole(1);
    const { logEvent } = useLogAccessEvent();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
    const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false);

    const updateSelectedRows = (rows: any[] | undefined) => {
        if (!rows) return;
        let rowsMap: Map<GridRowId, any> = apiRef.current.getSelectedRows();
        let rowArray: any[] = [];
        for (let row of Array.from(rowsMap.values())) {
            rowArray.push(row);
        }
        setSelectedRows(rowArray);
    };

    const [deletePool] = useDeleteCadreByCkeyMutation();

    const deleteWorkPool = () => {
        if (!selectedRows[0]) {
            return;
        }
        deletePool(selectedRows[0].cKey)
            .unwrap()
            .then(() => {
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${selectedRows[0].cKey}`,
                    workPoolName: selectedRows[0].name ?? "",
                };
                logEvent("WorkPoolDeleted", evtData);
                navigate("/resources/6");
                setConfirmDelete(false);
            });
    };

    const COLUMNS = [
        {
            field: "name",
            headerName: "Name",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <Link component={RouterLink} to={`/work-pool/${params.row.cKey}`}>
                    {params.value}
                </Link>
            ),
            flex: 0.5,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            flex: 1,
        },
        {
            field: "typeName",
            headerName: "Category",
            type: "string",
            flex: 0.5,
        },
    ];

    const miscButtonClick = (id: string) => {
        switch (id) {
            case "delete-work-pool-button":
                setConfirmDelete(true);
                break;
            case "edit-work-pool-button":
                setConfirmEdit(true);
                break;
            case "add-work-pool-button":
                props.addWorkPool();
                break;
            default:
                break;
        }
    }


    return (
        <>
            <CclGenericConfirmationDialog
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onOk={() => deleteWorkPool()}
            />
            {confirmEdit && (
                <EditWorkPoolDialog
                    workPool={selectedRows[0]}
                    open={confirmEdit}
                    onClose={() => {
                        setConfirmEdit(false);
                        navigate("/resources/6");
                    }}
                />
            )}

            <DataGridPro
                getRowId={(row: WorkPool) => row.cKey}
                rows={props.resources}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                loading={props.loading}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={{
                    toolbar: {
                        csvOptions: {
                            allColumns: true,
                            fileName: "ccl-file.csv",
                        },
                        showSaveState: true,
                        savestate: null,
                        clearstate: null,
                        resultBar: !props.fromResources,
                        resultBarText: props.fromResources
                            ? ""
                            : `${props.resources.length} work pools found`,
                        miscButtons:
                            isUserWorkPoolManager && !props.fromResources
                                ? [
                                      {
                                          id: "delete-work-pool-button",
                                          caption: "Delete Work Pool",
                                          onClick: () => {
                                              setConfirmDelete(true);
                                          },
                                          returnSelected: true,
                                          restricted: true,
                                          disable:
                                              selectedRows === undefined ||
                                              selectedRows.length > 1 ||
                                              selectedRows.length === 0,
                                      },
                                      {
                                          id: "edit-work-pool-button",
                                          caption: "Edit Work Pool",
                                          onClick: () => {
                                              setConfirmEdit(true);
                                          },
                                          returnSelected: true,
                                          restricted: true,
                                          disable:
                                              selectedRows === undefined ||
                                              selectedRows.length > 1 ||
                                              selectedRows.length === 0,
                                      },
                                      {
                                          id: "add-work-pool-button",
                                          caption: "Create Work Pool",
                                          onClick: props.addWorkPool,
                                          returnSelected: true,
                                          restricted: true,
                                      },
                                  ]
                                : [],
                        btnClick: miscButtonClick,
                        hideSettingsButtons: false,
                        anySelected: null, //anyRowsSelected,
                    },
                }}
                initialState={{
                    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                }}
                apiRef={apiRef}
                hideFooter={!props.fromResources}
                checkboxSelection={isUserWorkPoolManager && !props.fromResources}
                disableSelectionOnClick={false}
                onSelectionModelChange={(rows: any[] | undefined) => updateSelectedRows(rows)}
            />
        </>
    );
};

export default WorkPoolsSearchDataGridPro;
