import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclPersistentDataGrid from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { updateProductSaleGridState } from "../../app/slices/productSaleListTableStateSlice";
import { useSelector } from "react-redux";
import { ProductSaleParticipant } from "../../services/types/enterpriseParticipantApiTypes";
import { IconButton, Tooltip } from "@mui/material";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import CclDownloadWarningDialog from "../../components/common/cclDownloadWarningDialog";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../components/common/cclCommonLink";

export interface ProductSaleAssessmentListProps {
    productSales: ProductSaleParticipant[];
    loading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
    downloadReports: (rows?: any[]) => any;
    activateCompass: (rows?: any[]) => any;
    changeFacilitator: (rows?: any[]) => any;
    viewCompass: (imkey: number) => void;
}

export const ProductSaleAssessmentList: React.FC<ProductSaleAssessmentListProps> = (props) => {
    const [showExpirationWarning, setShowExpirationWarning] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const claimsService = new UserClaimsService();
    const stateSelector = useSelector((state: any) => state.productSaleListTableState);
    const isUserProductSaleManger = claimsService.IsUserInAppRole(2); // check for product sale manager role

    const COLUMNS = [
        {
            field: "imKey",
            headerName: "ID",
            type: "string",
            width: 125,
        },
        {
            field: "fullName",
            headerName: "Participant",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/user?email=${encodeURI(params.row.emailAddress)}&imkey=${
                        params.row.imKey
                    }`}
                />
            ),
            flex: 0.4,
        },
        {
            field: "emailAddress",
            headerName: "Participant Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 0.75,
        },
        {
            field: "facilitatorName",
            headerName: "Facilitator",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/user?email=${encodeURI(params.row.facilitatorEmail)}&imkey=${
                        params.row.facilitatorImKey
                    }`}
                />
            ),
            flex: 0.4,
        },
        {
            field: "facilitatorEmail",
            headerName: "Facilitator Email",
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 0.75,
        },
        {
            field: "assessmentType",
            headerName: "Assessment Type",
            type: "string",
            flex: 0.75,
        },
        {
            field: "scoreDate",
            headerName: "Scored Date",
            valueGetter: (params: any) => new Date(params.row.scoreDate),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 150,
        },
        {
            field: "isReleasedToCompass",
            headerName: "Released (Compass)",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    {isUserProductSaleManger ? (
                        <Tooltip title="View In Compass">
                            <IconButton
                                size="small"
                                onClick={() => props.viewCompass(params.row.imKey)}
                                color="secondary"
                                sx={{ pr: 2, pl: 2 }}
                            >
                                <ExploreOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {params.value ? "Yes" : "No"}
                </span>
            ),
            width: 150,
        },
    ];

    const completeDownload = () => {
        setShowExpirationWarning(false);
        props.downloadReports(selectedRows);
    };
    const handleDownload = (rows?: any[]) => {
        setShowExpirationWarning(true);
        setSelectedRows(rows ?? []);
    };

    return (
        <React.Fragment>
            <CclDownloadWarningDialog
                open={showExpirationWarning}
                onOk={() => completeDownload()}
            />
            <CclPersistentDataGrid
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                data={props.productSales}
                loading={props.loading}
                stateSelector={stateSelector}
                userPreferenceId={9}
                getRowIdFn={(row: ProductSaleParticipant) => row.reportId}
                actionCreator={updateProductSaleGridState}
                hideToolbar={props.hideToolbar === true}
                initState={JSON.stringify({
                    sorting: { sortModel: [{ field: "scoreDate", sort: "desc" }] },
                })}
                resultBar={props.showResultBar ?? false}
                resultBarText={props.resultBarText ?? ""}
                miscButtons={
                    isUserProductSaleManger
                        ? [
                              {
                                  id: "change-facilitator-button",
                                  caption: "Change Facilitator",
                                  onClick: props.changeFacilitator,
                                  returnSelected: true,
                                  restricted: true,
                                  disable: selectedRows.length === 0,
                              },
                              {
                                  id: "activate-compass-button",
                                  caption: "Activate Compass",
                                  onClick: props.activateCompass,
                                  returnSelected: true,
                                  restricted: true,
                                  disable: selectedRows.length === 0,
                              },
                              {
                                  id: "download-reports-button",
                                  caption: "Download Assessment(s)",
                                  onClick: handleDownload,
                                  returnSelected: true,
                                  restricted: true,
                                  disable: selectedRows.length === 0,
                              },
                          ]
                        : []
                }
                showCheckboxes={isUserProductSaleManger}
                disableSelectionOnClick={isUserProductSaleManger}
                onSelectedRowsChange={(rows) => setSelectedRows(rows as any[])}
            />
        </React.Fragment>
    );
};

export default ProductSaleAssessmentList;
