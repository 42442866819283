import * as React from "react";
import { Grid } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import { SessionDetailType } from "../../../../services/types/sessionDetailTypes";
import SessionDetailsAccordian from "./details/sessionDetailsAccordion";
import SessionAssessmentSetupAccordion from "./assessmentSetup/sessionAssessmentsSetupAccordian";
import { SessionProgramManagersAccordion } from "./programManagers/sessionProgramManagersAccordian";
import SessionSetupAccordion from "./setup/sessionSetupAccordion";

interface SessionGeneralPanelProps {
    sessionId: string;
    readOnly?: boolean;
}

const SessionGeneralPanel: React.FC<SessionGeneralPanelProps> = (props) => {
    const {
        data: sessionData,
        isLoading,
        isSuccess,
    } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });
    const [sessionDetail, setSessionDetail] = React.useState<SessionDetailType | undefined>(
        undefined
    );

    React.useEffect(() => {
        if (sessionData === undefined) {
            setSessionDetail(undefined);
            return;
        }

        let sd: SessionDetailType = JSON.parse(JSON.stringify(sessionData));
        sd.start = new Date(sd.start.toString().slice(0, -1));
        sd.end = new Date(sd.end.toString().slice(0, -1));
        setSessionDetail(sd);
    }, [sessionData]);

    if (isLoading) {
        return <ComponentLoader msg={"Loading Session Info"} />;
    }

    if (isSuccess && sessionDetail !== undefined) {
        return (
            <Grid container spacing={2} sx={{ height: "fit-content" }}>
                <Grid item xs={12} md={6}>
                    <SessionDetailsAccordian sessionDetail={sessionDetail} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SessionSetupAccordion
                        esKey={sessionDetail.sessionKey}
                        readOnly={props.readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SessionAssessmentSetupAccordion
                        esKey={sessionDetail.sessionKey}
                        projectCode={sessionDetail.sessionCode}
                        hasAssessmentPlatform={sessionDetail.assessmentPlatform !== "N/A"}
                        readOnly={props.readOnly}
                    />
                </Grid>
                {sessionDetail?.type.trim() === "Custom" ||
                sessionDetail?.type.trim() === "Coaching" ? (
                    <Grid item xs={12}>
                        <SessionProgramManagersAccordion
                            esKey={sessionDetail.sessionKey}
                            programCode={sessionDetail.sessionCode}
                            hasAssessmentPlatform={sessionDetail.assessmentPlatform !== "N/A"}
                            readOnly={props.readOnly}
                        />
                    </Grid>
                ) : null}
            </Grid>
        );
    }
    return <span>No Session Data Loaded</span>;
};

export default SessionGeneralPanel;
