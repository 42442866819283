import * as React from "react";
import { TextField, Typography } from "@mui/material";

export interface CclInfoTableEditTextProps {
    value: string;
    onChange: (newText: string, required: boolean) => void;
    required: boolean;
    readonly: boolean;
}

const CclInfoTableEditText: React.FC<CclInfoTableEditTextProps> = (props) => {
    const [text, setText] = React.useState<string>(props.value);

    React.useEffect(() => {
        setText(props.value);
    }, [props.value]);

    const handleChange = (newText: string) => {
        setText(newText);
        props.onChange(newText, props.required);
    };

    if (props.readonly) {
        return (
            <Typography sx={{ fontSize: 14 }}>
                {text === "" ? " " : text}
            </Typography>
        );
    }

    return (
        <TextField
            id="info-text-input"
            value={text}
            error={props.required && !text ? true : false}
            label={props.required && !text ? "Required" : ""}
            onChange={(e) => handleChange(e.target.value)}
            variant={"standard"}
            sx={{ fontSize: 14, width: 1 }}
            disabled={props.readonly}
        />
    );
};

export default CclInfoTableEditText;
