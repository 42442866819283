import * as React from "react";
import { Alert, Grid, Stack } from "@mui/material";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import ComponentLoader from "../../../../components/common/componentLoader";
import ApplicationAccountsDataGridPro from "../../../../components/shared/applicationAccountsDataGridPro/applicationAccountsDataGridPro";
import InvitationsCardContent from "../../../../components/shared/invitationCardContent/invitationsCardContent";
import {
    useDisableAccessAppllicationMutation,
    useEnableAccessAppllicationMutation,
    useGetCclGCUserAccountDetailQuery,
    useGetCclUserAccountDetailQuery,
    useGetCclUserApplicationAccountsQuery,
    useLazyGetGroupsByTenantIdQuery,
    useUpdateUserAccountMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { AccessEventApplicationDetails, AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import {
    ApplicationAccount,
    UpdateUserAccountRequest,
    UpdateUserAccountRequestPayload,
} from "../../../../services/types/rtkQueryTypes";
import SendInvitationDialog from "./sendInvitationDialog";
import UserEditApplicationDialog from "./userEditApplicationDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import UserDetailsCard from "../general/userDetailsCard";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { flattenObject } from "../../../../utilities/flattenObject";
import { Profile } from "../../../../services/types/enterpriseCoachingApiTypes";
import { CclInfoTblResourceInfoTemplate } from "../../../resources/resourceDetail/info/cclInfoTblResourceInfoTemplate";
import { useGetResourceByEmailQuery } from "../../../../services/cclTokenedResourceApi";

interface UserApplicationsPanelProps {
    emailAddress: string;
    firstName: string;
    lastName: string;
    imKey: string;
    profile: Profile;
}

const UserApplicationsPanel: React.FC<UserApplicationsPanelProps> = (props) => {
    const claimsService = new UserClaimsService();
    const isUserAdmin = claimsService.IsUserSuperAdmin();
    const [confirmOpenEnable, setConfirmOpenEnable] = React.useState<boolean>(false);
    const [confirmOpenDisable, setConfirmOpenDisable] = React.useState<boolean>(false);
    const [enableAccessAppllication] = useEnableAccessAppllicationMutation();
    const [disableAccessAppllication] = useDisableAccessAppllicationMutation();
    const [updateUserAccount] = useUpdateUserAccountMutation();
    const [getGroupbyTenantID, { data: groupsData }] = useLazyGetGroupsByTenantIdQuery();
    const { logEvent } = useLogAccessEvent();
    const { data: accounts, isLoading } = useGetCclUserApplicationAccountsQuery(
        props.emailAddress,
        {
            skip: props.emailAddress === "",
        }
    );
    const { data: enterpriseData } = useGetCclUserAccountDetailQuery(props.emailAddress, {
        skip: props.emailAddress == null || props.emailAddress === "",
    });
    const { data: grandCentralData, isLoading: gcLoading } = useGetCclGCUserAccountDetailQuery(props.emailAddress, {
        skip: props.emailAddress == null || props.emailAddress === "",
    });
    const [accountUserApp, setaccountUserApp] = React.useState<ApplicationAccount[]>();
    const [enableButton, setEnableButton] = React.useState<boolean>();
    const [disableButton, setDisableButton] = React.useState<boolean>();
    const [showEditDialog, setshowEditDialog] = React.useState<boolean>(false);
    const [userAccount, setUserAccount] = React.useState<ApplicationAccount | null>(null);
    const [accountId, setAccountId] = React.useState<string>("");
    const [sendInvitationOpen, setSendInvitationOpen] = React.useState<boolean>(false);
    const [sendInvitationAlertOpen, setSendInvitationAlertOpen] = React.useState<boolean>(false);
    const [sendInvitationAlertMessage, setSendInvitationAlertMessage] = React.useState<string>("");
    const [sendInvitationAlertSeverity, setSendInvitationAlertSeverity] = React.useState<
        "success" | "error"
    >("success");
    const [modTemplate, setModTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<ApplicationAccount>();
    const { data: resource } = useGetResourceByEmailQuery(props.emailAddress, {
        skip: props.emailAddress == null || props.emailAddress === "",
    });

    React.useEffect(() => {
        if (accounts) {
            setaccountUserApp(accounts);
        }
    }, [accounts]);

    React.useEffect(() => {
        if (!props.profile || !props.profile.email) return;
        const enterpriseDetail = enterpriseData?.find(
            (e) => e.email.toLowerCase() === props.profile.email.toLowerCase()
        );

        var newTemplate = CclInfoTblResourceInfoTemplate;
        var rowitem = newTemplate.find((r) => r.id === "ceContactId");
        if (typeof rowitem !== "undefined" && rowitem !== null) {
            rowitem.valueField.value = enterpriseDetail?.ceContactId ?? "";
        }

        rowitem = newTemplate.find((r) => r.id === "bookableresourceid");
        if (typeof rowitem !== "undefined" && rowitem !== null && resource) {
            rowitem.valueField.value =
                resource[0]?.bookableresourceid ?? "This user has no bookable resource id";
        }

        setModTemplate([...newTemplate]);
    }, [props.profile, enterpriseData, resource]);

    React.useEffect(() => {
        if (accountUserApp) {
            if (accountUserApp.filter((a) => a.enabled === true).length > 0) {
                setDisableButton(true);
            } else {
                setDisableButton(false);
            }
            if (accountUserApp.filter((a) => a.enabled === false).length > 0) {
                setEnableButton(true);
            } else {
                setEnableButton(false);
            }
        }
    }, [accountUserApp]);

    const openEditApplicationDialog = () => {
        if (!selectedRow) return;
        var tentantId = selectedRow.applicationId;
        getGroupbyTenantID(tentantId).unwrap();
        var acc = accountUserApp?.filter((a) => a.applicationId === tentantId);
        if (acc && acc?.length > 0) {
            setUserAccount(acc[0]);
            setAccountId(acc[0].accountId);
            setshowEditDialog(true);
        }
    };

    const updateUserApplication = (
        enabled: boolean,
        newGroups: string[] | undefined,
        expirationDate: Date | null
    ) => {
        if (userAccount === null) return;
        const addGroups: string[] =
            newGroups?.filter((g) => !userAccount.groups?.includes(g)) ?? [];
        const addGroupIds: string[] =
            addGroups.length === 0
                ? []
                : groupsData
                      ?.filter((g) => addGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];
        const removeGroups: string[] =
            userAccount.groups?.filter((g) => !newGroups?.includes(g)) ?? [];
        const removeGroupIds: string[] =
            removeGroups.length === 0
                ? []
                : groupsData
                      ?.filter((g) => removeGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];

        let payload: UpdateUserAccountRequestPayload = {
            accountId: accountId,
            enabled: enabled,
            expirationDate: expirationDate,
            addToGroups: addGroupIds,
            removeFromGroups: removeGroupIds,
        };

        let request: UpdateUserAccountRequest = {
            userId: userAccount.userId,
            payload: payload,
        };

        updateUserAccount(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventApplicationDetails = {
                    applicationId: userAccount.applicationId,
                    applicationName: userAccount.application,
                    imKey: +props.imKey,
                    email: props.emailAddress,
                };

                logEvent("UserApplicationAccountEdited", evtData);

                const accountDataEnable = accountUserApp?.map((a) => {
                    if (a.userId !== userAccount.userId) {
                        return a;
                    } else {
                        return {
                            ...a,
                            enabled: enabled,
                            groups: newGroups !== undefined ? newGroups : [],
                            expiration: expirationDate,
                        };
                    }
                });
                setaccountUserApp(accountDataEnable);
            });
        setshowEditDialog(false);
    };

    const enableUserAccess = () => {
        setConfirmOpenEnable(false);
        if (accounts) {
            var userId: string[] = [];
            accounts.forEach((usr) => {
                userId.unshift(usr.userId);
            });
            enableAccessAppllication(userId).then(() => {
                const evtData: AccessEventIndividualDetails = {
                    imKey: props.imKey,
                    email: props.emailAddress,
                };

                logEvent("UserApplicationAccessEnabled", evtData);
            });
            const accountDataEnable = accountUserApp?.map((a) => {
                if (a.enabled === true) {
                    return a;
                } else {
                    return { ...a, enabled: true };
                }
            });
            setaccountUserApp(accountDataEnable);
        }
    };

    const disableUserAccess = () => {
        setConfirmOpenDisable(false);
        if (accounts) {
            var userId: string[] = [];
            accounts.forEach((usr) => {
                userId.unshift(usr.userId);
            });
            disableAccessAppllication(userId).then(() => {
                const evtData: AccessEventIndividualDetails = {
                    imKey: props.imKey,
                    email: props.emailAddress,
                };

                logEvent("UserApplicationAccessDisabled", evtData);
            });

            const accountDataEnable = accountUserApp?.map((a) => {
                if (a.enabled === false) {
                    return a;
                } else {
                    return { ...a, enabled: false };
                }
            });
            setaccountUserApp(accountDataEnable);
        }
    };

    const closeSendInvitationDialog = (result: boolean | null, message: string | null) => {
        if (result === true) {
            setSendInvitationAlertMessage("Invitation sent successfully.");
            setSendInvitationAlertSeverity("success");
            setSendInvitationAlertOpen(true);
        } else if (result === false) {
            setSendInvitationAlertMessage(message ?? "Error sending invitation.");
            setSendInvitationAlertSeverity("error");
            setSendInvitationAlertOpen(true);
        }

        setSendInvitationOpen(false);
    };

    const closeSendInvitationAlert = () => {
        setSendInvitationAlertOpen(false);
    };

    const handleSave = () => {
        return;
    };

    const handleChange = () => {
        return;
    };

    const startEnableAllFlow = () => {
        setConfirmOpenEnable(true);
    };

    const startDisableAllFlow = () => {
        setConfirmOpenDisable(true);
    };

    const handleSelectionChange = (selection: (ApplicationAccount | undefined)[]) => {
        setSelectedRow(selection[0]);
        return;
    };

    const startSendInvitationFlow = () => {
        setSendInvitationOpen(true);
    };

    if (isLoading) {
        return <ComponentLoader msg={"Loading User Application Accounts..."} />;
    }

    return (
        <Stack height={1} width={1}>
            <CclAlertDialog
                open={sendInvitationAlertOpen}
                onOk={closeSendInvitationAlert}
                msg={sendInvitationAlertMessage}
                title={`Invitation ${
                    sendInvitationAlertSeverity === "success" ? "" : " Not"
                } Sent`}
                severity={sendInvitationAlertSeverity}
            />
            {groupsData !== undefined && userAccount != null ? (
                <UserEditApplicationDialog
                    emailAddress={props.emailAddress}
                    userAccount={userAccount}
                    open={showEditDialog}
                    onOk={updateUserApplication}
                    onCancel={() => setshowEditDialog(false)}
                    tenantGroups={
                        [...groupsData!]?.sort((a, b) => {
                            return a.name > b.name ? 1 : -1;
                        })!
                    }
                />
            ) : null}
            <Grid container spacing={2}>
                {enterpriseData != null && !gcLoading && (
                    <Grid item xs={6}>
                        <UserDetailsCard
                            gcDetail={grandCentralData ?? null}
                            enterpriseDetails={enterpriseData ?? null}
                            gcAccountEmail={props.emailAddress}
                            imKey={+props.imKey}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <CclGenericAccordian headingText={"D365"}>
                        {!props.profile.email && (
                            <Alert severity="info">
                                No D365 Bookable Resource Record exists for this user{" "}
                            </Alert>
                        )}
                        {props.profile.email && (
                            <CclInfoTable
                                template={modTemplate}
                                data={flattenObject(props.profile)}
                                onSave={handleSave}
                                onChange={handleChange}
                            />
                        )}
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12}>
                    <CclGenericAccordian headingText="Applications">
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <CclGenericConfirmationDialog
                                open={confirmOpenEnable}
                                onCancel={() => setConfirmOpenEnable(false)}
                                msg={"Are you sure you want to enable access to all applications?"}
                                onOk={enableUserAccess}
                            />
                            <CclGenericConfirmationDialog
                                open={confirmOpenDisable}
                                onCancel={() => setConfirmOpenDisable(false)}
                                msg={"Are you sure you want to disable access to all applications?"}
                                onOk={disableUserAccess}
                            />
                        </Stack>
                        <ApplicationAccountsDataGridPro
                            isSuperAdmin={isUserAdmin}
                            onEditClick={openEditApplicationDialog}
                            accounts={accountUserApp}
                            enableButton={enableButton}
                            disableButton={disableButton}
                            enableAll={startEnableAllFlow}
                            disableAll={startDisableAllFlow}
                            updateSelected={handleSelectionChange}
                        />
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12}>
                    <SendInvitationDialog
                        firstName={props.firstName}
                        lastName={props.lastName}
                        email={props.emailAddress}
                        open={sendInvitationOpen}
                        onClose={(result, message) => closeSendInvitationDialog(result, message)}
                        imKey={props.imKey}
                    />
                    <CclGenericAccordian headingText="Invitations">
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            width={1}
                        ></Stack>
                        <InvitationsCardContent
                            showInvitationId={true}
                            email={props.emailAddress}
                            sendInvitation={() => startSendInvitationFlow()}
                        />
                    </CclGenericAccordian>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default UserApplicationsPanel;
