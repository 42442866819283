import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclPersistentDataGrid from "../../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import {
    ApplicationRole,
    RemoveRoleFromUser,
    UserApplicationRoleDetailed,
} from "../../../../services/types/applicationRolesTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import { useRemoveRolefromUserMutation } from "../../../../services/cclTokenedApplicationRolesApi ";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventUserApplicationRole } from "../../../../services/types/accessEventTypes";
import { updateApplicationRolesSearchGridState } from "../../../../app/slices/applicationRolesSearchListTableStateSlice";
import CclCommonLink from "../../../../components/common/cclCommonLink";

export interface ApplicationRolesDataGridProps {
    users: UserApplicationRoleDetailed[];
    roles: ApplicationRole[];
    isLoading: boolean;
    startAddRoleFlow: () => void;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
}

export const ApplicationRolesDataGrid: React.FC<ApplicationRolesDataGridProps> = (props) => {
    const claimservice = new UserClaimsService();
    const { logEvent } = useLogAccessEvent();

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<UserApplicationRoleDetailed[]>([]);

    const [deleteUserRole, { isLoading }] = useRemoveRolefromUserMutation();

    function getFullName(params: GridRenderCellParams) {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    }

    const getUserRoles = (params: GridRenderCellParams) => {
        let rolesText = getRoleFromKey(params.row.applicationRoleKey);
        return rolesText;
    };

    const getRoleFromKey = (key: number) => {
        for (let i = 0; i < props.roles.length; i++) {
            if (key === props.roles[i].applicationRoleKey) {
                return props.roles[i].roleName;
            }
        }
        return "";
    };

    const startDelete = () => {
        if (selectedRows.length !== 1) return;
        setOpenDeleteConfirmation(true);
    };

    const handleDeleteRole = () => {
        const selectedRow = selectedRows[0];
        const payload: RemoveRoleFromUser = {
            roleKey: selectedRow.applicationRoleKey,
            imKey: selectedRow.imKey,
        };
        deleteUserRole(payload)
            .unwrap()
            .then((e) => {
                const evtData: AccessEventUserApplicationRole = {
                    imKey: selectedRow.imKey.toString(),
                    email: selectedRow.email,
                };
                logEvent("UserApplicationRoleDeleted", evtData);
                setOpenDeleteConfirmation(false);
            });
    };

    const COLUMNS = [
        {
            field: "name",
            headerName: "User",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) =>
                params.row.imKey !== "0" ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
                    />
                ) : (
                    ""
                ),
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 1,
        },
        {
            field: "roles",
            headerName: "Role",
            flex: 1,
            valueGetter: getUserRoles,
        },
    ];

    const stateSelector = useSelector((state: any) => state.applicationRolesSearchListTableState);

    return (
        <>
            <CclGenericConfirmationDialog
                open={openDeleteConfirmation}
                onCancel={() => {
                    setOpenDeleteConfirmation(false);
                }}
                onOk={handleDeleteRole}
            />
            <CclPersistentDataGrid
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                data={props.users}
                loading={props.isLoading || isLoading}
                stateSelector={stateSelector}
                userPreferenceId={0}
                getRowIdFn={(row: UserApplicationRoleDetailed) => row.userApplicationRoleKey}
                actionCreator={updateApplicationRolesSearchGridState}
                hideToolbar={props.hideToolbar === true}
                initState={JSON.stringify({
                    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                })}
                resultBar={props.showResultBar ?? false}
                resultBarText={props.resultBarText ?? ""}
                showCheckboxes={true}
                hideSettingsButtons={true}
                hideFooter={false}
                miscButtons={
                    claimservice.IsUserSuperAdmin()
                        ? [
                              {
                                  id: "delete-role-for-user-button",
                                  caption: "Delete",
                                  onClick: startDelete,
                                  returnSelected: false,
                                  restricted: true,
                                  disable: selectedRows.length !== 1,
                              },
                              {
                                  id: "add-role-to-user-button",
                                  caption: "Add",
                                  onClick: props.startAddRoleFlow,
                                  returnSelected: true,
                                  restricted: true,
                              },
                          ]
                        : []
                }
                onSelectedRowsChange={(selectedRows) =>
                    setSelectedRows(selectedRows as UserApplicationRoleDetailed[])
                }
            />
        </>
    );
};

export default ApplicationRolesDataGrid;
