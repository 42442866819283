import * as React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import ConfigureInvitationStepPanel from "./configureInvitationStepPanel";
import SelectRecipientsStepPanel from "./selectRecipientsStepPanel";
import SendInvitationsStepPanel from "./sendInvitationsStepPanel";
import { Application, Tenant } from "../../../../services/types/cclGrandCentralApiTypes";
import { RecipientInfo, SendInvitationsRequest } from "../../../../services/types/rtkQueryTypes";

export interface SendInvitationsStepperProps {
    applications: Application[];
    onFinish: (request: SendInvitationsRequest | null) => void;
}

export const SendInvitationsStepper: React.FC<SendInvitationsStepperProps> = (props) => {
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [selectedApplication, setSelectedApplication] = React.useState<Application | null>(null);
    const [selectedTenant, setSelectedTenant] = React.useState<Tenant | null>(null);
    const [selectedSystemId, setSelectedSystemId] = React.useState<string>("");
    const [request, setRequest] = React.useState<SendInvitationsRequest | null>(null);

    const onInvitationConfigChange = (
        request: SendInvitationsRequest,
        app: Application | undefined,
        tenant: Tenant | undefined,
        systemId: string
    ) => {
        setRequest(request);
        setSelectedApplication(app ?? null);
        setSelectedSystemId(systemId);
        setSelectedTenant(tenant ?? null);
        setActiveStep(1);
    };

    const onRecipientsChange = (recipients: RecipientInfo[]) => {
        if (request === null) return;

        setRequest({
            ...request,
            recipients: recipients,
        });
        setActiveStep(2);
    };

    const steps: [string, React.ReactNode][] = [
        [
            "Select Invitation Settings",
            <ConfigureInvitationStepPanel
                onNextPanel={onInvitationConfigChange}
                request={request}
                systemId={selectedSystemId}
            />,
        ],
        [
            "Select Recipients",
            <SelectRecipientsStepPanel
                onNextPanel={onRecipientsChange}
                onPreviousPanel={() => setActiveStep(0)}
            />,
        ],
        [
            "Send Invitations",
            <SendInvitationsStepPanel
                application={selectedApplication}
                tenant={selectedTenant}
                request={request}
                onFinalize={() => props.onFinish(request)}
                onPreviousPanel={() => setActiveStep(1)}
            />,
        ],
    ];

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    const stepProps: { completed?: boolean; key: number } = { key: index };

                    return (
                        <Step {...stepProps}>
                            <StepLabel>{step[0]}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {steps[activeStep][1]}
        </Box>
    );
};

export default SendInvitationsStepper;
