import * as React from "react";
import { GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import CclPersistentDataGrid from "../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { PartnerGroupPerson } from "../../../services/types/enterpriseNetworkPartnerTypes";
import { updatePartnerResourceGridState } from "../../../app/slices/partnerResourceListTableStateSlice";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../../components/common/cclCommonLink";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

export interface PartnerResourcesListProps {
    resources: PartnerGroupPerson[];
    loading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
    hidePartner: boolean;
    addPartnerResource: () => void;
    editPartnerResource: (imkey: number) => void;
}

export const PartnerResourcesDataGridPro: React.FC<PartnerResourcesListProps> = (props) => {
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const claimsService = new UserClaimsService();
    const isReadOnly: boolean = claimsService.IsReadOnly();

    const stateSelector = useSelector((state: any) => state.partnerResourceListTableState);
    const [resources, setResources] = React.useState<PartnerGroupPerson[]>(props.resources);

    const COLUMNS = [
        {
            field: "imKey",
            headerName: "ID",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
                />
            ),
        },
        {
            field: "name",
            headerName: "Resource",
            type: "string",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) =>
                !props.hidePartner || claimsService.IsUserNwaAdmin() ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
                    />
                ) : (
                    params.value
                ),
            flex: 0.8,
        },
        {
            field: "pgDescription",
            headerName: "Partner",
            type: "string",
            flex: 1.3,
            hide: props.hidePartner,
        },
        {
            field: "email",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 1.3,
        },
        {
            field: "active",
            headerName: "Active Coach",
            type: "string",
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.active && params.row.isCoach ? "Yes" : "No"}`,
            flex: 0.35,
        },
        {
            field: "isAdmin",
            headerName: "Admin",
            valueGetter: (params: GridValueGetterParams) => `${params.value ? "Yes" : "No"}`,
            flex: 0.35,
        },
    ];

    React.useLayoutEffect(() => {
        setResources(props.resources);
    }, [props.resources]);

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={resources}
            loading={props.loading}
            stateSelector={stateSelector}
            userPreferenceId={11}
            getRowIdFn={(row: PartnerGroupPerson) => row.imKey + row.pgKey}
            actionCreator={updatePartnerResourceGridState}
            hideToolbar={props.hideToolbar === true}
            miscButtons={
                isReadOnly
                    ? []
                    : [
                          {
                              id: "edit-partner-resource-button",
                              caption: "Edit Resource",
                              onClick: () => props.editPartnerResource(selectedRows[0]?.imKey),
                              returnSelected: true,
                              restricted: false,
                              disable: selectedRows.length !== 1,
                          },
                          {
                              id: "add-partner-resource-button",
                              caption: "Add Resource",
                              onClick: props.addPartnerResource,
                              returnSelected: true,
                              restricted: false,
                          },
                      ]
            }
            initState={JSON.stringify({ sorting: { sortModel: [{ field: "name", sort: "asc" }] } })}
            resultBar={props.showResultBar ?? false}
            resultBarText={props.resultBarText ?? ""}
            showCheckboxes={true}
            onSelectedRowsChange={(rows) => setSelectedRows(rows as any[])}
        />
    );
};

export default PartnerResourcesDataGridPro;
