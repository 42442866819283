import * as React from "react";
import { Grid } from "@mui/material";
import WorkPoolsDataGridPro from "./workPoolsSearchList";
import { WorkPool } from "../../../services/types/resources/workPoolTypes";
import {  useGetAllCadresQuery } from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import AddWorkPool from "./addWorkPool";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";


interface WorkPoolsTabPanelProps {}

const WorkPoolsTabPanel: React.FC<WorkPoolsTabPanelProps> = () => {
    const { data: getAllWorkPools, isLoading: loading } = useGetAllCadresQuery();
    const claimservice = new UserClaimsService();
    
    const [resources, setResources] = React.useState<WorkPool[]>([]);
    const [triggerNewPool, setTriggerNewPool] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!getAllWorkPools) {
            return;
        }

        if (claimservice.IsCCPUser()) {
            let editable = [...getAllWorkPools];
            editable = editable.filter((e) => e.ctKey === 2)
            setResources(editable);
        } else {
            setResources(getAllWorkPools);
        }
        
        // eslint-disable-next-line
    }, [getAllWorkPools, loading]);

    const startNewPoolFlow = () => {
        setTriggerNewPool(true);
    }

    return (
        <React.Fragment>
            {triggerNewPool && 
                <AddWorkPool 
                    open={triggerNewPool}
                    onClose={()=> {
                        setTriggerNewPool(false);
                    }}
                />
            }
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid item sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}>
                    {resources && (
                        <WorkPoolsDataGridPro 
                            loading={loading} 
                            resources={resources} 
                            addWorkPool={startNewPoolFlow}
                        />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default WorkPoolsTabPanel;