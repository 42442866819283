import * as React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { getErrorMsg } from "../../../utilities/rtkQueryErrorHelpers";
import { useGetCclUserApplicationAccountsQuery } from "../../../services/cclTokenedGrandCentralApi";
import ComponentLoader from "../../common/componentLoader";
import ApplicationAccountsDataGridPro from "../applicationAccountsDataGridPro/applicationAccountsDataGridPro";

interface ApplicationsCardContentProps {
    email: string;
}

const ApplicationsCardContent: React.FC<ApplicationsCardContentProps> = (props) => {
    const {
        data: accounts,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCclUserApplicationAccountsQuery(props.email, { skip: props.email === "" });

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isSuccess) {
        return <ApplicationAccountsDataGridPro accounts={accounts} hideCustomButtons={true} />;
    }

    if (isLoading || isLoading) {
        return <ComponentLoader msg={"Loading Accounts"} />;
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving application accounts for registration email ${
                props.email
            } (${getErrorMsg(error, "")})`
        );
    }

    return showErrorAlert(
        `Unknown error retrieving application accounts for registration email ${props.email}`
    );
};

export default ApplicationsCardContent;
