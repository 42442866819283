import React from "react";
import {
    AlertColor,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import CclButton from "../../components/common/cclButtons/cclButton";

interface SetAlertDialogProps {
    open: boolean;
    title: string | null;
    msg: string | null;
    severity: AlertColor | null;
    onOk: (title: string | null, msg: string | null, severity: AlertColor | null) => void;
    onClose: () => void;
}

const SetAlertDialog: React.FC<SetAlertDialogProps> = (props) => {
    const [title, setTitle] = React.useState<string | null>(props.title);
    const [msg, setMsg] = React.useState<string | null>(props.msg);
    const [severity, setSeverity] = React.useState<AlertColor | null>(props.severity);

    const handleSeverityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSeverity = event.target.value;
        setSeverity(newSeverity as AlertColor);
    };

    React.useEffect(() => {
        if (!props.open) {
            setTitle(props.title);
            setMsg(props.msg);
            setSeverity(props.severity);
        }
    }, [props.msg, props.open, props.severity, props.title]);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Update System Alert</DialogTitle>
            <DialogContent>
                <DialogContentText>Leave fields blank to use the default values.</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="alertTitle"
                    label="System Alert Title"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={title}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        setTitle(evt.target.value)
                    }
                />
                <TextField
                    autoFocus
                    multiline={true}
                    rows={4}
                    margin="dense"
                    id="alertMessage"
                    label="System Alert Message"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={msg}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        setMsg(evt.target.value)
                    }
                />
                <FormControl
                    fullWidth
                    sx={{
                        border: 1,
                        borderColor: "lightgray",
                        borderRadius: 1,
                        padding: 2,
                        paddingLeft: 3,
                        marginTop: 2,
                    }}
                >
                    <FormLabel
                        sx={{ color: "gray", textDecoration: "underline" }}
                        id="system-alert-severity-label"
                    >
                        Select Color
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="system-alert-severity-label"
                        name="system-alert-severity"
                        onChange={handleSeverityChange}
                        value={severity}
                        sx={{ justifyContent: "space-between" }}
                    >
                        <FormControlLabel
                            value="info"
                            control={<Radio color="info" />}
                            label="Info (Default)"
                            sx={{ color: "info.main" }}
                        />
                        <FormControlLabel
                            value="success"
                            control={<Radio color="success" />}
                            label="Success"
                            sx={{ color: "success.main" }}
                        />
                        <FormControlLabel
                            value="warning"
                            control={<Radio color="warning" />}
                            label="Warning"
                            sx={{ color: "warning.main" }}
                        />
                        <FormControlLabel
                            value="error"
                            control={<Radio color="error" />}
                            label="Danger"
                            sx={{ color: "error.main" }}
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <CclButton restricted={false} mode="secondary" onClick={props.onClose}>
                    Cancel
                </CclButton>
                <CclButton restricted={false} mode="primary" onClick={() => props.onOk(title, msg, severity)}>
                    Update Alert
                </CclButton>
            </DialogActions>
        </Dialog>
    );
};

export default SetAlertDialog;
