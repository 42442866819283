import * as React from "react";
import { Grid, Stack } from "@mui/material";
import ResourcesDataGridPro from "./resourceList";
import AdvancedSearchControl from "./advancedSearchControl";
import { useGetAllResourcesQuery } from "../../../services/cclTokenedResourceApi";

interface CclResourcesTabPanelProps {}

const CclResourcesTabPanel: React.FC<CclResourcesTabPanelProps> = (props) => {
    const { data: resources, isLoading, isSuccess, isError} = useGetAllResourcesQuery();
    const [advancedSearchResults, setAdvancedSearchResults] = React.useState<number[] | undefined>(
        undefined
    );

    const onSearchResults = (pKeys: number[] | undefined) => {
        setAdvancedSearchResults(pKeys);
    };

    const filteredResources = () => {
        if (!resources) {
            return [];
        } else if (advancedSearchResults) {
            return resources?.filter((x) => advancedSearchResults.includes(x.pkey));
        } else {
            return resources;
        }
    };

    const resultString = isSuccess
        ? `All resources retrieved (${filteredResources().length ?? 0} total records)`
        : isError
        ? "Error retrieving resources"
        : "";

    return (
        <Stack spacing={2} width={1}>
            <AdvancedSearchControl onSearchResults={onSearchResults} enabled={!isLoading} />
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid
                    item
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: 1,
                        height: 1,
                        minHeight: 500,
                    }}
                >
                    <ResourcesDataGridPro
                        loading={isLoading}
                        resources={filteredResources()}
                        showResultBar={!isLoading}
                        resultBarText={resultString}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default CclResourcesTabPanel;
