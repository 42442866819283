import * as React from "react";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import MyInfo from "./accessDetial/myInfoTab";
import Bios from "./accessDetial/biosTab";
import Assignments from "./accessDetial/assignmentsTab";
import Participants from "./accessDetial/participantsTab/participantsTab";
import Applications from "./accessDetial/applicationsTab";
import Notifications from "./accessDetial/notificationsTab";
import { Alert, Link, Typography } from "@mui/material";
import { UserClaimsService, eGroupIds } from "../../services/currentUserService/currentUserService";
import ResourceProductSalesPanel from "../resources/resourceDetail/participants/productsales/resourceProductSalesPanel";
import DetailPage from "../../components/common/detailPage/detailPage";
import MyAttributesTabPanel from "./accessDetial/myAttributesTab";

interface MyAccessProps {}

const MyAccess: React.FC<MyAccessProps> = () => {
    const claimsService = new UserClaimsService();
    const [name] = React.useState<string>(
        claimsService.GetCurrentUserName() ? claimsService.GetCurrentUserName() : "Unknown User"
    );
    const [email] = React.useState<string>(claimsService.GetCurrentUserEmail());

    const fullTablist: [string, number, React.ReactNode][] = [
        ["My Files", 1, <Participants />],
        ["My Profile", 2, <MyInfo name={name} email={email} />],
        [
            "My Bio",
            3,
            <Bios
                pKey={claimsService.GetCurrentResourceId()}
                email={claimsService.GetCurrentUserEmail()}
            />,
        ],
        [
            "My Attributes",
            4,
            <MyAttributesTabPanel
                readonly={false}
                fromResource={false}
                pkey={claimsService.GetCurrentResourceId()}
                email={claimsService.GetCurrentUserEmail()}
                imKey={claimsService.GetCurrentUserId()}
            />,
        ],
        ["My Assignments", 5, <Assignments />],
        ["My Emails", 6, <Notifications />],
        ["My Applications", 7, <Applications />],
    ];

    const nwaAdminTablist: [string, number, React.ReactNode][] = [
        ["My Files", 1, <Participants />],
        ["My Emails", 3, <Notifications />],
        ["My Applications", 2, <Applications />],
    ];

    const clientFacilitatorAndNwaResourceTabList: [string, number, React.ReactNode][] = [
        ["My Files", 1, <Participants />],
    ];

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                {`My Access / ` + name}
            </Typography>
        </SimpleBreadcrumbs>
    );

    const finalTablist = claimsService.IsUserInGroups([eGroupIds.eNWAAdmin])
        ? nwaAdminTablist
        : claimsService.IsUserInGroups([eGroupIds.eClientFacilitators, eGroupIds.eNWAResource])
        ? clientFacilitatorAndNwaResourceTabList
        : fullTablist;

    if (claimsService.CanUserViewMyAccess()) {
        return (
            <DetailPage
                breadcrumbs={breadcrumbs}
                descriptor="My Access"
                tablist={finalTablist}
                activeTab={1}
                identifier="my-access"
            ></DetailPage>
        );
    } else if (claimsService.IsUserInGroups([eGroupIds.eClientFacilitators])) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <ResourceProductSalesPanel email={claimsService.GetCurrentUserEmail()} />
            </StandardLayout>
        );
    } else if (claimsService.IsUserInGroups([eGroupIds.eNWAResource])) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <Participants />
            </StandardLayout>
        );
    } else {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <Alert severity="info">
                    Users must have a Bookable Resource Record in D365 PSA in order to use the My
                    Access module. Our records indicate that you do not have a Bookable Resource
                    Record in D365 PSA. A User Account Location Management Form (
                    <Link
                        href={"docs/UserAccountLocationManagementForm.docx"}
                        download={"UserAccountLocationManagementForm.docx"}
                    >
                        link
                    </Link>
                    ) will need to be filled out by your supervisor and submitted with a HTS ticket
                    assigned to <b>D365 - Access Request</b>. If you are an Independent Contractor
                    or Associate please reach out to your contact at CCL with this information.
                </Alert>
            </StandardLayout>
        );
    }
};

export default MyAccess;
