import * as React from "react";
import { DataGridPro, GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { SessionDetailResourceAppointment } from "../../../../services/types/sessionDetailTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclCommonLink from "../../../../components/common/cclCommonLink";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

export interface SessionAssignmentListProps {
    assignments: SessionDetailResourceAppointment[] | undefined;
}

export const SessionAssignmentListDataGridPro: React.FC<SessionAssignmentListProps> = (props) => {
    const claimsService = new UserClaimsService();
    const isNwaAdmin = claimsService.IsUserNwaAdmin();

    const COLUMNS = [
        {
            field: "name",
            headerName: "Resource",
            renderCell: (params: GridRenderCellParams) =>
                isNwaAdmin ? (
                    <span>{params.value}</span>
                ) : (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/resource/${params.row.pKey}/0`}
                    />
                ),
            flex: 0.8,
            valueGetter: getFullName,
        },
        {
            field: "email",
            headerName: "Email",
            type: "string",
            flex: 1,
        },
        {
            field: "mobilePhone",
            headerName: "Mobile Phone",
            type: "string",
            flex: 0.8,
        },
        {
            field: "resourceType",
            headerName: "Resource Type",
            type: "string",
            flex: 1,
        },
        {
            field: "startDateTimeUtc",
            headerName: "Start",
            valueGetter: (params: any) => new Date(params.row.startDateTimeUtc),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 150,
        },
        {
            field: "endDateTimeUtc",
            headerName: "End",
            valueGetter: (params: any) => new Date(params.row.endDateTimeUtc),
            type: "date",
            renderCell: (params: GridRenderCellParams) => {
                return <span>{params.value.toLocaleDateString()}</span>;
            },
            width: 150,
        },
        {
            field: "categoryName",
            headerName: "Category",
            type: "string",
            flex: 1.25,
        },
        {
            field: "taskName",
            headerName: "Task Name",
            type: "string",
            flex: 1.25,
        },
        {
            field: "programTitle",
            headerName: "Title",
            type: "string",
            flex: 1,
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.raKey}
            rows={props.assignments == null ? [] : props.assignments}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            components={{ Toolbar: CclDataGridToolbar }}
            initialState={{
                sorting: {
                    sortModel: [{ field: "startDateTimeUtc", sort: "desc" }],
                },
            }}
        />
    );
};

export default SessionAssignmentListDataGridPro;
