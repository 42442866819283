import * as React from "react";
import { Stack, Box, Grid } from "@mui/material";
import { Application } from "../../../../services/types/cclGrandCentralApiTypes";
import ApplicationInvitationDataGrid from "./applicationInvitationDataGrid";
import { useSearchInvitationsforApplicationMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { ApplicationSearchInvitationBody } from "../../../../services/types/applicationTypes";
import CclTextSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import CclDateSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";

interface ApplicationInvitationPanelProps {
    application: Application | undefined;
}

const ApplicationInvitationPanel: React.FC<ApplicationInvitationPanelProps> = (props) => {
    const [searchResultString, setSearchResultString] = React.useState<string>("");
    const [runSearch, { data: searchData, isLoading }] =
        useSearchInvitationsforApplicationMutation();

    const emailOrImKey = (value: string) => {
        if (isNaN(+value)) {
            return false;
        } else {
            return true;
        }
    };

    const searchInvitations = (
        startDate: Date | null,
        endDate: Date | null,
        searchEmail: string
    ) => {
        if (!props.application) {
            return;
        }

        const payload: ApplicationSearchInvitationBody = {
            applicationId: props.application.applicationId,
            payload: {
                imKey: searchEmail ? (emailOrImKey(searchEmail) ? +searchEmail : null) : null,
                emailAddress: searchEmail ? (emailOrImKey(searchEmail) ? "" : searchEmail) : "",
                startDate: startDate,
                endDate: endDate ? endDate : startDate ? new Date() : null,
            },
        };

        runSearch(payload)
            .unwrap()
            .then(() => {});

        let msg =
            searchEmail === undefined || searchEmail.length === 0
                ? `results for search by date (from ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()})`
                : `results for search by Email ('${searchEmail}')`;

        setSearchResultString(msg);
    };

    return (
        <Stack width={1} height={1} spacing={2}>
            <Box width={1} sx={{ display: "flex", flexDirection: "row" }}>
                <CclTextSearchBar
                    initialSearchTerm={""}
                    searchLabel="Enter Email"
                    executeSearch={(searchTerm: string) =>
                        searchInvitations(null, null, searchTerm)
                    }
                />
                <CclDateSearchBar
                    initialStartDate={null}
                    initialEndDate={null}
                    startDateLabel="From Date"
                    endDateLabel="To Date"
                    executeSearch={(startDate: Date | null, endDate: Date | null) =>
                        searchInvitations(startDate, endDate, "")
                    }
                    allowNullDates={false}
                />
                <Grid
                    container
                    spacing={1}
                    padding={2}
                    sx={{ maxWidth: "fit-content", height: "fit-content" }}
                >
                    <Grid item>
                        <CclUnrestrictedButton
                            onClick={() => {
                                searchInvitations(null, null, "");
                            }}
                            aria-label="Get All Invitations"
                            disabled={props.application === undefined}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                            }}
                        >
                            Get All
                        </CclUnrestrictedButton>
                    </Grid>
                </Grid>
            </Box>
            <ApplicationInvitationDataGrid
                invitations={searchData ? searchData : []}
                isLoading={isLoading}
                showResultBar={!isLoading}
                resultBarText={`${
                    searchData?.length ?? (searchResultString !== "" ? "No" : "")
                } ${searchResultString}`}
            />
        </Stack>
    );
};

export default ApplicationInvitationPanel;
