import * as React from "react";
import { Alert, AlertTitle, Card } from "@mui/material";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import { useGetAccessEventsByKeywordMutation } from "../../../../services/cclTokenedAccessEventsApi";
import { AccessEvent } from "../../../../services/types/accessEventTypes";
import AccessLogsDataGridPro from "../../../../pages/accesslogs/accessLogsDataGridPro";
import ComponentLoader from "../../../../components/common/componentLoader";

interface SessionApplicationLogsCardProps {
    sessionId: string;
    sessionCode: string;
}

const SessionApplicationLogsCard: React.FC<SessionApplicationLogsCardProps> = (props) => {
    const [logEntries, setAllLogEntries] = React.useState<AccessEvent[]>([]);
    const [runEventsByKeywordSearch, { isLoading, isError, error }] =
        useGetAccessEventsByKeywordMutation();

    React.useEffect(() => {
        if (props.sessionCode === "") return;

        let evts: AccessEvent[] = [];

        runEventsByKeywordSearch({
            keyword: props.sessionCode,
            start: null,
            end: null,
        })
            .unwrap()
            .then((logEvents1: AccessEvent[]) => {
                evts = [...logEvents1];
                runEventsByKeywordSearch({
                    keyword: props.sessionId,
                    start: null,
                    end: null,
                })
                    .unwrap()
                    .then((logEvents2: AccessEvent[]) => {
                        evts = [...evts, ...logEvents2];
                        setAllLogEntries(evts);
                    })
                    .catch(() => {
                        setAllLogEntries(evts);
                    });
            });
    }, [props.sessionId, props.sessionCode, runEventsByKeywordSearch]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isError) {
        return showErrorAlert(getErrorMsg(error));
    }

    return (
        <Card sx={{ height: 1, width: 1, m: 2, p: 2 }}>
            {isLoading ? (
                <ComponentLoader msg="Retrieving Session System Logs..." />
            ) : (
                <AccessLogsDataGridPro accessEvents={logEntries} loading={isLoading} />
            )}
        </Card>
    );
};

export default SessionApplicationLogsCard;
