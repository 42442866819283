import * as React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { CclPersistantDataGridMiscButton } from "../cclPersistentDataGrid/cclPersistentDataGrid";
import CclRestrictedButton from "../cclButtons/cclRestrictedButton";
import CclUnrestrictedButton from "../cclButtons/cclUnrestrictedButton";

//TODO - Resolve disparity between btn.onClick and btnClick, currently there seems to be no need for the btn.onClick prop inside of miscButtons

export interface CclDataGridCustomButtonToolbarProps {
    resultBar?: boolean;
    resultBarText?: string;
    miscButtons: CclPersistantDataGridMiscButton[];
    btnClick: (btnId: string) => any;
    anySelected: boolean;
}

const CclToolbarBtnSx = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "4px",
    paddingBottom: "4px",
    margin: "5px",
    float: "right",
    fontSize: "12px",
    lineHeight: "21px",
};

const CclDataGridCustomButtonToolbar: React.FC<CclDataGridCustomButtonToolbarProps> = (props) => {
    const handleMiscClick = (btnid: string) => {
        if (props.btnClick != null) props.btnClick(btnid);
    };

    return (
        <GridToolbarContainer sx={{ float: "right" }}>
            {props.resultBar && <span>{props.resultBarText}</span>}
            {props.miscButtons &&
                props.miscButtons.map((btn) =>
                    btn.restricted ? (
                        <CclRestrictedButton
                            key={btn.id}
                            id={btn.id}
                            disabled={btn.enableOnSelect ? !props.anySelected : btn.disable}
                            sx={CclToolbarBtnSx}
                            onClick={() => handleMiscClick(btn.id)}
                        >
                            {btn.caption}
                        </CclRestrictedButton>
                    ) : (
                        <CclUnrestrictedButton
                            key={btn.id}
                            id={btn.id}
                            disabled={btn.enableOnSelect ? !props.anySelected : btn.disable}
                            sx={CclToolbarBtnSx}
                            onClick={() => handleMiscClick(btn.id)}
                        >
                            {btn.caption}
                        </CclUnrestrictedButton>
                    )
                )}
        </GridToolbarContainer>
    );
};

export default CclDataGridCustomButtonToolbar;
