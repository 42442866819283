import * as React from "react";
import { Card, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import ApplicationsDataGridPro from "./applicationsList";
import {
    useGetCclAllApplicationsQuery,
    useSendInvitationsMutation,
} from "../../services/cclTokenedGrandCentralApi";
import { SendBatchInvitationsDialog } from "./sendBatchInvitations/sendBatchInvitationsDialog";
import {
    IndividualInvitationResponseStatus,
    SendInvitationsRequest,
} from "../../services/types/rtkQueryTypes";
import CclAlertDialog from "../../components/common/cclAlertDialog";
import { CSVLink } from "react-csv";
import Link from "react-csv/components/Link";
import CclStatusDialog from "../../components/common/cclStatusDialog";
import { AccessEventApplicationDetails } from "../../services/types/accessEventTypes";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import AddSystemDialog from "./addSystemDialog";

export const Applications: React.FC = () => {
    const csvLink = React.useRef<HTMLAnchorElement & Link & { link: HTMLAnchorElement }>(null);

    const [addSystemDialogOpen, setAddSystemDialogOpen] = React.useState<boolean>(false);
    const [downloadStatusOpen, setDownloadStatusOpen] = React.useState<boolean>(false);
    const [downloadStatusMessage, setDownloadStatusMessage] = React.useState<string>("");
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const [alertTitle, setAlertTitle] = React.useState<string>("");
    const [alertSeverity, setAlertSeverity] = React.useState<"error" | "success" | "info">("info");
    const [sendInvitationsOpen, setSendInvitationsOpen] = React.useState<boolean>(false);
    const [sendInvitationRequest] = useSendInvitationsMutation();
    const { data, isLoading } = useGetCclAllApplicationsQuery();
    const [sendResults, setSendResults] = React.useState<IndividualInvitationResponseStatus[]>([]);
    const { logEvent } = useLogAccessEvent();

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Applications
            </Typography>
        </SimpleBreadcrumbs>
    );

    const openSendInvitationsPopup = () => {
        setSendInvitationsOpen(true);
    };

    const closeSendInvitationPopup = (request: SendInvitationsRequest | null) => {
        setSendInvitationsOpen(false);
        if (request === null) return;

        sendInvitationRequest(request)
            .unwrap()
            .then((response) => {
                setDownloadStatusOpen(false);
                if (response == null) return;

                if (response.numberFailed === 0) {
                    const app = data?.find((a) => a.applicationId === request.applicationId);
                    const evtData: AccessEventApplicationDetails = {
                        applicationId: request.applicationId,
                        applicationName: app?.name ?? "",
                    };
                    logEvent("BulkUserApplicationInvitationSent", evtData);

                    setAlertSeverity("info");
                    setAlertMessage(
                        `Successfully sent ${response.numberInivitationsSent} invitations.`
                    );
                    setAlertTitle("Invitations Sent Successfully");
                } else {
                    setAlertSeverity("error");
                    setAlertMessage(response.message);
                    setAlertTitle("Attention");
                }
                setSendResults(response.results);
                setTimeout(() => {
                    csvLink.current?.link.click();
                });
                setAlertOpen(true);
            })
            .catch(() => {
                setAlertSeverity("error");
                setAlertMessage("Invitation request failed.");
                setAlertTitle("Error");
                setAlertOpen(true);
            });
        setDownloadStatusOpen(true);
        setDownloadStatusMessage(`Sending ${request.recipients.length} invitations...`);
    };

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <React.Fragment>
                <CSVLink
                    data={sendResults}
                    filename="batch-invitation-results.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                />
                <CclStatusDialog
                    open={downloadStatusOpen}
                    onOk={() => setDownloadStatusOpen(false)}
                    severity={"info"}
                    title={"Sending Invitations"}
                    msg={downloadStatusMessage}
                />
                <AddSystemDialog
                    open={addSystemDialogOpen}
                    onClose={() => setAddSystemDialogOpen(false)}
                />
                <CclAlertDialog
                    open={alertOpen}
                    msg={alertMessage}
                    title={alertTitle}
                    onOk={() => setAlertOpen(false)}
                    severity={alertSeverity}
                />
                <SendBatchInvitationsDialog
                    open={sendInvitationsOpen}
                    onClose={closeSendInvitationPopup}
                    applications={data ?? []}
                />
                <Stack height={1} width={1}>
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ApplicationsDataGridPro
                            loading={isLoading}
                            applications={data ? data : []}
                            onSendInvitationClick={openSendInvitationsPopup}
                            onAddSystemClick={() => setAddSystemDialogOpen(true)}
                        />
                    </Card>
                </Stack>
            </React.Fragment>
        </StandardLayout>
    );
};

export default Applications;
