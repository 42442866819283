import * as React from "react";
import ComponentLoader from "../../../components/common/componentLoader";
import FlexGrid from "../../../layouts/flexGrid";
import { useGetResourceAssignmentsQuery } from "../../../services/cclTokenedResourceApi";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import AssignmentsDataGrid from "../../resources/resourceDetail/assignments/assignmentsDataGrid";
import Contracts from "../../resources/resourceDetail/contracts/contracts";
import { Grid, Stack } from "@mui/material";
import CclGenericAccordian from "../../../components/common/cclGenericAccordian";

interface AssignmentsProps {}

const Assignments: React.FC<AssignmentsProps> = () => {
    const claimsService = new UserClaimsService();
    const imkey = claimsService.GetCurrentUserId();

    const { data, isSuccess } = useGetResourceAssignmentsQuery(+imkey, { skip: !+imkey });

    if (isSuccess) {
        return (
            <FlexGrid>
                <Stack height={1} width={1}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <CclGenericAccordian headingText="Assignments" defaultExpanded={true}>
                                <AssignmentsDataGrid assignments={data} />
                            </CclGenericAccordian>
                        </Grid>
                        <Grid item xs={12}>
                            <CclGenericAccordian headingText="Contracts" defaultExpanded={true}>
                                <Contracts
                                    pKey={`${claimsService.GetCurrentResourceId()}`}
                                    name={claimsService.GetCurrentUserName()}
                                    imKey={`${imkey}`}
                                    email={claimsService.GetCurrentUserEmail()}
                                />
                            </CclGenericAccordian>
                        </Grid>
                    </Grid>
                </Stack>
            </FlexGrid>
        );
    }

    return <ComponentLoader msg="Loading Assignments" />;
};

export default Assignments;
