import * as React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useGetCclUserInvitationsQuery } from "../../../services/cclTokenedGrandCentralApi";
import ComponentLoader from "../../common/componentLoader";
import InvitationsDataGridPro from "../invitationsDataGridPro/invitationsDataGridPro";
import { getErrorMsg } from "../../../utilities/rtkQueryErrorHelpers";
import { Invitation } from "../../../services/types/rtkQueryTypes";

interface InvitationsCardContentProps {
    email: string;
    showInvitationId: boolean
    sendInvitation?: () => void;
}

const InvitationsCardContent: React.FC<InvitationsCardContentProps> = (props) => {
    const [invitations, setInvitations] = React.useState<Invitation[]>([]);
    const { data, isLoading, isSuccess, isError, error } = useGetCclUserInvitationsQuery(
        props.email,
        { skip: props.email === "" }
    );

    React.useEffect(() => {
        setInvitations(data ?? []);
    }, [data]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    if (isSuccess) {
        return <InvitationsDataGridPro sendInvitation={props.sendInvitation} showInvitationId={props.showInvitationId} invitations={invitations} />;
    }

    if (isLoading || isLoading) {
        return <ComponentLoader msg={"Loading Invitations"} />;
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving invitations for registration email ${props.email} (${getErrorMsg(
                error
            )})`
        );
    }

    return showErrorAlert(
        `Unknown error retrieving invitations for registration email ${props.email}`
    );
};

export default InvitationsCardContent;
