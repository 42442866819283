import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
} from "@mui/material";
import * as React from "react";
import { useGetCclAllSystemsQuery } from "../../../../services/cclTokenedGrandCentralApi";
import { GcSystem } from "../../../../services/types/cclGrandCentralApiTypes";

export interface SystemSelectProps {
    onChange: (newId: string) => void;
    selectedSystem: string;
}

export const SystemSelect: React.FC<SystemSelectProps> = (props) => {
    const [selectedSystem, setSelectedSystem] = React.useState<string>(props.selectedSystem);
    const { data: gcsystems, isLoading, isError } = useGetCclAllSystemsQuery();
    let systemOptions: { val: string; option: string }[] = [];

    if (gcsystems && gcsystems.length > 0) {
        var systemsCopy = [...gcsystems];
        systemOptions = systemsCopy
            .sort((a: GcSystem, b: GcSystem) => {
                return a.systemName.localeCompare(b.systemName);
            })
            .map((s) => {
                return { val: s.systemId, option: s.systemName };
            });
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedSystem(event.target.value);
        props.onChange(event.target.value);
    };

    if (gcsystems && gcsystems.length > 0) {
        return (
            <FormControl fullWidth>
                <InputLabel id="select-system-label">Select System</InputLabel>
                <Select
                    labelId="select-system-label"
                    id="select-system"
                    value={selectedSystem}
                    label="Select System"
                    onChange={handleChange}
                    disabled={gcsystems.length <= 0}
                >
                    {systemOptions.map((opt) => (
                        <MenuItem key={opt.val} value={opt.val}>
                            {opt.option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    } else {
        return (
            <FormControl fullWidth>
                <TextField
                    disabled
                    id="system"
                    label="System"
                    defaultValue={
                        isLoading
                            ? "Retrieving Systems..."
                            : isError
                            ? "Error Retrieving Systems"
                            : ""
                    }
                />
            </FormControl>
        );
    }
};

export default SystemSelect;
