import { Stack } from "@mui/material";
import * as React from "react";
import FlexGrid from "../../../../layouts/flexGrid";
import PageLoader from "../../../../components/common/pageLoader";
import ApplicationRolesDataGrid from "./applicationRolesDataGrid";
import {
    useGetAllRolesQuery,
    useGetAllUsersWithRolesQuery,
} from "../../../../services/cclTokenedApplicationRolesApi ";
import AddRoleToUserPanel from "./addRoleToUser";
import { GrandCentralGetUsersbyGroupPayload } from "../../../../services/types/cclGrandCentralApiTypes";
import { eGroupIds } from "../../../../services/currentUserService/currentUserService";
import { Config } from "../../../../config";
import { useGetUsersbyGroupQuery } from "../../../../services/cclTokenedGrandCentralApi";

interface ApplicationRolesPanelProps {}

const ApplicationRolesPanel: React.FC<ApplicationRolesPanelProps> = (props) => {
    const { data, isLoading } = useGetAllUsersWithRolesQuery();
    const { data: roles, isLoading: rolesLoading } = useGetAllRolesQuery();

    const [showAddRole, setShowAddRole] = React.useState<boolean>(false);

    const applicationId: string = Config.REACT_APP_APPLICATION_ID;

    const usersByRole: GrandCentralGetUsersbyGroupPayload = {
        applicationId: applicationId,
        groupIds: `${eGroupIds.eCCLAdmin},${eGroupIds.eCCLSuperAdmin}`,
    };

    const { data: usersByGroup } = useGetUsersbyGroupQuery(usersByRole);
    const { data: rolesList } = useGetAllRolesQuery();

    const startAddRoleFlow = () => {
        setShowAddRole(true);
    };

    const onClose = () => {
        setShowAddRole(false);
    };

    const sortUsersArray = (array: any[]) => {
        let sortedArr = [...array];
        return sortedArr.sort((a, b) => a.firstName.localeCompare(b.firstName));
    };

    const sortRolesArray = (array: any[]) => {
        let sortedArr = [...array];
        return sortedArr.sort((a, b) => a.roleName.localeCompare(b.roleName));
    };

    return (
        <Stack width={1} height={1} spacing={2}>
            {isLoading || rolesLoading ? (
                <PageLoader msg={"Loading user's application data..."} />
            ) : null}
            {data && roles && (
                <FlexGrid>
                    <ApplicationRolesDataGrid
                        users={data.filter((n) => n.imKey !== 0)}
                        roles={roles}
                        isLoading={isLoading}
                        showResultBar={!isLoading}
                        startAddRoleFlow={startAddRoleFlow}
                    />
                </FlexGrid>
            )}
            {showAddRole && rolesList && usersByGroup && (
                <AddRoleToUserPanel
                    open={showAddRole}
                    onClose={onClose}
                    roles={sortRolesArray(rolesList)}
                    users={sortUsersArray(usersByGroup)}
                />
            )}
        </Stack>
    );
};

export default ApplicationRolesPanel;
