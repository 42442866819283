import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Stack, Typography } from "@mui/material";
import ScoringStatusPanel from "./scoringStatus/scoringStatusPanel";
import SessionRaterManagementPanel from "./raterManagement/sessionraterManagementPanel";
import { SessionDetailType } from "../../../../services/types/sessionDetailTypes";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import { useGetAppUrlsQuery } from "../../../../services/cclTokenedSettingsApi";
import { ExpandMore } from "@mui/icons-material";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

interface AssessmentsPanelProps {
    sessionDetails: SessionDetailType | undefined;
    readOnly?: boolean;
}

const AssessmentsPanel: React.FC<AssessmentsPanelProps> = (props) => {
    const [expanded, setExpanded] = React.useState(true);
    const { data: urls } = useGetAppUrlsQuery();
    const { logEvent } = useLogAccessEvent();

    if (props.sessionDetails === undefined) {
        return (
            <Box>
                <Alert severity="error">Error: Session data not found!</Alert>
            </Box>
        );
    }

    const openCclScoring = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        const evtData: AccessEventSessionDetails = {
            projectCode: props.sessionDetails?.sessionCode ?? "unknown",
        };
        logEvent("SessionViewedInCCLScoring", evtData);
        window.open(`${urls["CCLScoring"]}/program-status/${props.sessionDetails?.sessionKey}`, "_blank");        
    };

    return (
        <Stack width={1}>
            <Accordion key={`status-${props.sessionDetails.sessionKey}`} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={{ width: "100%", marginLeft: "15px", fontWeight: "bolder" }}>
                        Assessments
                    </Typography>
                    <Box width={1} sx={{ marginRight: "10px" }}>
                        {!props.readOnly ? (
                            <CclUnrestrictedButton
                                size="small"
                                onClick={openCclScoring}
                                sx={{
                                    float: "right",
                                }}
                            >
                                View In CCL Scoring
                            </CclUnrestrictedButton>
                            ) : null}
                        </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <ScoringStatusPanel
                        sessionKey={props.sessionDetails.sessionKey}
                        sessionCode={props.sessionDetails.sessionCode}
                        readOnly={props.readOnly}
                    />
                </AccordionDetails>
            </Accordion>
            <CclGenericAccordian headingText="Raters" defaultExpanded={true}>
                <SessionRaterManagementPanel
                    sessionKey={props.sessionDetails.sessionKey}
                    programCode={props.sessionDetails.sessionCode}
                    readOnly={props.readOnly}
                />
            </CclGenericAccordian>
        </Stack>
    );
};

export default AssessmentsPanel;
