import React from "react";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import {
    useDeleteRaterByIdMutation,
    useGetParticipantRaterByEsKeyMutation,
    useUpdateRaterSwitchMutation,
} from "../../../../../services/cclTokenedEnterpriseRaterApi";
import { RaterDetails, RaterSwitchRequest } from "../../../../../services/types/assessmentAPITypes";
import ComponentLoader from "../../../../../components/common/componentLoader";
import { Grid, Stack } from "@mui/material";
import RaterManagementDataGridPro from "../../../../registrations/registrationDetail/raterManagement/raterManagementDataGridPro";
import SessionParticipantDialog from "./sessionParticipantDialog";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";

interface SessionRaterManagementPanelProps {
    sessionKey: number;
    programCode: string;
    readOnly?: boolean;
}

const SessionRaterManagementPanel: React.FC<SessionRaterManagementPanelProps> = (props) => {
    const [searchRaters, { data: RaterDetailsData, isLoading }] =
        useGetParticipantRaterByEsKeyMutation();
    const [updateRaterType] = useUpdateRaterSwitchMutation();
    const [onRaterDelete] = useDeleteRaterByIdMutation();
    const [ConfirmOpen, SetConfirmOpen] = React.useState<boolean>(false);
    const [RaterDetailsDataNew, SetRaterDetailsDataNew] = React.useState<RaterDetails[]>([]);
    const claimsService = new UserClaimsService();
    const isUserRaterManager = claimsService.IsUserInAppRole(3);
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        searchRaters(props.sessionKey);
    }, [props.sessionKey, searchRaters]);

    React.useEffect(() => {
        SetRaterDetailsDataNew(RaterDetailsData ?? []);
    }, [RaterDetailsData]);

    const PushRaterTypeChange = (RMKey: number, RaterTypeKey: number) => {
        var RaterObj = RaterDetailsDataNew?.filter((r) => r.rM_Key === RMKey)[0];
        const newObj: RaterSwitchRequest = {
            formActualID: RaterObj?.formActualKey ?? 0,
            raterTypeKey: RaterTypeKey,
            rmkey: RMKey,
        };
        updateRaterType(newObj)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: props.programCode ?? "",
                };
                logEvent("RaterTypeChanged", evtData);
            });
    };

    const DeleteRaterById = (RMKey: number) => {
        onRaterDelete(RMKey)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: props.programCode ?? "",
                };                
                logEvent("RaterDeleted", evtData);
            });
    };

    const handleOk = () => {
        SetConfirmOpen(false);
        searchRaters(props.sessionKey);
    };

    const handleAddRaters = async () => {
        SetConfirmOpen(true);
    };

    if (isLoading) {
        return <ComponentLoader msg="Loading Rater Details" />;
    }

    return (
        <Stack height={1} width={1}>
            <SessionParticipantDialog
                raterList={RaterDetailsDataNew}
                esKey={props.sessionKey}
                open={ConfirmOpen}
                oK={() => handleOk()}
                onCancel={() => SetConfirmOpen(false)}
            />
            <Grid container height={1} spacing={2}>
                <Grid item xs={12}>
                    <RaterManagementDataGridPro
                        programCode={props.programCode}
                        isLoading={isLoading}
                        isSessionRater={true}
                        ratersDetailsList={RaterDetailsDataNew}
                        OnRaterTypeChanged={PushRaterTypeChange}
                        OnRaterDelete={DeleteRaterById}
                        showToolbar={true}
                        addraters={handleAddRaters}
                        isUserRaterManager={isUserRaterManager}
                        readOnly={props.readOnly}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default SessionRaterManagementPanel;
