import * as React from "react";
import {
    FormControlLabel,
    Stack,
    Switch,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import EmailViewer from "../../../../components/common/emailViewer";


export interface addEmailTemplateTabTwo {
    setValue: (key: string, value: string) => void;
    content: string;
}

export const AddEmailTemplateTabTwo: React.FC<addEmailTemplateTabTwo> = (props) => {

    const [editMode, setEditMode] = React.useState<boolean>(true);

    const handleEdit = () => {
        if (editMode) {
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    }

    return (
        <>
            <Stack sx={{width: "100%", margin: 2}} direction={"row"}>
                        <FormControlLabel 
                            control= {
                                <Switch  onChange={handleEdit}/>
                            }
                            label= "Enable Preview Mode"
                        />
                    </Stack>
                    {editMode ? 
                    <>
                        <Editor
                            apiKey="y7y53muqd751xs3r3tgo3wey3uwqehd18hiyp1s2pjdprezv"
                            onInit={(
                                evt: any,
                                editor: { getContent: () => string | undefined }
                            ) => props.setValue("content",editor.getContent()!)}
                            onEditorChange={(newValue: string | undefined) => {
                                let val = newValue;
                                if (val === undefined) {
                                    val = "";
                                }
                                props.setValue("content", val);
                            }}
                            value={props.content}
                            init={{
                                height: 700,
                                menubar: "file edit view insert format table help",
                                branding: false,
                                contextmenu: "",
                                menu: {
                                    insert: {
                                        title: "Insert",
                                        items: "image link inserttable | charmap | insertdatetime",
                                    },
                                    format: {
                                        title: "Format",
                                        items: "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat",
                                    },
                                },
                                plugins: [
                                    "advlist autolink lists link image charmap print preview",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime table paste code help properties",
                                    "code",
                                    "fullscreen",
                                    "image",
                                ],
                                toolbar:
                                    "undo redo | formatselect | properties | bold italic fontcolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                        />
                    </>
                    :
                         <EmailViewer 
                            content={props.content} 
                            disableLinks={false}                    
                        />
                    }
        </>
    );
};

export default AddEmailTemplateTabTwo;
