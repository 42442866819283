import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import UserSearchDataGridPro from "./userSearchList";
import {
    updateUserSearchParams,
    UserSearchListConfigState,
} from "../../app/slices/userSearchListConfigSlice";
import { SearchEnterpriseUsersRequest } from "../../services/types/search.service.types";
import {
    useCreateNewEnterpriseUserMutation,
    useSearchEnterpriseUsersMutation,
} from "../../services/cclTokenedEnterpriseUserApi";
import CclErrorDialog from "../../components/common/cclErrorDialog";
import CreateNewUserDialog from "./createNewUserDialog";
import { CreateEnterpriseUserRequest } from "../../services/types/enterpriseUserApiTypes";
import CclAlertDialog from "../../components/common/cclAlertDialog";
import { useNavigate, useSearchParams } from "react-router-dom";
import ComponentLoader from "../../components/common/componentLoader";
import { getErrorMsg } from "../../utilities/rtkQueryErrorHelpers";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import { AccessEventIndividualDetails } from "../../services/types/accessEventTypes";
import CclDoubleTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclDoubleTextSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";

interface UsersProps {}

const Users: React.FC<UsersProps> = () => {
    // redux state
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const searchparam = searchParams.get("searchparam") ?? "";
    const config: UserSearchListConfigState = useSelector(
        (state: any) => state.userSearchListConfig
    );
    let navigate = useNavigate();

    const [searchEmail, setSearchEmail] = React.useState<string>(
        searchparam ? searchparam : config.Email
    );
    const [searchFirstName, setSearchFirstName] = React.useState<string>(config.FirstName);
    const [searchLastName, setSearchLastName] = React.useState<string>(config.LastName);
    const [searchResultString, setSearchResultString] = React.useState<string>("");
    const [searchUsers, { data: users, isLoading, isSuccess }] = useSearchEnterpriseUsersMutation();
    const [
        triggerCreateNewUser,
        { isLoading: createUserLoading, isError: createUserIsError, error: createUserError },
    ] = useCreateNewEnterpriseUserMutation();
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);
    const [showCreateNewUserDialog, setShowCreateNewUserDialog] = React.useState<boolean>(false);
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        if (isSuccess && users && users.length >= 30000) setShowSearchToBroad(true);
        //eslint-disable-next-line
    }, [users, isSuccess]);

    React.useEffect(() => {
        if (!createUserIsError) return;

        let msg = getErrorMsg(createUserError, "Error Creating New User");
        setAlertMessage(msg);
        setShowAlert(true);

        //eslint-disable-next-line
    }, [createUserError]);

    React.useEffect(() => {
        if (!searchParams || !searchparam) {
            return;
        }
        const payload = {
            keywords: [`${searchParams.get("searchparam") ?? ""}`],
        };
        runSearchUsers(payload);
        //eslint-disable-next-line
    }, [searchParams]);

    const getUsersByEmail = async (email: string) => {
        if (email == null || email === "") {
            return;
        }
        setSearchFirstName("");
        setSearchLastName("");
        runSearchUsers({
            keywords: email.split(" "),
            firstName: "",
            lastName: "",
            partialSearch: false,
        });
    };

    const getUsersByName = async (firstName: string, lastName: string) => {
        if ((firstName == null || firstName === "") && (lastName == null || lastName === "")) {
            return;
        }
        setSearchEmail("");
        runSearchUsers({
            keywords: [],
            firstName: firstName,
            lastName: lastName,
            partialSearch: false,
        });
    };

    const runSearchUsers = async (payload: SearchEnterpriseUsersRequest) => {
        searchUsers(payload);

        dispatch(
            updateUserSearchParams({
                FirstName: payload.firstName ?? "",
                LastName: payload.lastName ?? "",
                Email: payload.keywords.join(", "),
                IsEmailSearchActive: payload.keywords.length > 0,
            })
        );

        var s = payload.firstName;
        var e = payload.lastName;
        let msg =
            payload.keywords.length === 0
                ? `results for search by name ( ${s}  ${e})`
                : `results for search by keyword ('${payload.keywords}')`;

        setSearchResultString(msg);
    };

    const handleCreateNewUser = async () => {
        setShowCreateNewUserDialog(true);
    };

    const createNewUser = async (email: string, first: string, last: string) => {
        const payload: CreateEnterpriseUserRequest = {
            firstName: first,
            lastName: last,
            email: email,
        };
        triggerCreateNewUser(payload)
            .unwrap()
            .then((response) => {
                if (response) {
                    const evtData: AccessEventIndividualDetails = {
                        imKey: response.imKey.toString(),
                        email: response.email,
                    };

                    logEvent("NewUserCreated", evtData);
                    navigate(
                        `/user?email=${encodeURI(response.email)}&imkey=${
                            response.imKey
                        }&firstName=${response.firstName}&lastName=${response.lastName}`
                    );
                }
            });
        setShowCreateNewUserDialog(false);
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Users
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <React.Fragment>
                {createUserLoading && <ComponentLoader msg={"Creating new user..."} />}
                <CclErrorDialog
                    open={showSearchToBroad}
                    title={"Too Many Results"}
                    msg={
                        "There are too many results for this search. Consider adjusting your search parameters and searching again."
                    }
                    onOk={() => setShowSearchToBroad(false)}
                />
                <CclAlertDialog
                    open={showAlert}
                    onOk={() => setShowAlert(false)}
                    severity={"error"}
                    title={"Error Creating New User"}
                    msg={alertMessage}
                />
                <CreateNewUserDialog
                    open={showCreateNewUserDialog}
                    onOk={createNewUser}
                    onCancel={() => setShowCreateNewUserDialog(false)}
                />
                <Stack height={1} width={1}>
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <CclTextSearchBar
                                initialSearchTerm={searchEmail}
                                searchLabel="Enter Email(s) or ID(s) or Search Term(s)"
                                executeSearch={getUsersByEmail}
                            />
                            <CclDoubleTextSearchBar
                                executeSearch={getUsersByName}
                                initialFieldOneSearchTerm={searchFirstName}
                                fieldOneSearchLabel={"Enter First Name"}
                                initialFieldTwoSearchTerm={searchLastName}
                                fieldTwoSearchLabel={"Enter Last Name"}
                                requireBothFields={false}
                                suppressErrors={true}
                            />
                        </Box>
                        <Grid
                            sx={{
                                height: 1,
                                width: 1,
                                padding: 2,
                                paddingTop: 0,
                            }}
                        >
                            <UserSearchDataGridPro
                                createNewUser={handleCreateNewUser}
                                loading={isLoading}
                                users={users ?? []}
                                showResultBar={!isLoading}
                                resultBarText={`${
                                    users?.length ?? (searchResultString !== "" ? "No" : "")
                                } ${searchResultString}`}
                            />
                        </Grid>
                    </Card>
                </Stack>
            </React.Fragment>
        </StandardLayout>
    );
};

export default Users;
