import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import PartnerResourcesDataGridPro from "./partnerResourceList";
import {
    useLazyGetAllPartnerResourcesQuery,
    useLazyGetPartnerResourceByEmailQuery,
    useLazyGetSinglePartnerResourceByEmailQuery,
    useLazyGetSinglePartnerResourcesQuery,
} from "../../../services/cclTokenedEnterpriseNetworkPartnerApi";
import {
    PartnerGroupPerson,
    SinglePartnerResourceByEmailRequest,
} from "../../../services/types/enterpriseNetworkPartnerTypes";
import EditPartnerResource from "./editPartnerResource";

interface PartnerResourcesTabPanelProps {
    isNwaAdmin: boolean | null;
    partnerId: string | null;
}

const PartnerResourcesTabPanel: React.FC<PartnerResourcesTabPanelProps> = (props) => {
    const searchSelector = useSelector((state: any) => state.partnerResourceListConfig);

    const [searchResultString, setSearchResultString] = React.useState<string>("");
    const [showAddResource, setShowAddResource] = React.useState<boolean>(false);
    const [
        emailPartnerSearchAllTrigger,
        {
            data: emailAllResources,
            isLoading: emailAllIsLoading,
            isSuccess: emailAllIsSuccess,
            isError: emailAllIsError,
        },
    ] = useLazyGetPartnerResourceByEmailQuery();
    const [
        allPartnersTrigger,
        {
            data: allResources,
            isLoading: allIsLoading,
            isSuccess: allIsSuccess,
            isError: allIsError,
        },
    ] = useLazyGetAllPartnerResourcesQuery();
    const [
        emailPartnerSearchSingleTrigger,
        {
            data: emailSingleResource,
            isLoading: emailSingleIsLoading,
            isSuccess: emailSingleIsSuccess,
            isError: emailSingleIsError,
        },
    ] = useLazyGetSinglePartnerResourceByEmailQuery();
    const [
        singlePartnerTrigger,
        {
            data: singleResources,
            isLoading: singleIsLoading,
            isSuccess: singleIsSuccess,
            isError: singleIsError,
        },
    ] = useLazyGetSinglePartnerResourcesQuery();
    const [resources, setResources] = React.useState<PartnerGroupPerson[]>([]);
    const [resourceToEdit, setResourceToEdit] = React.useState<PartnerGroupPerson | null>(null);

    useEffect(() => {
        if (searchSelector?.Email != null) {
            if (props.isNwaAdmin && props.partnerId != null) {
                const request: SinglePartnerResourceByEmailRequest = {
                    email: searchSelector.Email,
                    partnerId: props.partnerId,
                };
                emailPartnerSearchSingleTrigger(request);
            } else {
                emailPartnerSearchAllTrigger(searchSelector.Email);
            }
        } else {
            if (props.isNwaAdmin) {
                if (props.partnerId != null) singlePartnerTrigger(props.partnerId);
            } else {
                allPartnersTrigger();
            }
        }
        // eslint-disable-next-line
    }, [searchSelector.Email]);

    useEffect(() => {
        let resultStr: string = "";
        let success: boolean = true;
        let iserror: boolean = false;
        let rsrcs: PartnerGroupPerson[] | undefined = undefined;

        const getAll: boolean = searchSelector?.Email == null;
        if (getAll) {
            if (props.isNwaAdmin) {
                success = singleIsSuccess;
                iserror = singleIsError;
                rsrcs = singleResources;
            } else {
                success = allIsSuccess;
                iserror = allIsError;
                rsrcs = allResources;
            }
        } else {
            if (props.isNwaAdmin) {
                success = emailSingleIsSuccess;
                iserror = emailSingleIsError;
                if (emailSingleResource != null) rsrcs = [emailSingleResource];
                else rsrcs = [];
            } else {
                success = emailAllIsSuccess;
                iserror = emailAllIsError;
                rsrcs = emailAllResources;
            }
        }

        if (rsrcs === undefined) {
            setResources([]);
        } else {
            setResources([...rsrcs]);
        }

        if (success) {
            resultStr = getAll
                ? `All partner resources retrieved (${rsrcs?.length ?? 0} total records)`
                : `${rsrcs?.length ?? 0} ${
                      rsrcs?.length === 1 ? "result" : "results"
                  } for search by email ('${searchSelector?.Email?.trim()}')`;
        } else if (iserror) {
            resultStr = "Error retrieving resources";
        }
        setSearchResultString(resultStr);
        // eslint-disable-next-line
    }, [
        emailAllResources,
        emailSingleResource,
        allResources,
        singleResources,
        searchSelector?.Email,
    ]);

    const dataLoading = (): boolean => {
        return allIsLoading || emailAllIsLoading || singleIsLoading || emailSingleIsLoading;
    };

    const addPartnerResource = () => {
        setResourceToEdit(null);
        setShowAddResource(true);
    };

    const editPartnerResource = (imkey: number) => {
        const r = resources.find((re) => re.imKey === imkey);
        if (r == null) return;

        setResourceToEdit(r);
        setShowAddResource(true);
    };

    return (
        <React.Fragment>
            <EditPartnerResource
                open={showAddResource}
                handleClose={() => setShowAddResource(false)}
                resource={resourceToEdit}
                userPartnerKey={props.partnerId != null ? +props.partnerId : null}
            />
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid item sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}>
                    {resources && (
                        <PartnerResourcesDataGridPro
                            loading={dataLoading()}
                            resources={resources}
                            showResultBar={!dataLoading()}
                            resultBarText={searchResultString}
                            hidePartner={props.isNwaAdmin ?? false}
                            addPartnerResource={addPartnerResource}
                            editPartnerResource={editPartnerResource}
                        />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default PartnerResourcesTabPanel;
