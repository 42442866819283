import * as React from "react";
import { Dialog, DialogContent, Button, DialogTitle, Box } from "@mui/material";
import { SendInvitationsStepper } from "./sendInvitationsStepper/sendInvitationsStepper";
import { Application } from "../../../services/types/cclGrandCentralApiTypes";
import { SendInvitationsRequest } from "../../../services/types/rtkQueryTypes";

export interface SendBatchInvitationsDialogProps {
    open: boolean;
    onClose: (request: SendInvitationsRequest | null) => void;
    applications: Application[];
}

export const SendBatchInvitationsDialog: React.FC<SendBatchInvitationsDialogProps> = (props) => {
    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth={true}
            open={props.open}
            onClose={() => props.onClose(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{mb: "10px"}}>
                Send Invitations
            </DialogTitle>
            <DialogContent>
                <SendInvitationsStepper
                    applications={props.applications}
                    onFinish={props.onClose}
                />
                <Box sx={{width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "10px"}}>
                    <Button
                        variant="outlined"
                        color="primary"
                        id="ok-btn"
                        onClick={() => props.onClose(null)}
                    >
                        Cancel
                    </Button>                    
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default SendBatchInvitationsDialog;
