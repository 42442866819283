import { ListItemButton, ListItem, IconButton, ListItemText } from "@mui/material";
import React from "react";
import { AssociatedTemplate } from "../../../../services/types/generateDocumentsApiTypes";
import DeleteIcon from "@mui/icons-material/Delete";

interface SelectedTemplatesListItemProps {
    template: AssociatedTemplate;
    handleTemplateDelete: (associatedTemplate: AssociatedTemplate) => void;
    deleteDisabled: boolean;
}

const SelectedTemplatesListItem: React.FC<SelectedTemplatesListItemProps> = (props) => {
    const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
        props.handleTemplateDelete(props.template);
    };

    return (
        <ListItemButton key={"lib_" + props.template.id}>
            <ListItem
                key={"li_" + props.template.id}
                secondaryAction={
                    <IconButton
                        color="primary"
                        disabled={props.deleteDisabled}
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => handleDeleteClick(e)}
                    >
                        <DeleteIcon />
                    </IconButton>
                }
            >
                <ListItemText
                    key={"lit_" + props.template.id}
                    primary={props.template.name}
                    sx={{ fontSize: 8 }}
                />
            </ListItem>
        </ListItemButton>
    );
};

export default SelectedTemplatesListItem;
