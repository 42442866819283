import { Card, CardContent, Grid, Stack, TextField } from "@mui/material";
import * as React from "react";
import AdvancedSearchAttributeListItem from "./advancedSearchAttributeListItem";
import {
    useAdvancedProfileSearchMutation,
    useGetAllAttributeOptionsQuery,
    useGetAllCountriesQuery,
    useGetRolesQuery,
    useGetStatesandProvincesByCMKeyQuery,
    useGetUsedTimeZonesQuery,
} from "../../../services/cclTokenedEnterpriseCoachingApi";
import AdvancedSearchMultiSelect from "./advancedSearchMultiSelect";
import {
    AdvancedProfileSearchArgs,
    SearchAttribute,
} from "../../../services/types/enterpriseCoachingApiTypes";
import AdvancedSearchMultiSelectWithStringIds from "./advancedSearchMultiSelectWithStringIds";
import AdvancedSearchSingleSelect from "./advancedSearchSingleSelect";
import { useGetAllCadresQuery } from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import CclUnrestrictedLoadingButton from "../../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import CclUnrestrictedButton from "../../../components/common/cclButtons/cclUnrestrictedButton";

interface AdvancedSearchFormProps {
    onSearchResults: (pkeys: number[]) => void;
}

export interface AttributeSearch {
    attributeType: AutocompleteItem | undefined;
    attributeValue: AutocompleteItem | undefined;
    attributeLevel: AutocompleteItem | undefined;
    id: string;
}

export interface AutocompleteItem {
    id: string;
    label: string;
}

const AdvancedSearchForm: React.FC<AdvancedSearchFormProps> = (props) => {
    const [searchArgs, setSearchArgs] = React.useState<AdvancedProfileSearchArgs | undefined>(
        undefined
    );
    const [attributes, setAttributes] = React.useState<Array<AttributeSearch>>([]);
    const [shippingCountryKey, setShippingCountryKey] = React.useState<string>();
    const [city, setCity] = React.useState<string>();
    const [searchClicked, setSearchClicked] = React.useState<boolean>(false);
    const { data: workPoolsList } = useGetAllCadresQuery();
    const { data: countryList } = useGetAllCountriesQuery();
    const { data: stateList, refetch: fetchStates } = useGetStatesandProvincesByCMKeyQuery(
        Number(shippingCountryKey),
        { skip: !shippingCountryKey }
    );
    const { data: rolesList } = useGetRolesQuery();
    const { data: allAttributeOptions } = useGetAllAttributeOptionsQuery();
    const [runSearch, { data: pKeys, error, isError, isLoading }] =
        useAdvancedProfileSearchMutation();
    const { logEvent } = useLogAccessEvent();
    const { data: usedTimeZones } = useGetUsedTimeZonesQuery();

    const claimservice = new UserClaimsService();

    let workPools = workPoolsList;
    if (claimservice.IsCCPUser()) {
        workPools = workPools?.filter((e) => e.ctKey === 2);
    }

    React.useEffect(() => {
        if (shippingCountryKey) {
            fetchStates();
        }
        let args = searchArgs;
        if (args) {
            args.shippingPmKeys = [];
            setSearchArgs(args);
        }
        // eslint-disable-next-line
    }, [shippingCountryKey]);

    React.useEffect(() => {
        if (searchClicked) props.onSearchResults(pKeys ? pKeys : []);
        // eslint-disable-next-line
    }, [pKeys]);

    let timeZonesMap = usedTimeZones
        ?.map((c) => {
            return { id: c, label: c };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const countryMap = countryList
        ?.map((c) => {
            return { id: c.cmKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const stateMap = stateList
        ?.map((c) => {
            return { id: c.spKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    const workPollMap = workPools
        ?.map((c) => {
            return { id: c.cKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    let rolesMap = rolesList
        ?.map((c) => {
            return { id: c.prtKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const handleDeleteAttribute = (id: string) => {
        setAttributes(attributes.filter((x) => x.id !== id));
        let args = searchArgs;
        if (args && args.searchAttributes) {
            args.searchAttributes = args.searchAttributes.filter((x) => x.id !== id);
            setSearchArgs(args);
        }
    };

    const handleAttributeSelected = (attribute: SearchAttribute) => {
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                shippingCmKeys: [],
                shippingPmKeys: [],
                searchAttributes: [attribute],
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            if (args.searchAttributes) {
                let tempArgs = args.searchAttributes.filter((x) => x.id !== attribute.id);
                args.searchAttributes = tempArgs.concat(attribute);
                setSearchArgs(args);
            } else {
                args.searchAttributes = [attribute];
                setSearchArgs(args);
            }
        }
    };

    const timeZoneHandler = (ids: string[]) => {
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                timeZones: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.timeZones = ids;
            setSearchArgs(args);
        }
    };

    const cKeyHandler = (ids: number[]) => {
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                cKey: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.cKey = ids;
            setSearchArgs(args);
        }
    };

    const prtKeyHandler = (ids: number[]) => {
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                prtKey: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.prtKey = ids;
            setSearchArgs(args);
        }
    };

    const originCmKeyHandler = (ids: number[]) => {
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                originCmKeys: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.originCmKeys = ids;
            setSearchArgs(args);
        }
    };

    const shippingCountryChanged = (cmKey: Array<number>) => {
        if (cmKey && cmKey.length > 0) {
            setShippingCountryKey(cmKey[0].toString());
        } else {
            setShippingCountryKey(undefined);
        }

        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                shippingCmKeys: cmKey.length > 0 ? cmKey : [],
                shippingPmKeys: [],
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.shippingCmKeys = cmKey.length > 0 ? cmKey : [];
            setSearchArgs(args);
        }
    };

    const shippingStateChanged = (pmKey: Array<number>) => {
        if (searchArgs) {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.shippingPmKeys = pmKey;
            setSearchArgs(args);
        }
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value);
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                shippingPmKeys: [],
                shippingCmKeys: [],
                shippingCity: e.target.value,
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.shippingCity = e.target.value;
            setSearchArgs(args);
        }
    };

    const addAttribute = () => {
        var attribute: AttributeSearch = {
            id: crypto.randomUUID(),
            attributeType: undefined,
            attributeValue: undefined,
            attributeLevel: undefined,
        };
        setAttributes(attributes.concat(attribute));
    };

    const search = () => {
        setSearchClicked(true);
        if (searchArgs)
            runSearch(searchArgs)
                .unwrap()
                .then(() => {
                    logEvent("ProfileSearchExecuted", null);
                });
    };

    if (isError) {
        console.log("there was an error " + error);
    }

    return (
        <Card>
            <CardContent>
                <Grid container spacing={4} maxWidth={2000}>
                    <Grid item xs={12} md={12} lg={12}>
                        <AdvancedSearchMultiSelect
                            dataList={workPollMap ? workPollMap : []}
                            id="workPools"
                            label="Work Pools"
                            handleSelectionChanged={cKeyHandler}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <AdvancedSearchMultiSelect
                            dataList={rolesMap ? rolesMap : []}
                            id="roles"
                            label="Roles"
                            handleSelectionChanged={prtKeyHandler}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <AdvancedSearchMultiSelectWithStringIds
                            dataList={timeZonesMap ? timeZonesMap : []}
                            id="time-zone"
                            label="Time Zone"
                            handleSelectionChanged={timeZoneHandler}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AdvancedSearchSingleSelect
                            dataList={countryMap ? countryMap : []}
                            id="origin-country"
                            label="Country of Origin"
                            handleSelectionChanged={originCmKeyHandler}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <AdvancedSearchSingleSelect
                            dataList={countryMap ? countryMap : []}
                            id="shipping-country"
                            label="Country"
                            handleSelectionChanged={shippingCountryChanged}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <AdvancedSearchSingleSelect
                            dataList={stateMap ? stateMap : []}
                            id="shipping-state"
                            label="State"
                            handleSelectionChanged={shippingStateChanged}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            id="shipping-city-tf"
                            label="City"
                            variant="outlined"
                            value={city}
                            onChange={handleCityChange}
                            fullWidth
                        />
                    </Grid>

                    {attributes.map((f, index) => (
                        <Grid item xs={12} md={12} lg={12} key={"gi_" + f.id}>
                            <AdvancedSearchAttributeListItem
                                handleAttributeSelected={handleAttributeSelected}
                                attributeSearch={f}
                                handleDelete={handleDeleteAttribute}
                                allAttributeOptions={allAttributeOptions}
                                key={f.id}
                            />
                        </Grid>
                    ))}

                    <Grid item xl={12}>
                        <Stack direction={"row"} spacing={4}>
                            <CclUnrestrictedButton onClick={() => addAttribute()}>
                                Add Attribute
                            </CclUnrestrictedButton>
                            <CclUnrestrictedLoadingButton
                                loading={isLoading}
                                onClick={search}
                                disabled={!searchArgs}
                            >
                                Search
                            </CclUnrestrictedLoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AdvancedSearchForm;
