import * as React from "react";
import { Button, ButtonProps } from "@mui/material";

type CclButtonProps = ButtonProps & {
    restricted: boolean;
    mode: "primary" | "secondary" | "tertiary";
};

export const CclButton: React.FC<CclButtonProps> = (props) => {
    const { restricted, mode, sx, children, ...passthroughprops } = props;
    
    const v = props.mode === "primary" ? "contained" : props.mode === "secondary" ? "outlined" : "text";    
    const brdr = mode !== "tertiary" ? "1px solid" : "0px";
    const disabledStyle = {
        backgroundColor: "white",
        color: "#A6A6A6",
        borderColor: "#A6A6A6",
        border: brdr
    };

    return (
        <Button
            color={restricted ? "secondary" : "primary"}
            variant={v}
            sx={{
                "&.Mui-disabled": disabledStyle,
                ...sx,
            }}
            {...passthroughprops}
        >
            {children}
        </Button>
    );
};

export default CclButton;
