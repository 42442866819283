import * as React from "react";
import { Alert, AlertTitle, Card, CardHeader } from "@mui/material";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import { useSearchJournalEntriesMutation } from "../../../../services/cclTokenedJournalEntryApi";
import { JournalEntry } from "../../../../services/types/journalEntryTypes";
import ComponentLoader from "../../../../components/common/componentLoader";
import HistoryTimeLine from "../../../../components/common/historyTimeline/historyTimeline";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";
import SessionJournalDrawer from "../sessionJournalDrawer";

interface SessionJournalEntryTimelineCardProps {
    sessionCode: string;
    sessionId: string;
    drawerOpen: boolean;
}

const SessionJournalEntryTimelineCard: React.FC<SessionJournalEntryTimelineCardProps> = (props) => {
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(props.drawerOpen);
    const [allJournalEntries, setAllJournalEntries] = React.useState<JournalEntry[]>([]);
    const [
        runSearchJournalEntries,
        {
            isLoading: journalEntriesLoading,
            isError: journalEntriesIsError,
            error: journalEntriesError,
        },
    ] = useSearchJournalEntriesMutation();
    const claimsService = new UserClaimsService();
    const allowJournalEntries = claimsService.IsUserInGroups([
        eGroupIds.eClientAdmin,
        eGroupIds.eNWAAdmin,
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
    ]);

    React.useEffect(() => {
        if (props.drawerOpen === drawerOpen) return;
        setDrawerOpen(props.drawerOpen);
    }, [props.drawerOpen]);

    React.useEffect(() => {
        if (props.sessionCode === "") return;

        let entries: JournalEntry[] = [];

        runSearchJournalEntries({
            journalCategoryIds: [2, 3], // session and participant journal entries
            categoryDataTerm: props.sessionCode,
        })
            .unwrap()
            .then((journalentries: JournalEntry[]) => {
                entries = [...journalentries];
                setAllJournalEntries(entries);
            })
            .catch(() => {
                setAllJournalEntries(entries);
            });
    }, [props.sessionCode, runSearchJournalEntries]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (journalEntriesIsError) {
        return showErrorAlert(getErrorMsg(journalEntriesError));
    }

    return (
        <React.Fragment>
            {allowJournalEntries && (
                <SessionJournalDrawer
                    sessionId={props.sessionId}
                    sessionCode={props?.sessionCode ?? ""}
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    onSave={() => setDrawerOpen(false)}
                />
            )}
            <Card sx={{ height: 1, width: { xs: 1, lg: 0.8, xl: 0.6 }, m: 2, p: 2 }}>
                {allowJournalEntries ? (
                    <CardHeader
                        action={
                            <CclButton
                                restricted={false}
                                mode={"primary"}
                                onClick={() => setDrawerOpen(true)}
                            >
                                Create Journal Entry
                            </CclButton>
                        }
                    />
                ) : null}
                {journalEntriesLoading ? (
                    <ComponentLoader msg="Retrieving Journal Entries..." />
                ) : (
                    <HistoryTimeLine events={[]} journalEntries={allJournalEntries} />
                )}
            </Card>
        </React.Fragment>
    );
};

export default SessionJournalEntryTimelineCard;
