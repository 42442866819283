import React from "react";
import {
    EmailRequest,
    EmailType,
    RaterDetails,
} from "../../../../services/types/assessmentAPITypes";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import { Alert, IconButton, Link, Snackbar } from "@mui/material";
import ReadOnlyGate from "../../../../components/userAccess/ReadOnlyGate";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RaterTypesDialog from "./raterTypesDialog";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import {
    useReSendRaterInvitationMutation,
    useReSendRaterRemindersMutation,
} from "../../../../services/cclTokenedEnterpriseRaterApi";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { AccessEventRegistrationDetails } from "../../../../services/types/accessEventTypes";

export interface RaterManagementDataGridProProps {
    programCode: string;
    ratersDetailsList: RaterDetails[];
    OnRaterTypeChanged: (RMkey: number, RaterTypeKey: number) => void;
    OnRaterDelete: (RMkey: number) => void;
    isLoading: boolean;
    showToolbar: boolean;
    addraters: () => void;
    isUserRaterManager: boolean;
    isSessionRater: boolean;
    readOnly?: boolean;
}

export const RaterManagementDataGridPro: React.FC<RaterManagementDataGridProProps> = (props) => {
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [confirmOpenDelete, setConfirmOpenDelete] = React.useState<boolean>(false);
    const [raterTypeChanged, setraterTypeChanged] = React.useState<string>("");
    const [rmKey, setRMKey] = React.useState<number>(0);
    const [InMKey, setInMKey] = React.useState<number>(0);
    const [rmkeyDelete, setrmkeyDelete] = React.useState<number>(0);
    const [newRaterDetail, setNewRaterDetail] = React.useState(props.ratersDetailsList);
    const [open, setOpen] = React.useState<boolean>(false);
    const [msg, setMsg] = React.useState<string>("");
    const [reSendReminders, { isSuccess: isSuccessReminder }] = useReSendRaterRemindersMutation();
    const [reSendInvitation, { isSuccess: isSuccessInvitation }] =
        useReSendRaterInvitationMutation();
    const [confirmOpenReminder, setConfirmOpenReminder] = React.useState<boolean>(false);
    const [confirmOpenInvitation, setConfirmOpenInvitation] = React.useState<boolean>(false);
    const [ids, setIds] = React.useState<number[]>([]);
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        setNewRaterDetail(props.ratersDetailsList);
    }, [props.ratersDetailsList]);

    React.useEffect(() => {
        if (isSuccessReminder) {
            setMsg("Rater Reminders Re-Sent Successfully.");
            setOpen(true);
        }
    }, [isSuccessReminder]);

    React.useEffect(() => {
        if (isSuccessInvitation) {
            setMsg("Rater Invitation Re-Sent Successfully.");
            setOpen(true);
        }
    }, [isSuccessInvitation]);

    const OnButtonClick = (buttonName: string) => {
        if (buttonName === "Add") {
            props.addraters();
        }
        if (buttonName === "Reminder") {
            setConfirmOpenReminder(true);
        }
        if (buttonName === "Invitation") {
            setConfirmOpenInvitation(true);
        }
    };

    const OnCheckBoxChangedReminders = () => {
        setConfirmOpenReminder(false);
        if (ids != null && ids.length > 0) {
            var newEmailrequest: EmailRequest[] = [];
            ids.forEach((i) => {
                const EmailList: EmailRequest = {
                    EmailDateToSend: new Date(),
                    EmailTypeId: EmailType.RaterReminder,
                    ParticipantId: newRaterDetail?.filter((r) => r.rM_Key === i)[0].participant_ESI_Key ?? "",
                    RaterId: parseInt(i.toString()),
                };
                newEmailrequest.push(EmailList);
            });
            reSendReminders(newEmailrequest)
                .unwrap()
                .then(() => {
                    const esiKeys = newRaterDetail?.map(rd => rd.participant_ESI_Key);
                    const evtData:AccessEventRegistrationDetails = {
                        esiKey: esiKeys.join(','),
                        projectCode: props.programCode
                    };                    
                    logEvent("RaterRemindersResent", evtData);
                });
        }
    };

    const OnCheckBoxChangedInvitation = () => {
        setConfirmOpenInvitation(false);
        if (ids != null && ids.length > 0) {
            var newEmailrequest: EmailRequest[] = [];
            ids.forEach((i) => {
                const EmailList: EmailRequest = {
                    EmailDateToSend: new Date(),
                    EmailTypeId: EmailType.ProductRaterInvitation,
                    ParticipantId:
                        newRaterDetail?.filter((r) => r.rM_Key === i)[0].participant_ESI_Key ?? "",
                    RaterId: i,
                };
                newEmailrequest.push(EmailList);
            });
            reSendInvitation(newEmailrequest)
                .unwrap()
                .then(() => {
                    const esiKeys = newRaterDetail?.map(rd => rd.participant_ESI_Key);
                    const evtData:AccessEventRegistrationDetails = {
                        esiKey: esiKeys.join(','),
                        projectCode: props.programCode
                    };                    
                    logEvent("RaterInvitationsResent", evtData);
                });
        }
    };

    const handleOk = (rmKey: number, raterTypeNew: string, raterTypeId: number) => {
        setConfirmOpen(false);
        var newObj = newRaterDetail?.map((e) => {
            if (e.rM_Key === rmKey) {
                return {
                    ...e,
                    raterTypeName: raterTypeNew,
                };
            }
            return e;
        });
        setNewRaterDetail(newObj);
        props.OnRaterTypeChanged(rmKey, raterTypeId);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleDelete = (rmKey: number) => {
        var newObj = newRaterDetail.filter((r) => r.rM_Key !== rmKey);
        setNewRaterDetail(newObj);
        props.OnRaterDelete(rmKey);
    };

    const handleCOnfirmationOk = () => {
        setConfirmOpenDelete(false);
        handleDelete(rmkeyDelete);
    };

    const claimsService = new UserClaimsService();

    const COLUMNS = [
        {
            field: "participantName",
            headerName: "Participant",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <Link href={`/registration/${encodeURI(params.row.participant_ESI_Key)}`}>
                    {params.value}
                </Link>
            ),
            flex: 0.4,
        },
        {
            field: "raterName",
            headerName: "Rater",
            type: "string",
            flex: 0.4,
        },
        {
            field: "raterEmail",
            headerName: "Email",
            type: "string",
            flex: 0.4,
        },
        {
            field: "formMasterName",
            headerName: "Assessment",
            type: "string",
            flex: 0.4,
        },
        {
            field: "raterTypeName",
            headerName: "Type",
            type: "string",
            flex: 0.4,
        },
        {
            field: "bpiasT_Name",
            headerName: "Status",
            type: "string",
            flex: 0.3,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            flex: 0.25,
            renderCell: (params: GridRenderCellParams) =>
                props.readOnly ? null : (
                    <ReadOnlyGate
                        reactElement={
                            <>
                                <IconButton
                                    sx={{ color: "warning.main" }}
                                    onClick={() => {
                                        setraterTypeChanged(params.row["raterTypeName"]);
                                        setRMKey(params.row["rM_Key"]);
                                        setInMKey(params.row["instrumentKey"]);
                                        setConfirmOpen(true);
                                    }}
                                    aria-label="edit"
                                >
                                    {" "}
                                    <EditIcon />
                                </IconButton>
                                <CclGenericConfirmationDialog
                                    open={confirmOpenDelete}
                                    onCancel={() => setConfirmOpenDelete(false)}
                                    onOk={() => handleCOnfirmationOk()}
                                />
                                <IconButton
                                    sx={{ color: "warning.main" }}
                                    onClick={() => {
                                        setrmkeyDelete(params.row["rM_Key"]);
                                        setConfirmOpenDelete(true);
                                    }}
                                    aria-label="delete"
                                >
                                    {" "}
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                    />
                ),
        },
    ];

    return (
        <>
            <RaterTypesDialog
                open={confirmOpen}
                onOk={handleOk}
                instrumentId={InMKey}
                rmKey={rmKey}
                raterTypeName={raterTypeChanged}
                onCancel={() => setConfirmOpen(false)}
            />
            <DataGridPro
                columns={
                    props.isUserRaterManager
                        ? props.isSessionRater
                            ? addCustomDataGridFilters(COLUMNS as GridColumns<any>)
                            : addCustomDataGridFilters(
                                  COLUMNS.filter(
                                      (c) => c.field !== "participantName"
                                  ) as GridColumns<any>
                              )
                        : props.isSessionRater
                        ? addCustomDataGridFilters(
                              COLUMNS.filter(
                                  (c) => c.field !== "bpiasT_Name" && c.field !== "actions"
                              ) as GridColumns<any>
                          )
                        : props.isSessionRater
                        ? addCustomDataGridFilters(
                              COLUMNS.filter(
                                  (c) => c.field !== "bpiasT_Name" && c.field !== "actions"
                              ) as GridColumns<any>
                          )
                        : addCustomDataGridFilters(
                              COLUMNS.filter(
                                  (c) =>
                                      c.field !== "bpiasT_Name" &&
                                      c.field !== "actions" &&
                                      c.field !== "participantName"
                              ) as GridColumns<any>
                          )
                }
                rows={newRaterDetail == null ? [] : newRaterDetail}
                loading={props.isLoading}
                getRowId={(row: RaterDetails) => row.rM_Key}
                checkboxSelection
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={
                    props.isUserRaterManager && !claimsService.IsReadOnly()
                        ? {
                              toolbar: {
                                  showSaveState: true,
                                  hideSettingsButtons: true,
                                  miscButtons: [
                                      {
                                          id: "Add",
                                          caption: "Add",
                                          disable: false,
                                          returnSelected: false,
                                          restricted: true,
                                          onClick: () => OnButtonClick("Add"),
                                      },
                                      {
                                          id: "Reminder",
                                          caption: "Re-Send Reminders",
                                          disable: false,
                                          returnSelected: true,
                                          restricted: true,
                                          onClick: () => OnButtonClick("Reminder"),
                                      },
                                      {
                                          id: "Invitation",
                                          caption: "Re-Send Invitation",
                                          disable: false,
                                          returnSelected: true,
                                          restricted: true,
                                          onClick: () => OnButtonClick("Invitation"),
                                      },
                                  ],
                                  anySelected: ids?.length > 0,
                                  btnClick: OnButtonClick,
                                  isRaterMangementRole: props.isUserRaterManager,
                              },
                          }
                        : undefined
                }
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    setIds(ids.map((i) => parseInt(i.toString())));
                }}
                autoHeight
            />

            <CclGenericConfirmationDialog
                open={confirmOpenInvitation}
                onCancel={() => setConfirmOpenInvitation(false)}
                onOk={OnCheckBoxChangedInvitation}
            />
            <CclGenericConfirmationDialog
                open={confirmOpenReminder}
                onCancel={() => setConfirmOpenReminder(false)}
                onOk={OnCheckBoxChangedReminders}
            />

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    {msg}
                </Alert>
            </Snackbar>
        </>
    );
};

export default RaterManagementDataGridPro;
