import * as React from "react";
import { DataGridPro, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SessionDetailResourceAppointment } from "../../../../services/types/sessionDetailTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclCommonLink from "../../../../components/common/cclCommonLink";

export interface AssignmentsDataGridProps {
    assignments: SessionDetailResourceAppointment[];
}

export const AssignmentsDataGrid: React.FC<AssignmentsDataGridProps> = (props) => {
    const claimsService = new UserClaimsService();
    const showLinks = claimsService.ShouldSeeAccessInternalLinks();

    const COLUMNS = [
        {
            field: "programCode",
            headerName: "Project ID",
            type: "string",
            renderCell: (params: GridRenderCellParams) =>
                showLinks ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/session/${params.row.esKey}`}
                    />
                ) : (
                    params.value
                ),
            flex: 0.3,
        },
        {
            field: "resourceType",
            headerName: "Resource Type",
            type: "string",
            flex: 0.35,
        },
        {
            field: "startDateTimeUtc",
            headerName: "Start",
            valueGetter: (params: any) => new Date(params.row.startDateTimeUtc),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            flex: 0.25,
        },
        {
            field: "endDateTimeUtc",
            headerName: "End",
            valueGetter: (params: any) => new Date(params.row.endDateTimeUtc),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            flex: 0.25,
        },
        {
            field: "categoryName",
            headerName: "Category",
            type: "string",
            flex: 0.35,
        },
        {
            field: "taskName",
            headerName: "Task Name",
            type: "string",
            flex: 0.35,
        },
        {
            field: "programTitle",
            headerName: "Title",
            type: "string",
            flex: 1,
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.raKey}
            rows={props.assignments}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            components={{ Toolbar: CclDataGridToolbar }}
            initialState={{
                sorting: {
                    sortModel: [{ field: "startDateTimeUtc", sort: "desc" }],
                },
            }}
            autoHeight
        />
    );
};

export default AssignmentsDataGrid;
