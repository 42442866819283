import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface ResourceListConfigState {
  Email: string | null
};

// Define the initial state using that type
const initialState: ResourceListConfigState = {
  Email: null
};

export const resourceListConfigSlice = createSlice({
  name: 'resourceListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateResourceEmailSearchParam: (state, action: PayloadAction<string | null>) => {
      state.Email = action.payload;
      console.log("world " + crypto.randomUUID());
    }
  }
});

export const { updateResourceEmailSearchParam } = resourceListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectResourceListConfig = (state: RootState) => state;
export default resourceListConfigSlice.reducer;