import * as React from "react";
import UnderConstruction from "../../components/common/underConstruction";

export interface ScoringCalendarProps {}

export const ScoringCalendar: React.FC<ScoringCalendarProps> = (props) => {
    return <UnderConstruction msg={"The Scoring Calendar is coming soon to CCL Access!"} />;
};

export default ScoringCalendar;
