import * as React from "react";
import { Alert, AlertColor, AlertTitle, Box } from "@mui/material";
import { Asset } from "../../../../services/types/enterpriseParticipantApiTypes";
import FileUploadDialog from "./fileUploadDialog/fileUploadDialog";
import SessionAssetsDataGrid from "./sessionFileDataGrid/sessionFilesDataGrid";
import { SessionDetailAssignedCoach } from "../../../../services/types/sessionDetailTypes";
import { useDeleteAssetsMutation } from "../../../../services/cclTokenedAssetsApi";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import PageLoader from "../../../../components/common/pageLoader";
import { PersonInfo } from "../../../../models/personInfo";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import CclDownloadWarningDialog from "../../../../components/common/cclDownloadWarningDialog";
import CclStatusDialog from "../../../../components/common/cclStatusDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";

interface SessionFilesSubPanelProps {
    sessionId: string;
    assets: Asset[];
    coaches: SessionDetailAssignedCoach[];
    readOnly?: boolean;
}

const SessionFilesSubPanel: React.FC<SessionFilesSubPanelProps> = (props) => {
    const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
    const [statusTitle, setStatusTitle] = React.useState<string>("");
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [statusEnableOk, setStatusEnableOk] = React.useState<boolean>(false);
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("info");
    const [showExpirationError, setShowExpirationError] = React.useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [buttonAction, setButtonAction] = React.useState<string>("");
    const [ids, setIds] = React.useState<number[]>([]);
    const [openUploadDialog, setOpenUploadDialog] = React.useState<boolean>(false);
    const [personList, setPersonList] = React.useState<PersonInfo[]>([]);
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [loaderMessage, setLoaderMessage] = React.useState<string>("");
    const [showLargeUploadWarning, setShowLargeUploadWarning] = React.useState<boolean>(false);
    const [selectedCount, setSelectedCount] = React.useState<number>(0);
    const { data: sessionDetails } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const [deleteAssets, { isSuccess: deleteSuccess, isError: deleteError }] =
        useDeleteAssetsMutation();
    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    React.useEffect(() => {
        if (props.coaches != null) {
            const people: PersonInfo[] = props.coaches
                .filter(
                    (value, index, self) => index === self.findIndex((t) => t.imKey === value.imKey)
                )
                .map((p) => {
                    return { firstName: p.firstName, lastName: p.lastName, imKey: p.imKey };
                });
            setPersonList(people);
        }
    }, [props.coaches]);

    React.useEffect(() => {
        if (deleteSuccess || deleteError) {
            setShowLoader(false);
        }
    }, [deleteSuccess, deleteError]);

    const uploadDialogClosed = () => {
        setOpenUploadDialog(false);
    };

    const onGridToolbarButtonClick = (eventName: string, selectedIds: (number | string)[]) => {
        const newids = selectedIds.map((i) => +i);
        setButtonAction(eventName);
        setIds(newids);
        if (eventName === "upload") {
            completeAction(eventName, newids);
        } else if (eventName === "download") {
            if (selectedIds.length > 30) {
                setSelectedCount(selectedIds.length);
                setShowLargeUploadWarning(true);
            } else {
                setShowExpirationError(true);
            }
        } else {
            setConfirmOpen(true);
        }
    };

    const cancelAction = () => {
        setConfirmOpen(false);
        setButtonAction("");
        setIds([]);
    };

    const completeAction = (eventname: string, aikeys: number[]) => {
        setConfirmOpen(false);
        setShowExpirationError(false);
        switch (eventname) {
            case "upload":
                setOpenUploadDialog(true);
                break;

            case "download":
                let filename: string = "";
                if (aikeys.length > 1) {
                    filename =
                        sessionDetails != null
                            ? `${sessionDetails.sessionCode}_SessionFiles.zip`
                            : "SessionFiles.zip";
                } else if (aikeys.length === 1) {
                    let ast = props.assets.find((a) => a.id === aikeys[0]);
                    if (ast == null) return; // just in case id isn't found
                    filename = ast.name;
                    if (ast.fileExtension !== "") {
                        var fileExt = ast.name.split(".").pop();
                        if (fileExt?.toLowerCase() !== ast.fileExtension.toLowerCase())
                            filename += ast.fileExtension;
                    }
                } else {
                    cancelAction();
                    return; // nothing selected
                }

                downloadService
                    .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: false })
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("IndividualFilesDownloaded", evtData);
                        setStatusMessage("Download Complete");
                        setStatusSeverity("success");
                        setStatusEnableOk(true);
                    })
                    .catch((error) => {
                        const msg = error.message ?? "Unknown Error";

                        setStatusMessage(`Error downloading selected file(s). ${msg}`);
                        setStatusSeverity("error");
                        setStatusEnableOk(true);
                    });
                setStatusOpen(true);
                setStatusTitle("Downloading Session Files");
                setStatusMessage(
                    "...downloading selected session files. If you have selected multiple files this could take several minutes. Please be patient. A confirmation message will be displayed when the download has completed."
                );
                setStatusSeverity("info");
                break;

            case "delete":
                deleteAssets({ eskey: +props.sessionId, aikeys: aikeys })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("IndividualFilesDeleted", evtData);
                    });
                setLoaderMessage("Deleting selected files...");
                setShowLoader(true);
                break;
        }
        cancelAction();
    };

    return (
        <Box
            width={1}
            sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}
        >
            <CclAlertDialog
                title={`Too Many Files (${selectedCount}) Selected For Download`}
                msg="For data security and privacy purposes files can only be downloaded in batches of 30 or less. Please deselect some files and try again."
                open={showLargeUploadWarning}
                onOk={() => setShowLargeUploadWarning(false)}
                severity={"error"}
            />
            <CclStatusDialog
                open={statusOpen}
                onOk={() => setStatusOpen(false)}
                severity={statusSeverity}
                title={statusTitle}
                msg={statusMessage}
                enableOk={statusEnableOk}
            />
            <CclDownloadWarningDialog
                open={showExpirationError}
                onOk={() => completeAction("download", ids)}
            />
            <CclGenericConfirmationDialog
                open={confirmOpen}
                onCancel={cancelAction}
                onOk={() => completeAction(buttonAction, ids)}
            />
            <FileUploadDialog
                sessionId={props.sessionId}
                people={personList}
                open={openUploadDialog}
                personType={"resource"}
                onClose={uploadDialogClosed}
                projectCode={sessionDetails?.sessionCode ?? ""}
                isSessionFile={true}
            />
            {personList.length === 0 && (
                <Alert severity="info" sx={{ m: 3, p: 3 }}>
                    <AlertTitle>No resources assigned</AlertTitle>
                    Session documents are meant for assigned resources
                </Alert>
            )}
            <div style={{ flexGrow: 1 }}>
                {showLoader && <PageLoader msg={loaderMessage} />}
                <SessionAssetsDataGrid
                    coaches={personList}
                    assets={props.assets}
                    handleEvent={onGridToolbarButtonClick}
                    readOnly={props.readOnly}
                />
            </div>
        </Box>
    );
};

export default SessionFilesSubPanel;
