import * as React from "react";
import { Switch } from "@mui/material";
import { DataGridPro, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ApplicationFeature } from "../../../../services/types/applicationFeatureTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";

export interface ApplicationFeaturesDataGridProProps {
    features: ApplicationFeature[];
    triggerUpdateFlow: (id: number) => void;
    loading: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
}

export const ApplicationFeaturesDataGridPro: React.FC<ApplicationFeaturesDataGridProProps> = (props) => {

    const COLUMNS = [
        {
            field: "applicationFeatureId",
            headerName: "ID",
            type: "number",
            width: 100,
        },
        {
            field: "name",
            headerName: "Feature Name",
            type: "string",
            flex: 0.8,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            flex: 1,
        },
        {
            field: "active",
            headerName: "Active?",
            type: "boolean",
            flex: 0.6,
        },
        {
            field: "createdUser",
            headerName: "Created By",
            flex: 0.75,
            type: "string",
        },
        {
            field: "createdUtc",
            headerName: "Created Date (utc)",
            type: "string",
            flex: 1.1,
        },
        {
            field: "modifiedUser",
            headerName: "Last Modified By",
            flex: 1,
            type: "string",
        },
        {
            field: "modifiedUtc",
            headerName: "Last Modified Date (utc)",
            flex: 0.8,
            type: "string",
        },
        {
            field: "actions",
            headerName: "Change Active?",
            flex: 0.65,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        <Switch checked={params.row.active} onChange={()=>props.triggerUpdateFlow(params.row.applicationFeatureId)}/>
                    </>
                );
            },
        },
    ];

    return (
            <DataGridPro
                rows={props.features}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                loading={props.loading}
                getRowId={(row: ApplicationFeature) => row.applicationFeatureId}
                initialState={
                    {                  
                        sorting: 
                            {
                              sortModel: 
                                [{ field: 'applicationFeatureId', sort: 'asc' }]
                            },
                    }
                    }
            />
    );
};

export default ApplicationFeaturesDataGridPro;
