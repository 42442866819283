import * as React from "react";
import { Stack, Card, Grid, AlertColor, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateProductSaleSearchParams } from "../../app/slices/productSaleListConfigSlice";
import CclErrorDialog from "../../components/common/cclErrorDialog";
import CclGenericConfirmationDialog from "../../components/common/cclGenericConfirmationDialog";
import {
    useChangeProductSalesFacilitatorMutation,
    useGetProductSaleParticipantsByDateMutation,
    useLazyGetParticipantCompassUrlQuery,
    useLazyGetProductSaleParticipantsByFacilitatorOrParticipantEmailQuery,
    useReleaseProductSalesMutation,
} from "../../services/cclTokenedEnterpriseParticipantApi";
import {
    ChangeProductSalesFacilitatorPayload,
    GetCompassUrlResponse,
    ProductSaleParticipant,
} from "../../services/types/enterpriseParticipantApiTypes";
import { getDateStringAdjusted } from "../../utilities/dateHelpers";
import ProductSaleAssessmentList from "./productSaleAssessmentList";
import { useSendCompassInvitationsMutation } from "../../services/cclTokenedGrandCentralApi";
import {
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../services/types/rtkQueryTypes";
import { FileDownloadService } from "../../services/fileDownloadService/fileDownloadService";
import CclStatusDialog from "../../components/common/cclStatusDialog";
import { useParams } from "react-router-dom";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import CclDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclDateSearchBar";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import CclEnterNewFacilitatorDialog from "./cclEnterNewFacilitatorDialog";

interface ProductSalesCardProps {}

const ProductSalesCard: React.FC<ProductSalesCardProps> = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.productSaleListConfig);

    const params = useParams();

    const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
    const [statusTitle, setStatusTitle] = React.useState<string>("");
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [statusEnableOk, setStatusEnableOk] = React.useState<boolean>(false);
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("info");

    const [searchEmail, setSearchEmail] = React.useState<string>("");
    const [startDate, setStartDate] = React.useState<Date | null>(new Date(config.StartDate));
    const [endDate, setEndDate] = React.useState<Date | null>(new Date(config.EndDate));
    const [endDateError, setEndDateError] = React.useState<boolean>(false);
    const [startDateError, setStartDateError] = React.useState<boolean>(false);
    const [
        runSearch,
        {
            data: paxByDate,
            isLoading: paxByDateIsLoading,
            isError: paxByDateIsError,
            error: paxByDateError,
        },
    ] = useGetProductSaleParticipantsByDateMutation();
    const [
        trigger,
        {
            data: paxByEmail,
            isLoading: paxByEmailIsLoading,
            isError: paxByEmailIsError,
            error: paxByEmailError,
        },
    ] = useLazyGetProductSaleParticipantsByFacilitatorOrParticipantEmailQuery();
    const [searchResultString, setSearchResultString] = React.useState<string>("");
    const [reportsToReplace, setReportsToReplace] = React.useState<string[]>([]);
    const [openFacilitatorEmailDialog, setOpenFacilitatorEmailDialog] =
        React.useState<boolean>(false);
    const [newFacilitatorEmail, setNewFacilitatorEmail] = React.useState<string>("");
    const [sendChangeFacilitator, { isSuccess: changeFacilitatorSuccess }] =
        useChangeProductSalesFacilitatorMutation();
    const { logEvent } = useLogAccessEvent();
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [errorOpen, setErrorOpen] = React.useState<boolean>(false);
    const [releaseReports] = useReleaseProductSalesMutation();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();
    const downloadService = new FileDownloadService();

    const [productSales, setProductSales] = React.useState<ProductSaleParticipant[]>([]);

    // eslint-disable-next-line
    const clearDateSearch = () => {
        // this is a hack to overcome an error in the mui datepicker that won't allow us to set an invalid date to null.
        // We have to first set a valid date, then go to null
        if (startDateError) {
            setStartDate(new Date());
            setTimeout(() => setStartDate(null), 100);
        } else {
            setStartDate(null);
        }
        if (endDateError) {
            setEndDate(new Date());
            setTimeout(() => setEndDate(null), 100);
        } else {
            setEndDate(null);
        }
        setEndDateError(false);
        setStartDateError(false);
    };

    React.useEffect(() => {
        if (!params.searchparam) {
            // the config date times are set to generic dates when searching by keyword
            //  here we make sure the dates are cleared if the keyword search is active
            //  this is really only for when we return to the page after leaving
            if (config.IsEmailSearchActive) {
                setStartDate(null);
                setEndDate(null);
                trigger(config.Email.trim());
            } else {
                runSearch({
                    start: config.StartDate,
                    end: config.EndDate,
                });
            }
            return;
        }
        setProductSales([]);
        setSearchEmail(params.searchparam);
        getAssessmentsByEmail(params.searchparam);
        // eslint-disable-next-line
    }, [params]);

    React.useEffect(() => {
        if (!changeFacilitatorSuccess) return;

        if (config.IsEmailSearchActive) {
            clearDateSearch();
            trigger(config.Email.trim());
        } else {
            setSearchEmail("");
            runSearch({
                start: config.StartDate,
                end: config.EndDate,
            });
        }

        setStartDateError(false);
        setEndDateError(false);
        // eslint-disable-next-line
    }, [changeFacilitatorSuccess]);

    React.useEffect(() => {
        if (paxByDateIsError) {
            setErrorOpen(true);
            return;
        }

        if (paxByDate) {
            setProductSales(paxByDate);
            setSearchResultString(
                `${
                    paxByDate?.length
                } results for search by start date (from ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()})`
            );
        } else {
            setProductSales([]);
            setSearchResultString("");
        }
        // eslint-disable-next-line
    }, [paxByDateIsError, paxByDate]);

    React.useEffect(() => {
        if (paxByEmailIsError) {
            setErrorOpen(true);
            return;
        }

        if (paxByEmail) {
            setProductSales(paxByEmail);
            setSearchResultString(
                `${paxByEmail?.length} results for search by email ('${searchEmail.trim()}')`
            );
        } else {
            setProductSales([]);
            setSearchResultString("");
        }
        // eslint-disable-next-line
    }, [paxByEmail, paxByEmailIsError]);

    const getAssessmentsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            setStartDateError(startDate == null);
            setEndDateError(endDate == null);
            return;
        }
        setStartDateError(false);
        setEndDateError(false);
        setSearchEmail("");
        runSearch({
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        });
        setStartDate(startDate);
        setEndDate(endDate);
        dispatch(
            updateProductSaleSearchParams({
                StartDate: startDate.toISOString(),
                EndDate: endDate.toISOString(),
                Email: searchEmail,
                IsEmailSearchActive: false,
            })
        );
    };

    const getAssessmentsByEmail = async (email?: string) => {
        if ((searchEmail == null || searchEmail.trim() === "") && !email) {
            return;
        }
        clearDateSearch();
        if (email) setSearchEmail(email.trim());
        trigger(email ? email.trim() : searchEmail.trim());
        dispatch(
            updateProductSaleSearchParams({
                StartDate: getDateStringAdjusted(-2), // api requires some date but it won't be used in search
                EndDate: getDateStringAdjusted(2), // api requires some date but it won't be used in search
                Email: email ? email.trim() : searchEmail.trim(),
                IsEmailSearchActive: true,
            })
        );
    };

    const handleConfirmChangeFacilitator = () => {
        if (reportsToReplace?.length > 0 && newFacilitatorEmail !== "") {
            let payload: ChangeProductSalesFacilitatorPayload = {
                facilitatorEmail: newFacilitatorEmail,
                reportIds: reportsToReplace,
            };
            sendChangeFacilitator(payload)
                .unwrap()
                .then(() => {
                    logEvent("FacilitatorChanged", null);
                });
        }
        setConfirmOpen(false);
    };

    const handleChangeFacilitator = (email: string) => {
        setOpenFacilitatorEmailDialog(false);
        setNewFacilitatorEmail(email);
        setConfirmOpen(true);
    };

    const changeFacilitator = (rows?: any[]) => {
        if (rows != null && rows?.length > 0) {
            var reportIds: string[] = rows.map((r) => r.reportId);
            setReportsToReplace([...reportIds]);
            setOpenFacilitatorEmailDialog(true);
        } else {
            setReportsToReplace([]);
            setOpenFacilitatorEmailDialog(false);
        }
    };

    const handleDownloadReports = (rows?: any[]) => {
        setStatusOpen(true);
        setStatusTitle("Download Assessment(s)");
        setStatusMessage(
            "...downloading selected assessment(s). If you have selected multiple participants this could take several minutes. Please be patient. A confirmation message will be displayed when the download has completed."
        );
        setStatusSeverity("info");
        setStatusEnableOk(false);

        if (rows === undefined || rows.length <= 0) {
            setStatusMessage("You must select at least one item to download.");
            setStatusSeverity("error");
            setStatusEnableOk(true);
            return;
        }

        const reportIds = rows.map((r) => r.reportId as string);

        let filename = "ProductSales.zip";
        if (rows.length === 1) {
            if (rows[0] == null) return; // shouldn't happen but just in case
            filename = `${rows[0].fullName} ${rows[0].assessmentType}.pdf`;
        }

        downloadService
            .DownloadProductSaleReports({
                fname: filename,
                reportids: reportIds,
            })
            .then(() => {
                logEvent("UserDownloadedProductSale", null);
                setStatusTitle("Download Complete");
                setStatusMessage("Assesment(s) downloaded");
                setStatusSeverity("success");
                setStatusEnableOk(true);
            })
            .catch((error) => {
                const msg = error.message ?? "Unknown error";
                setStatusMessage("Error: " + msg);
                setStatusSeverity("error");
                setStatusEnableOk(true);
            });
    };

    const handleActivateCompass = (rows?: any[]) => {
        setStatusOpen(true);
        setStatusTitle("Activate Compass");
        setStatusMessage("Releasing product sale reports to Compass...");
        setStatusSeverity("info");
        setStatusEnableOk(false);

        if (rows === undefined || rows.length <= 0) {
            setStatusMessage("You must select at least one item to activate.");
            setStatusSeverity("error");
            setStatusEnableOk(true);
            return;
        }

        const reportIds = rows.map((r) => r.reportId as string);
        releaseReports(reportIds)
            .unwrap()
            .then((releaseResults) => {
                if (releaseResults === undefined || releaseResults.length <= 0) {
                    setStatusMessage("No reports were released to Compass.");
                    setStatusSeverity("warning");
                    setStatusEnableOk(true);
                    return;
                }

                const releasedReportIds: string[] =
                    releaseResults?.filter((r) => r.success).map((r) => r.reportId) ?? [];

                if (releasedReportIds != null && releasedReportIds.length > 0) {
                    setStatusTitle("Sending Compass Invitation(s)");
                    const prodSaleParticipants: ProductSaleParticipant[] =
                        productSales?.filter((ps) =>
                            releasedReportIds.some((r) => r === ps.reportId)
                        ) ?? [];
                    const sendInvitesPayload: SendCompassInvitationRequest = {
                        recipients: prodSaleParticipants.map((p) => {
                            return {
                                email: p.emailAddress,
                                firstName: p.firstName,
                                lastName: p.lastName,
                            };
                        }),
                        disableEmail: false,
                        autoAccept: false,
                        ignorePendingInvites: false,
                    };
                    //refresh product sale search results
                    if (config.IsEmailSearchActive) {
                        getAssessmentsByEmail();
                    } else {
                        getAssessmentsByDate(startDate, endDate);
                    }
                    sendCompassInvitations(sendInvitesPayload)
                        .unwrap()
                        .then((response: SendInvitationResponse) => {
                            setStatusTitle("Compass Activation Results");
                            let msg: string =
                                response.numberInivitationsSent !== 0
                                    ? `Activation successful for ${response.numberInivitationsSent} report(s). `
                                    : "";

                            if (response.numberFailed > 0)
                                msg = `${msg}Failed to send Compass invitations for ${response.numberFailed} report(s).`;

                            const severity: AlertColor =
                                response.numberInivitationsSent === 0
                                    ? "error"
                                    : response.numberFailed === 0
                                    ? "success"
                                    : "warning";

                            if (response.numberInivitationsSent > 0) {
                                logEvent("UserActivatedProductSales", null);
                            }
                            setStatusMessage(msg);
                            setStatusSeverity(severity);
                            setStatusEnableOk(true);
                        })
                        .catch((error) => {
                            const msg = error.data ?? "Unknown error";
                            setStatusMessage("Error: " + msg);
                            setStatusSeverity("error");
                            setStatusEnableOk(true);
                        });
                } else {
                    setStatusMessage("Error: Failed to release any of the selected reports.");
                    setStatusSeverity("error");
                }
            })
            .catch((error) => {
                const msg = error.data ?? "Unknown error";
                setStatusMessage("Error: " + msg);
                setStatusSeverity("error");
            });
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch((error) => {
                const msg = `Error: ${error.data ?? "Unknown error"}`;
                setStatusOpen(true);
                setStatusTitle("Error Opening Compass For Participant");
                setStatusMessage(msg);
                setStatusSeverity("error");
                setStatusEnableOk(true);
            });
    };

    return (
        <Stack height={1} width={1}>
            <CclStatusDialog
                open={statusOpen}
                onOk={() => setStatusOpen(false)}
                severity={statusSeverity}
                title={statusTitle}
                msg={statusMessage}
                enableOk={statusEnableOk}
            />
            <CclEnterNewFacilitatorDialog
                open={openFacilitatorEmailDialog}
                onOk={(email: string) => handleChangeFacilitator(email)}
                onCancel={() => setOpenFacilitatorEmailDialog(false)}
            />
            <CclGenericConfirmationDialog
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onOk={handleConfirmChangeFacilitator}
            />
            <CclErrorDialog
                open={errorOpen}
                title={"Error Retrieving Product Sale Assessments"}
                msg={"Make sure the date range for your search is not larger than one month."}
                onOk={() => setErrorOpen(false)}
            ></CclErrorDialog>
            <Card
                sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CclTextSearchBar
                        initialSearchTerm={searchEmail}
                        searchLabel="Enter Participant or Facilitator Email"
                        executeSearch={getAssessmentsByEmail}
                    />
                    <CclDateSearchBar
                        initialStartDate={startDate}
                        initialEndDate={endDate}
                        startDateLabel="Earliest Scored Date"
                        endDateLabel="Latest Scored Date"
                        executeSearch={getAssessmentsByDate}
                        allowNullDates={false}
                    />
                </Box>
                <Grid
                    sx={{
                        height: 1,
                        width: 1,
                        padding: 2,
                        paddingTop: 0,
                    }}
                >
                    <ProductSaleAssessmentList
                        loading={paxByEmailIsLoading || paxByDateIsLoading}
                        productSales={productSales ?? []}
                        showResultBar={!(paxByEmailIsLoading || paxByDateIsLoading)}
                        resultBarText={searchResultString}
                        changeFacilitator={(rows?: any[]) => changeFacilitator(rows)}
                        downloadReports={(rows?: any[]) => handleDownloadReports(rows)}
                        activateCompass={(rows?: any[]) => handleActivateCompass(rows)}
                        viewCompass={viewCompass}
                    />
                </Grid>
            </Card>
        </Stack>
    );
};

export default ProductSalesCard;
