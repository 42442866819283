import ComponentLoader from "../../../../components/common/componentLoader";
import { RaterDetails, RaterSwitchRequest } from "../../../../services/types/assessmentAPITypes";
import {
    useDeleteRaterByIdMutation,
    useGetRaterDetailsByParticipantIDMutation,
    useUpdateRaterSwitchMutation,
} from "../../../../services/cclTokenedEnterpriseRaterApi";
import { Grid, Stack } from "@mui/material";
import React from "react";
import SessionAssessmentDialog from "./sessionAssessmentDialog";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { RaterManagementDataGridPro } from "./raterManagementDataGridPro";
import { AccessEventRegistrationDetails } from "../../../../services/types/accessEventTypes";

interface RaterManagementPanelProps {
    esikey: string;
    programCode: string;
}

const RaterManagementPanel: React.FC<RaterManagementPanelProps> = (props) => {
    const [searchRaters, { data: RaterDetailsData, isLoading }] =
        useGetRaterDetailsByParticipantIDMutation();
    const [updateRaterType] = useUpdateRaterSwitchMutation();
    const [onRaterDelete] = useDeleteRaterByIdMutation();
    const [ConfirmOpen, SetConfirmOpen] = React.useState<boolean>(false);
    const [RaterDetailsDataNew, SetRaterDetailsDataNew] = React.useState<RaterDetails[]>([]);
    const claimsService = new UserClaimsService();
    const isUserRaterManager = claimsService.IsUserInAppRole(3);
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        searchRaters(parseInt(props.esikey));
    }, [props.esikey, searchRaters]);

    React.useEffect(() => {
        SetRaterDetailsDataNew(RaterDetailsData ?? []);
    }, [RaterDetailsData]);

    const PushRaterTypeChange = (RMKey: number, RaterTypeKey: number) => {
        var RaterObj = RaterDetailsDataNew?.filter((r) => r.rM_Key === RMKey)[0];
        const newObj: RaterSwitchRequest = {
            formActualID: RaterObj?.formActualKey ?? 0,
            raterTypeKey: RaterTypeKey,
            rmkey: RMKey,
        };
        updateRaterType(newObj)
            .unwrap()
            .then(() => {
                const evtData: AccessEventRegistrationDetails = {
                    esiKey: props.esikey
                };
                logEvent("RaterTypeChanged", evtData);
            });
    };

    const DeleteRaterById = (RMKey: number) => {
        onRaterDelete(RMKey)
            .unwrap()
            .then(() => {
                const evtData: AccessEventRegistrationDetails = {
                    esiKey: props.esikey
                };                
                logEvent("RaterDeleted", evtData);
            });
    };

    const handleOk = () => {
        SetConfirmOpen(false);
        searchRaters(parseInt(props.esikey));
    };

    const handleAddRaters = async () => {
        SetConfirmOpen(true);
    };

    if (isLoading) {
        return <ComponentLoader msg="Loading rater details..." />;
    }

    return (
        <Stack height={1} width={1}>
            {/*     {RaterDetailsData && RaterDetailsData.map(r => r.formActualScoredInd === 1).length > 0 && <Alert severity="warning">
                Some of the assessments are scored  
    </Alert>} */}
            <SessionAssessmentDialog
                raterList={RaterDetailsDataNew}
                esiKey={parseInt(props.esikey)}
                open={ConfirmOpen}
                oK={() => handleOk()}
                onCancel={() => SetConfirmOpen(false)}
            />
            <Grid container height={1} spacing={2}>
                <Grid item xs={12}>
                    <RaterManagementDataGridPro
                        programCode={props.programCode}
                        isLoading={isLoading}
                        isSessionRater={false}
                        ratersDetailsList={RaterDetailsDataNew}
                        OnRaterTypeChanged={PushRaterTypeChange}
                        OnRaterDelete={DeleteRaterById}
                        showToolbar={true}
                        addraters={handleAddRaters}
                        isUserRaterManager={isUserRaterManager}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default RaterManagementPanel;
