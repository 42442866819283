import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Autocomplete,
    Alert,
} from "@mui/material";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import { useGetRaterTypesByInstrumentKeyQuery } from "../../../../services/cclTokenedEnterpriseRaterApi";
import ComponentLoader from "../../../../components/common/componentLoader";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";

export type RaterTypesDialogProps = {
    open: boolean;
    onOk: (rMKey: number, raterTypeName: string, raterTypeId: number) => void;
    raterTypeName: string;
    rmKey: number;
    instrumentId: number;
    onCancel: () => any;
};

const RaterTypesDialog: React.FC<RaterTypesDialogProps> = (props) => {
    const [openConfirm, setopenConfirm] = React.useState<boolean>(false);
    const [raterTypeChanged, setRaterTypeChanged] = React.useState<string>("");
    const { data: RaterTypeList, isSuccess } = useGetRaterTypesByInstrumentKeyQuery(
        props.instrumentId,
        { skip: props.instrumentId === null }
    );

    const handleOk = () => {
        setopenConfirm(false);
        props.onOk(
            props.rmKey,
            raterTypeChanged,
            RaterTypeList
                ? RaterTypeList?.filter((r) => r.name === raterTypeChanged)[0].raterTypeId
                : 0
        );
    };

    React.useEffect(() => {
        setRaterTypeChanged("");
    }, [props.open]);

    if (isSuccess) {
        return (
            <Dialog
                fullWidth={true}
                open={props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Switch Rater Type from {props.raterTypeName} to
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="tags-standard"
                        options={RaterTypeList?.filter((r) => r.name !== props.raterTypeName).map(
                            (option) => option.name
                        )}
                        value={raterTypeChanged}
                        onChange={(e: any, newValue: string | null) => {
                            setRaterTypeChanged(newValue ?? "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Rater Types"
                                label="Rater Types"
                                sx={{ mt: 2, mb: 2 }}
                            />
                        )}
                    />
                    {(raterTypeChanged === "Boss" ||
                        raterTypeChanged === "Other" ||
                        raterTypeChanged === "Superior") && (
                        <Alert severity="warning">
                            You are switching this rater to a non-Confidential Type. Please notify
                            the rater if necessary.
                        </Alert>
                    )}
                </DialogContent>

                <DialogActions>
                    <CclGenericConfirmationDialog
                        open={openConfirm}
                        onCancel={() => setopenConfirm(false)}
                        onOk={handleOk}
                    />
                    <CclUnrestrictedOutlinedButton id="cancel-btn" onClick={() => props.onCancel()}>
                        Cancel
                    </CclUnrestrictedOutlinedButton>
                    <CclRestrictedButton
                        id="ok-btn"
                        disabled={raterTypeChanged === "" || raterTypeChanged === null}
                        onClick={() => setopenConfirm(true)}
                    >
                        Save
                    </CclRestrictedButton>
                </DialogActions>
            </Dialog>
        );
    }
    return <ComponentLoader msg="Loading Rater Types" />;
};

export default RaterTypesDialog;
