import * as React from "react";
import CclAlertDialog from "./cclAlertDialog";

export interface CclDownloadWarningDialogProps {
    open: boolean;
    onOk: () => void;
}

export const CclDownloadWarningDialog: React.FC<CclDownloadWarningDialogProps> = (props) => {
    return (
        <CclAlertDialog
            severity="info"
            open={props.open}
            onOk={props.onOk}
            title="Important"
            msg={
                "Please Note: You may automatically lose access to PDF files you have downloaded after 60 days. You can return to CCL Access to download another copy if that happens. We also kindly request you delete any downloaded files within 60 days of downloading them. This is to ensure CCL complies with data security and privacy legislation."
            }
        />
    );
};

export default CclDownloadWarningDialog;
