import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Autocomplete,
} from "@mui/material";
import { useGetAssessmentByParticipantQuery } from "../../../../services/cclTokenedEnterpriseRaterApi";
import AddRatersDialog from "./addRatersDialog";
import { RaterDetails } from "../../../../services/types/assessmentAPITypes";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";

export type SessionAssessmentDialogProps = {
    open: boolean;
    onCancel: () => any;
    oK: () => any;
    esiKey: number;
    raterList: RaterDetails[];
};

const SessionAssessmentDialog: React.FC<SessionAssessmentDialogProps> = (props) => {
    const [assessments, setAssessments] = React.useState<string>("");
    const [instrumentKey, setInstrumentKey] = React.useState<number>();
    const { data: assessmentListData } = useGetAssessmentByParticipantQuery(props.esiKey, {
        skip: props.esiKey === 0,
    });
    const [ConfirmOpen, SetConfirmOpen] = React.useState<boolean>(false);
    const [open, setopen] = React.useState<boolean>(false);

    React.useEffect(() => {
        setAssessments("");
        setopen(props.open);
    }, [props.open]);

    React.useEffect(() => {
        if (assessments) {
            var InMKey = assessmentListData?.filter((a) => a.instrumentName === assessments)[0]
                .instrumentId;
            setInstrumentKey(InMKey ?? 0);
        }
    }, [assessments, assessmentListData]);

    const handleOk = () => {
        SetConfirmOpen(false);
        props.oK();
    };

    return (
        <Dialog
            fullWidth={true}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Select Assessment</DialogTitle>
            <DialogContent>
                <AddRatersDialog
                    raterList={props.raterList}
                    instrumentId={instrumentKey ?? 0}
                    esiKey={props.esiKey}
                    preferredLanguageID={1}
                    onOk={() => handleOk()}
                    open={ConfirmOpen}
                    onCancel={() => SetConfirmOpen(false)}
                />
                <Autocomplete
                    id="Assessment-DropDown"
                    options={
                        assessmentListData
                            ?.filter((a) => a.instrumentTypeId === 2 || a.instrumentTypeId === 4)
                            .map((option) => option.instrumentName) ?? []
                    }
                    value={assessments}
                    onChange={(e: any, newValue: string | null) => {
                        setAssessments(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Assessment List"
                            label="Assessment List"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
            </DialogContent>

            <DialogActions>
                <CclUnrestrictedOutlinedButton id="cancel-btn" onClick={() => props.onCancel()}>
                    Cancel
                </CclUnrestrictedOutlinedButton>
                <CclRestrictedButton
                    id="ok-btn"
                    disabled={assessments === ""}
                    onClick={() => SetConfirmOpen(true)}
                >
                    Ok
                </CclRestrictedButton>
            </DialogActions>
        </Dialog>
    );
};
export default SessionAssessmentDialog;
