import * as React from "react";
import { AlertColor, Box } from "@mui/material";
import { Asset, Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import FileUploadDialog from "./fileUploadDialog/fileUploadDialog";
import ParticipantAssetsDataGrid from "./participantFileDataGrid/participantFilesDataGrid";
import {
    useReleaseSessionAssetsMutation,
    useRemoveAssetsMutation,
    useDeleteAssetsMutation,
} from "../../../../services/cclTokenedAssetsApi";
import {
    useGetCclEventByEventKeyQuery,
    useGetSessionOptionsByEventKeyQuery,
    useSetEventSessionOptionMutation,
    useUpdateSessionCoachesAccessQuery,
} from "../../../../services/cclTokenedSessionApi";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import PageLoader from "../../../../components/common/pageLoader";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import {
    SessionEventOption,
    SetSessionEventOptionRequest,
} from "../../../../services/types/sessionOptionsType";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import CclDownloadWarningDialog from "../../../../components/common/cclDownloadWarningDialog";
import CclStatusDialog from "../../../../components/common/cclStatusDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";

interface ParticipantFilesSubPanelProps {
    sessionId: string;
    assets: Asset[];
    participants: Participant[];
    readOnly?: boolean;
}

const ParticipantFilesSubPanel: React.FC<ParticipantFilesSubPanelProps> = (props) => {
    const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
    const [statusTitle, setStatusTitle] = React.useState<string>("");
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [statusEnableOk, setStatusEnableOk] = React.useState<boolean>(false);
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("info");

    const claimsService = new UserClaimsService();
    const [showExpirationError, setShowExpirationError] = React.useState<boolean>(false);
    const [showLargeUploadWarning, setShowLargeUploadWarning] = React.useState<boolean>(false);
    const [selectedCount, setSelectedCount] = React.useState<number>(0);
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [errorOpen, setErrorOpen] = React.useState<boolean>(false);
    const [errorDialogMessage, setErrorDialogMessage] = React.useState<string>("");
    const [buttonAction, setButtonAction] = React.useState<string>("");
    const [ids, setIds] = React.useState<number[]>([]);
    const [openUploadDialog, setOpenUploadDialog] = React.useState<boolean>(false);
    const [coachReleaseEnabled, setCoachReleaseEnabled] = React.useState<boolean>(false);
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [loaderMessage, setLoaderMessage] = React.useState<string>("");
    const { data: sessionDetails } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const { data: sessionOptions, isSuccess: optionsSuccess } = useGetSessionOptionsByEventKeyQuery(
        props.sessionId,
        { skip: props.sessionId === "" }
    );
    const { refetch } = useUpdateSessionCoachesAccessQuery(props.sessionId); //, {skip: !updateCoachAccess}
    const [
        releaseAssets,
        { isSuccess: releaseSuccess, isError: releaseError, error: releaseErrorMsg },
    ] = useReleaseSessionAssetsMutation();
    const [
        removeAssets,
        { isSuccess: removeSuccess, isError: removeError, error: removeErrorMsg },
    ] = useRemoveAssetsMutation();
    const [
        deleteAssets,
        { isSuccess: deleteSuccess, isError: deleteError, error: deleteErrorMsg },
    ] = useDeleteAssetsMutation();
    const [setCoachReleaseOption] = useSetEventSessionOptionMutation();
    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    const personList = props.participants.map((p) => {
        return { firstName: p.firstName, lastName: p.lastName, imKey: p.imKey };
    });

    React.useEffect(() => {
        setShowLoader(false);
        if (optionsSuccess) {
            let opt = sessionOptions.sessionEventOptions.find((o) => o.optionId === 3);
            setCoachReleaseEnabled(opt != null);
        }
        // eslint-disable-next-line
    }, [sessionOptions]);

    React.useEffect(() => {
        if (releaseSuccess || releaseError) {
            setShowLoader(false);
            if (releaseError) {
                setErrorDialogMessage(
                    `Error releasing selected file(s).${getErrorMsg(releaseErrorMsg)}`
                );
                setErrorOpen(true);
            }
        }
        // eslint-disable-next-line
    }, [releaseSuccess, releaseError]);

    React.useEffect(() => {
        if (removeSuccess || removeError) {
            setShowLoader(false);
            if (removeError) {
                setErrorDialogMessage(
                    `Error removing selected file(s).${getErrorMsg(removeErrorMsg)}`
                );
                setErrorOpen(true);
            }
        }
        // eslint-disable-next-line
    }, [removeSuccess, removeError]);

    React.useEffect(() => {
        if (deleteSuccess || deleteError) {
            setShowLoader(false);
            if (deleteError) {
                setErrorDialogMessage(
                    `Error deleting selected file(s).${getErrorMsg(deleteErrorMsg)}`
                );
                setErrorOpen(true);
            }
        }
        // eslint-disable-next-line
    }, [deleteSuccess, deleteError]);

    const uploadDialogClosed = () => {
        setOpenUploadDialog(false);
    };

    const onGridToolbarButtonClick = (eventName: string, selectedIds: (number | string)[]) => {
        let newids = selectedIds.map((i) => +i);
        setButtonAction(eventName);
        setIds(newids);
        if (eventName === "upload") {
            completeAction(eventName, newids);
        } else if (eventName === "download") {
            if (selectedIds.length > 30) {
                setSelectedCount(selectedIds.length);
                setShowLargeUploadWarning(true);
            } else {
                setShowExpirationError(true);
            }
        } else {
            setConfirmOpen(true);
        }
    };

    const cancelAction = () => {
        setConfirmOpen(false);
        setButtonAction("");
        setIds([]);
    };

    const completeAction = (eventname: string, aikeys: number[]) => {
        setConfirmOpen(false);
        setShowExpirationError(false);
        switch (eventname) {
            case "upload":
                setOpenUploadDialog(true);
                break;

            case "download":
                let filename: string = "";
                if (aikeys.length > 1) {
                    filename =
                        sessionDetails != null
                            ? `${sessionDetails.sessionCode}_ParticipantFiles.zip`
                            : "ParticipantFiles.zip";
                } else if (aikeys.length === 1) {
                    let ast = props.assets.find((a) => a.id === aikeys[0]);
                    if (ast == null) return; // just in case id isn't found
                    filename = ast.name;
                    if (ast.fileExtension !== "") {
                        let fileExt = ast.name.split(".").pop();
                        if (fileExt?.toLowerCase() !== ast.fileExtension.toLowerCase())
                            filename += ast.fileExtension;
                    }
                } else {
                    cancelAction();
                    return; // nothing selected
                }
                downloadService
                    .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: false })
                    .then(() => {
                        setShowLoader(false);
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesDownloaded", evtData);
                        setStatusTitle("Download Complete");
                        setStatusMessage("File(s) downloaded");
                        setStatusSeverity("success");
                        setStatusEnableOk(true);
                    })
                    .catch((error) => {
                        const msg = error.message ?? "Unknown Error";
                        setStatusMessage(`Error downloading selected file(s). ${msg}`);
                        setStatusSeverity("error");
                        setStatusEnableOk(true);
                    });
                setStatusOpen(true);
                setStatusTitle("Downloading Participant Files");
                setStatusMessage(
                    "...downloading selected participant files. If you have selected multiple files this could take several minutes. Please be patient. A confirmation message will be displayed when the download has completed."
                );
                setStatusSeverity("info");
                setStatusEnableOk(false);
                break;

            case "release":
                const clearedAssets: Asset[] = props.assets
                    .filter((a) => aikeys.find((key) => key === a.id) != null)
                    .filter((a) => a.fileType !== "Consultant Report");
                releaseAssets({ eskey: +props.sessionId, aikeys: clearedAssets.map((a) => a.id) })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesReleased", evtData);
                    });
                setLoaderMessage("Releasing selected assets...");
                setShowLoader(true);
                break;

            case "unrelease":
                removeAssets({ eskey: +props.sessionId, aikeys: aikeys })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesUnReleased", evtData);
                    });
                setLoaderMessage("Removing access for selected assets...");
                setShowLoader(true);
                break;

            case "delete":
                deleteAssets({ eskey: +props.sessionId, aikeys: aikeys })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesDeleted", evtData);
                    });
                setLoaderMessage("Deleting selected assets...");
                setShowLoader(true);
                break;

            case "coachreleaseenable":
                callSetCoachReleaseOption(true);
                refetch();
                setShowLoader(true);
                break;

            case "coachreleasedisable":
                callSetCoachReleaseOption(false);
                setShowLoader(true);
                break;
        }
        cancelAction();
    };

    const callSetCoachReleaseOption = (enable: boolean) => {
        if (sessionDetails == null) return;
        const option: SessionEventOption = {
            optionId: 3,
            optionName: "Coach Release",
            parentOptionId: null,
            active: enable,
            scheduled: false,
            scheduledActivation: null,
            changedBy: claimsService.GetCurrentUserEmail(),
            changedDate: new Date(),
        };

        const request: SetSessionEventOptionRequest = {
            eskey: sessionDetails?.sessionKey.toString(),
            eventOption: option,
        };

        setCoachReleaseOption(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: sessionDetails?.sessionCode ?? "",
                };

                logEvent("AllowCoachReleaseChanged", evtData);
            });
    };

    return (
        <Box width={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
            <CclStatusDialog
                open={statusOpen}
                onOk={() => setStatusOpen(false)}
                severity={statusSeverity}
                title={statusTitle}
                msg={statusMessage}
                enableOk={statusEnableOk}
            />
            <CclGenericConfirmationDialog
                open={confirmOpen}
                onCancel={cancelAction}
                onOk={() => completeAction(buttonAction, ids)}
            />
            <CclDownloadWarningDialog
                open={showExpirationError}
                onOk={() => completeAction("download", ids)}
            />
            <CclErrorDialog
                open={errorOpen}
                onOk={() => setErrorOpen(false)}
                msg={errorDialogMessage}
            />
            <CclAlertDialog
                title={`Too Many Files (${selectedCount}) Selected For Download`}
                msg="For data security and privacy purposes files can only be downloaded in batches of 30 or less. Please deselect some files and try again."
                open={showLargeUploadWarning}
                onOk={() => setShowLargeUploadWarning(false)}
                severity={"error"}
            />
            <FileUploadDialog
                sessionId={props.sessionId}
                people={personList}
                open={openUploadDialog}
                personType={"participant"}
                onClose={uploadDialogClosed}
                isSessionFile={false}
                projectCode={sessionDetails?.sessionCode ?? ""}
            />
            <div style={{ flexGrow: 1 }}>
                {showLoader && <PageLoader msg={loaderMessage} />}
                <ParticipantAssetsDataGrid
                    participants={personList}
                    assets={props.assets}
                    handleEvent={onGridToolbarButtonClick}
                    coachReleaseEnabled={coachReleaseEnabled}
                    readOnly={props.readOnly}
                />
            </div>
        </Box>
    );
};

export default ParticipantFilesSubPanel;
