import * as React from "react";
import {
    Box,
    Card,
    CardHeader,
    Dialog,
    DialogTitle,
    IconButton,
    List,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import UploadFileListItem from "./uploadFileListItem";
import StyledDropzone from "./styledDropzone";
import PersonUploadDataGrid from "./personUploadDataGrid";
import CloseIcon from "@mui/icons-material/Close";
import { useGetCclEventByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import { PersonInfo } from "../../../../../models/personInfo";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclRestrictedOutlinedButton from "../../../../../components/common/cclButtons/cclRestrictedOutlinedButton";
import CclRestrictedButton from "../../../../../components/common/cclButtons/cclRestrictedButton";

type FileUploadItem = {
    file: File;
    uploadAttempted: boolean;
    uploadSuccess: boolean;
};

interface FileUploadDialogProps {
    sessionId: string;
    projectCode: string;
    isSessionFile: boolean;
    people: PersonInfo[];
    personType: string;
    open: boolean;
    onClose: () => void;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = (props) => {
    const maxFilesToUpload = 7;
    const [fileUploadItemsList, setFileUploadItemsList] = React.useState<FileUploadItem[]>([]);
    const [imkeys, setImkeys] = React.useState<number[]>([]);
    const [filter, setFilter] = React.useState<string>("");
    const { data: sessionDetails } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const { logEvent } = useLogAccessEvent();

    const enableUpload =
        imkeys.length <= 0
            ? false
            : fileUploadItemsList.filter((f) => !f.uploadAttempted).length <= 0;

    React.useEffect(() => {
        if (!props.open) setFileUploadItemsList([]);
    }, [props.open]);

    const assetProperties: { [key: string]: string } = {
        ProgramId: `${props.sessionId}`,
        ProgramCode: `${sessionDetails?.sessionCode ?? "Unknown Session Code"}`,
    };

    const getFilteredPeople = (): PersonInfo[] => {
        const newFilteredPeople =
            filter.length === 0
                ? [...props.people]
                : props.people.filter(
                      (p) =>
                          p.firstName.toLowerCase().includes(filter) ||
                          p.lastName.toLowerCase().includes(filter) ||
                          imkeys.find((i) => i === p.imKey) != null
                  );

        return newFilteredPeople.sort((a, b) => {
            return a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName);
        });
    };

    const filteredPeople = getFilteredPeople();
    const selectCardTitle = `Select ${
        props.personType.charAt(0).toUpperCase() + props.personType.slice(1).toLowerCase() + "s"
    }`;

    const filterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newfilter = evt.target.value.toLowerCase();
        setFilter(newfilter);
    };

    const addFile = (acceptedFiles: File[]) => {
        const newuploaditems = acceptedFiles.map((f) => {
            return {
                file: f,
                id: "none", // will be a guid after first chunk uploaded
                uploadAttempted: false,
                uploadSuccess: false,
            };
        });
        setFileUploadItemsList((prev) => [...prev, ...newuploaditems]);
    };

    const removeFile = (file: File) => {
        const newFileItemsList = fileUploadItemsList.filter((item) => item.file.name !== file.name);
        setFileUploadItemsList(newFileItemsList);
    };

    const fileUploadResult = (file: File, status: boolean) => {
        const updatedUploadItems = fileUploadItemsList.map((ui) => {
            if (ui.file.name === file.name) {
                return { ...ui, uploadSuccess: status };
            }
            return ui;
        });
        setFileUploadItemsList(updatedUploadItems);
        startNextUpload(false);
    };

    const startNextUpload = (logUploadEvent: boolean) => {
        // get the next file to upload (alpabetical by those with attempted == false)
        const itemsToUpload = fileUploadItemsList
            .filter((ui) => !ui.uploadAttempted)
            .sort((a, b) => {
                return a.file.name.localeCompare(b.file.name);
            });

        if (itemsToUpload == null || itemsToUpload.length <= 0) return;

        const updatedUploadItems = fileUploadItemsList.map((ui) => {
            if (ui.file.name === itemsToUpload[0].file.name) {
                return { ...ui, uploadAttempted: true };
            }
            return ui;
        });

        setFileUploadItemsList(updatedUploadItems);
        // only log the button press, not individual uploads
        if (logUploadEvent) {
            const evtData: any = {
                projectCode: props.projectCode ?? "",
            };
            const tag = props.isSessionFile ? "IndividualFilesUploaded" : "ParticipantFilesUploaded";

            logEvent(tag, evtData);
        }
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xl"
            PaperProps={{ sx: { width: "100%", height: "85%" } }}
        >
            <DialogTitle>
                {`Upload ${
                    props.personType === "participant" ? "Participant" : "Session"
                } Files for ${sessionDetails?.sessionCode}`}
                <IconButton
                    onClick={props.onClose}
                    aria-label="close"
                    sx={{ position: "absolute", right: 8, top: 8, color: "disabled" }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box height={1} width={1} sx={{ p: 5 }}>
                <Stack direction="column" spacing={5} width={1} height={1}>
                    <Stack direction="row" spacing={5} width={1} height={1}>
                        <Card sx={{ width: 0.5, height: 1 }}>
                            <CardHeader title={selectCardTitle} />
                            <Stack
                                display="flex"
                                width={1}
                                flexGrow={1}
                                sx={{ height: "calc(100% - 60px)", p: 4 }}
                                spacing={4}
                            >
                                <TextField
                                    value={filter}
                                    label={`Find ${props.personType}`}
                                    onChange={filterChange}
                                    sx={{ width: 1, maxHeight: "57px" }}
                                    id="find-person-search"
                                    variant="outlined"
                                />
                                <PersonUploadDataGrid
                                    people={filteredPeople ?? []}
                                    onSelectChange={(imkeys: number[]) => setImkeys(imkeys)}
                                />
                            </Stack>
                        </Card>
                        <Card sx={{ width: 0.5, height: 1 }}>
                            <CardHeader title={"Select Files For Upload"} />
                            <Stack
                                spacing={3}
                                sx={{ pl: 3, pr: 3, pt: 1, maxHeight: "calc(100%-10px)" }}
                            >
                                <StyledDropzone
                                    disabled={fileUploadItemsList.length >= maxFilesToUpload}
                                    onAddFile={addFile}
                                    maxFilesToUpload={maxFilesToUpload}
                                />
                                <Stack
                                    width={1}
                                    direction="row"
                                    display="flex"
                                    justifyContent="space-between"
                                    sx={{ pl: 2, pr: 2 }}
                                >
                                    <CclRestrictedOutlinedButton
                                        disabled={fileUploadItemsList.length === 0}
                                        size="small"
                                        onClick={() => setFileUploadItemsList([])}
                                    >
                                        Clear Files
                                    </CclRestrictedOutlinedButton>
                                    <CclRestrictedButton
                                        size="small"
                                        onClick={() => startNextUpload(true)}
                                        disabled={enableUpload}
                                    >
                                        Upload Files
                                    </CclRestrictedButton>
                                </Stack>
                                <Typography variant="subtitle1" sx={{ pl: 2, pr: 2 }}>
                                    Files To Upload
                                </Typography>
                                <List sx={{ mt: 0, pb: 0, borderBottom: "1px solid lightgray" }}>
                                    {fileUploadItemsList &&
                                        fileUploadItemsList.length > 0 &&
                                        fileUploadItemsList.map((item) => (
                                            <UploadFileListItem
                                                eskey={props.sessionId}
                                                key={item.file.name}
                                                file={item.file}
                                                imkeys={imkeys}
                                                properties={assetProperties}
                                                makeAvailable={props.personType !== "participant"}
                                                startUpload={item.uploadAttempted}
                                                deleteFile={removeFile}
                                                onUploadResult={fileUploadResult}
                                                projectCode={props.projectCode}
                                            />
                                        ))}
                                </List>
                            </Stack>
                        </Card>
                    </Stack>
                    <Box>
                        <CclRestrictedOutlinedButton
                            onClick={props.onClose}
                            sx={{ float: "right" }}
                        >
                            Close
                        </CclRestrictedOutlinedButton>
                    </Box>
                </Stack>
            </Box>
        </Dialog>
    );
};

export default FileUploadDialog;
