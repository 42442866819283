import * as React from "react";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { Box, Stack, Typography } from "@mui/material";
import StyledDropzone from "./fileUpload/styledDropzone";
import { RecipientInfo } from "../../../../services/types/rtkQueryTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export interface SelectRecipientsStepPanelProps {
    onNextPanel: (recipients: RecipientInfo[]) => void;
    onPreviousPanel: () => void;
}

interface csvLine {
    FirstName: string;
    LastName: string;
    Email: string;
}

export const SelectRecipientsStepPanel: React.FC<SelectRecipientsStepPanelProps> = (props) => {
    const [recipientList, setRecipientList] = React.useState<RecipientInfo[]>([]);
    const [csvFilename, setCsvFilename] = React.useState<string>("");
    const [parseErrors, setParseErrors] = React.useState<Papa.ParseError[]>([]);

    const addFile = (files: File[]) => {
        Papa.parse(files[0], {
            header: true,
            skipEmptyLines: "greedy",

            complete: (results: any) => {
                setCsvFilename(files[0].name);
                let newRecipients: RecipientInfo[] = [];
                if (results.errors?.length > 0) {
                    setRecipientList([]);
                    setParseErrors(results.errors);
                } else {
                    let errors: Papa.ParseError[] = [];
                    let rowNum: number = 0;
                    for (var line of results.data) {
                        rowNum++;
                        const obj = line as csvLine;
                        const fieldCount = Object.keys(obj).length;

                        if (fieldCount !== 3) {
                            errors.push({
                                type: "FieldMismatch",
                                code: fieldCount < 3 ? "TooFewFields" : "TooManyFields",
                                message:
                                    "Invalid record format. Fix the record in the existing file, or redownload the template and try again.",
                                row: rowNum,
                            });
                            continue;
                        }

                        if (obj.Email == null || obj.FirstName == null || obj.LastName == null) {
                            errors.push({
                                type: "FieldMismatch",
                                code: "TooFewFields",
                                message:
                                    "Invalid record format. One or more fields is empty or missing.",
                                row: rowNum,
                            });
                            continue;
                        }

                        if (
                            obj.Email.trim() === "" ||
                            obj.FirstName.trim() === "" ||
                            obj.LastName.trim() === ""
                        ) {
                            errors.push({
                                type: "FieldMismatch",
                                code: "TooFewFields",
                                message:
                                    "Invalid record format. One or more fields is blank or missing (invalid).",
                                row: rowNum,
                            });
                            continue;
                        }

                        const recipient = {
                            email: obj.Email.trim(),
                            firstName: obj.FirstName.trim(),
                            lastName: obj.LastName.trim(),
                        };
                        newRecipients.push(recipient);
                    }

                    setRecipientList(newRecipients);
                    setParseErrors(errors);
                }
            },
        });
    };

    return (
        <Stack direction={"column"}>
            <Box
                sx={{
                    width: 1,
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                <Stack direction={"column"} width={1} spacing={2}>
                    <Stack direction={"row"} width={1} spacing={2}>
                        <StyledDropzone onAddFile={addFile} multiple={false} />
                        <Box
                            sx={{
                                width: "100%",
                                borderStyle: "solid",
                                borderWidth: 2,
                                borderRadius: 3,
                                borderColor: "lightgray",
                                m: "7px",
                                p: 2,
                            }}
                        >
                            {(recipientList.length === 0 && parseErrors.length === 0) ||
                            recipientList.length > 0 ? (
                                <>
                                    <Typography
                                        sx={{
                                            m: 2,
                                            fontWeight: "bold",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Loaded Recipient Summary:
                                    </Typography>
                                    {recipientList.length === 0 ? (
                                        <Typography sx={{ m: 2 }}>
                                            No recipients uploaded yet.{" "}
                                        </Typography>
                                    ) : (
                                        <Typography sx={{ m: 2 }}>
                                            {recipientList.length} recipients loaded (file:{" "}
                                            {csvFilename})
                                        </Typography>
                                    )}
                                </>
                            ) : null}
                            {parseErrors.length > 0 ? (
                                <>
                                    <Typography
                                        sx={{
                                            m: 2,
                                            fontWeight: "bold",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        CSV Loading Errors:
                                    </Typography>
                                    <ul>
                                        {parseErrors.map((e) => {
                                            return e.row ? (
                                                <li>
                                                    Row {e.row}: {e.message}{" "}
                                                </li>
                                            ) : (
                                                <li>
                                                    <p>
                                                        There was an issue with the file you tried
                                                        to upload. Ensure all lines have a first
                                                        name, last name, and email address, each
                                                        field separated by a comma.{" "}
                                                    </p>
                                                    <Typography sx={{ color: "red" }}>
                                                        Error: {e.message}
                                                    </Typography>
                                                </li>
                                            );
                                        })}
                                    </ul>{" "}
                                </>
                            ) : recipientList.length > 0 ? (
                                <Typography sx={{ m: 2 }}>No errors</Typography>
                            ) : null}
                        </Box>
                    </Stack>
                    <Box sx={{ pl: 2 }}>
                        <CSVLink
                            className={"button"}
                            data={[]}
                            headers={[
                                { label: "FirstName", key: "firstname" },
                                { label: "LastName", key: "lastname" },
                                { label: "Email", key: "email" },
                            ]}
                            filename={"invitation-recipients.csv"}
                            target="_blank"
                        >
                            Download Template
                        </CSVLink>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclButton
                    disabled={false}
                    onClick={props.onPreviousPanel}
                    sx={{ mr: 1 }} 
                    restricted={false} 
                    mode={"secondary"}                
                    >
                    Back
                </CclButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclButton
                    disabled={recipientList.length === 0}
                    onClick={() => props.onNextPanel(recipientList)} 
                    restricted={false} 
                    mode={"primary"}                
                    >
                    Next
                </CclButton>
            </Box>
        </Stack>
    );
};

export default SelectRecipientsStepPanel;
