import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import AddEmailTemplateTabOne from "./createTemplateDialogTabOne";
import AddEmailTemplateTabTwo from "./createNewTemplateTabTwo";
import { useCreateEmailTemplateMutation } from "../../../../services/cclTokenedEmailTemplateApi";
import { EmailMessageTemplate } from "../../../../services/types/emailTemplateTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { AccessEventEmailTemplate } from "../../../../services/types/accessEventTypes";


export interface addEmailtemplate {
    open: boolean;
    onClose: () => void;
}

export const AddEmailtemplate: React.FC<addEmailtemplate> = (props) => {

    let claimservice = new UserClaimsService();

    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);

    const [trackingId, setTrackingId] = React.useState<string>("");
    const [templateName, setTemplateName] = React.useState<string>("");
    const [subject, setSubject] = React.useState<string>("");
    const [content, setContent] = React.useState<string>("");
    const [ccRecipients, setCcRecipients] = React.useState<string>("");
    const [bccRecipients, setBccRecipients] = React.useState<string>("");
    const [notificationType, setNotificationType] = React.useState<number>(1);

    const [createTemplate] = useCreateEmailTemplateMutation();

    const [tab, setTab] = React.useState<number>(0);

    const { logEvent } = useLogAccessEvent();

    const addEmailtemplate = () => {
        let newTemplate: EmailMessageTemplate = {
            id: "",
            templateId: "",
            isParent: false,
            parentId: "",
            trackingId: trackingId,
            templateName: templateName,
            languageKey: 0,
            subject: subject,
            ccRecipients: ccRecipients,
            bccRecipients: bccRecipients,
            content: content,
            createdUtc: new Date().toISOString(),
            createdBy: claimservice.GetCurrentUserEmail(),
            modifiedUtc: new Date().toISOString(),
            modifiedBy: claimservice.GetCurrentUserEmail(),
            notificationType: notificationType,
        };

        createTemplate(newTemplate)
            .unwrap()
            .then(() => {
                props.onClose();
                const evtData: AccessEventEmailTemplate = {
                    templateName: templateName,
                };
                logEvent("EmailTemplateCreated", evtData);
            });
    };

    const onCancel = () => {
        setOpenConfirmation(false);
    };

    const onOk = () => {
        setOpenConfirmation(false);
        addEmailtemplate();
    };

    const checkRequirments = () => {
        if (trackingId === "" || templateName === "" || subject === "") {
            return false;
        }
        return true;
    };

    const handleValueChange = (key: string, value: string) => { 
        switch(key) {
            case "trackingId":
                setTrackingId(value);
                break;
            case "templateName":
                setTemplateName(value);
                break;
            case "subject":
                setSubject(value);
                break;
            case "ccRecipients":
                setCcRecipients(value);
                break;
            case "bccRecipients":
                setBccRecipients(value);
                break
            case "content":
                setContent(value);
                break;
            case "notificationType":
                setNotificationType(parseInt(value));
                break;
            default:
                break;
        }
    }

    const tabChange = (tab: number) => { 
        setTab(tab);
    }

    return (
        <>
            <CclGenericConfirmationDialog open={openConfirmation} onCancel={onCancel} onOk={onOk} />

            <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
                <DialogTitle>Create New Email Template</DialogTitle>
                <DialogContent>
                {tab === 0 && 
                    <AddEmailTemplateTabOne 
                        setValue={handleValueChange}
                        trackingId={trackingId} 
                        templateName={templateName} 
                        subject={subject} 
                        ccRecipients={ccRecipients} 
                        bccRecipients={bccRecipients}
                        notificationType={notificationType} 
                    />
                }
                {tab === 1 &&
                      <AddEmailTemplateTabTwo 
                            setValue={handleValueChange}
                            content={content}             
                      />
                }
                </DialogContent>
                <DialogActions>
                    <CclUnrestrictedOutlinedButton
                        onClick={props.onClose}
                        sx={{mr: "auto"}}
                    >
                        Cancel
                    </CclUnrestrictedOutlinedButton>
                    {tab === 1 &&
                        <CclUnrestrictedOutlinedButton
                            onClick={() => tabChange(0)}
                        >
                            Back
                        </CclUnrestrictedOutlinedButton>
                    }
                    <CclUnrestrictedButton 
                        disabled={!checkRequirments()}
                        onClick={() => {
                            if (tab === 0) {
                                tabChange(1);
                            } else {
                                setOpenConfirmation(true);
                            }
                        }}
                    >
                        {tab === 0 ? "Next" : "Create"}
                    </CclUnrestrictedButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddEmailtemplate;
