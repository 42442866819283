import * as React from "react";
import { Alert, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useSearchResourcesMutation } from "../../../../../services/cclTokenedResourceApi";
import { useAddCadreMemberMutation } from "../../../../../services/cclTokenedEnterpirseCoachingCadreApi";
import {
    UpdateWorkPoolMember,
    WorkPool,
} from "../../../../../services/types/resources/workPoolTypes";
import { AccessEventWorkPoolDetails } from "../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclUnrestrictedLoadingButton from "../../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";

export interface AddUserDialogProps {
    data: WorkPool | undefined;
    cKey: number;
    open: boolean;
    onClose: () => void;
}

export const AddUserDialog: React.FC<AddUserDialogProps> = (props) => {
    const [searchEmail, setSearchEmail] = React.useState<string>("");
    const [emailSearchError, setEmailSearchError] = React.useState<boolean>(false);
    const [searchError, setSearchError] = React.useState<boolean>(false);

    const [runSearch, { data, isLoading, isError }] = useSearchResourcesMutation();
    const [runAddMember, { isLoading: loading, isError: error }] = useAddCadreMemberMutation();

    const { logEvent } = useLogAccessEvent();

    const handleEmailSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            if (emailSearchError || searchEmail === "") {
                setSearchEmail("");
                runSearch({ email: "" });
            } else {
                runSearch({ email: searchEmail });
            }
        }
    };

    const handleClose = () => {
        setSearchEmail("");
        setEmailSearchError(false);
        setSearchError(false);
        props.onClose();
    };

    React.useEffect(() => {
        const handleClose = () => {
            setSearchEmail("");
            setEmailSearchError(false);
            setSearchError(false);
            props.onClose();
        };

        const addMember = (member: UpdateWorkPoolMember) => {
            runAddMember(member)
                .unwrap()
                .then(() => {
                    const evtData: AccessEventWorkPoolDetails = {
                        workPoolId: `${props.data?.cKey}`,
                        workPoolName: props.data?.name ?? "",
                    };
                    logEvent("WorkPoolMemberAdded", evtData);
                    handleClose();
                });
        };

        if (data && data.length > 0) {
            const member: UpdateWorkPoolMember = {
                cKey: props.cKey,
                imKey: data[0].imkey,
            };
            addMember(member);
        } else if (data && data.length === 0 && !searchError) {
            setSearchError(true);
        }
        //eslint-disable-next-line
    }, [data, runAddMember, logEvent]);

    console.log(searchError)

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>
                Add work pool member
            </DialogTitle>
            <DialogContent sx={{overflow: "hidden"}}>
                <Grid container component="form" sx={{m: 5}}>
                    <Grid item xs={12} sm={6} md={8} sx={{ p: 1 }}>
                        <TextField
                            value={searchEmail}
                            sx={{ width: 1, maxHeight: "57px" }}
                            id="outlined-adornment-email-search"
                            label="Enter Email"
                            variant="outlined"
                            error={emailSearchError}
                            helperText={emailSearchError ? "Email can't be blank" : ""}
                            onChange={(e) => {
                                e.preventDefault();
                                setSearchEmail(e.target.value);
                                setEmailSearchError(e.target.value === "");
                            }}
                            onKeyDown={(e) => handleEmailSearchKeyPress(e)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} sx={{ p: 1 }}>
                        <CclUnrestrictedLoadingButton
                            onClick={() => runSearch({ email: searchEmail })}
                            aria-label="Search by Email"
                            sx={{ width: 1, height: 1, maxHeight: "57px" }}
                            disabled={
                                emailSearchError || searchEmail === "" || isLoading || loading
                            }
                            loading={isLoading || loading}
                        >
                            Search
                        </CclUnrestrictedLoadingButton>
                    </Grid>
                </Grid>
                {searchError && <Alert severity="info">No user found with that email</Alert>}
                {isError && <Alert severity="error">Error searching for user</Alert>}
                {error && <Alert severity="error">Error adding user to workpool</Alert>}
            </DialogContent>
        </Dialog>
    );
};

export default AddUserDialog;
