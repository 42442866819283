import * as React from "react";
import { Link, Typography } from "@mui/material";
import CclInfoTableEditDatePicker from "./cclInfoTableEditComponents/cclInfoTableEditDatePicker";
import CclInfoTableEditSelect from "./cclInfoTableEditComponents/cclInfoTableEditSelect";
import CclInfoTableEditText from "./cclInfoTableEditComponents/cclInfoTableEditText";
import CclInfoTableEditCheckbox from "./cclInfoTableEditComponents/cclInfoTableEditCheckbox";
import CclInfoTableEditNotes from "./cclInfoTableEditComponents/cclInfoTableNotes";

export enum eInfoTableValueFieldEditType {
    Text = 0,
    DatePicker = 1,
    DateTimePicker = 2,
    Select = 3,
    Checkbox = 4,
    DateTimeText = 5,
    Link = 6,
    TextInput = 7,
    YesNoLabel = 8,
    NotesInput = 9,
}

export type CclInfoTableValueFieldProps = {
    value: string;
    editFieldType: eInfoTableValueFieldEditType;
    url: string | null;
    hoverText: string | null;
    checked: boolean | null;
    id: string;
    selectOptions: { val: string; option: string }[] | null;
    label?: string;
    required?: boolean;
    onChange?: (name: string, value: any, required: boolean) => void;
    editable?: boolean;
    readonly?: boolean;
};

const CclInfoTableValueField: React.FC<CclInfoTableValueFieldProps> = (props) => {
    const [required, setRequired] = React.useState<boolean>(false);
    const readOnly = (!props?.editable ?? false) || (props?.readonly ?? false);

    const handleChange = (value: any, required: boolean) => {
        if (props.onChange) props.onChange(props.id, value, required);
    };

    React.useEffect(() => {
        if (props.required) {
            setRequired(true);
        } else {
            setRequired(false);
        }
    }, [props.required]);

    return (() => {
        switch (props.editFieldType) {
            case eInfoTableValueFieldEditType.Text:
                return <Typography sx={{ fontSize: 14, width: 1 }}>{props.value}</Typography>;
            case eInfoTableValueFieldEditType.DatePicker:
                return (
                    <CclInfoTableEditDatePicker
                        required={required}
                        value={props.value}
                        onChange={handleChange}
                        readonly={readOnly}
                    />
                );
            case eInfoTableValueFieldEditType.Select:
                return (
                    <CclInfoTableEditSelect
                        required={required}
                        value={props.value}
                        onChange={handleChange}
                        options={props.selectOptions}
                        readonly={readOnly}
                    />
                );
            case eInfoTableValueFieldEditType.Checkbox:
                return (
                    <CclInfoTableEditCheckbox
                        required={required}
                        value={props.checked}
                        onChange={handleChange}
                        readonly={readOnly}
                    />
                );
            case eInfoTableValueFieldEditType.Link:
                return props.value == null || props.value === "" ? (
                    <Typography>{""}</Typography>
                ) : (
                    <Link href={props.url as string}>{props.value}</Link>
                );
            case eInfoTableValueFieldEditType.TextInput:
                return (
                    <CclInfoTableEditText
                        required={required}
                        value={props.value}
                        onChange={handleChange}
                        readonly={readOnly}
                    />
                );
            case eInfoTableValueFieldEditType.YesNoLabel:
                return (
                    <Typography sx={{ fontSize: 14, fontWeight: "fontWeightBold", width: 1 }}>
                        {props.value ? "Yes" : "No"}
                    </Typography>
                );
            case eInfoTableValueFieldEditType.NotesInput:
                return (
                    <CclInfoTableEditNotes
                        label={props.label ?? ""}
                        value={props.value}
                        onChange={handleChange}
                        required={required}
                        readonly={readOnly}
                    />
                );
            case eInfoTableValueFieldEditType.DateTimePicker:
            default:
                let value = new Date(props.value).toLocaleDateString();
                return <Typography>{value ? value : ""}</Typography>;
        }
    })();
};

export default CclInfoTableValueField;
