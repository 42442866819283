import * as React from "react";
import ComponentLoader from "../../../../components/common/componentLoader";
import FlexGrid from "../../../../layouts/flexGrid";
import { useGetResourceAssignmentsQuery } from "../../../../services/cclTokenedResourceApi";
import { Profile } from "../../../../services/types/enterpriseCoachingApiTypes";
import AssignmentsDataGrid from "./assignmentsDataGrid";
import { Grid, Stack } from "@mui/material";
import Contracts from "../contracts/contracts";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

export type AssignmentsProps = {
    profile: Profile;
    pkey: string;
};

export const Assignments: React.FC<AssignmentsProps> = (props) => {
    const imKey = props.profile.imKey;
    const email = props.profile.email;
    const firstName = props.profile.firstName;
    const lastName = props.profile.lastName;

    const { data, isLoading, isError } = useGetResourceAssignmentsQuery(imKey, {
        skip: !imKey,
    });

    if (isLoading) {
        return <ComponentLoader msg="Loading Assignments" />;
    }

    if (isError || (!isLoading && !data)) {
        return <span>Failed to load assignments</span>;
    }

    return (
        <FlexGrid>
            <Stack height={1} width={1}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <CclGenericAccordian headingText={"Assignments"} defaultExpanded={true}>
                            <AssignmentsDataGrid assignments={data} />
                        </CclGenericAccordian>
                    </Grid>
                    <Grid item xs={12}>
                        <CclGenericAccordian headingText="Contracts" defaultExpanded={true}>
                            <Contracts
                                pKey={props.pkey}
                                imKey={`${imKey}`}
                                email={email}
                                name={firstName + " " + lastName}
                            />
                        </CclGenericAccordian>
                    </Grid>
                </Grid>
            </Stack>
        </FlexGrid>
    );
};
export default Assignments;
