import * as React from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    UpdateWorkPool,
    WorkPool,
    WorkPoolType,
} from "../../../../../services/types/resources/workPoolTypes";
import {
    useGetAllCadreTypesQuery,
    useUpdateCadreDetailsMutation,
} from "../../../../../services/cclTokenedEnterpirseCoachingCadreApi";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import { AccessEventWorkPoolDetails } from "../../../../../services/types/accessEventTypes";
import CclRestrictedLoadingButton from "../../../../../components/common/cclButtons/cclRestrictedLoadingButton";
import CclRestrictedOutlinedButton from "../../../../../components/common/cclButtons/cclRestrictedOutlinedButton";

interface EditWorkPoolProps {
    workPool: WorkPool | undefined;
    open: boolean;
    onClose: () => void;
}

const EditWorkPoolDialog: React.FC<EditWorkPoolProps> = (props) => {
    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [ctKey, setCtKey] = React.useState<number>(props.workPool ? props.workPool.ctKey : 1);

    React.useEffect(() => {
        if (props.workPool) {
            setName(props.workPool.name);
            setDescription(props.workPool.description);
            setCtKey(props.workPool.ctKey);
        }
    }, [props.workPool]);

    const { data: allCadreTypes } = useGetAllCadreTypesQuery();

    const { logEvent } = useLogAccessEvent();

    const [updateWorkPool, { isLoading }] = useUpdateCadreDetailsMutation();

    const handleUpdateWorkPool = () => {
        if (!props.workPool) {
            return;
        }
        const payload: UpdateWorkPool = {
            cKey: props.workPool.cKey,
            name: name.length > 0 ? name : props.workPool.name,
            description: description.length > 0 ? description : props.workPool.description,
            ctKey: ctKey,
        };

        updateWorkPool(payload)
            .unwrap()
            .then(() => {
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${props.workPool?.cKey}`,
                    workPoolName: props.workPool?.name ?? "",
                };

                logEvent("WorkPoolEdited", evtData);
                setName("");
                setDescription("");
                props.onClose();
            });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <DialogTitle>
                <Typography variant="h5">Edit Work Pool</Typography>
            </DialogTitle>
            <DialogContent sx={{ overflow: "hidden" }}>
                {props.workPool && (
                    <Stack>
                        <Box margin={"15px"}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                sx={{ width: "100%" }}
                                defaultValue={props.workPool.name}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setName(e.target.value);
                                }}
                            ></TextField>
                        </Box>
                        <Box margin={"15px"}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                sx={{ width: "100%" }}
                                defaultValue={props.workPool.description}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setDescription(e.target.value);
                                }}
                            ></TextField>
                        </Box>

                        <Box margin={"15px"}>
                            {allCadreTypes && (
                                <>
                                    <Typography>Select Work Pool Type</Typography>
                                    <Select
                                        sx={{
                                            width: "100%",
                                        }}
                                        defaultValue={ctKey}
                                        label={"Select Work Pool Type"}
                                        onChange={(e) => {
                                            setCtKey(
                                                typeof e.target.value === "number"
                                                    ? e.target.value
                                                    : 1
                                            );
                                        }}
                                    >
                                        {allCadreTypes.map((type: WorkPoolType) => (
                                            <MenuItem value={type.ctKey}>{type.name}</MenuItem>
                                        ))}
                                    </Select>
                                </>
                            )}
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <CclRestrictedOutlinedButton
                                sx={{ width: "20%", marginLeft: "auto", marginRight: "10px" }}
                                onClick={props.onClose}
                            >
                                Cancel
                            </CclRestrictedOutlinedButton>

                            <CclRestrictedLoadingButton
                                sx={{ width: "20%", marginRight: "15px" }}
                                onClick={handleUpdateWorkPool}
                                loading={isLoading}
                                disabled={
                                    name.length > 0 ||
                                    description.length > 0 ||
                                    ctKey !== props.workPool.ctKey
                                        ? false
                                        : true
                                }
                            >
                                Save
                            </CclRestrictedLoadingButton>
                        </Box>
                    </Stack>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditWorkPoolDialog;
