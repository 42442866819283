import * as React from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EmailIcon from "@mui/icons-material/Email";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
} from "@mui/material";
import CclButton from "../../components/common/cclButtons/cclButton";

export type CreateNewUserDialogProps = {
    open: boolean;
    onOk: (email: string, first: string, last: string) => void;
    onCancel: () => any;
};

const CreateNewUserDialog: React.FC<CreateNewUserDialogProps> = (props) => {
    const title = "Create User";
    const [email, setEmail] = React.useState<string>("");
    const [emailInputError, setEmailInputError] = React.useState<string>("");
    const [first, setFirst] = React.useState<string>("");
    const [firstInputError, setFirstInputError] = React.useState<string>("");
    const [last, setLast] = React.useState<string>("");
    const [lastInputError, setLastInputError] = React.useState<string>("");

    React.useEffect(() => {
        if (props.open) return;
        setFirst("");
        setLast("");
        setEmail("");
    }, [props.open]);

    const validateEmail = (): boolean => {
        let e: string = email.trim();
        let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
        return regex.test(e.replace(/\s/g, ""));
    };

    const handleOk = () => {
        let eie: string = "";
        let fie: string = "";
        let lie: string = "";

        if (!validateEmail()) eie = "Not a valid email address";
        if (first === "") fie = "First name can not be empty";
        if (last === "") lie = "Last name can not be empty";

        setEmailInputError(eie);
        setFirstInputError(fie);
        setLastInputError(lie);

        if (eie !== "" || fie !== "" || lie !== "") return;

        props.onOk(email, first, last);
    };

    const handleKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            handleOk();
        }
    };

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    name="newUserFirstName"
                    placeholder="New User's First Name"
                    value={first}
                    id="outlined-adornment-new-user-first-name"
                    label="New User's First Name"
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <AccountBoxIcon />
                            </InputAdornment>
                        ),
                    }}
                    error={firstInputError !== ""}
                    helperText={firstInputError}
                    onChange={(e) => {
                        e.preventDefault();
                        setFirst(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    name="newUserLastName"
                    placeholder="New User's Last Name"
                    value={last}
                    id="outlined-adornment-new-user-last-name"
                    label="New User's Last Name"
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <AccountBoxIcon />
                            </InputAdornment>
                        ),
                    }}
                    error={lastInputError !== ""}
                    helperText={lastInputError}
                    onChange={(e) => {
                        e.preventDefault();
                        setLast(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    name="newUserEmail"
                    placeholder="New User's Email"
                    value={email}
                    id="outlined-adornment-new-user-email"
                    label="New User's Email"
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    error={emailInputError !== ""}
                    helperText={emailInputError}
                    onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 2 }}
                />
            </DialogContent>

            <DialogActions>
                <CclButton id="cancel-btn" onClick={() => props.onCancel()} restricted={false} mode={"secondary"}>
                    Cancel
                </CclButton>
                <CclButton id="ok-btn" onClick={handleOk} restricted={false} mode={"primary"}>
                    Create
                </CclButton>
            </DialogActions>
        </Dialog>
    );
};

export default CreateNewUserDialog;
