import * as React from "react";
import { LoadingButtonProps, LoadingButton } from "@mui/lab";

type CclLoadingButtonProps = LoadingButtonProps & {
    restricted: boolean;
    mode: "primary" | "secondary" | "tertiary";
};

export const CclLoadingButton: React.FC<CclLoadingButtonProps> = (props) => {
    const { restricted, mode, sx, children, ...passthroughprops } = props;
    
    const v = props.mode === "primary" ? "contained" : props.mode === "secondary" ? "outlined" : "text";    
    const brdr = mode === "tertiary" ? "0px" : "1px solid";
    const disabledStyle = {
        backgroundColor: "white",
        color: "#A6A6A6",
        borderColor: "#A6A6A6",
        border: {brdr}
    };

    return (
        <LoadingButton
            color={restricted ? "secondary" : "primary"}
            variant={v}
            sx={{
                "&.Mui-disabled": disabledStyle,
                ...sx,
            }}
            {...passthroughprops}
        >
            {children}
        </LoadingButton>
    );
};

export default CclLoadingButton;
