import React from "react";
import { Grid, TextField } from "@mui/material";
import CclUnrestrictedButton from "../cclButtons/cclUnrestrictedButton";

interface CclTextSearchBarProps {
    initialSearchTerm: string;
    searchLabel: string;
    executeSearch: (searchTerm: string) => void;
    errorStateChange?: (error: boolean) => void;
}

const CclTextSearchBar: React.FC<CclTextSearchBarProps> = (props) => {
    const [searchString, setSearchString] = React.useState<string>(props.initialSearchTerm);
    const [keywordSearchError, setKeywordSearchError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setSearchString(props.initialSearchTerm);
    }, [props.initialSearchTerm]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    const setSearchError = (error: boolean) => {
        if (error !== keywordSearchError) {
            setKeywordSearchError(error);
            if (props.errorStateChange) props.errorStateChange(error);
        }
    };

    const handleKeywordSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            if (!keywordSearchError && searchString !== "") {
                evt.preventDefault();
                props.executeSearch(searchString);
            }
        }
    };

    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={12} sm={8} md={10}>
                <TextField
                    value={searchString === "*" ? "" : searchString}
                    sx={searchInputSx}
                    id="outlined-adornment-keyword-search"
                    label={props.searchLabel ?? "Search Term"}
                    variant="outlined"
                    error={keywordSearchError}
                    fullWidth={true}
                    helperText={keywordSearchError ? "Field cannot be empty" : ""}
                    onChange={(e) => {
                        e.preventDefault();
                        setSearchString(e.target.value);
                        setSearchError(e.target.value === "");
                    }}
                    onKeyDown={(e) => handleKeywordSearchKeyPress(e)}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <CclUnrestrictedButton
                    onClick={() => props.executeSearch(searchString.trim())}
                    aria-label="Search By ID or Keyword"
                    sx={{ width: 1 }}
                    disabled={keywordSearchError || searchString === ""}
                >
                    Search
                </CclUnrestrictedButton>
            </Grid>
        </Grid>
    );
};

export default CclTextSearchBar;
