import * as React from "react";
import {
    DataGridPro,
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { IconButton, Tooltip } from "@mui/material";
import { Participant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import CclDownloadWarningDialog from "../../../../../components/common/cclDownloadWarningDialog";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ResourceParticipantAssetListDataGrid from "./resourceParticipantAssetList";
import AssetDetailToggle from "./assetDetailToggle";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclAlertDialog from "../../../../../components/common/cclAlertDialog";
import CclDataGridToolbar from "../../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclCommonLink from "../../../../../components/common/cclCommonLink";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

export interface ResourceRegistrationsListProps {
    participants: Participant[] | undefined;
    isLoading: boolean;
    handleEvent: (buttonName: string, selectedIds: number[]) => void;
    viewCompass: (imkey: number) => void;
    allowOperations: boolean;
    alternativeEventOnClickHandler?: any;
}

export type ParticipantAiKeys = {
    [key: string]: number[];
};

export const ResourceRegistrationsListDataGridPro: React.FC<ResourceRegistrationsListProps> = (
    props
) => {
    const [selectedEsiKeys, setSelectedEsiKeys] = React.useState<(number | string)[]>([]);
    const [selectedAiKeys, setSelectedAiKeys] = React.useState<ParticipantAiKeys>({});
    const [aiKeysSelected, setAiKeysSelected] = React.useState<boolean>(false);
    const [showExpirationWarning, setShowExpirationWarning] = React.useState<boolean>(false);
    const [showLargeUploadWarning, setShowLargeUploadWarning] = React.useState<boolean>(false);
    const [selectedCount, setSelectedCount] = React.useState<number>(0);
    const claimsService = new UserClaimsService();
    const showLinks = claimsService.ShouldSeeAccessInternalLinks();

    const getDetailPanelContent: DataGridProProps["getDetailPanelContent"] = React.useCallback(
        ({ row }: any) => {
            const handleAssetSelectionChange = (esiKey: number, aikeys: number[]) => {
                let pAiKeys = JSON.parse(JSON.stringify(selectedAiKeys));
                const esikey = esiKey.toString();
                delete pAiKeys[esikey];
                pAiKeys[esiKey] = [...aikeys];
                setAiKeysSelected(anyAssetsSelected(pAiKeys));
                setSelectedAiKeys(pAiKeys);
            };

            return row.assets != null && row.assets.length > 0 ? (
                <ResourceParticipantAssetListDataGrid
                    esiKey={row.esiKey}
                    assets={row.assets}
                    selectedAssets={selectedAiKeys[row.esiKey]}
                    assetsSelectionChange={handleAssetSelectionChange}
                />
            ) : null;
        },
        [selectedAiKeys]
    );

    React.useEffect(() => {
        let pAiKeys: ParticipantAiKeys = {};
        if (props.participants === undefined || props.participants.length === 0) {
            setSelectedAiKeys(pAiKeys);
        } else {
            props.participants.forEach((p) => {
                const esikey: string = p.esiKey.toString();
                pAiKeys[esikey] = [];
            });
            setSelectedAiKeys(pAiKeys);
        }
    }, [props.participants]);

    const handleAiKeyEvent = (evt: string) => {
        let aikeys: number[] = [];

        for (let esi in selectedAiKeys) {
            if (selectedAiKeys[esi].length > 0) {
                const keys = [...selectedAiKeys[esi]];
                aikeys = aikeys.concat(keys);
            }
        }
        props.handleEvent(evt, aikeys);
    };

    const handleEvent = (evt: string) => {
        switch (evt) {
            case "download":
                let aikeys: number[] = [];

                for (let esi in selectedAiKeys) {
                    if (selectedAiKeys[esi].length > 0) {
                        const keys = [...selectedAiKeys[esi]];
                        aikeys = aikeys.concat(keys);
                    }
                }

                if (aikeys.length > 30) {
                    setSelectedCount(aikeys.length);
                    setShowLargeUploadWarning(true);
                    return;
                }
                setShowExpirationWarning(true);
                break;
            case "activate":
                const esikeys: number[] = selectedEsiKeys.map((ek) => +ek);
                props.handleEvent(evt, esikeys);
                break;
            case "release":
                handleAiKeyEvent("release");
                break;
            default:
                return;
        }
    };

    const completeDownload = () => {
        setShowExpirationWarning(false);
        handleAiKeyEvent("download");
    };

    const anyAssetsSelected = (aikeys: ParticipantAiKeys): boolean => {
        for (let esi in aikeys) {
            if (aikeys[esi].length > 0) return true;
        }
        return false;
    };

    const COLUMNS = [
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params: GridRenderCellParams) => (
                <AssetDetailToggle
                    id={params.id}
                    value={params.value}
                    selectedCount={selectedAiKeys[params.row.esiKey]?.length ?? 0}
                />
            ),
            width: 65,
        },
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
        },
        {
            field: "name",
            headerName: "Participant",
            flex: 0.65,
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) =>
                showLinks ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/registration/${params.row.esiKey}`}
                    />
                ) : (
                    params.value
                ),
        },
        {
            field: "emailAddress",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 1,
        },
        {
            field: "projectCode",
            headerName: "Project ID",
            renderCell: (params: GridRenderCellParams) =>
                showLinks ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/session/${params.row.esKey}`}
                    />
                ) : props.alternativeEventOnClickHandler !== undefined ? (
                    <span
                        style={{ cursor: "pointer", color: "#003da7" }}
                        onClick={() => props.alternativeEventOnClickHandler(params.row.esKey)}
                    >
                        {params.value}
                    </span>
                ) : (
                    params.value
                ),
            type: "string",
            flex: 0.4,
        },
        {
            field: "sessionType",
            headerName: "Type",
            valueGetter: (params: GridRenderCellParams) => {
                return params.row.sessionType;
            },
            type: "string",
            flex: 0.25,
        },
        {
            field: "startDate",
            headerName: "Start Date",
            valueGetter: (params: any) => new Date(params.row.startDate),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 175,
        },
        {
            field: "sessionHostPlatform",
            headerName: "Hosting Platform",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.row.sessionHostPlatform}</span>
            ),
            width: 200,
        },
        {
            field: "sessionAssessmentPlatform",
            headerName: "Assessment Platform",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.row.sessionAssessmentPlatform}</span>
            ),
            width: 200,
        },
        {
            field: "sessionHasCompassSku",
            headerName: "Compass",
            renderCell: (params: GridRenderCellParams) => (
                <span>
                    <Tooltip title="View In Compass">
                        <IconButton
                            size="small"
                            onClick={() => props.viewCompass(params.row.imKey)}
                            color="primary"
                            sx={{ pr: 2, pl: 1 }}
                        >
                            <ExploreOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    {params.value ? "Yes" : "No"}
                </span>
            ),
            width: 85,
        },
    ];

    return (
        <React.Fragment>
            <CclDownloadWarningDialog
                open={showExpirationWarning}
                onOk={() => completeDownload()}
            />
            <CclAlertDialog
                title={`Too Many Files (${selectedCount}) Selected For Download`}
                msg="For data security and privacy purposes files can only be downloaded in batches of 30 or less. Please deselect some files and try again."
                open={showLargeUploadWarning}
                onOk={() => setShowLargeUploadWarning(false)}
                severity={"error"}
            />
            <DataGridPro
                density="compact"
                getRowId={(row) => row.esiKey}
                rows={props.participants == null ? [] : props.participants}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                components={{
                    Toolbar: CclDataGridToolbar,
                    DetailPanelExpandIcon: KeyboardArrowRightIcon,
                    DetailPanelCollapseIcon: KeyboardArrowDownIcon,
                }}
                componentsProps={
                    !props.allowOperations
                        ? undefined
                        : {
                              toolbar: {
                                  showSaveState: true,
                                  hideSettingsButtons: true,
                                  miscButtons: [
                                      {
                                          id: "download",
                                          caption: "Download File(s)",
                                          label: "Download File(s)",
                                          disable: !aiKeysSelected,
                                          onClick: () => handleEvent("download"),
                                      },
                                      {
                                          id: "release",
                                          caption: "Release File(s)",
                                          label: "Release File(s)",
                                          disable: !aiKeysSelected,
                                          onClick: () => handleEvent("release"),
                                      },
                                      {
                                          id: "activate",
                                          caption: "Activate Compass",
                                          label: "Activate Compass",
                                          disable: !selectedEsiKeys?.length,
                                          onClick: () => handleEvent("activate"),
                                      },
                                  ],
                                  btnClick: (buttonName: string) => handleEvent(buttonName),
                              },
                          }
                }
                loading={props.isLoading}
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(esiKeys: GridSelectionModel) => {
                    const previouslySelected = [...selectedEsiKeys];
                    const currentlySelected = [...esiKeys.map((e) => +e)];
                    setSelectedEsiKeys(currentlySelected);
                    const newSelectedEsiKeys = currentlySelected.filter(
                        (cs) => !previouslySelected.some((ps) => ps === cs)
                    );
                    const unselectedEsiKeys = previouslySelected.filter(
                        (ps) => !currentlySelected.some((cs) => cs === ps)
                    );
                    let selectedaikeys = selectedAiKeys;

                    unselectedEsiKeys.forEach((e) => {
                        delete selectedaikeys[e];
                        selectedaikeys[e] = [];
                    });
                    newSelectedEsiKeys.forEach((e) => {
                        delete selectedaikeys[e];
                        const pax = props.participants?.find((p) => p.esiKey === e) ?? null;
                        selectedaikeys[e] =
                            pax == null || pax.assets == null || pax.assets.length === 0
                                ? []
                                : pax.assets.map((a) => a.id);
                    });

                    setAiKeysSelected(anyAssetsSelected(selectedaikeys));
                    setSelectedAiKeys(selectedaikeys);
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "name", sort: "asc" }],
                    },
                }}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={({ row }) => {
                    const rowcount = (row.assets?.length ?? 0) > 0 ? row.assets.length : 1;
                    return rowcount * 36 + 42;
                }}
                sx={{
                    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                }}
            />
        </React.Fragment>
    );
};

export default ResourceRegistrationsListDataGridPro;
