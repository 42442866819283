import * as React from "react";
import { Box, CardActions, Grid, Typography } from "@mui/material";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import CclInfoTable from "../../../../components/common/cclInfoTable/cclInfoTable";
import ComponentLoader from "../../../../components/common/componentLoader";
import {
    useGetCclNotificationQuery,
    useResendNotificationMutation,
    useSendNotificationLowersMutation,
} from "../../../../services/cclTokenedNotificationApi";
import { AccessEventNotificationDetails } from "../../../../services/types/accessEventTypes";
import { CclInfoTblNotificationTemplate } from "../CclInfoTblNotificationTemplate";
import ReadOnlyGate from "../../../../components/userAccess/ReadOnlyGate";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { FeatureFlag } from "../../../../featureFlags";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";
import { useGetAccessEventsByKeywordMutation } from "../../../../services/cclTokenedAccessEventsApi";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import NotificationPreviewPanel from "../preview/NotificationPreviewPanel";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";

interface NotificationGeneralPanelProps {
    notificationId: string;
}

const NotificationGeneralPanel: React.FC<NotificationGeneralPanelProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetCclNotificationQuery(props.notificationId, {
        skip: !props.notificationId,
    });
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [duplicateSentOpen, setDuplicateSentOpen] = React.useState<boolean>(false);
    const [reSendNotification] = useResendNotificationMutation();
    const [sendNotification] = useSendNotificationLowersMutation();
    const { logEvent } = useLogAccessEvent();
    const features = React.useContext(FeatureFlag);
    const [runEventsByKeywordSearch, { data: resendValidationData }] =
        useGetAccessEventsByKeywordMutation();
    const currentUserService = new UserClaimsService();
    const isUserClientAdmin = currentUserService.IsUserInGroups([eGroupIds.eClientAdmin]);

    React.useEffect(() => {
        if (resendValidationData !== undefined) {
            let duplicateEventFound = false;
            let foundEvents = resendValidationData.filter(
                (e) => e.accessEventTypeName === "Notification Resent"
            );
            if (foundEvents.length > 0) {
                for (let i = 0; i < foundEvents.length; i++) {
                    let eventDate = new Date(foundEvents[i].createdUtc.toString() + "Z");
                    let nowDate = new Date();
                    let diff = Math.abs(nowDate.valueOf() - eventDate.valueOf());
                    let diffInHours = diff / 1000 / 60 / 60;
                    if (diffInHours < 1) {
                        duplicateEventFound = true;
                        break;
                    }
                }
            }
            if (duplicateEventFound) {
                setDuplicateSentOpen(true);
            } else {
                setConfirmOpen(true);
            }
        }
    }, [resendValidationData]);

    let filterdata = {
        notificationId: data?.notificationId,
        subject: data?.subject,
        createdUtc: data?.createdUtc,
        sender:
            data?.sender.name !== "" && data?.sender.name !== null
                ? '"' + data?.sender.name + '"<' + data?.sender.identifier + ">"
                : data?.sender.identifier,
        replyTo:
            data?.replyTo.name !== "" && data?.replyTo.name !== null
                ? '"' + data?.replyTo.name + '"<' + data?.replyTo.identifier + ">"
                : data?.replyTo.identifier,
        ccRecipients: GetDtoValue("Cc"),
        bccRecipients: GetDtoValue("Bcc"),
        recipients: GetDtoValue("To"),
        templateTrackingId: data?.templateTrackingId,
    };

    const ResentNotification = () => {
        if (features?.resend?.active) {
            console.log("Sent Notification!");
            sendNotification(data?.notificationId!)
                .unwrap()
                .then(() => {
                    let rcpnt: string = "Unknown";
                    if (data?.recipients && data?.recipients.length > 0) {
                        rcpnt =
                            data.recipients.length > 1
                                ? data.recipients.map((r) => r.identifier).join(", ")
                                : data.recipients[0].identifier;
                    }
                    const evtData: AccessEventNotificationDetails = {
                        notificationId: data?.notificationId ?? "",
                        subject: data?.subject ?? "",
                        recipient: rcpnt,
                    };

                    logEvent("EmailResent", evtData);
                });
        } else {
            reSendNotification(data?.notificationId!)
                .unwrap()
                .then(() => {
                    let rcpnt: string = "Unknown";
                    if (data?.recipients && data?.recipients.length > 0) {
                        rcpnt =
                            data.recipients.length > 1
                                ? data.recipients.map((r) => r.identifier).join(", ")
                                : data.recipients[0].identifier;
                    }
                    const evtData: AccessEventNotificationDetails = {
                        notificationId: data?.notificationId ?? "",
                        subject: data?.subject ?? "",
                        recipient: rcpnt,
                    };

                    logEvent("EmailResent", evtData);
                });
        }
        setConfirmOpen(false);
    };

    const canResend = () => {
        runEventsByKeywordSearch({
            keyword: props.notificationId,
            start: null,
            end: null,
        });
    };

    function GetDtoValue(ToValue: string | undefined) {
        let toValue: string = "";
        switch (ToValue) {
            case "To":
                data?.recipients.forEach((items, i) => {
                    toValue =
                        toValue +
                        (items.name.trim() !== "" && items.name !== null
                            ? '"' +
                              items.name +
                              '" ' +
                              (items.identifier !== null ? "<" + items.identifier + "> " : "") +
                              (items.alternateIdentifier !== null
                                  ? "<" + items.alternateIdentifier + "> "
                                  : "")
                            : items.identifier +
                              " " +
                              (items.alternateIdentifier != null ? items.alternateIdentifier : ""));
                });
                return toValue;
            case "Cc":
                data?.ccRecipients.forEach((items, i) => {
                    toValue =
                        toValue +
                        (items.name.trim() !== "" && items.name !== null
                            ? '"' +
                              items.name +
                              '" ' +
                              (items.identifier !== null ? "<" + items.identifier + "> " : "")
                            : items.identifier);
                });
                return toValue;
            case "Bcc":
                data?.bccRecipients.forEach((items, i) => {
                    toValue =
                        toValue +
                        (items.name.trim() !== "" && items.name !== null
                            ? '"' +
                              items.name +
                              '" ' +
                              (items.identifier !== null ? "<" + items.identifier + "> " : "")
                            : items.identifier);
                });
                return toValue;
        }
    }

    if (isLoading) {
        return <ComponentLoader msg={"Loading Notification Data"} />;
    }

    if (isSuccess) {
        return (
            <Grid container>
                <Grid item xs={12} lg={5}>
                    <CclAlertDialog
                        title="Resend Email"
                        severity="warning"
                        open={duplicateSentOpen}
                        onOk={() => setDuplicateSentOpen(false)}
                        msg="This notification has already been resent within the last hour. Please allow up to one hour for the 
                                        resent notification to show up in CCL Access before attempting to resend again. 
                                        You can use the notifications module to validate if the notification has indeed been resent and check status."
                    />
                    <CclGenericConfirmationDialog
                        open={confirmOpen}
                        onCancel={() => setConfirmOpen(false)}
                        msg={"Are you sure you want to re-queue this email for processing?"}
                        onOk={() => ResentNotification()}
                    />
                    <Grid container>
                        <Grid item xs={12} margin={1}>
                            <CclGenericAccordian headingText="Details">
                                <Box
                                    sx={{
                                        m: 2,
                                        border: 1,
                                        borderColor: "#EEEEEE",
                                        borderRadius: 3,
                                    }}
                                >
                                    <CclInfoTable
                                        template={CclInfoTblNotificationTemplate}
                                        data={filterdata}
                                    />
                                    <CardActions sx={{ float: "right" }}>
                                        <ReadOnlyGate
                                            readOnly={isUserClientAdmin}
                                            reactElement={
                                                <CclUnrestrictedButton onClick={() => canResend()}>
                                                    {features?.resend?.active
                                                        ? "Send Email"
                                                        : "Resend "}
                                                </CclUnrestrictedButton>
                                            }
                                        />
                                    </CardActions>
                                </Box>
                            </CclGenericAccordian>
                        </Grid>
                        <Grid item xs={12} margin={1}>
                            <CclGenericAccordian headingText="History">
                                <Box
                                    sx={{
                                        h: 1,
                                        m: 1,
                                        border: 1,
                                        borderColor: "#EEEEEE",
                                        borderRadius: 3,
                                    }}
                                >
                                    <div style={{ overflow: "auto" }}>
                                        {data?.statuses.map((items, i) => (
                                            <Box sx={{ p: 2 }}>
                                                <Typography variant="body2" gutterBottom>
                                                    <b>{items.name}: </b>{" "}
                                                    {new Date(items.createdUtc).toLocaleString()}{" "}
                                                    <br /> {items.description}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </div>
                                </Box>
                            </CclGenericAccordian>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} lg={6} margin={1}>
                    <NotificationPreviewPanel
                        notificationId={props.notificationId}
                        forceChecked={false}
                    />
                </Grid>
            </Grid>
        );
    }
    return <span>Failed to Load Notification Information</span>;
};

export default NotificationGeneralPanel;
