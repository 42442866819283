import * as React from "react";
import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import { UpdateWorkPool, WorkPoolType } from "../../../services/types/resources/workPoolTypes";
import {
    useAddNewCadreMutation,
    useGetAllCadreTypesQuery,
} from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import { AccessEventWorkPoolDetails } from "../../../services/types/accessEventTypes";
import CclGenericConfirmationDialog from "../../../components/common/cclGenericConfirmationDialog";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import CclUnrestrictedOutlinedButton from "../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedLoadingButton from "../../../components/common/cclButtons/cclRestrictedLoadingButton";

export interface addWorkPool {
    open: boolean;
    onClose: () => void;
}

export const AddWorkPool: React.FC<addWorkPool> = (props) => {
    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
    const [ctKey, setCtKey] = React.useState<number>(1);

    const [addNewCadre, { isLoading, isError }] = useAddNewCadreMutation();
    const { data: allCadreTypes, isLoading: cadreTypesLoading } = useGetAllCadreTypesQuery();

    const { logEvent } = useLogAccessEvent();

    const addWorkPool = () => {
        const newWorkPool: UpdateWorkPool = {
            cKey: 0,
            name: name,
            description: description,
            ctKey: ctKey,
        };

        addNewCadre(newWorkPool)
            .unwrap()
            .then((e) => {
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${e.cKey}`,
                    workPoolName: e.name,
                };

                logEvent("WorkPoolCreated", evtData);
                props.onClose();
            });
    };

    const onCancel = () => {
        setOpenConfirmation(false);
    };

    const onOk = () => {
        setOpenConfirmation(false);
        addWorkPool();
    };

    return (
        <>
            <CclGenericConfirmationDialog open={openConfirmation} onCancel={onCancel} onOk={onOk} />

            <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth={true}>
                <DialogTitle>Create new Work Pool</DialogTitle>
                <DialogContent>
                    <Stack spacing={3}>
                        <TextField
                            value={name}
                            onChange={(e) => {
                                e.preventDefault();
                                setName(e.target.value);
                            }}
                            sx={{ width: 1, maxHeight: "57px", marginTop: "10px" }}
                            variant="outlined"
                            label="Name"
                        ></TextField>

                        <TextField
                            value={description}
                            onChange={(e) => {
                                e.preventDefault();
                                setDescription(e.target.value);
                            }}
                            sx={{ width: 1, maxHeight: "57px" }}
                            variant="outlined"
                            label="Description"
                        ></TextField>

                        <Box width={"100%"}>
                            {allCadreTypes && (
                                <>
                                    <Select
                                        sx={{
                                            width: "100%",
                                        }}
                                        defaultValue={1}
                                        label={"Select Work Pool Type"}
                                        onChange={(e) => {
                                            setCtKey(
                                                typeof e.target.value === "number"
                                                    ? e.target.value
                                                    : 1
                                            );
                                        }}
                                    >
                                        {allCadreTypes.map((type: WorkPoolType) => (
                                            <MenuItem value={type.ctKey}>{type.name}</MenuItem>
                                        ))}
                                    </Select>
                                </>
                            )}
                            {cadreTypesLoading && (
                                <Alert severity="info" sx={{ fontSize: 14 }}>
                                    Loading work pool types
                                </Alert>
                            )}
                        </Box>
                    </Stack>
                </DialogContent>

                {isLoading && (
                    <Alert severity="info" sx={{ fontSize: 14 }}>
                        Saving new Work Pool
                    </Alert>
                )}
                {isError && (
                    <Alert severity="error" sx={{ fontSize: 14 }}>
                        An Error has occured while saving your new Work Pool
                    </Alert>
                )}

                <DialogActions>
                    <CclUnrestrictedOutlinedButton onClick={props.onClose} disabled={isLoading}>
                        Cancel
                    </CclUnrestrictedOutlinedButton>

                    <CclRestrictedLoadingButton
                        onClick={() => setOpenConfirmation(true)}
                        loading={isLoading}
                        disabled={
                            name.length > 0 && description.length > 0 && ctKey !== 0 ? false : true
                        }
                    >
                        Create
                    </CclRestrictedLoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddWorkPool;
