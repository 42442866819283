import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Link } from "@mui/material";
import { Application } from "../../services/types/cclGrandCentralApiTypes";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import CclPersistentDataGrid, {
    CclPersistantDataGridMiscButton,
} from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { updateApplicationsSearchGridState } from "../../app/slices/applicationSearchListTableStateSlice";
import { Link as RouterLink } from "react-router-dom";
import CclCommonLink from "../../components/common/cclCommonLink";

export interface ApplicationListProps {
    applications: Application[];
    loading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
    onSendInvitationClick: () => void;
    onAddSystemClick: () => void;
}

export const ApplicationsDataGridPro: React.FC<ApplicationListProps> = (props) => {
    var claimsService = new UserClaimsService();
    var isAdmin = claimsService.IsUserCclAdminOrSuperAdmin();
    var isSuperAdmin = claimsService.IsUserSuperAdmin();
    var isGrandCentralSystemManager = claimsService.IsUserInAppRole(7);

    const COLUMNS = [
        {
            field: "name",
            headerName: "Application",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`/application/${params.row.applicationId}`} component={RouterLink}>
                    {params.value}
                </Link>
            ),
            width: 300,
        },
        {
            field: "loginApplication",
            headerName: "Home Page URL",
            valueFormatter: (_: any) => {
                return _.value?.homePageUrl ?? "";
            },
            renderCell: (params: GridRenderCellParams) => {
                if (
                    params.row?.loginApplication?.homePageUrl == null ||
                    params.row?.loginApplication?.homePageUrl === ""
                ) {
                    return null;
                }
                return (
                    <CclCommonLink
                        text={params.row?.loginApplication?.homePageUrl ?? ""}
                        keyVal={params.row?.applicationId}
                        to={params.row?.loginApplication?.homePageUrl ?? ""}
                        target={"_blank"}
                        aria-label={params.row?.loginApplication?.homePageUrl ?? ""}
                    />
                );
            },
            flex: 1,
        },
        {
            field: "systemName",
            headerName: "System",
            renderCell: (params: GridRenderCellParams) =>
                isAdmin ? (
                    <Link to={`/system/${params.row.systemId}`} component={RouterLink}>
                        {params.value}
                    </Link>
                ) : (
                    params.value
                ),
            flex: 1,
        },
    ];

    const stateSelector = useSelector((state: any) => state.applicationSearchTableState);

    let buttons: CclPersistantDataGridMiscButton[] = [];
    if (isSuperAdmin) {
        buttons.push({
            id: "send-application-invitations-button",
            caption: "Send Invitations",
            onClick: props.onSendInvitationClick,
            returnSelected: true,
            restricted: true,
        });
    }

    if (isGrandCentralSystemManager) {
        buttons.push({
            id: "add-system-button",
            caption: "Add System",
            onClick: props.onAddSystemClick,
            returnSelected: true,
            restricted: true,
        });
    }

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={props.applications}
            loading={props.loading}
            stateSelector={stateSelector}
            userPreferenceId={7}
            getRowIdFn={(row: Application) => row.applicationId}
            actionCreator={updateApplicationsSearchGridState}
            initState={JSON.stringify({ sorting: { sortModel: [{ field: "name", sort: "asc" }] } })}
            hideSettingsButtons={false}
            miscButtons={buttons}
            resultBar={true}
            resultBarText={`${props.applications.length} applications found`}
        />
    );
};

export default ApplicationsDataGridPro;
