import * as React from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
    UpdateWorkPoolMember,
    WorkPool,
    WorkPoolMember,
} from "../../../../../services/types/resources/workPoolTypes";
import {
    useRemoveCadreMemberMutation,
    useUpdateCadreMemberAvailabilityMutation,
} from "../../../../../services/cclTokenedEnterpirseCoachingCadreApi";
import { AccessEventWorkPoolDetails } from "../../../../../services/types/accessEventTypes";
import { useSelector } from "react-redux";
import CclGenericConfirmationDialog from "../../../../../components/common/cclGenericConfirmationDialog";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import CclPersistentDataGrid from "../../../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { updateWorkPoolResourceGridState } from "../../../../../app/slices/workPoolsResourceListTableStateSlice";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclCommonLink from "../../../../../components/common/cclCommonLink";
import AddUserDialog from "../searchUsers/addUserDialog";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

interface WorkPoolGeneralProps {
    loading: boolean;
    data: WorkPool;
}

const WorkPoolGeneral: React.FC<WorkPoolGeneralProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.workPoolResourceListTableState);

    const claimservice = new UserClaimsService();

    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
    const [addUserDialogOpen, setAddUserDialogOpen] = React.useState<boolean>(false);

    const [removeMember] = useRemoveCadreMemberMutation();
    const [doUpdateAvailability] = useUpdateCadreMemberAvailabilityMutation();

    const [target, setTarget] = React.useState<UpdateWorkPoolMember>({
        cKey: props.data.cKey,
        imKey: 0,
    });

    const [selectedRow, setSelectedRow] = React.useState<any>(null);

    const { logEvent } = useLogAccessEvent();

    const startRemoveMemberFlow = () => {
         setTarget({
             cKey: props.data.cKey,
             imKey: selectedRow[0].imKey,
         });
         setConfirmDelete(true);
    };

    const handleRemoveMember = () => {
        removeMember(target)
            .unwrap()
            .then(() => {
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${props.data?.cKey}`,
                    workPoolName: props.data?.name ?? "",
                };
                logEvent("WorkPoolMemberDeleted", evtData);
                setConfirmDelete(false);
            });
        setConfirmDelete(false)
    };

    const updateAvailability = (imKey: string, cKey: string) => {
        doUpdateAvailability([imKey, cKey]).unwrap().then(() => {
            const evtData : AccessEventWorkPoolDetails = {
                workPoolId: props.data.cKey.toString(),
                workPoolName: props.data.name,
            }
            logEvent("WorkPoolMemberEdited", evtData);
        });
    };

    let COLUMNS = [
        {
            field: "imKey",
            headerName: "ID",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink text={params.value} keyVal={params.value} to={`/resource/${params.row.pKey}/0`} />
            ),
            type: "string",
            width: 100,
        },
        {
            field: "name",
            headerName: "Resource",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink text={params.value} keyVal={params.value} to={`/resource/${params.row.pKey}/0`} />
            ),
            flex: 0.8,
            valueGetter: getFullName,
        },
        {
            field: "emailAddress",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink text={params.value} keyVal={params.value} to={`mailto:${params.value}`}  type={"email"}/>
            ),
            flex: 1.25,
        },
        {
            field: "status",
            headerName: "D365 Status",
            flex: 0.65,
            type: "string",
        },
        {
            field: "resourceType",
            headerName: "Resource Type",
            flex: 0.65,
            type: "string",
        },
        {
            field: "region",
            headerName: "Region",
            flex: 0.65,
            type: "string",
        },
        {
            field: "reportingLocation",
            headerName: "Reporting Location",
            flex: 0.65,
            type: "string",
        },
        {
            field: "available",
            headerName: "Available",
            renderCell: (params: GridRenderCellParams) => (
                <>
                    {claimservice.IsUserInAppRole(1) ? (
                        <>
                            <Switch
                                defaultChecked={params.value}
                                onChange={() => {
                                    updateAvailability(params.row.imKey, params.row.cKey);
                                }}
                                color="secondary"
                            ></Switch>
                        </>
                    ) : (
                        <>
                            <Typography>{params.value ? "Yes" : "No"}</Typography>
                        </>
                    )}
                </>
            ),
            flex: 0.5,
            type: "string",
        },
    ];

    return (
        <>
            <Stack spacing={2} width={"100%"}>
                {props.data.deliverySupportCadreMember && (
                    <CclPersistentDataGrid
                        columns={COLUMNS}
                        data={props.data.deliverySupportCadreMember}
                        loading={props.loading}
                        stateSelector={stateSelector}
                        userPreferenceId={0}
                        getRowIdFn={(row: WorkPoolMember) => row.imKey}
                        actionCreator={updateWorkPoolResourceGridState}
                        initState={JSON.stringify({
                            sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                            columns: { columnVisibilityModel: { status: true } },
                            filter: {
                                filterModel: {
                                    items: [
                                        {
                                            id: 1,
                                            columnField: "status",
                                            operatorValue: "startsWith",
                                            value: "active",
                                        },
                                    ],
                                },
                            },
                        })}
                        miscButtons={
                            claimservice.IsUserInAppRole(1) ? (
                                [
                                {
                                    id: "delete-member",
                                    caption: "Delete Member",
                                    onClick: () => {startRemoveMemberFlow()},
                                    restricted: true,
                                    returnSelected: true,
                                    disable: !selectedRow || selectedRow?.length === 0 || selectedRow?.length > 1,
                                },
                                {
                                    id: "add-member",
                                    caption: "Add Member",
                                    onClick: () => {setAddUserDialogOpen(true)},
                                    restricted: true,
                                    returnSelected: true,
                                }
                            ]) : []}
                        hideFooter={false}
                        hideSettingsButtons={true}
                        showCheckboxes={true}
                        onSelectedRowsChange={(rows: any[] | undefined) => setSelectedRow(rows)}
                    />
                )}
            </Stack>

            <AddUserDialog 
                data={props.data} 
                cKey={props.data.cKey} 
                open={addUserDialogOpen} 
                onClose={() => setAddUserDialogOpen(false)}            
            />

            <CclGenericConfirmationDialog
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onOk={() => handleRemoveMember()}
            />
        </>
    );
};

export default WorkPoolGeneral;
