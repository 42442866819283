import * as React from "react";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import { DetailsTabPanel } from "../../../../components/common/detailTabPanel/detailTabPanel";
import SessionApplicationLogsCard from "./sessionApplicationLogsCard";
import SessionJournalEntryTimelineCard from "./sessionJournalEntryTimelineCard";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";

interface SessionHistoryPanelProps {
    sessionId: string;
    drawerOpen: boolean;
}

const SessionHistoryPanel: React.FC<SessionHistoryPanelProps> = (props) => {
    const claimsService = new UserClaimsService();
    const [alignment, setAlignment] = React.useState<number>(0);
    const { data: sessionDetail } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue != null) setAlignment(newValue);
    };

    // Tablist
    const tablist: [string, React.ReactNode][] = claimsService.IsUserInGroups([
        eGroupIds.eClientAdmin,
    ])
        ? [
              [
                  "Journal Entries",
                  <SessionJournalEntryTimelineCard
                      sessionCode={sessionDetail?.sessionCode ?? ""}
                      sessionId={props.sessionId}
                      drawerOpen={props.drawerOpen}
                  />,
              ],
          ]
        : [
              [
                  "Application Logs",
                  <SessionApplicationLogsCard
                      sessionId={props.sessionId}
                      sessionCode={sessionDetail?.sessionCode ?? ""}
                  />,
              ],
              [
                  "Journal Entries",
                  <SessionJournalEntryTimelineCard
                      sessionCode={sessionDetail?.sessionCode ?? ""}
                      sessionId={props.sessionId}
                      drawerOpen={props.drawerOpen}
                  />,
              ],
          ];

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                m: 2,
                borderWidth: 0,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box display={"flex"} justifyContent={"center"} sx={{ borderWidth: 0, flexGrow: 1 }}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleTabChange}
                    sx={{ mb: 3 }}
                >
                    {tablist.map((tab, i) => (
                        <ToggleButton key={i} id={`${props.sessionId}-history-tab-${i}`} value={i}>
                            {tab[0]}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
            {tablist.map((tab, i) => (
                <DetailsTabPanel
                    detailType={props.sessionId}
                    key={tab[0]}
                    value={alignment}
                    index={i}
                >
                    {tab[1]}
                </DetailsTabPanel>
            ))}
        </Box>
    );
};

export default SessionHistoryPanel;
