import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
} from "@mui/material";
import * as React from "react";
import { Application } from "../../../../services/types/cclGrandCentralApiTypes";

export interface ApplicationSelectProps {
    applications: Application[];
    onChange: (newId: string) => void;
    selectedApplication: string;
}

export const ApplicationSelect: React.FC<ApplicationSelectProps> = (props) => {
    const [selectedApplication, setSelectedApplication] = React.useState<string>(
        props.selectedApplication
    );
    const applicationsCopy = [...props.applications];
    const applicationOptions = applicationsCopy
        .sort((a: Application, b: Application) => {
            return a.name.localeCompare(b.name);
        })
        .map((a) => {
            return { val: a.applicationId, option: a.name };
        });

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedApplication(event.target.value);
        props.onChange(event.target.value);
    };

    if (props.applications.length !== 1) {
        return (
            <FormControl fullWidth>
                <InputLabel id="select-application-label">Select Application</InputLabel>
                <Select
                    labelId="select-application-label"
                    id="select-application"
                    value={selectedApplication}
                    label="Select Application"
                    onChange={handleChange}
                    disabled={props.applications.length <= 0}
                >
                    {applicationOptions.map((opt) => (
                        <MenuItem key={opt.val} value={opt.val}>
                            {opt.option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    } else {
        return (
            <FormControl fullWidth>
                <TextField
                    disabled
                    id="application"
                    label="Application"
                    defaultValue={props.applications[0].name}
                />
            </FormControl>
        );
    }
};

export default ApplicationSelect;
