import * as React from "react";
import { Grid } from "@mui/material";
import { useGetAllContractsQuery } from "../../../services/cclTokenedEnterpriseCoachingApi";
import ContractSearchDataGridPro from "./contractList";

interface ContractSearchTabPanelProps {
    isNwaAdmin: boolean | null;
    partnerId: string | null;
}

const ContractSearchTabPanel: React.FC<ContractSearchTabPanelProps> = (props) => {
    const { data: allContracts, isLoading } = useGetAllContractsQuery(null);

    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid item sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}>
                    <ContractSearchDataGridPro loading={isLoading} contracts={allContracts ?? []} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ContractSearchTabPanel;
