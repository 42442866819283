import { Box, FormControlLabel, Stack, Switch } from '@mui/material';
import * as React from 'react'
import AdvancedSearchForm from './advancedSearchForm';

interface AdvancedSearchControlProps {
    onSearchResults: (pkeys: number[] | undefined) => void;
    enabled: boolean;
    
}

const AdvancedSearchControl: React.FC<AdvancedSearchControlProps> = (props) => {

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        props.onSearchResults(undefined);
    };

    return (
        <Stack width={1} spacing={2}>
            <Box width={260}><FormControlLabel control={<Switch disabled={!props.enabled}  checked={checked} onChange={handleChange} />} label="Advanced Profile Search" /></Box>
            { checked && <AdvancedSearchForm onSearchResults={props.onSearchResults} />}
        </Stack>
                
    );

};

export default AdvancedSearchControl;