import React, { useEffect } from "react";
import {
    Stack,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert,
} from "@mui/material";
import CclUnrestrictedButton from "../../../../../components/common/cclButtons/cclUnrestrictedButton";
import { SessionProgramManager } from "../../../../../services/types/sessionDetailTypes";
import { usePutProgramManagerSessionMutation } from "../../../../../services/cclTokenedSessionApi";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";

interface AddEditProgramManagerDialogProps {
    open: boolean;
    esKey: number;
    programCode: string;
    onCancel: () => void;
    managerToEdit: SessionProgramManager | null;
}

const AddEditProgramManagerDialog: React.FC<AddEditProgramManagerDialogProps> = (props) => {
    const [sessionManager, setSessionManager] = React.useState<SessionProgramManager>(
        props.managerToEdit ?? {
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            programId: props.esKey,
            individualId: 0,
            status: "Add",
        }
    );
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
    const [showSaving, setShowSaving] = React.useState(false);
    const [saveProgramManager] = usePutProgramManagerSessionMutation();
    const { logEvent } = useLogAccessEvent();

    const actionButtonText = props.managerToEdit ? "Update Administrator" : "Add Administrator";
    const textInputSx = {
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    useEffect(() => {
        if (props.managerToEdit != null) {
            setSessionManager(props.managerToEdit);
        } else {
            setSessionManager({
                email: "",
                firstName: "",
                lastName: "",
                phone: "",
                programId: props.esKey,
                individualId: 0,
                status: "Add",
            });
        }
    }, [props.esKey, props.managerToEdit]);

    const isProgramManagerValid = (): boolean => {
        setEmailError(sessionManager?.email === "");
        setFirstNameError(sessionManager?.firstName === "");
        setLastNameError(sessionManager?.lastName === "");
        setPhoneError(sessionManager?.phone === "");

        return (
            sessionManager?.email !== "" &&
            sessionManager?.firstName !== "" &&
            sessionManager?.lastName !== "" &&
            sessionManager?.phone !== ""
        );
    };

    const updateProgramManager = () => {
        if (props.managerToEdit == null) return; // TODO: error handler
        if (!isProgramManagerValid()) {
            return;
            /// TODO: show error alert here
        }
        var payload: SessionProgramManager = { ...props.managerToEdit };
        payload.programId = props.esKey;
        payload.status = "Remove";
        saveProgramManager(payload)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    projectCode: props.programCode ?? "",
                };

                logEvent("SessionSetupSaved", evtData);
                addProgramManager();
            })
            .catch(); // TODO: error handler
    };

    const addProgramManager = () => {
        if (!isProgramManagerValid()) {
            return;
            /// TODO: show error alert here
        }

        setShowSaving(true);
        var payload = { ...sessionManager };
        payload.programId = props.esKey;
        payload.status = "Add";
        saveProgramManager(payload)
            .unwrap()
            .then(() => {
                setShowSaving(false);
                const evtData: AccessEventSessionDetails = {
                    projectCode: props.programCode ?? "",
                };

                logEvent("SessionSetupSaved", evtData);
                props.onCancel();
            })
            .catch(() => {
                setShowSaving(false);
            }); // TODO: error handler
    };

    return (
        <Dialog open={props.open} maxWidth={"lg"} fullWidth>
            <DialogTitle id="update-client-admin-dialog">
                {props.managerToEdit ? "Update Client Administrator" : "Add Client Administrator"}
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "5px !important" }}>
                <Stack direction="row" justifyContent="space-between" spacing={3}>
                    <TextField
                        error={emailError}
                        helperText={emailError ? "Enter an Email Address" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.email = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.email}
                        id="txtEmail"
                        label="Email"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                    <TextField
                        error={firstNameError}
                        helperText={firstNameError ? "Enter a First Name" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.firstName = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.firstName}
                        id="txtFirstName"
                        label="First Name"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                    <TextField
                        error={lastNameError}
                        helperText={lastNameError ? "Enter a Last Name" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.lastName = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.lastName}
                        id="txtLastName"
                        label="Last Name"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                    <TextField
                        error={phoneError}
                        helperText={phoneError ? "Enter a Phone Number" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.phone = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.phone}
                        id="txtPhone"
                        label="Phone"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                {showSaving ? (
                    <Alert severity="info" sx={{ marginRight: "10px" }}>
                        Saving client administrator information...
                    </Alert>
                ) : null}
                <CclUnrestrictedButton onClick={props.onCancel}>Cancel</CclUnrestrictedButton>
                <CclUnrestrictedButton
                    onClick={() =>
                        props.managerToEdit ? updateProgramManager() : addProgramManager()
                    }
                >
                    {actionButtonText}
                </CclUnrestrictedButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditProgramManagerDialog;
