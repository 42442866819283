import * as React from "react";
import { Alert, CardContent, Divider, List, ListItem, ListItemText } from "@mui/material";
import { GrandCentralAccountEmailDetail } from "../../../../services/types/cclGrandCentralApiTypes";
import { useRemoveEmailMutation } from "../../../../services/cclTokenedGrandCentralApi";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

export type GrandCentralEmailsCardProps = {
    gcAccountId: string;
    gcAccountEmail: string;
    gcEmails: GrandCentralAccountEmailDetail[];
    isUserAdmin: boolean;
    imKey: string;
};

const GrandCentralEmailsCard: React.FC<GrandCentralEmailsCardProps> = (props) => {
    const [gcEmails, setGcEmails] = React.useState<GrandCentralAccountEmailDetail[]>(
        props.gcEmails
    );
    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
    const [removeEmailResultOpen, setRemoveEmailResultOpen] = React.useState<boolean>(false);
    const [removeEmailResult, setRemoveEmailResult] = React.useState<boolean>(false);
    const [emailToRemove, setEmailToRemove] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();
    const [removeEmail] = useRemoveEmailMutation();

    return (
        <CclGenericAccordian headingText="Emails">
            <CclGenericConfirmationDialog
                open={openConfirmation}
                onCancel={() => setOpenConfirmation(false)}
                msg={"Are you sure you want to remove this email from the account?"}
                onOk={() => {
                    removeEmail(emailToRemove)
                        .unwrap()
                        .then((response) => {
                            if (response) {
                                setRemoveEmailResult(true);
                                setRemoveEmailResultOpen(true);
                                setGcEmails(gcEmails.filter((e) => e.email !== emailToRemove));
                                const evtData: AccessEventIndividualDetails = {
                                    imKey: props.imKey,
                                    email: props.gcAccountEmail,
                                };

                                logEvent("UserAccountEdited", evtData);
                            } else {
                                setRemoveEmailResult(false);
                                setRemoveEmailResultOpen(true);
                            }
                        })
                        .catch((error) => {
                            setRemoveEmailResult(false);
                            setRemoveEmailResultOpen(true);
                        });
                    setOpenConfirmation(false);
                }}
            />
            <CclAlertDialog
                open={removeEmailResultOpen}
                onOk={() => setRemoveEmailResultOpen(false)}
                msg={
                    removeEmailResult
                        ? `Successfully removed ${emailToRemove} from account.`
                        : `Failed to remove ${emailToRemove} from account.`
                }
                title={`Email was${removeEmailResult ? "" : " not"} removed.`}
                severity={removeEmailResult ? "success" : "error"}
            />
            <CardContent sx={{ pt: 0 }}>
                {gcEmails.length > 1 ? (
                    <List sx={{ pt: 0, width: 1 }}>
                        {gcEmails.map((e) => (
                            <React.Fragment key={e.email}>
                                <ListItem
                                    sx={{ p: 2 }}
                                    alignItems="flex-start"
                                    secondaryAction={
                                        props.isUserAdmin && !e.isAuth0Email ? (
                                            <CclRestrictedButton
                                                size={"small"}
                                                onClick={() => {
                                                    setEmailToRemove(e.email);
                                                    setOpenConfirmation(true);
                                                }}
                                            >
                                                Remove Email
                                            </CclRestrictedButton>
                                        ) : null
                                    }
                                >
                                    <ListItemText primary={e.email} />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Alert severity="info">
                        {`There are no other emails associated with this account, other than ${props.gcAccountEmail}.`}
                    </Alert>
                )}
            </CardContent>
        </CclGenericAccordian>
    );
};

export default GrandCentralEmailsCard;
