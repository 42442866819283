import React from "react";
import { Alert, Box, Grid, Typography } from "@mui/material";
import {
    useFinalizeImageChunkUploadMutation,
    useUploadAssetChunkMutation,
} from "../../../../services/cclTokenedAssetsApi";
import { useSaveProfilePictureMutation } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import returnDefaultUserImage from "../../../../utilities/defaultUserPicture";
import ImageDropzone from "./imageDropzone";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import CclUnrestrictedLoadingButton from "../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";

interface ProfileImageProps {
    name: string;
    picture: string;
    refetch: () => void;
    imKey: string;
    restrictAccess?: boolean;
    fromResources?: boolean;
    email: string;
}

const ProfileImage: React.FC<ProfileImageProps> = (props) => {
    const chunksize = 1024 * 512; // .5 MB chunks
    let numChunks: number = 0;

    const [saveProfilePicture, { isSuccess }] = useSaveProfilePictureMutation();
    const [
        uploadChunk,
        { data: chunkUploadResponse, isSuccess: chunkSuccess, isError: chunkIsError },
    ] = useUploadAssetChunkMutation();
    const [
        finalizeUpload,
        { data: finalizeResponse, isSuccess: finalizeSuccess, isError: finalizeIsError },
    ] = useFinalizeImageChunkUploadMutation();

    const [uploadError, setUploadError] = React.useState<string>("");
    const [uploadSuccess, setUploadSuccess] = React.useState<boolean>(false);
    const [currentChunk, setCurrentChunk] = React.useState<number>(1);
    const [acceptedFile, setAcceptedFile] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);

    document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27) {
            setEdit(false);
        }
    };

    const { logEvent } = useLogAccessEvent();

    const editedProfilePictureEvent = (imkey: string) => {
        const tag: string = props.fromResources
            ? "ResourceProfilePictureUpdated"
            : "UserUpdatedProfilePicture";
        const evtData: AccessEventIndividualDetails = {
            imKey: imkey,
            email: props.email,
        };

        logEvent(tag, evtData);
    };

    // eslint-disable-next-line
    const callFinalizeUpload = (id: string) => {
        let file: File = acceptedFile[0].file;
        let type = file.type;
        let typeArr = type.split("/");
        type = typeArr[1];
        let fd: FormData = new FormData();
        fd.append("id", id);
        fd.append("fileName", `${props.imKey}.jpg`);
        fd.append("contentType", file.type);
        fd.append("chunkCount", numChunks.toString());
        finalizeUpload(fd)
            .unwrap()
            .then(() => {
                editedProfilePictureEvent(props.imKey);
                setEdit(false);
                setAcceptedFile([]);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setUploadError("An error occurred while processing your request");
            });
    };

    React.useEffect(() => {
        if (chunkSuccess) {
            if (chunkUploadResponse && chunkUploadResponse.success) {
                if (currentChunk >= numChunks) {
                    callFinalizeUpload(chunkUploadResponse.id);
                } else {
                    const newchunknum = currentChunk + 1;
                    setCurrentChunk(newchunknum);
                    let fd: FormData = new FormData();
                    fd.append("id", chunkUploadResponse?.id ?? "");
                    fd.append("chunkNumber", newchunknum.toString());
                    fd.append("chunk", getChunk(newchunknum));
                    uploadChunk(fd);
                }
            } else {
            }
        } else if (chunkIsError) {
            // add cancel upload here
            setEdit(false);
            setUploadSuccess(false);
            setLoading(false);
            setUploadError("An error has occured uploading your image");
        }
        // eslint-disable-next-line
    }, [chunkIsError, chunkSuccess]);

    React.useEffect(() => {
        if (finalizeResponse) {
            setCurrentChunk(1);
            if (finalizeSuccess) {
                setUploadSuccess(true);
                props.refetch();
                setLoading(false);
                uploadAlert();
            } else {
                setUploadSuccess(false);
                setLoading(false);
                setUploadError("An error has occured uploading your image");
            }
            setEdit(false);
        }
        // eslint-disable-next-line
    }, [finalizeSuccess, finalizeIsError]);

    if (acceptedFile) {
        numChunks =
            acceptedFile[0]?.file != null ? Math.ceil(acceptedFile[0]?.file.size / chunksize) : 0;
    }

    const getChunk = (chunknum: number): Blob => {
        let file: File = acceptedFile[0].file;
        const start = (chunknum - 1) * chunksize;
        let end = chunknum * chunksize;
        if (end > file.size) end = file.size;
        return file.slice(start, end);
    };

    const changeProfilePicture = () => {
        setEdit(!edit);
    };

    const addFile = (acceptedFiles: File[]) => {
        const newuploaditems = acceptedFiles.map((f) => {
            return {
                file: f,
            };
        });
        setAcceptedFile(newuploaditems);
    };

    const saveImage = () => {
        let file: File = acceptedFile[0].file;
        let formData: FormData = new FormData();
        let fileExtension = "image/" + file.name.split(".").pop();
        if (numChunks === 1) {
            formData.append("picFile", file.slice(0, file.size) as Blob);
            formData.append("contentType", file.type ? file.type : fileExtension);
            setLoading(true);
            uploadImage(formData);
        } else {
            //Upload chunks
            formData.append("id", "none");
            formData.append("chunk", getChunk(1));
            formData.append("contentType", file.type);
            formData.append("chunkNumber", currentChunk.toString());
            setLoading(true);
            uploadChunk(formData);
        }
    };

    const uploadImage = (data: FormData) => {
        let obj = {
            imKey: props.imKey,
            payload: {
                picFile: data,
            },
        };
        saveProfilePicture(obj)
            .unwrap()
            .then(() => {
                editedProfilePictureEvent(props.imKey);
                setUploadSuccess(true);
                setLoading(false);
                uploadAlert();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setUploadError("An error occurred while processing your request");
                setUploadSuccess(false);
            });
        setEdit(false);
        setAcceptedFile([]);
    };

    const uploadAlert = () => {
        setUploadSuccess(true);
        const timer = setTimeout(() => {
            setUploadSuccess(false);
        }, 4000);
        return () => clearTimeout(timer);
    };

    React.useEffect(() => {
        if (!isSuccess) {
            return;
        }
        props.refetch();
        // eslint-disable-next-line
    }, [isSuccess, props.refetch]);

    const claimservice = new UserClaimsService();

    const checkAccess = () => {
        if (props.restrictAccess) {
            if (claimservice.IsUserCclAdminOrSuperAdmin()) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    return (
        <Grid container spacing={2} sx={{ width: "100%" }} alignItems={"center"}>
            <Grid item xs={4}>
                <Typography variant="h6" sx={{ fontWeight: "bolder", textAlign: "left", ml: 5 }}>
                    {props.name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {props.picture ? (
                    <Box
                        component="img"
                        sx={{
                            height: "100px",
                            width: "100px",
                            margin: "auto",
                            borderRadius: "50%",
                        }}
                        src={props.picture}
                    />
                ) : (
                    returnDefaultUserImage(props.name)
                )}
            </Grid>
            <Grid item xs={edit ? 4 : 3}>
                {uploadError && <Alert severity="error">{uploadError}</Alert>}
                {uploadSuccess && <Alert severity="info">Image Uploaded Succesfully</Alert>}
                {edit && (
                    <ImageDropzone
                        onAddFile={addFile}
                        disabled={false}
                        fileToDisplay={acceptedFile.length > 0 ? acceptedFile[0].file : null}
                    />
                )}
            </Grid>
            <Grid item xs={edit ? 2 : 3}>
                {checkAccess() ? (
                    <>
                        {!edit ? (
                            <CclUnrestrictedButton
                                sx={{ float: "right", mr: 5 }}
                                onClick={changeProfilePicture}
                            >
                                <Typography variant="body2">Change Profile Picture</Typography>
                            </CclUnrestrictedButton>
                        ) : (
                            <Box sx={{ float: "right", mr: 5 }}>
                                {acceptedFile.length > 0 ? (
                                    <CclUnrestrictedLoadingButton
                                        sx={{ height: "20%" }}
                                        loading={loading}
                                        onClick={saveImage}
                                    >
                                        Upload Image
                                    </CclUnrestrictedLoadingButton>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

export default ProfileImage;
