import * as React from "react";
import { AlertColor, Stack } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import FlexGrid from "../../../../layouts/flexGrid";
import { useGetAllContractsByProfileKeyQuery } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import ContractsDataGrid from "./contractsDataGrid";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import CclStatusDialog from "../../../../components/common/cclStatusDialog";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import SignContractBanner from "./signContractBanner";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

export interface ContractsProps {
    pKey: string;
    name: string;
    imKey: string;
    email: string;
}

export const Contracts: React.FC<ContractsProps> = (props) => {
    const claimsService = new UserClaimsService();
    const isUsersRecord = claimsService.GetCurrentResourceId() === props.pKey;
    const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
    const [statusTitle, setStatusTitle] = React.useState<string>("");
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [statusEnableOk, setStatusEnableOk] = React.useState<boolean>(false);
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("info");
    const {
        data: contracts,
        isLoading,
        isSuccess,
    } = useGetAllContractsByProfileKeyQuery(props.pKey);
    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    const handleEvents = (evt: string, ids: number[]) => {
        if (ids.length <= 0) return;
        if (evt === "download") {
            setStatusOpen(true);
            setStatusTitle("Download Contracts");
            setStatusMessage("Downloading contracts....");
            setStatusSeverity("info");
            setStatusEnableOk(false);
            if (contracts === undefined || contracts.length <= 0) {
                setStatusMessage("You must select at least one Contract to download.");
                setStatusSeverity("error");
                setStatusEnableOk(true);
                return;
            }
            const selectedcontracts = contracts.filter((c) =>
                ids.some((i) => i === c.profileContractId)
            );
            let aikeys: number[] = selectedcontracts.flatMap((c) => {
                if (c.status === 2) return [];
                if (c.aiKey === undefined || c.aiKey === null) {
                    if (
                        c.contract.aiKey === undefined ||
                        c.contract.aiKey === null ||
                        c.contract.aiKey === 0
                    ) {
                        return [];
                    }
                    return c.contract.aiKey;
                }
                if (c.aiKey !== 0) return c.aiKey;
                return [];
            });

            if (aikeys.length <= 0) {
                setStatusMessage("No valid contracts were found for the selected years.");
                setStatusSeverity("error");
                setStatusEnableOk(true);
                return;
            }

            let filename = `${props.name} Contracts.zip`;
            if (aikeys.length === 1) {
                const c = contracts.find(
                    (c) => c.aiKey === aikeys[0] || c.contract.aiKey === aikeys[0]
                );
                filename = c != null ? c.contract.title : `${props.name} Contract.pdf`;
            }

            downloadService
                .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: true })
                .then(() => {
                    const evtData: AccessEventIndividualDetails = {
                        imKey: props.imKey,
                        email: props.email,
                    };

                    logEvent("UserDownloadedContract", evtData);
                    setStatusTitle("Download Complete");
                    setStatusMessage(`${aikeys.length > 0 ? "Contracts" : "Contract"} downloaded`);
                    setStatusSeverity("success");
                    setStatusEnableOk(true);
                })
                .catch((error) => {
                    const msg = error.message ?? "Unknown error";
                    setStatusMessage("Error: " + msg);
                    setStatusSeverity("error");
                    setStatusEnableOk(true);
                });
        }
    };

    if (isLoading) {
        return <ComponentLoader msg={"Loading Contracts"} />;
    }

    if (isSuccess) {
        return (
            <Stack width={1} direction={"column"} spacing={3} height={1}>
                <SignContractBanner isUsersRecord={isUsersRecord} pKey={props.pKey} />
                <CclStatusDialog
                    open={statusOpen}
                    onOk={() => setStatusOpen(false)}
                    severity={statusSeverity}
                    title={statusTitle}
                    msg={statusMessage}
                    enableOk={statusEnableOk}
                />
                <FlexGrid>
                    <ContractsDataGrid
                        data={contracts}
                        isLoading={isLoading}
                        handleEvent={handleEvents}
                        readonly={!isUsersRecord && claimsService.IsReadOnly()}
                    />
                </FlexGrid>
            </Stack>
        );
    }
    return <span>Failed to load contracts.</span>;
};
export default Contracts;
