import * as React from "react";
import { Alert, Box, Stack } from "@mui/material";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import ScoringStatusPanel from "../../../sessions/sessionDetail/assessments/scoringStatus/scoringStatusPanel";
import { Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import RaterManagementPanel from "../raterManagement/raterManagementPanel";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

interface RegistrationAssessmentsPanelProps {
    sessionKey: number | undefined;
    registration: Participant | undefined;
    sessionCode: string | undefined;
}

const RegistrationAssessmentsPanel: React.FC<RegistrationAssessmentsPanelProps> = (props) => {
    const currentUserService = new UserClaimsService();
    const isClientAdmin = currentUserService.IsUserInGroups([eGroupIds.eClientAdmin]);
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        logEvent("UserViewedAssessmentStatus", null);
     }, [logEvent]);    

    if (props.sessionKey === undefined || props.registration?.esiKey == null) {
        return (
            <Box>
                <Alert severity="error">Error: Session and/or participant data not found!</Alert>
            </Box>
        );
    }

    return (
        <Stack width={1}>
            <CclGenericAccordian headingText="Assessments" defaultExpanded={true}>
                <ScoringStatusPanel
                    sessionKey={props.sessionKey}
                    sessionCode={props.sessionCode}
                    participant={props.registration}
                    readOnly={isClientAdmin}
                />
            </CclGenericAccordian>
            <CclGenericAccordian headingText="Raters" defaultExpanded={true}>
                <RaterManagementPanel esikey={props.registration.esiKey.toString()} programCode={props.registration.sessionCode}/>
            </CclGenericAccordian>
        </Stack>
    );
};

export default RegistrationAssessmentsPanel;
