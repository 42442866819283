import * as React from "react";
import { Alert, AlertColor, AlertTitle } from "@mui/material";
import AssetsDataGridPro from "../../../../components/shared/assetsDataGridPro/assetsDataGridPro";
import { useGetAssetsByImKeyQuery } from "../../../../services/cclTokenedAssetsApi";
import ComponentLoader from "../../../../components/common/componentLoader";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import CclStatusDialog from "../../../../components/common/cclStatusDialog";
import FileUploadDialog from "../../../resources/resourceDetail/files/upload/fileUploadDialog";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

interface UserFilesPanelProps {
    email: string;
    imKey: number;
    readonly?: boolean;
    fullName?: string;
}

const UserFilesPanel: React.FC<UserFilesPanelProps> = (props) => {
    const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
    const [uploadDialogOpen, setUploadDialogsOpen] = React.useState<boolean>(false);
    const [statusOkEnabled, setStatusEnableOk] = React.useState<boolean>(false);
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("info");
    const [statusTitle, setStatusTitle] = React.useState<string>("");
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    const downloadService = new FileDownloadService();
    const {
        data: assets,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetAssetsByImKeyQuery(props.imKey, { skip: !props.imKey });

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    const handleEvents = (evt: string, ids: number[]) => {
        switch (evt) {
            case "download":
                setStatusTitle("Download Files");
                setStatusOpen(true);
                setStatusSeverity("info");
                setStatusMessage(
                    "...downloading selected files. This could take a few minutes, depending on the number of files selected. Please be patient. A confirmation message will be displayed when the download has completed."
                );
                setStatusEnableOk(false);
                if (ids === undefined || ids.length <= 0) {
                    setStatusMessage("You must select at least one item to download.");
                    setStatusEnableOk(true);
                    setStatusSeverity("warning");
                    return;
                }
                let filename = props.fullName ? `${props.fullName}Files.zip` : "Files.zip";
                if (ids.length === 1) {
                    let asset = assets?.find((a) => a.id === ids[0]);
                    if (asset) {
                        filename = asset.name;
                    }
                }

                downloadService
                    .DownloadAssets({
                        fname: filename,
                        aikeys: ids,
                        flatzip: true,
                    })
                    .then(() => {
                        const evtData: AccessEventIndividualDetails = {
                            imKey: props.imKey.toString(),
                            email: props.email,
                        };
                        logEvent("IndividualFilesDownloaded", evtData);
                        setStatusTitle("Download Complete")
                        setStatusMessage(`${ids.length > 0 ? "Files" : "File"} downloaded`);
                        setStatusSeverity("success");
                        setStatusEnableOk(true);
                    })
                    .catch((error) => {
                        const msg = error.message ?? "Unknown error";
                        setStatusMessage("Error: " + msg);
                        setStatusEnableOk(true);
                        setStatusSeverity("error");
                    });
                break;

            case "upload":
                setUploadDialogsOpen(true);
                break;

            default:
                break;
        }
    };

    if (isSuccess || isLoading) {
        return (
            <React.Fragment>
                <CclStatusDialog
                    open={statusOpen}
                    onOk={() => setStatusOpen(false)}
                    title={statusTitle}
                    msg={statusMessage}
                    enableOk={statusOkEnabled}
                    severity={statusSeverity}
                />
                <FileUploadDialog email={props.email} imkey={props.imKey} open={uploadDialogOpen} onClose={() => setUploadDialogsOpen(false)} />
                <AssetsDataGridPro
                    assets={assets ?? []}
                    isLoading={isLoading}
                    handleEvent={handleEvents}
                    readonly={props.readonly ?? false}
                />
            </React.Fragment>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg={"Loading Files"} />;
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving files for user key ${props.imKey} (${getErrorMsg(error)})`
        );
    }

    return showErrorAlert(`Unknown error retrieving files for user key ${props.imKey}`);
};
export default UserFilesPanel;
