import * as React from "react";
import {
    Alert,
    Box,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import {
    useAddIdentityMutation,
    useRemoveIdentityMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import { GrandCentralIdentityDetail } from "../../../../services/types/cclGrandCentralApiTypes";
import {
    AddEnterpriseIdentity,
    AddEnterpriseIdentityPayload,
    RemoveEnterpriseIdentity,
} from "../../../../services/types/rtkQueryTypes";
import AddIdentityDialog from "./addIdentityDialog";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

export type GrandCentralIdentitiesCardProps = {
    gcAccountId: string;
    gcAccountEmail: string;
    gcIdentities: GrandCentralIdentityDetail[];
    isUserAdmin: boolean;
    tenantId: string;
    imKey: string;
};

const GrandCentralIdentitiesCard: React.FC<GrandCentralIdentitiesCardProps> = (props) => {
    const [selectedIdentity, setSelectedIdentity] = React.useState<string>("");
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [addIdentityOpen, setAddIdentityOpen] = React.useState<boolean>(false);
    const [resultOpen, setResultOpen] = React.useState<boolean>(false);
    const [lastAction, setLastAction] = React.useState<"add" | "remove">("remove");
    const [lastActionResult, setLastActionResult] = React.useState<boolean>(false);
    const { logEvent } = useLogAccessEvent();
    const [removeIdentityMutation, { isSuccess: removeSuccess, isError: removeError }] =
        useRemoveIdentityMutation();
    const [addIdentityMutation, { isSuccess: addSuccess, isError: addError }] =
        useAddIdentityMutation();

    React.useEffect(() => {
        if (!removeSuccess && !removeError) return;

        setLastAction("remove");
        setResultOpen(true);

        if (removeSuccess) {
            setLastActionResult(true);
            const evtData: AccessEventIndividualDetails = {
                imKey: props.imKey,
                email: props.gcAccountEmail,
            };

            logEvent("UserAccountEdited", evtData);
        } else if (removeError) {
            setLastActionResult(false);
        }
        // eslint-disable-next-line
    }, [removeSuccess, removeError]);

    React.useEffect(() => {
        if (!addSuccess && !addError) return;

        setLastAction("add");
        setResultOpen(true);

        if (addSuccess) {
            setLastActionResult(true);
            const evtData: AccessEventIndividualDetails = {
                imKey: props.imKey,
                email: props.gcAccountEmail,
            };

            logEvent("UserAccountEdited", evtData);
        } else if (addError) {
            setLastActionResult(false);
        }
        // eslint-disable-next-line
    }, [addSuccess, addError]);

    const removeIdentity = () => {
        const request: RemoveEnterpriseIdentity = {
            email: props.gcAccountEmail ?? "",
            identityId: selectedIdentity,
        };
        setConfirmOpen(false);
        removeIdentityMutation(request);
    };

    const closeAddIdentity = (newIdentity: { providerCode: string; providerId: string } | null) => {
        setAddIdentityOpen(false);
        if (newIdentity == null) return;

        const payload: AddEnterpriseIdentityPayload = {
            providerIdValue: newIdentity.providerId,
            identityProviderCode: newIdentity.providerCode,
            tenantId: props.tenantId,
        };

        const request: AddEnterpriseIdentity = {
            email: props.gcAccountEmail,
            payload: payload,
        };
        addIdentityMutation(request);
    };

    return (
        <CclGenericAccordian headingText="Identities" sx={{ height: 1 }}>
            <CclGenericConfirmationDialog
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                msg={"Are you sure you want to remove this identity from the account?"}
                onOk={removeIdentity}
            />
            <AddIdentityDialog
                gcAccountId={props.gcAccountId}
                gcAccountEmail={props.gcAccountEmail}
                open={addIdentityOpen}
                handleClose={closeAddIdentity}
            />
            <CclAlertDialog
                open={resultOpen}
                onOk={() => setResultOpen(false)}
                msg={
                    lastAction === "add"
                        ? lastActionResult === true
                            ? "Identity Added"
                            : "Failed to add enterprise id to account."
                        : lastActionResult === true
                        ? "Identity Removed"
                        : "Failed to remove provider id from account."
                }
                title={`Identity was${lastActionResult ? "" : "not"} ${
                    lastAction === "add" ? "added" : "removed"
                }.`}
                severity={lastActionResult ? "info" : "error"}
            />
            <CardContent sx={{ pt: 0 }}>
                {props.gcIdentities.length > 0 ? (
                    <List sx={{ pt: 0, width: 1 }}>
                        {props.gcIdentities.map((i) => (
                            <React.Fragment key={i.identityId}>
                                <ListItem
                                    sx={{ p: 2 }}
                                    alignItems="flex-start"
                                    secondaryAction={
                                        props.isUserAdmin ? (
                                            <CclRestrictedButton
                                                size={"small"}
                                                onClick={() => {
                                                    setSelectedIdentity(i.identityId);
                                                    setConfirmOpen(true);
                                                }}
                                            >
                                                Remove Identity
                                            </CclRestrictedButton>
                                        ) : null
                                    }
                                >
                                    <ListItemText
                                        primary={i.identityProviderCode}
                                        secondary={
                                            <Typography
                                                sx={{ display: "inline" }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {i.providerIdValue}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Alert severity="info">
                        {`There are no provider identities associated with this account.`}
                    </Alert>
                )}
                {props.isUserAdmin ? (
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <CclRestrictedButton
                            aria-label="add-enterprise-identity"
                            size={"small"}
                            onClick={() => setAddIdentityOpen(true)}
                            sx={{ mr: 4 }}
                        >
                            Add Identity
                        </CclRestrictedButton>
                    </Box>
                ) : null}
            </CardContent>
        </CclGenericAccordian>
    );
};

export default GrandCentralIdentitiesCard;
