import * as React from "react";
import { Alert, Box, Card, Typography } from "@mui/material";
import { FeatureFlag } from "../../../../featureFlags";
import { useUpdateApplicationFeatureMutation } from "../../../../services/cclTokenedApplicationFeaturesApi";
import { UpdateApplicationFeature } from "../../../../services/types/applicationFeatureTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import ComponentLoader from "../../../../components/common/componentLoader";
import ApplicationFeaturesDataGridPro from "./admininistrationDataGrid";
import PageLoader from "../../../../components/common/pageLoader";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventFeatureDetails } from "../../../../services/types/accessEventTypes";

const Administration: React.FC = (props) => {
    const { logEvent } = useLogAccessEvent();
    const [confirmUpdate, setConfirmUpdate] = React.useState<boolean>(false);
    const [updateId, setUpdateId] = React.useState<number>(0);

    const features  = React.useContext(FeatureFlag);
    const claimservice = new UserClaimsService();

    const [update, {isLoading}] = useUpdateApplicationFeatureMutation();

    const triggerUpdateFlow = (id: number) => {
        setUpdateId(id);
        setConfirmUpdate(true);
     }

    const toggleFeature = () => {
        console.log(features);
        const updateFeature: UpdateApplicationFeature = {
            Id : updateId,
            UserEmail: claimservice.GetCurrentUserEmail()
        }
        update(updateFeature).unwrap().then(() => {
            const fname = features.allFeatures.find(f => f.applicationFeatureId === updateId)?.name ?? "Unknown";
            const evtData:AccessEventFeatureDetails = {
                featureId: updateId.toString(),
                featureName: fname,
            };
            logEvent("ApplicationFeatureChanged", evtData);
        });
        setUpdateId(0)
        setConfirmUpdate(false);
    }

    if (!claimservice.IsUserInAppRole(6)) {
        return (
                <Box>
                    <Alert severity="error">Feature Manager Role is required to access this page</Alert>
                </Box>
            );
    }

    return (
        <Box sx={{width: "100%"}}>
            {isLoading ? (
                <PageLoader msg={"Loading application features..."} />
            ) : null}
            <Card sx={{height:"90%"}}>
                <Typography sx={{margin: "10px"}} variant="h5" >Application Features</Typography>
                {isLoading || features.allFeatures.length <= 0?
                    <ComponentLoader msg ="Updating Features..."/> :
                        <ApplicationFeaturesDataGridPro
                            features={features.allFeatures}
                            triggerUpdateFlow={triggerUpdateFlow}
                            loading={isLoading}
                        />
                }
            </Card>

            {confirmUpdate && 
                <CclGenericConfirmationDialog 
                    open={confirmUpdate} 
                    onCancel={()=> {
                        setConfirmUpdate(false);
                        setUpdateId(0); 
                    }} 
                    onOk={toggleFeature } 
                />
            }
        </Box>
    );
};

export default Administration;
