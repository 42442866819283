import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import { Auth0Logins } from "../../../../services/types/cclGrandCentralApiTypes";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclCommonLink from "../../../../components/common/cclCommonLink";

export interface ApplicationLoginsDataGridProps {
    Auth0LoginList: Auth0Logins[] | undefined;
    isLoading: boolean;
}

export const ApplicationLoginsDataGrid: React.FC<ApplicationLoginsDataGridProps> = (props) => {
    const COLUMNS = [
        {
            field: "userName",
            headerName: "User",
            type: "string",
            renderCell: (params: GridRenderCellParams) => {
                if (params.row.userName !== "") {
                    return (
                        <CclCommonLink
                            text={params.row.userName}
                            keyVal={params.row.logDateTime.toString()}
                            to={`/users?searchparam=${encodeURI(params.row.userName)}`}
                        />
                    );
                }

                return <span>{`${params.row.ipAddress} (ip address)`}</span>;
            },
            flex: 1,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            flex: 1,
        },
        {
            field: "logDateTime",
            headerName: "Date",
            flex: 1,
            type: "date",
            valueGetter: (params: any) => new Date(params.row.logDateTime),
            renderCell: (params: GridRenderCellParams) => (
                <span>{params?.value.toLocaleString()}</span>
            ),
        },
        {
            field: "browser",
            headerName: "Platform",
            flex: 1,
            type: "string",
        },
        {
            field: "isMobile",
            headerName: "Mobile Device",
            flex: 1,
            type: "string",
            valueGetter: (params: GridValueGetterParams) =>
                `${params.value === true ? "Yes" : "No"}`,
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.logDateTime.toString()}
            rows={props.Auth0LoginList == null ? [] : props.Auth0LoginList}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            components={{ Toolbar: CclDataGridToolbar }}
            loading={props.isLoading}
            initialState={{ sorting: { sortModel: [{ field: "logDateTime", sort: "desc" }] } }}
        />
    );
};

export default ApplicationLoginsDataGrid;
