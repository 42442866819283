import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Auth0Logins } from "../../../../services/types/cclGrandCentralApiTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

export interface Auth0LoginTableProps {
    Auth0LoginList: Auth0Logins[] | undefined;
    isLoading: boolean;
}

const Auth0LoginTable: React.FC<Auth0LoginTableProps> = (props) => {
    const COLUMNS = [
        {
            field: "description",
            headerName: "Description",
            type: "string",
            flex: 1,
        },
        {
            field: "clientName",
            headerName: "Application",
            type: "string",
            flex: 1,
        },
        {
            field: "logDateTime",
            headerName: "Date",
            flex: 1,
            type: "date",
            valueGetter: (params: any) => new Date(params.row.logDateTime),
            renderCell: (params: GridRenderCellParams) => (
                <span>{params?.value.toLocaleString()}</span>
            ),
        },
        {
            field: "browser",
            headerName: "Platform",
            flex: 1,
            type: "string",
        },
        {
            field: "isMobile",
            headerName: "Mobile Device",
            flex: 1,
            type: "string",
            valueGetter: (params: GridValueGetterParams) =>
                `${params.value === true ? "Yes" : "No"}`,
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.logDateTime.toString()}
            rows={props.Auth0LoginList == null ? [] : props.Auth0LoginList}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            components={{ Toolbar: CclDataGridToolbar }}
            loading={props.isLoading}
            initialState={{ sorting: { sortModel: [{ field: "logDateTime", sort: "desc" }] } }}
        />
    );
};

export default Auth0LoginTable;
