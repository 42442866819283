import * as React from "react";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import EmailTemplatesDataGridPro from "./emailTemplateDataGrid";
import { useGetAllEmailTemplatesQuery, useLazyGetEmailTemplateByIdQuery, useLazyGetEmailTemplatesByKeywordQuery } from "../../services/cclTokenedEmailTemplateApi";
import { Card, Link, Stack, TextField, Typography } from "@mui/material";
import { EmailMessageTemplate } from "../../services/types/emailTemplateTypes";
import CclUnrestrictedLoadingButton from "../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import PageLoader from "../../components/common/pageLoader";
import { Link as RouterLink } from "react-router-dom";

interface TemplatePageProps {}

const TemplatePage: React.FC<TemplatePageProps> = () => {

    const [data, setData] = React.useState<EmailMessageTemplate[]>([]);

    const [searchparam, setSearchParam] = React.useState<string>("");
    const [searchMode, setSearchMode] = React.useState<boolean>(false);
    const [searchId, setSearchId] = React.useState<boolean>(false);
    const [resultBar, setResultBar] = React.useState<boolean>(false);
    const [resultBarText, setResultBarText] = React.useState<string>("");

    React.useEffect(() => {
        window.addEventListener("keydown", e => {
            if (e.key === "Enter") {
                handleSearch();
            }
        });
    })

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Link component={RouterLink} variant="h6" color="text.primary" to="/emails">Emails</Link>
            <Typography variant="h6" color="text.primary">
                Email Templates
            </Typography>
        </SimpleBreadcrumbs>
    );

    const { data: emailTemplates, isLoading}  = useGetAllEmailTemplatesQuery();
    const [search, {data: searchResults, isLoading: searchLoading}] = useLazyGetEmailTemplateByIdQuery();
    const [searchTerm, {data: searchTermResults, isLoading: searchTermLoading}] = useLazyGetEmailTemplatesByKeywordQuery();

    const handleSearch = () => {
        let test = checkValidId(searchparam);
        if (test) {
            setSearchId(true);
            setSearchMode(true);
            search(searchparam);
        } else {
            if(searchparam.length > 0) {
                setSearchId(false);
                setSearchMode(true);
                searchTerm(searchparam);
            }
        }        
    }

    const handleSearchAll = () => {
        setSearchMode(false);
        if (emailTemplates) {
           setData(emailTemplates);
           setResultBar(true);
           setResultBarText(`All email templates retrieved (${emailTemplates.length} total found)`); 
        }
    }

    const checkValidId = (id: string) => {
        if (id.length > 0) {
            //eslint-disable-next-line
            if (!(/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(id)) {
                return false;
            }
        }
        return true;
    }

    React.useEffect(() => {
        if(searchMode) {
            if(searchId && searchResults) {
                let value = [searchResults];
                setData(value);
                setResultBar(true);
                setResultBarText(`${value.length} results for "${searchparam}"`);
            } else {
                if(searchTermResults) {
                    setData(searchTermResults);
                    setResultBar(true);
                    setResultBarText(`${searchTermResults.length} results for "${searchparam}"`);
                }
            }
        } else {
            if (emailTemplates) {
                setData(emailTemplates);
                setResultBar(true);
                setResultBarText(`All email templates retrieved (${emailTemplates.length} total found)`);
            }
        }
        //eslint-disable-next-line
    }, [searchResults, searchTermResults, emailTemplates]);

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Card sx={{ height: 1, width: 1, display: "flex", flexDirection: "column" }}>
                <Stack direction={"row"} sx={{alignItems: "center"}}>
                    <TextField 
                        sx={{m: 2, width: "85%"}}
                        onChange={(e)=> {setSearchParam(e.target.value)}}
                        label="Search Keyword or ID"
                    />
                    <CclUnrestrictedLoadingButton
                        sx={{m: 2, ml: "auto", height: "70%"}}
                        loading={searchLoading || searchTermLoading}
                        disabled={searchparam.length === 0}
                        onClick={handleSearch}
                    >
                        Search
                    </CclUnrestrictedLoadingButton>
                    <CclUnrestrictedLoadingButton
                        loading={isLoading}
                        sx={{m: 2, height: "70%"}}
                        onClick={handleSearchAll}
                    >
                        Get All
                    </CclUnrestrictedLoadingButton>
                </Stack>
                {data && !isLoading && 
                    <EmailTemplatesDataGridPro 
                        emailTemplates={data}
                        isLoading={isLoading}
                        resultBarText={resultBarText}
                        resultBar={resultBar}
                    />
                }

                {isLoading && 
                    <PageLoader msg={"Loading Email Templates..."} />
                }
            </Card>
        </StandardLayout>
    );
};

export default TemplatePage;
