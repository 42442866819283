import * as React from "react";
import { Grid, Typography, FormControlLabel, Switch, Divider, Box } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { Stack } from "@mui/system";
import {
    useGetSystemSummaryQuery,
    useUpdateSystemSummaryMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { Application, SystemSummary } from "../../../../services/types/cclGrandCentralApiTypes";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclUnrestrictedLoadingButton from "../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventApplicationDetails } from "../../../../services/types/accessEventTypes";

interface ApplicationGeneralPanelProps {
    systemId: string | undefined;
    application: Application | undefined;
}

const ApplicationGeneralPanel: React.FC<ApplicationGeneralPanelProps> = (props) => {
    const { data, isError } = useGetSystemSummaryQuery(props.systemId ? props.systemId : "", {
        skip: props.systemId === "",
    });
    const [updateSummary, { isLoading: updateSummaryIsLoading }] = useUpdateSystemSummaryMutation();

    const [contentValue, setcontentValue] = React.useState<string | undefined>(data?.summary);
    const [checked, setChecked] = React.useState(false);
    const userClaimsService = new UserClaimsService();
    const { logEvent } = useLogAccessEvent();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const updateSummaryClick = () => {
        let payload: SystemSummary = {
            systemId: props.systemId ? props.systemId : "",
            summary: contentValue ? contentValue : "",
        };
        updateSummary(payload).unwrap().then(() => {
            const evtData:AccessEventApplicationDetails = {
                applicationId: props.application?.applicationId ?? "Error",
                applicationName: props.application?.name ?? "Error",
            }
            logEvent("ApplicationDetailsEdited", evtData);
        });
    };

    React.useEffect(() => {
        setcontentValue(data?.summary);
    }, [data]);

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <CclGenericAccordian headingText={"Application Summary"}>
                    <Box sx={{ m: 2 }}>
                        <Stack padding={0} margin={0}>
                            {!isError && userClaimsService.IsUserSuperAdmin() && (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="warning"
                                                checked={checked}
                                                onChange={handleChange}
                                            />
                                        }
                                        label="Edit Application Summary"
                                    />
                                </>
                            )}
                            {isError && (
                                <>
                                    <Divider />
                                    <Typography variant="body1">
                                        More information regarding this application will be
                                        implemented soon in future releases.
                                    </Typography>
                                </>
                            )}
                            {!isError}
                            {!isError && !checked && (
                                <>
                                    <Divider />
                                    <Box marginTop={4} margin={3}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    contentValue && contentValue.length > 0
                                                        ? contentValue
                                                        : "This application does not have a summary.",
                                            }}
                                        />
                                    </Box>
                                </>
                            )}
                            {!isError && checked && (
                                <>
                                    <Editor
                                        apiKey="y7y53muqd751xs3r3tgo3wey3uwqehd18hiyp1s2pjdprezv"
                                        onInit={(
                                            evt: any,
                                            editor: { getContent: () => string | undefined }
                                        ) => setcontentValue(editor.getContent()!)}
                                        onEditorChange={(newValue: string | undefined) =>
                                            setcontentValue(newValue)
                                        }
                                        value={contentValue}
                                        init={{
                                            height: 500,
                                            branding: false,
                                            menu: {
                                                format: {
                                                    title: "Format",
                                                    items: "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat",
                                                },
                                            },
                                            plugins: [
                                                "advlist autolink lists link image charmap print preview",
                                                "searchreplace visualblocks code fullscreen",
                                                "insertdatetime table paste code help properties",
                                            ],
                                            toolbar:
                                                "undo redo | formatselect | properties | bold italic fontcolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                                            content_style:
                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                        }}
                                    />
                                    <CclUnrestrictedLoadingButton
                                        onClick={updateSummaryClick}
                                        loading={updateSummaryIsLoading}
                                        sx={{ mt: 2, w: "fit-content", alignSelf: "flex-end" }}
                                    >
                                        Save
                                    </CclUnrestrictedLoadingButton>
                                </>
                            )}
                        </Stack>
                    </Box>
                </CclGenericAccordian>
            </Grid>
        </Grid>
    );
};

export default ApplicationGeneralPanel;
