import * as React from "react";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { Stack } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ComponentLoader from "../../../components/common/componentLoader";
import ApplicationsCardContent from "../../../components/shared/applicationsCardContent/applicationsCardContent";
import InvitationsCardContent from "../../../components/shared/invitationCardContent/invitationsCardContent";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";

interface ApplicationsProps {}

const Applications: React.FC<ApplicationsProps> = () => {
    const claimsService = new UserClaimsService();
    const email = claimsService.GetCurrentUserEmail();

    if (email != null) {
        return (
            <Stack height={1} width={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="resource-applications-content"
                                id="resource-applications-header"
                            >
                                <Typography sx={{ fontWeight: "bolder" }}>Applications</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ApplicationsCardContent email={email} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="resource-invitations-content"
                                id="resource-invitations-header"
                            >
                                <Typography sx={{ fontWeight: "bolder" }}>Invitations</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InvitationsCardContent showInvitationId={false} email={email} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Stack>
        );
    }

    return <ComponentLoader msg={"Loading Applications"} />;
};

export default Applications;
