import * as React from "react";
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import { CclPersistantDataGridMiscButton } from "../cclPersistentDataGrid/cclPersistentDataGrid";
import CclButton from "../cclButtons/cclButton";

//TODO - Resolve disparity between btn.onClick and btnClick, currently there seems to be no need for the btn.onClick prop inside of miscButtons

export interface CclDataGridToolbarProps {
    showSaveState: boolean;
    savestate: () => any;
    clearstate: () => any;
    resultBar?: boolean;
    resultBarText?: string;
    miscButtons?: CclPersistantDataGridMiscButton[];
    btnClick?: (btnId: string) => any;
    hideSettingsButtons?: boolean;
    anySelected: boolean;
}

const CclToolbarBtnSx = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "4px",
    paddingBottom: "4px",
    margin: "5px",
    float: "right",
    fontSize: "12px",
    lineHeight: "21px",
};

const CclDataGridToolbar: React.FC<CclDataGridToolbarProps> = (props) => {
    const handleMiscClick = (btnid: string) => {
        if (props.btnClick != null) props.btnClick(btnid);
    };

    const handleCheckedClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.checked
            ? handleMiscClick(event.target.id + "enable")
            : handleMiscClick(event.target.id + "disable");
    };

    const baseFloat = {
        float: "right",
        margin: "5px",
    };

    return (
        <GridToolbarContainer>
            {!props.showSaveState && (
                <Box sx={{ width: 1 }}>
                    <GridToolbarColumnsButton
                        variant="outlined"
                        sx={{ margin: "5px" }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    />
                    <GridToolbarFilterButton
                        className="mui-filter-button"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    />
                    <GridToolbarDensitySelector
                        variant="outlined"
                        sx={{ m: "5px" }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    />
                    <GridToolbarExport variant="outlined" sx={{ m: "5px" }} />
                    {props.resultBar && <span>{props.resultBarText}</span>}
                </Box>
            )}
            {props.showSaveState && (
                <Box sx={{ width: 1 }}>
                    <GridToolbarColumnsButton
                        variant="outlined"
                        sx={{ margin: "5px" }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    />
                    <GridToolbarFilterButton
                        className="mui-filter-button"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    />
                    <GridToolbarDensitySelector
                        variant="outlined"
                        sx={{ m: "5px" }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    />
                    <GridToolbarExport variant="outlined" sx={{ m: "5px" }} />
                    {props.resultBar && <span>{props.resultBarText}</span>}
                    {props.hideSettingsButtons ? (
                        <>
                            <Box sx={baseFloat}></Box>
                        </>
                    ) : (
                        <>
                            <CclButton sx={CclToolbarBtnSx} onClick={props.savestate} restricted={false} mode={"secondary"}>
                                Save Settings
                            </CclButton>
                            <CclButton sx={CclToolbarBtnSx} onClick={props.clearstate} restricted={false} mode={"secondary"}>
                                Clear Settings
                            </CclButton>
                        </>
                    )}
                    {props.miscButtons &&
                        props.miscButtons.map((btn) =>
                            btn.type === "check" ? (
                                <FormControlLabel
                                    label={btn.caption}
                                    control={
                                        <Checkbox
                                            sx={{ pl: 8 }}
                                            key={btn.id}
                                            id={btn.id}
                                            edge="start"
                                            onChange={handleCheckedClick}
                                            checked={btn.checkedState}
                                            inputProps={{
                                                "aria-labelledby": `coach-release-toggle`,
                                            }}
                                            disabled={
                                                btn.enableOnSelect
                                                    ? !props.anySelected
                                                    : btn.disable
                                            }
                                        />
                                    }
                                />
                            ) : btn.restricted ? (
                                <CclButton
                                    key={btn.id}
                                    id={btn.id}
                                    disabled={btn.enableOnSelect ? !props.anySelected : btn.disable}
                                    sx={CclToolbarBtnSx}
                                    onClick={() => handleMiscClick(btn.id)}
                                    restricted={true}
                                    mode={"primary"}
                                >
                                    {btn.caption}
                                </CclButton>
                            ) : (
                                <CclButton
                                    key={btn.id}
                                    id={btn.id}
                                    disabled={btn.enableOnSelect ? !props.anySelected : btn.disable}
                                    sx={CclToolbarBtnSx}
                                    onClick={() => handleMiscClick(btn.id)}
                                    restricted={false}
                                    mode={"primary"}
                                >
                                    {btn.caption}
                                </CclButton>
                            )
                        )}
                </Box>
            )}
        </GridToolbarContainer>
    );
};

export default CclDataGridToolbar;
