import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Autocomplete,
} from "@mui/material";
import {
    AddRaterRequest,
    RaterDetails,
    SearchRaters,
} from "../../../../services/types/assessmentAPITypes";
import {
    useAddratersMutation,
    useGetLanguagesByInstrumentIdQuery,
    useGetRaterTypesByInstrumentKeyQuery,
    useSearchRatersMutation,
} from "../../../../services/cclTokenedEnterpriseRaterApi";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedButton from "../../../../components/common/cclButtons/cclRestrictedButton";

export type AddRatersDialogProps = {
    open: boolean;
    onOk: () => any;
    onCancel: () => any;
    instrumentId: number;
    esiKey: number;
    preferredLanguageID: number;
    raterList: RaterDetails[];
};

const AddRatersDialog: React.FC<AddRatersDialogProps> = (props) => {
    const [msg, setMsg] = React.useState<string>("");
    const [raterTypeSelect, setRaterTypeSelect] = React.useState<string>("");
    const [languageSelect, setLanguageSelect] = React.useState<string>("");
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [addRatersData, { isSuccess: isSuccessAddRaters }] = useAddratersMutation();
    const { data: RaterTypeList } = useGetRaterTypesByInstrumentKeyQuery(props.instrumentId, {
        skip: props.instrumentId === 0,
    });
    const { data: LanguageListData } = useGetLanguagesByInstrumentIdQuery(
        { Instrument: props.instrumentId, PreferredLanguageId: props.preferredLanguageID },
        { skip: props.instrumentId === 0 }
    );
    const [searchData, { isSuccess: isSuccessSearch, data: searchResults }] =
        useSearchRatersMutation();
    const { logEvent } = useLogAccessEvent();

    React.useEffect(() => {
        setEmail("");
        setFirstName("");
        setLastName("");
        setLanguageSelect("");
        setRaterTypeSelect("");
        setEmailError(false);
    }, [props.open]);

    React.useEffect(() => {
        if (isSuccessSearch) {
            if (searchResults) {
                setFirstName(searchResults.firstName);
                setLastName(searchResults.lastName);
                setLanguageSelect(
                    LanguageListData?.find(
                        (a) => a.languageId === parseInt(searchResults.preferredLanguageId)
                    )?.name ?? "English"
                );
            } else {
                setFirstName("");
                setLastName("");
                setLanguageSelect("");
            }
        }
        //eslint-disable-next-line
    }, [isSuccessSearch, searchResults]);

    React.useEffect(() => {
        if (isSuccessAddRaters) {
            props.onOk();
        }
        //eslint-disable-next-line
    }, [isSuccessAddRaters]);

    const validateEmail = (searchEmail: string) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(searchEmail)) {
            setEmailError(true);
            setMsg("Enter a valid email address");
        } else {
            var isExist = props.raterList.filter(
                (r) =>
                    r.raterEmail === searchEmail &&
                    parseInt(r.participant_ESI_Key) === props.esiKey &&
                    r.instrumentKey === props.instrumentId
            );
            if (isExist.length > 0) {
                setEmailError(true);
                setMsg("Email already exist");
            } else {
                setMsg("");
                setEmailError(false);
                var emailObj: SearchRaters = { email: searchEmail };
                searchData(emailObj);
            }
        }
    };

    const AddRatersFunction = () => {
        var addRatersList: AddRaterRequest[] = [];
        const newObj: AddRaterRequest = {
            email: email,
            firstName: firstName,
            instrumentId: props.instrumentId,
            languageId: LanguageListData?.find((a) => a.name === languageSelect)?.languageId ?? 1,
            lastName: lastName,
            participantId: props.esiKey,
            raterTypeId: RaterTypeList?.find((r) => r.name === raterTypeSelect)?.raterTypeId ?? 0,
        };
        addRatersList.push(newObj);
        addRatersData(addRatersList)
            .unwrap()
            .then(() => {
                logEvent("RaterAdded", null);
            });
    };

    return (
        <Dialog
            fullWidth={true}
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CclGenericConfirmationDialog
                open={open}
                onCancel={() => setOpen(false)}
                onOk={() => {
                    setOpen(false);
                    AddRatersFunction();
                }}
            />
            <DialogTitle id="alert-dialog-title">Add Rater</DialogTitle>
            <DialogContent>
                <TextField
                    error={emailError}
                    helperText={emailError ? msg : ""}
                    onChange={(e: any) => {
                        e.preventDefault();
                        validateEmail(e.target.value);
                        setEmail(e.target.value);
                    }}
                    value={email}
                    id="txtEmail"
                    label="Email"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    onChange={(e: any) => {
                        e.preventDefault();
                        setFirstName(e.target.value);
                    }}
                    value={firstName}
                    disabled={searchResults !== null}
                    id="txtFirstName"
                    label="First Name"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    onChange={(e: any) => {
                        e.preventDefault();
                        setLastName(e.target.value);
                    }}
                    value={lastName}
                    id="txtLastName"
                    disabled={searchResults !== null}
                    label="Last Name"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mt: 2, mb: 2 }}
                />
                <Autocomplete
                    id="Language-DropDown"
                    options={LanguageListData?.map((option) => option.name) ?? []}
                    value={languageSelect}
                    disabled={searchResults !== null}
                    onChange={(e: any, newValue: string | null) => {
                        setLanguageSelect(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Languages"
                            label="Languages"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
                <Autocomplete
                    id="Rater-DropDown"
                    options={RaterTypeList?.map((option) => option.name) ?? []}
                    value={raterTypeSelect}
                    onChange={(e: any, newValue: string | null) => {
                        setRaterTypeSelect(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Rater Types"
                            label="Rater Types"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
            </DialogContent>

            <DialogActions>
                <CclUnrestrictedOutlinedButton id="cancel-btn" onClick={() => props.onCancel()}>
                    Cancel
                </CclUnrestrictedOutlinedButton>
                <CclRestrictedButton
                    id="ok-btn"
                    disabled={
                        emailError ||
                        email === "" ||
                        firstName === "" ||
                        lastName === "" ||
                        raterTypeSelect === "" ||
                        languageSelect === ""
                    }
                    onClick={() => setOpen(true)}
                >
                    Add
                </CclRestrictedButton>
            </DialogActions>
        </Dialog>
    );
};
export default AddRatersDialog;
