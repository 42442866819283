import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextFieldProps,
    Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ApplicationAccount, TenantGroup } from "../../../../services/types/rtkQueryTypes";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export type UserEditApplicationDialogProps = {
    open: boolean;
    onOk: (userAccess: boolean, groups: string[] | undefined, expirationDate: Date | null) => void;
    onCancel: () => any;
    tenantGroups: TenantGroup[];
    userAccount: ApplicationAccount;
    emailAddress: string;
};

const UserEditApplicationDialog: React.FC<UserEditApplicationDialogProps> = (props) => {
    const [enableApplication, setenableApplication] = React.useState<boolean>(
        props.userAccount.enabled
    );
    const [groupAddRemove, setgroupAddRemove] = React.useState<string[] | undefined>(
        props.userAccount.groups
    );
    const [expirationDate, setexpirationDate] = React.useState<Date | null>(
        props.userAccount.expiration
    );
    const [isExpirationError, setisExpirationError] = React.useState<boolean>(false);
    const [openConfirm, setopenConfirm] = React.useState<boolean>(false);

    const handleOk = () => {
        setopenConfirm(false);
        props.onOk(enableApplication, groupAddRemove, expirationDate);
    };

    React.useEffect(() => {
        setenableApplication(props.userAccount.enabled);
        setexpirationDate(props.userAccount.expiration);
        setgroupAddRemove(props.userAccount.groups);
    }, [props.userAccount]);

    return (
        <Dialog
            fullWidth={true}
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{`Edit ${props.userAccount.application} for ${props.emailAddress}`}</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={enableApplication}
                                onChange={(e: any) => setenableApplication(e.target.checked)}
                                name="gilad"
                                sx={{ mt: 2, mb: 2 }}
                            />
                        }
                        label="Enabled"
                    />
                </FormGroup>
                <DatePicker
                    label="Expiration Date"
                    value={expirationDate}
                    onChange={(newValue: Date | null) => {
                        setexpirationDate(newValue);
                    }}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                        params.fullWidth = true;
                        return (
                            <TextField
                                {...params}
                                error={isExpirationError}
                                helperText={isExpirationError ? "Enter a valid date" : ""}
                            />
                        );
                    }}
                    onError={(reason, value) => {
                        if (reason) {
                            setisExpirationError(true);
                        } else {
                            setisExpirationError(false);
                        }
                    }}
                />
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={props.tenantGroups.map((option) => option.name)}
                    value={groupAddRemove}
                    onChange={(e: any, newValue: string[]) => {
                        setgroupAddRemove(newValue);
                    }}
                    // defaultValue={props.applicationGroups}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={props.userAccount.application}
                            placeholder="Groups"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
            </DialogContent>

            <DialogActions>
                <CclGenericConfirmationDialog
                    open={openConfirm}
                    onCancel={() => setopenConfirm(false)}
                    msg={"This will cause changes to be committed to the system."}
                    onOk={handleOk}
                />
                <CclButton id="cancel-btn" onClick={() => props.onCancel()} restricted={false} mode={"secondary"}>
                    Cancel
                </CclButton>
                <CclButton id="ok-btn" onClick={() => setopenConfirm(true)} restricted={false} mode={"primary"}>
                    Save
                </CclButton>
            </DialogActions>
        </Dialog>
    );
};

export default UserEditApplicationDialog;
