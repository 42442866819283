import * as React from "react";
import CclInfoTable from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { SessionDetailType } from "../../../../../services/types/sessionDetailTypes";
import { CclInfoTblSessionInfoTemplate } from "./cclInfoTblSessionInfoTemplate";
import CclGenericAccordian from "../../../../../components/common/cclGenericAccordian";
import { Alert, AlertColor, Box } from "@mui/material";

interface SessionDetailsAccordionProps {
    sessionDetail: SessionDetailType;
}

const SessionDetailsAccordion: React.FC<SessionDetailsAccordionProps> = (props) => {
    function getStatusElement(): JSX.Element {
        let severity: AlertColor = props.sessionDetail.status === "Active" ? "success" : "error";
        return (
            <Box width={1} sx={{ marginRight: "10px" }}>
                <Alert
                    icon={false}
                    severity={severity}
                    sx={{
                        float: "right",
                        padding: "6px",
                        "& .MuiAlert-message": {
                            fontSize: "1.1em",
                            fontWeight: "bold",
                            padding: "1px",
                        },
                    }}
                >
                    {props.sessionDetail.status}
                </Alert>
            </Box>
        );
    }

    return (
        <CclGenericAccordian
            headingText="Session Details"
            defaultExpanded={true}
            summaryRightSideElement={getStatusElement()}
        >
            {props.sessionDetail != null && (
                <CclInfoTable template={CclInfoTblSessionInfoTemplate} data={props.sessionDetail} />
            )}
        </CclGenericAccordian>
    );
};

export default SessionDetailsAccordion;
