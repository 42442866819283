import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
} from "@mui/material";
import * as React from "react";
import { Tenant } from "../../../../services/types/cclGrandCentralApiTypes";

export interface TenantSelectProps {
    tenants: Tenant[];
    onChange: (newId: string) => void;
    selectedTenant: string;
}

export const TenantSelect: React.FC<TenantSelectProps> = (props) => {
    const [selectedTenant, setSelectedTenant] = React.useState<string>(props.selectedTenant);
    const tenantCopy = [...props.tenants];
    const tenantOptions = tenantCopy
        .sort((a: Tenant, b: Tenant) => {
            return a.tenantName.localeCompare(b.tenantName);
        })
        .map((t) => {
            return { val: t.tenantId, option: t.tenantName };
        });

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedTenant(event.target.value);
        props.onChange(event.target.value);
    };

    if (props.tenants.length !== 1) {
        return (
            <FormControl fullWidth>
                <InputLabel id="select-tenant-label">Select Tenant</InputLabel>
                <Select
                    labelId="select-tenant-label"
                    id="select-tenant"
                    value={selectedTenant}
                    label="Select Tenant"
                    onChange={handleChange}
                    disabled={props.tenants.length <= 0}
                >
                    {tenantOptions.map((opt) => (
                        <MenuItem key={opt.val} value={opt.val}>
                            {opt.option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    } else {
        return (
            <FormControl fullWidth>
                <TextField
                    disabled
                    id="tenant"
                    label="Tenant"
                    defaultValue={props.tenants[0].tenantName}
                />
            </FormControl>
        );
    }
};

export default TenantSelect;
