import * as React from "react";
import {
    DataGridPro,
    GridRenderCellParams,
    GridSelectionModel,
    GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Asset } from "../../../services/types/enterpriseParticipantApiTypes";
import CclDownloadWarningDialog from "../../common/cclDownloadWarningDialog";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CclCommonLink from "../../common/cclCommonLink";
import CclDataGridToolbar from "../../common/cclDataGridToolbar/cclDataGridToolbar";

export interface AssetsDataGridProProps {
    assets: Asset[] | undefined;
    handleEvent?: (evt: string, selected: number[]) => void;
    isLoading: boolean;
    readonly: boolean;
}

export const AssetsDataGridPro: React.FC<AssetsDataGridProProps> = (props) => {
    const claimsService = new UserClaimsService();
    const showLinks = claimsService.ShouldSeeAccessInternalLinks();
    const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
    const [showExpirationWarning, setShowExpirationWarning] = React.useState<boolean>(false);

    const COLUMNS = [
        {
            field: "id",
            headerName: "ID",
            type: "string",
            width: 100,
        },
        {
            field: "projectId",
            headerName: "Project ID",
            type: "string",
            renderCell: (params: GridRenderCellParams) =>
                showLinks && params.row.esKey != null ? (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/session/${params.row.esKey}`}
                    />
                ) : (
                    params.value
                ),
            flex: 0.25,
        },
        {
            field: "name",
            headerName: "File",
            flex: 1,
        },
        {
            field: "createdDate",
            headerName: "Uploaded",
            type: "date",
            valueGetter: (params: GridValueGetterParams) => new Date(params.row.createdDate),
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            flex: 0.25,
        },
        {
            field: "isReleased",
            headerName: "Status",
            flex: 0.35,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.value ? "Released" : "Not Released"}`,
            renderCell: (params: GridRenderCellParams) =>
                params.value === "Released" ? (
                    "Released"
                ) : (
                    <>
                        Not Released
                        <ErrorOutlineIcon color="error" fontSize="small" sx={{ ml: 1 }} />
                    </>
                ),
        },
        {
            field: "fileType",
            headerName: "Type",
            type: "string",
            flex: 0.4,
        },
    ];

    const handleEvent = (evt: string): void => {
        if (props.handleEvent !== undefined) {
            if (evt === "download") {
                setShowExpirationWarning(true);
            } else {
                props.handleEvent(evt, selectedIds);
            }
        }
    };

    const completeDownload = () => {
        if (props.handleEvent !== undefined) {
            setShowExpirationWarning(false);
            props.handleEvent("download", selectedIds);
        }
    };

    return (
        <React.Fragment>
            <CclDownloadWarningDialog
                open={showExpirationWarning}
                onOk={() => completeDownload()}
            />
            <DataGridPro
                getRowId={(row) => row.id}
                rows={props.assets == null ? [] : props.assets}
                columns={COLUMNS}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={
                    props.handleEvent !== undefined
                        ? {
                              toolbar: {
                                  showSaveState: true,
                                  hideSettingsButtons: true,
                                  miscButtons: [
                                    {
                                        id: "upload",
                                        caption: "Upload File(s)",
                                        label: "Upload File(s)",
                                        onClick: () => handleEvent("upload"),
                                    },
                                    {
                                          id: "download",
                                          caption: "Download File(s)",
                                          label: "Download File(s)",
                                          disable: selectedIds.length <= 0,
                                          onClick: () => handleEvent("download"),
                                      },
                                  ],
                                  btnClick: (buttonName: string) => handleEvent(buttonName),
                              },
                          }
                        : undefined
                }
                disableSelectionOnClick
                checkboxSelection={!props.readonly}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    setSelectedIds([...ids.map((i) => i as number)]);
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "createdDate", sort: "desc" }],
                    },
                }}
                loading={props.isLoading}
            />
        </React.Fragment>
    );
};

export default AssetsDataGridPro;
