import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Autocomplete,
} from "@mui/material";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { RaterDetails } from "../../../../../services/types/assessmentAPITypes";
import SessionAssessmentDialog from "../../../../registrations/registrationDetail/raterManagement/sessionAssessmentDialog";
import CclUnrestrictedOutlinedButton from "../../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclRestrictedButton from "../../../../../components/common/cclButtons/cclRestrictedButton";

export type SessionParticipantDialogProps = {
    open: boolean;
    onCancel: () => any;
    oK: () => any;
    esKey: number;
    raterList: RaterDetails[];
};

const SessionParticipantDialog: React.FC<SessionParticipantDialogProps> = (props) => {
    const { data: participantListData } = useGetCclParticipantsByEventKeyQuery(
        props.esKey.toString(),
        { skip: props.esKey === 0 }
    );
    const [ConfirmOpen, SetConfirmOpen] = React.useState<boolean>(false);
    const [open, setopen] = React.useState<boolean>(false);
    const [participantName, setParticipantName] = React.useState<string>("");
    const [participantId, setParticipantId] = React.useState<number>(0);

    React.useEffect(() => {
        setParticipantName("");
        setopen(props.open);
    }, [props.open]);

    React.useEffect(() => {
        if (participantName) {
            var Id = participantListData?.find((p) => p.fullName === participantName)?.esiKey;
            setParticipantId(Id ?? 0);
        }
    }, [participantListData, participantName]);

    const handleOk = () => {
        SetConfirmOpen(false);
        props.oK();
    };

    return (
        <Dialog
            fullWidth={true}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Select Participant</DialogTitle>
            <DialogContent>
                <SessionAssessmentDialog
                    raterList={props.raterList}
                    esiKey={participantId}
                    open={ConfirmOpen}
                    oK={() => handleOk()}
                    onCancel={() => SetConfirmOpen(false)}
                />
                <Autocomplete
                    id="Participant-DropDown"
                    options={participantListData?.map((option) => option.fullName) ?? []}
                    value={participantName}
                    onChange={(e: any, newValue: string | null) => {
                        setParticipantName(newValue ?? "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Participant List"
                            label="Participant List"
                            sx={{ mt: 2, mb: 2 }}
                        />
                    )}
                />
            </DialogContent>

            <DialogActions>
                <CclUnrestrictedOutlinedButton id="cancel-btn" onClick={() => props.onCancel()}>
                    Cancel
                </CclUnrestrictedOutlinedButton>
                <CclRestrictedButton
                    id="ok-btn"
                    disabled={participantName === ""}
                    onClick={() => SetConfirmOpen(true)}
                >
                    Ok
                </CclRestrictedButton>
            </DialogActions>
        </Dialog>
    );
};
export default SessionParticipantDialog;
