import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { PersonGrandCentralDocument } from "../../../../services/types/search.service.types";
import CclPersistentDataGrid from "../../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { updateApplicationUsersSearchGridState } from "../../../../app/slices/applicationUsersSearchListTableStateSlice";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../../../components/common/cclCommonLink";

export interface ApplicationUsersDataGridProps {
    users: PersonGrandCentralDocument[];
    isLoading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText?: string;
    editButtonHandler: (userEmail: string) => void;
}

export const ApplicationUsersDataGrid: React.FC<ApplicationUsersDataGridProps> = (props) => {
    const [selectedRow, setSelectedRow] = React.useState<any>(null);

    const claimsService = new UserClaimsService();
    const isSuperAdmin = claimsService.IsUserSuperAdmin();
    function getFullName(params: any) {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    }

    const COLUMNS = [
        {
            field: "imKey",
            headerName: "ID",
            type: "string",
            renderCell: (params: GridRenderCellParams) =>
                params.row.imKey !== "0" ? (
                    <CclCommonLink
                        keyVal={params.value}
                        text={params.value}
                        to={`/user?email=${encodeURI(params.row.accountEmails[0].email)}&imkey=${
                            params.value
                        }`}
                    />
                ) : (
                    ""
                ),
            width: 100,
        },
        {
            field: "name",
            headerName: "User",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) =>
                params.row.imKey !== "0" ? (
                    <CclCommonLink
                        keyVal={params.value}
                        text={params.value}
                        to={`/user?email=${encodeURI(params.row.accountEmails[0].email)}&imkey=${
                            params.row.imKey
                        }`}
                    />
                ) : (
                    ""
                ),
            width: 450,
        },
        {
            field: "accountEmails",
            headerName: "Email",
            valueGetter: (params: any) => {
                return params.row.accountEmails[0].email;
            },
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            width: 220,
        },
        {
            field: "Enabled",
            headerName: "Enabled",
            width: 150,
            valueGetter: (params: any) => {
                return params.row.accountApplications[0].applicationEnabled ? "Yes" : "No";
            },
        },
        {
            field: "accountApplications",
            headerName: "Groups",
            flex: 1,
            valueGetter: (params: any) => {
                return params.row.accountApplications.length > 0
                    ? params.row.accountApplications[0].applicationGroups
                          .map((x: { groupName: any }) => x.groupName)
                          .join(", ")
                    : "";
            },
        },
    ];

    const stateSelector = useSelector((state: any) => state.applicationUsersSearchListTableState);

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={props.users}
            loading={props.isLoading}
            stateSelector={stateSelector}
            userPreferenceId={12}
            getRowIdFn={(row: PersonGrandCentralDocument) => row.imKey}
            actionCreator={updateApplicationUsersSearchGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "email", sort: "asc" }] },
            })}
            miscButtons={
                isSuperAdmin
                    ? [
                          {
                              id: "edit-app-user",
                              caption: "Edit Access",
                              onClick: () =>
                                  props.editButtonHandler(selectedRow[0].accountEmails[0].email),
                              restricted: false,
                              returnSelected: true,
                              disable: !selectedRow || selectedRow?.length !== 1,
                          },
                      ]
                    : []
            }
            resultBar={props.showResultBar ?? false}
            resultBarText={props.resultBarText ?? ""}
            showCheckboxes={true}
            onSelectedRowsChange={(rows: any[] | undefined) => setSelectedRow(rows)}
        />
    );
};

export default ApplicationUsersDataGrid;
