import { Alert, AlertTitle, Box, Card, CardHeader, CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import CclInfoTable from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { useGetCclEventByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import { SessionDetailType } from "../../../../../services/types/sessionDetailTypes";
import { CclInfoTblSessionPopupTemplate } from "./cclInfoTblSessionPopupInfoTemplate";

interface SessionPopupPanelProps {
    sessionId: string;
    toggleLoading: any;
}

const SessionPopupPanel: React.FC<SessionPopupPanelProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });
    const [sessionDetail, setSessionDetail] = React.useState<SessionDetailType | undefined>(
        undefined
    );

    React.useEffect(() => {
        if (data === undefined) {
            setSessionDetail(undefined);
        } else {
            let sd: SessionDetailType = JSON.parse(JSON.stringify(data));
            sd.start = new Date(sd.start.toString().slice(0, -1));
            sd.end = new Date(sd.end.toString().slice(0, -1));
            setSessionDetail(sd);
        }
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        if (isLoading) {
            props.toggleLoading(true);
        } else {
            props.toggleLoading(false);
        }
        // eslint-disable-next-line
    }, [isLoading]);

    if (isLoading) {
        return (
            <Box sx={{ m: 4 }}>
                <Alert severity={"info"} icon={<CircularProgress size={20} />}>
                    <AlertTitle>Loading Session Info...</AlertTitle>
                </Alert>
            </Box>
        );
    }

    if (isSuccess) {
        return (
            <Grid container>
                <Grid item>
                    <Card sx={{ m: 2, border: 1, borderColor: "#EEEEEE", borderRadius: 3 }}>
                        <CardHeader title="Session Details" />
                        {sessionDetail != null && (
                            <CclInfoTable
                                template={CclInfoTblSessionPopupTemplate}
                                data={sessionDetail}
                            />
                        )}
                    </Card>
                </Grid>
            </Grid>
        );
    }
    return <span>No Session Data Loaded</span>;
};

export default SessionPopupPanel;
