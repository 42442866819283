import * as React from "react";
import { StandardLayout } from "../../../layouts/standard";
import PageLoader from "../../../components/common/pageLoader";
import { useParams, Link as RouterLink } from "react-router-dom";
import { EventDocument } from "../../../services/types/search.service.types";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import { Typography, Link, Alert, AlertTitle, Stack } from "@mui/material";
import SessionGeneralPanel from "./general/sessionGeneralPanel";
import SessionParticipantsPanel from "./participant/sessionParticipantsPanel";
import SessionResourcesPanel from "./resources/sessionResourcesPanel";
import { useGetCclEventByEventKeyQuery } from "../../../services/cclTokenedSessionApi";
import DetailPage from "../../../components/common/detailPage/detailPage";
import SessionAssignmentsPanel from "./assignments/sessionAssignmentsPanel";
import SessionDigitalProductsPanel from "./digitalProducts/sessionDigitalProductsPanel";
import SessionDocumentsPanel from "./documents/sessionDocumentsPanel";
import SessionFilesPanel from "./files/sessionFilesPanel";
import SessionHistoryPanel from "./history/sessionHistoryPanel";
import CustomNotificationPanel from "./customNotification/customNotificationPanel";
import CclGenericConfirmationDialog from "../../../components/common/cclGenericConfirmationDialog";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../services/currentUserService/currentUserService";
import { getErrorMsg } from "../../../utilities/rtkQueryErrorHelpers";
import EditNoteIcon from "@mui/icons-material/EditNote";
import useCombinationKeyPress from "../../../hooks/useCombinationKeyPress";
import ScoringStatusPanel from "./assessments/scoringStatus/scoringStatusPanel";
import AssessmentsPanel from "./assessments/assessmentsPanel";

export const SessionContext = React.createContext<EventDocument | null>(null);

export type SessionDetailProps = {
    sessionId: string;
    tabIndex?: string;
};

export const SessionDetail: React.FC = (props) => {
    const params = useParams<SessionDetailProps>();
    const sessionId = params.sessionId !== undefined ? params.sessionId : "";
    const requestedTabIndex = params.tabIndex !== undefined ? params.tabIndex : "";

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [saveNeeded, setSaveNeeded] = React.useState<boolean>(false);
    const [requestedTab, setRequestedTab] = React.useState<number>(0);
    const [actualTab, setActualTab] = React.useState<number>(
        requestedTabIndex ? +requestedTabIndex : 0
    );
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const { data, isLoading, isSuccess, isError, error } = useGetCclEventByEventKeyQuery(
        sessionId,
        { skip: sessionId === "" }
    );
    const detailEl = React.useRef<HTMLDivElement>(null);
    const onKeyDown = (event: KeyboardEvent) => {
        setDrawerOpen(true);
        event.preventDefault();
    };
    useCombinationKeyPress(["j"], onKeyDown, detailEl.current);
    const claimsService = new UserClaimsService();
    const allowJournalEntries =
        claimsService.IsUserCclAdminOrSuperAdmin() ||
        claimsService.IsUserNwaAdmin() ||
        claimsService.IsUserInGroups([eGroupIds.eClientAdmin]);
    const handleSetSaveNeeded = (saveNeeded: boolean) => {
        setSaveNeeded(saveNeeded);
    };

    const isClientAdmin = claimsService.IsUserInGroups([eGroupIds.eClientAdmin]);
    const isProductSale = data?.typeKey === 7 || data?.typeKey === 9;

    const fullTablist: [string, number, React.ReactNode][] = [
        ["Details", 0, <SessionGeneralPanel sessionId={sessionId} readOnly={isClientAdmin} />],
        [
            "Registrations",
            4,
            <SessionParticipantsPanel sessionId={sessionId} readOnly={isClientAdmin} />,
        ],
        ["Assessments", 10, <AssessmentsPanel sessionDetails={data} readOnly={isClientAdmin} />],
        [
            "Files",
            7,
            <SessionFilesPanel
                sessionId={sessionId}
                readOnly={isClientAdmin}
                isProductSale={isProductSale}
            />,
        ],
        [
            "Resources",
            5,
            <SessionResourcesPanel
                sessionId={sessionId}
                setSaveNeeded={handleSetSaveNeeded}
                readOnly={isClientAdmin}
            />,
        ],
        ["Assignments", 3, <SessionAssignmentsPanel sessionId={sessionId} />],
        ["Documents", 6, <SessionDocumentsPanel sessionId={sessionId} />],
        ["Emails", 2, <CustomNotificationPanel sessionId={sessionId} />],
        [
            "Products",
            8,
            <SessionDigitalProductsPanel sessionId={sessionId} readOnly={isClientAdmin} />,
        ],
        ["Logs", 9, <SessionHistoryPanel sessionId={sessionId} drawerOpen={drawerOpen} />],
    ];

    const inactiveTablist: [string, number, React.ReactNode][] = [
        ["Details", 0, <SessionGeneralPanel sessionId={sessionId} readOnly={isClientAdmin} />],
        [
            "Assessments",
            10,
            <ScoringStatusPanel sessionKey={data?.sessionKey} sessionCode={data?.sessionCode} readOnly={isClientAdmin} />,
        ],
        ["Logs", 9, <SessionHistoryPanel sessionId={sessionId} drawerOpen={drawerOpen} />],
    ];

    let tablist = data?.status.toLowerCase() === "active" ? fullTablist : inactiveTablist;

    if (claimsService.IsReadOnly()) {
        tablist = fullTablist.filter((x) => x[0] !== "Notifcations" && x[0] !== "Documents");
    }

    if (
        !claimsService.IsUserInGroups([
            eGroupIds.eCCLSuperAdmin,
            eGroupIds.eCCLAdmin,
            eGroupIds.eNWAAdmin,
        ])
    ) {
        tablist = fullTablist.filter((x) => x[0] !== "Assesments");
    }
    if (!claimsService.IsUserCclAdminOrSuperAdmin()) {
        tablist = fullTablist.filter((x) => x[0] !== "Raters");
    }
    if (isClientAdmin) {
        tablist = fullTablist.filter(
            (x) =>
                x[0] === "Details" ||
                x[0] === "Assessments" ||
                x[0] === "Registrations" ||
                x[0] === "Files" ||
                x[0] === "Resources" ||
                x[0] === "Products" ||
                x[0] === "Logs"
        );
    }

    if (isProductSale) {
        // for product sales we don't need all the tabs
        tablist = fullTablist.filter(
            (x) =>
                x[0] !== "Resources" &&
                x[0] !== "Assignments" &&
                x[0] !== "Documents" &&
                x[0] !== "Emails" &&
                x[0] !== "Products"
        );
    }

    function showErrorAlert(msg: string | undefined) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <div>
                    <Alert severity="error" sx={{ fontSize: 14 }}>
                        <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                        {msg}
                    </Alert>
                </div>
            </StandardLayout>
        );
    }

    const handleTabChangeRequest = (event: React.SyntheticEvent, newValue: number) => {
        if (!saveNeeded) {
            setActualTab(newValue);
            return;
        }
        // save needed so open dialog
        setRequestedTab(newValue);
        setConfirmOpen(true);
    };

    const breadcrumbs = (
        <Stack direction={"row"} spacing={3}>
            <SimpleBreadcrumbs>
                <Typography variant="h6" color="text.primary">
                    <Link key="findSessionLink" component={RouterLink} to="/sessions">
                        Sessions
                    </Link>
                </Typography>
                <Typography variant="h6" color="text.primary">
                    {data != null ? data.title : "Unknown Event"}
                </Typography>
            </SimpleBreadcrumbs>
            {allowJournalEntries && <EditNoteIcon onClick={() => setDrawerOpen(true)} />}
        </Stack>
    );

    if (isLoading) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={"Loading Session Data..."} />
            </StandardLayout>
        );
    }

    if (isError) {
        return showErrorAlert(`Error retrieving ${sessionId} (${getErrorMsg(error)})`);
    }

    if (isSuccess && data != null) {
        return (
            <div ref={detailEl}>
                <CclGenericConfirmationDialog
                    open={confirmOpen}
                    onCancel={() => setConfirmOpen(false)}
                    onOk={() => {
                        setActualTab(requestedTab);
                        setSaveNeeded(false);
                        setConfirmOpen(false);
                    }}
                    msg={"You're changes have not been saved."}
                />
                <DetailPage
                    descriptor={"session"}
                    breadcrumbs={breadcrumbs}
                    tablist={tablist}
                    handleTabChange={handleTabChangeRequest}
                    activeTab={actualTab}
                />
            </div>
        );
    }

    return showErrorAlert(`No session data found for session ${sessionId}`);
};

export default SessionDetail;
