import * as React from "react";
import { Box, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { useLazyGetGroupsByTenantIdQuery } from "../../../../services/cclTokenedGrandCentralApi";
import { Application, Tenant } from "../../../../services/types/cclGrandCentralApiTypes";
import { SendInvitationsRequest } from "../../../../services/types/rtkQueryTypes";
import CclGenericConfirmationDialog from "../../../../components/common/cclGenericConfirmationDialog";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export interface SendInvitationsStepPanelProps {
    application: Application | null;
    tenant: Tenant | null;
    request: SendInvitationsRequest | null;
    onFinalize: () => void;
    onPreviousPanel: () => void;
}

export const SendInvitationsStepPanel: React.FC<SendInvitationsStepPanelProps> = (props) => {
    const tenantName = props.tenant?.tenantName ?? "";
    const [getTenantGroups, { data: tenantGroups }] = useLazyGetGroupsByTenantIdQuery();
    const [selectedTenantGroups, setSelectedTenantGroups] = React.useState<string[]>([]);
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const [confirmMsg, setConfirmMsg] = React.useState<string>("");

    React.useEffect(() => {
        if (props.request == null || props.application == null) return;
        setConfirmMsg(
            `You are about to send ${props.request.recipients.length} invitations for access to ${props.application.name}. Continue?`
        );
    }, [props.request, props.application]);

    React.useEffect(() => {
        if (props.request === null || props.request.groups.length === 0) return;
        getTenantGroups(props.request.tenantId);
    }, [getTenantGroups, props.request]);

    React.useEffect(() => {
        if (props.request === null || tenantGroups === undefined) return;
        const selected = tenantGroups.filter((g) => props.request?.groups.includes(g.groupId));
        setSelectedTenantGroups(selected.map((s) => s.name));
    }, [props.request, tenantGroups]);

    const handleFinalize = () => {
        setConfirmOpen(false);
        props.onFinalize();
    };

    return (
        <React.Fragment>
            <CclGenericConfirmationDialog
                open={confirmOpen}
                title={"Send Invitations?"}
                msg={confirmMsg}
                onCancel={() => setConfirmOpen(false)}
                onOk={handleFinalize}
            />
            <Box
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                    mb: 3,
                }}
            >
                {props.request === null || props.application === null || tenantName === "" ? (
                    <Typography sx={{ m: 2, fontWeight: "bold", textDecoration: "underline" }}>
                        Error: Invitation configuration is not set, please go back to previous tabs
                        and configure invitation settings and recipient list.
                    </Typography>
                ) : (
                    <Stack direction="row">
                        <Box width={1}>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.recipients.length}
                                        secondary="Number of Recipients"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.application.name}
                                        secondary="Application"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={tenantName} secondary="Tenant" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.daysValid}
                                        secondary="Days Invitation Is Valid"
                                    />
                                </ListItem>
                            </List>
                        </Box>
                        <Box width={1}>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            props.request.state === ""
                                                ? "(empty)"
                                                : props.request.state
                                        }
                                        secondary="State"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={selectedTenantGroups?.join(", ")}
                                        secondary="Groups"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.disableEmail ? "Yes" : "No"}
                                        secondary="Disable Email"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.autoAccept ? "Yes" : "No"}
                                        secondary="Auto Accept"
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Stack>
                )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <CclButton
                    disabled={false}
                    onClick={props.onPreviousPanel}
                    sx={{ mr: 1 }} 
                    restricted={false} 
                    mode={"secondary"}                
                >
                    Back
                </CclButton>
                <Box sx={{ flex: "1 1 auto" }} />
                <CclButton
                    disabled={props.request === null}
                    onClick={() => setConfirmOpen(true)} 
                    restricted={false}
                     mode={"primary"}                
                    >
                    Send Invitations
                </CclButton>
            </Box>
        </React.Fragment>
    );
};

export default SendInvitationsStepPanel;
