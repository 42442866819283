import React from "react";
import {
    useGridApiRef,
    DataGridPro,
    GridRowId,
    GridColumnVisibilityModel,
} from "@mui/x-data-grid-pro";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
    useRemoveUserPreferenceMutation,
    useSetUserPreferenceMutation,
} from "../../../services/cclTokenedUserPreferenceApi";
import CclDataGridToolbar from "../cclDataGridToolbar/cclDataGridToolbar";
import InfoIcon from "@mui/icons-material/Info";
import { Dialog, DialogContent, Stack, DialogContentText } from "@mui/material";

export interface CclPersistentDataGridProps {
    columns: any[];
    data: any[];
    loading: boolean;
    stateSelector: any;
    userPreferenceId: number;
    getRowIdFn: any;
    actionCreator: ActionCreatorWithPayload<any, any>;
    hideToolbar?: boolean;
    initState?: string;
    resultBar?: boolean;
    resultBarText?: string;
    miscButtons?: CclPersistantDataGridMiscButton[];
    showCheckboxes?: boolean;
    disableSelectionOnClick?: boolean;
    hideSettingsButtons?: boolean;
    columnVisibility?: GridColumnVisibilityModel;
    exportName?: string;
    onSelectedRowsChange?: (rows?: any[]) => any;
    hideFooter?: boolean;
    autoHeight?: boolean;
    selectionModel?: any;
}

export interface CclPersistantDataGridMiscButton {
    id: string;
    caption: string;
    onClick: (rows?: any[]) => any;
    type?: string;
    checkedState?: boolean; // For checkboxes, otherwise unneeded
    restricted: boolean;
    disable?: boolean;
    returnSelected: boolean;
    enableOnSelect?: boolean;
}

interface IStringIndex {
    [key: string]: any;
}

export const CclPersistentDataGrid: React.FC<CclPersistentDataGridProps> = (props) => {
    const initState =
        props.hideToolbar === true
            ? props.initState == null
                ? undefined
                : JSON.parse(props.initState)
            : props.stateSelector?.TableState == null
            ? undefined
            : JSON.parse(props.stateSelector.TableState);

    const emailClaimKey = "email";
    const imKeyClaimKey = "http://schemas.ccl.org/accounts/claims/ccl-identity/id";

    const [saveUserPref] = useSetUserPreferenceMutation();
    const [deleteUserPref] = useRemoveUserPreferenceMutation();
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const claimsSelector = useSelector((state: any) => state.userClaims);
    const [showSaveState, setShowSaveState] = React.useState<boolean>(
        claimsSelector.claims[emailClaimKey] !== "" && claimsSelector.claims[imKeyClaimKey] !== ""
    );
    const [anyRowsSelected, setAnyRowsSelected] = React.useState<boolean>(false);
    const [showTableStateChanged, setShowTableStateChanged] = React.useState<boolean>(false);
    const [showTableChangeMessage, setShowTableChangeMessage] = React.useState<string>("");
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>(
            props.columnVisibility
                ? props.columnVisibility
                : initState?.columns?.columnVisibilityModel == null
                ? {
                      id: false,
                      brokerId: false,
                  }
                : initState.columns.columnVisibilityModel
        );

    React.useEffect(() => {
        setShowSaveState(
            claimsSelector.claims[emailClaimKey] !== "" &&
                claimsSelector.claims[imKeyClaimKey] !== ""
        );
    }, [claimsSelector]);

    React.useEffect(() => {
        if (apiRef == null || props.hideToolbar) return;
        const selectedRows = apiRef.current.getSelectedRows();
        apiRef.current.selectRows([...selectedRows.keys()], false, true);
        // eslint-disable-next-line
    }, [props.data]);

    function getDataGridState(): any {
        var st: any = apiRef.current.exportState();
        var fullstate = apiRef.current.state;
        if (st.columns == null) st.columns = { columnVisibilityModel: {} };
        st.columns.columnVisibilityModel = columnVisibilityModel;
        st.density = fullstate.density.value;
        st.preferencePanel = undefined;
        return st;
    }

    const handleTableStateChange = (isClearEvt: boolean) => {
        const msg = isClearEvt ? "Clearing table settings..." : "Saving table settings...";

        setShowTableChangeMessage(msg);
        setShowTableStateChanged(true);
    };

    const saveStateUserPref = () => {
        saveUserPref({
            email: claimsSelector.claims[emailClaimKey],
            userId: claimsSelector.claims[imKeyClaimKey],
            data: JSON.stringify(getDataGridState()),
            preferenceId: props.userPreferenceId,
        });
        handleTableStateChange(false);
    };

    const deleteStateUserPref = () => {
        deleteUserPref({
            email: claimsSelector.claims[emailClaimKey],
            userId: claimsSelector.claims[imKeyClaimKey],
            data: "", // unused in remove operation
            preferenceId: props.userPreferenceId,
        });

        // reset view model
        let visibilitymodel: IStringIndex = {};
        props.columns.map((c) => (visibilitymodel[c.field] = true));
        setColumnVisibilityModel(visibilitymodel);
        // clear filters
        apiRef.current.setFilterModel({ items: [] });
        // remove column ordering
        props.columns.map((c, index, arr) => apiRef.current.setColumnIndex(c.field, index));
        // reset density (hard code to 'compact' for now as that's default for access)
        apiRef.current.setDensity("compact");
        // apply init state sort model
        const sm = JSON.parse(props?.initState ?? '{"sorting":{"sortModel":[]}}');
        apiRef.current.setSortModel(sm.sorting.sortModel);
        let st = getDataGridState();
        st.columns.columnVisibilityModel = visibilitymodel;
        dispatch(props.actionCreator(JSON.stringify(st)));
        handleTableStateChange(true);
    };

    const saveStateToStore = () => {
        dispatch(props.actionCreator(JSON.stringify(getDataGridState())));
    };

    const onChangeHandler = props.hideToolbar === true ? undefined : saveStateToStore;

    const onColumnViewModelChangeHandler = (newModel: GridColumnVisibilityModel) => {
        setColumnVisibilityModel(newModel);
        let st = getDataGridState();
        st.columns.columnVisibilityModel = newModel;
        dispatch(props.actionCreator(JSON.stringify(st)));
    };

    const miscButtonClick = (btnid: string) => {
        var btn = props.miscButtons?.find((mb) => mb.id === btnid);
        if (btn == null) return;

        if (!props.hideToolbar && btn.returnSelected) {
            var selectedRowsMap: Map<GridRowId, any> = apiRef.current.getSelectedRows();
            var selectedRows: any[] = [];
            for (let row of Array.from(selectedRowsMap.values())) {
                selectedRows.push(row);
            }
            btn.onClick(selectedRows);
        } else {
            btn.onClick();
        }
    };

    const onSelectedRowsChange = () => {
        var selectedRowsMap: Map<GridRowId, any> = apiRef.current.getSelectedRows();
        var selectedRows: any[] = [];
        for (let row of Array.from(selectedRowsMap.values())) {
            selectedRows.push(row);
        }
        setAnyRowsSelected(selectedRows.length > 0);

        if (props.onSelectedRowsChange == null) return;

        props.onSelectedRowsChange(selectedRows);
    };

    return (
        <React.Fragment>
            <Dialog
                open={showTableStateChanged}
                onClose={() => setShowTableStateChanged(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Stack direction="row" alignItems={"center"}>
                        <InfoIcon color="primary" fontSize="large" sx={{ pr: 1 }} />
                        <DialogContentText id="alert-dialog-description">
                            <span>{showTableChangeMessage}</span>
                        </DialogContentText>
                    </Stack>
                </DialogContent>
            </Dialog>
            <DataGridPro
                getRowId={(row) => props.getRowIdFn(row)}
                rows={props.data}
                columns={props.columns}
                loading={props.loading}
                components={
                    props.hideToolbar === true ? undefined : { Toolbar: CclDataGridToolbar }
                }
                componentsProps={
                    props.hideToolbar === true
                        ? undefined
                        : {
                              toolbar: {
                                  csvOptions: {
                                      allColumns: true,
                                      fileName: props.exportName
                                          ? props.exportName
                                          : "ccl-file.csv",
                                  },
                                  showSaveState: showSaveState,
                                  savestate: saveStateUserPref,
                                  clearstate: deleteStateUserPref,
                                  resultBar: props.resultBar ?? false,
                                  resultBarText: props.resultBarText ?? "",
                                  miscButtons: props.miscButtons,
                                  btnClick: miscButtonClick,
                                  hideSettingsButtons: props.hideSettingsButtons,
                                  anySelected: anyRowsSelected,
                              },
                          }
                }
                initialState={initState}
                columnVisibilityModel={columnVisibilityModel}
                apiRef={props.hideToolbar === true ? undefined : apiRef}
                hideFooter={props.hideFooter ?? true}
                checkboxSelection={props.showCheckboxes ?? false}
                disableSelectionOnClick={props.disableSelectionOnClick ?? false}
                onColumnOrderChange={onChangeHandler}
                onColumnVisibilityModelChange={(newModel) =>
                    onColumnViewModelChangeHandler(newModel)
                }
                onFilterModelChange={onChangeHandler}
                onPinnedColumnsChange={onChangeHandler}
                onSortModelChange={onChangeHandler}
                onSelectionModelChange={() => onSelectedRowsChange()}
                autoHeight={props.autoHeight ?? undefined}
            />
        </React.Fragment>
    );
};

export default CclPersistentDataGrid;
