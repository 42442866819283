import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogTitle, InputAdornment, Stack, TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CclButton from "../../components/common/cclButtons/cclButton";

export type CclEnterNewFacilitatorDialogProps = {
    open: boolean;
    onOk: (email: string) => void;
    onCancel: () => void;
};

const CclEnterNewFacilitatorDialog: React.FC<CclEnterNewFacilitatorDialogProps> = (props) => {
    const [newFacEmail, setNewFacEmail] = React.useState<string>("");
    const [inputError, setInputError] = React.useState<string>("");

    React.useEffect(() => {
        if (props.open) return;
        setNewFacEmail("");
    }, [props.open]);

    const validateEmail = (): boolean => {
        let facEmail: string = newFacEmail.trim();
        let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
        return regex.test(facEmail.replace(/\s/g, ""));
    };

    const handleOk = () => {
        if (validateEmail()) {
            setInputError("");
            props.onOk(newFacEmail.trim());
        } else {
            setInputError("Not a valid email address");
        }
    };

    const handleKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            handleOk();
        }
    };

    return (
        <Dialog
            open={props.open}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="enter-new-facilitator-email"
            onClose={() => setNewFacEmail("")}
        >
            <DialogTitle>
                Change Facilitator
            </DialogTitle>
            <DialogContent >
                <Stack direction="row" alignItems={"center"}>
                    <DialogContentText id="alert-dialog-description" sx={{ width: 1 }}>
                        <TextField
                            name="facilitatorEmail"
                            placeholder="Enter New Facilitator's Email"
                            value={newFacEmail}
                            id="outlined-adornment-email-search"
                            label="Enter New Facilitator's Email"
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            error={inputError !== ""}
                            helperText={inputError}
                            onChange={(e) => {
                                e.preventDefault();
                                setNewFacEmail(e.target.value);
                            }}
                            onKeyDown={(e) => handleKeyPress(e)}
                            sx={{mt: "10px"}}
                        />
                    </DialogContentText>
                </Stack>
            </DialogContent>

            <DialogActions>
                <CclButton id="cancel-btn" onClick={props.onCancel} restricted={false} mode={"secondary"}>
                    Cancel
                </CclButton>
                <CclButton id="ok-btn" onClick={handleOk} restricted={false} mode={"primary"}>
                    Okay
                </CclButton>
            </DialogActions>
        </Dialog>
    );
};

export default CclEnterNewFacilitatorDialog;
