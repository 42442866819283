import * as React from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import CclUnrestrictedOutlinedButton from "../../../../components/common/cclButtons/cclUnrestrictedOutlinedButton";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";

export type AddIdentityDialogProps = {
    gcAccountId: string;
    gcAccountEmail: string;
    open: boolean;
    handleClose: (newIdentity: { providerCode: string; providerId: string } | null) => void;
};

const AddIdentityDialog: React.FC<AddIdentityDialogProps> = (props) => {
    const [newProviderId, setNewProviderId] = React.useState<string>("");

    React.useEffect(() => {
        if (!props.open) setNewProviderId("");
    }, [props.open]);

    return (
        <Dialog
            open={props.open}
            aria-labelledby="add-provider-identity"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>Add Enterprise ID (ImKey)</DialogTitle>
            <DialogContent>
                <TextField
                    value={newProviderId}
                    sx={{ mt: 3, width: 1, maxHeight: "57px" }}
                    label="ImKey"
                    variant="outlined"
                    onChange={(e) => {
                        e.preventDefault();
                        setNewProviderId(e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <CclUnrestrictedOutlinedButton
                    id="cancel-btn"
                    onClick={() => props.handleClose(null)}
                >
                    Cancel
                </CclUnrestrictedOutlinedButton>
                <CclUnrestrictedButton
                    id="save-btn"
                    onClick={() =>
                        props.handleClose({ providerCode: "enterprise", providerId: newProviderId })
                    }
                    disabled={newProviderId === ""}
                >
                    Save
                </CclUnrestrictedButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddIdentityDialog;
